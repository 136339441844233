/**
 * @require ./explorer.js
 */
(function (global) {
    var ExplorerTabs = {};

    var $tabMenu = $('#explorer-tab-menu');
    var $moreMenu = $('#explorer-tab-menu-more');
    var $tabItems = $tabMenu.children('.tab-view-tab').not($moreMenu);
    var $moreItems = $moreMenu.find('.dropdown-item');
    var $optionMenu = $('#explorer-tab-menu-options');

    function generate(availableMenuItems) {
        $tabItems.addClass('hide disabled');
        $moreItems.addClass('hide disabled');

        renderMenu(availableMenuItems);
    }

    function renderMenu(menuItems) {
        menuItems = menuItems || [];

        var tabItems = menuItems.map(renderMenuItem);

        if (menuItems.length) {
            tabItems.push(createMoreItem());
        }

        tabItems.push(createTabMenuOptionsItem());

        $tabMenu.html(tabItems.join(''));

        $moreMenu = $('#explorer-tab-menu-more');
        $optionMenu = $('#explorer-tab-menu-options');

        var optionsSet = !Explorer.GetWithChildLocations() || Explorer.GetWithDeactivatedElements();

        $optionMenu
            .find('> span')
            .toggleClass('items-selected', optionsSet);

        $moreMenu.find('ul').html(menuItems.map(renderMoreMenuItem));

        $moreItems = $moreMenu.find('.dropdown-item');

        $tabMenu.find('img')
            .off('error')
            .on('error', onIconNotFoundError);

        Explorer.ResizeTabMenu();
    }

    function onIconNotFoundError() {
        var $icon = $(this);
        var $button = $icon.parents('.tab-view-tab');
        var menuItem = Explorer.MenuItems[$button.data('tab-id')];

        if (!menuItem) {
            return;
        }

        var defaultIconFilePath = CustomMenuItems.GetDefaultOfficeIconFilePath(menuItem.ActionType);

        if (!defaultIconFilePath) {
            return;
        }

        $icon.attr('src', defaultIconFilePath);
    }

    function renderMenuItem(menuItem) {
        if (!menuItem) {
            return '';
        }

        var currentTab = Explorer.GetCurrentTab();
        var tabIsActive = currentTab && currentTab.OID === menuItem.OID;
        var additionalListItemAttrs = [];

        if (menuItem.ActionType === Enums.CustomMenuItemActionType.ShowIssueReport) {
            additionalListItemAttrs.push('count="0"');
        }

        return [
            '<li class="tab-view-tab{0}" data-tab-id="{1}" '.format(tabIsActive ? ' active' : '', menuItem.OID),
            additionalListItemAttrs.join(' '),
            '>',
                '<img src="{0}" />'.format(menuItem.Icon),
                '<span class="title">{0}</span>'.format(menuItem.Title),
            '</li>'
        ].join('');
    }

    function renderMoreMenuItem(menuItem) {
        if (!menuItem) {
            return '';
        }

        return [
            '<li class="dropdown-item" data-tab-id="{0}" count="0">'.format(menuItem.OID),
                '<span class="title">{0}</span>'.format(menuItem.Title),
            '</li>'
        ].join('');
    }

    function createMoreItem() {
        return [
            '<li id="explorer-tab-menu-more" class="tab-item btn-dropdown">',
                '<div class="title">{0}</div>'.format(i18next.t('tabs.more')),
                '<ul class="dropdown-menu dropdown-menu-right"></ul>',
            '</li>'
        ].join('');
    }

    function createTabMenuOptionsItem() {
        var withChildLocations = Explorer.GetWithChildLocations();
        var withDeactivatedElements = Explorer.GetWithDeactivatedElements();

        return [
            '<li id="explorer-tab-menu-options" class="btn-steal btn-right">',
                '<span>{0}</span>'.format(i18next.t('tabs.options')),
                '<ul class="dropdown-menu dropdown-menu-right dropdown-checkbox-menu">',
                    '<li class="dropdown-item" data-option="showQRCode" count="0">{0}</li>'.format(i18next.t('tabs.showQRCode')),
                    '<li class="dropdown-item dropdown-checkbox-item{0}" data-option="withChildLocations" count="0">{1}</li>'
                        .format(withChildLocations ? ' dropdown-checkbox-item-checked' : '', i18next.t('tabs.withChildLocations')),
                    '<li class="dropdown-item dropdown-checkbox-item{0}" data-option="withDeactivatedElements" count="0">{1}</li>'
                        .format(withDeactivatedElements ? ' dropdown-checkbox-item-checked' : '', i18next.t('tabs.withDeactivatedElements')),
                '</ul>',
            '</li>',
        ].join('');
    }

    ExplorerTabs.Generate = generate;

    return (global.Tabs = ExplorerTabs);
})( window.Explorer || (window.Explorer = {}) );