/**
 * @require ./explorer.js
 */
(function ( global ) {
	var Values = {};

    var elements;
    var visibleParametergroups;

    var $body = $('body');
    var $tree = $('#center-tree');
    var $explorerDashboard = $('#explorer-dashboard');
    var $explorerInfo = $('#explorer-info');
    var $explorerValues = $('#explorer-values');
    var $explorerNews = $('#explorer-news');
    var $explorerIssues = $('#explorer-issues');
    var $explorerDisturbances = $('#explorer-disturbances');
    var $explorerScheduling = $('#explorer-scheduling');
    var $explorerFiles = $('#explorer-files');
    var $explorerArchive = $('#explorer-archive');
    var $properties = $('#properties');
    var $explorerTabMenu = $('#explorer-tab-menu');
    var $explorerOptionMenu = $('#explorer-tab-menu-options');
    var $tableWrapper = $explorerValues.find('.content-wrapper');

    function show(match) {
        var initResult = Explorer.Init(match);

        if (!initResult.CurrentTabIsAvailable) {
            var currentTab = Explorer.GetCurrentTab();

            if (currentTab && initResult.NewTab && initResult.NewTab.OID !== currentTab.OID) {
                Explorer.ShowTab(initResult.NewTab);
                return;
            }
        }

        init();
        updateTable();
    }

    function init() {
        elements = {};
        visibleParametergroups = {};

        $tree.tree(
            'remove-class-from-all-nodes',
            'jquery-tree-node-marker-green jquery-tree-node-marker-yellow jquery-tree-node-marker-red'
        );

        $tableWrapper.empty();

        initTab();
        unbindEvents();
        bindEvents();
        updateTabCounter(Explorer.GetCurrentTab());
    }

    function initTab() {
        $explorerDashboard.addClass('hide');
        $explorerInfo.addClass('hide');
        $explorerValues.removeClass('hide');
        $explorerNews.addClass('hide');
        $explorerIssues.addClass('hide');
        $explorerDisturbances.addClass('hide');
        $explorerScheduling.addClass('hide');
        $explorerFiles.addClass('hide');
        $explorerArchive.addClass('hide');
        $properties.removeClass('active');

        $explorerTabMenu.find('.open').removeClass('open');

        $explorerOptionMenu.removeClass('active');
    }

    function unbindEvents() {
        $body.off('click.hideRecorditem');
        $tableWrapper.off('click.expandCollapse');
        $tableWrapper.off('click.showAnalysis');
        $tableWrapper.off('click.showRecorditem');
    }

    function bindEvents() {
        $tableWrapper.on('click.expandCollapse', '.arrow', onExpandCollapse);
        $tableWrapper.on('click.showAnalysis', '.parametergroup, .parameter', onShowAnalysis);
        $tableWrapper.on('click.showRecorditem', '.recorditem[data-oid]', onShowRecorditem);
    }

    function onExpandCollapse(event) {
        var $parametergroup = $(this).closest('.parametergroup');
        var oid = $parametergroup.data('oid');
        var $parameters = $tableWrapper.find('.parameter[data-parametergroup-oid="{0}"]'.format(oid));

        $parametergroup.toggleClass('expanded');
        $parameters.toggleClass('expanded');

        event.stopPropagation();
        return false;
    }

    function onShowAnalysis() {
        var oid = $(this).data('oid');

        CheckpointAnalysis.Show(oid, null, elements);
    }

    function onShowRecorditem(event) {
        var $this = $(this);
        var $parameter = $this.closest('.parameter');
        var parameterOID = $parameter.data('oid');
        var recorditemOID = $this.data('oid');
        var checkpoint = elements[parameterOID];

        var checkpointGroup = checkpoint.Parent;

        var groupRolesThatMayRecordValues = (checkpointGroup.AdditionalSettings || {}).RolesThatMayRecordValues || [];
        var checkpointRolesThatMayRecordValues = (checkpoint.AdditionalSettings || {}).RolesThatMayRecordValues || [];

        var rolesThatMayRecordValues = groupRolesThatMayRecordValues
            .concat(checkpointRolesThatMayRecordValues)
            .filter(function (roleIdentifier) {
                return Roles.hasOwnProperty(roleIdentifier);
            });

        var rolesAtLocation = ressources.users.getRolesAtLocation(User.OID, CurrentEntity.OID);
        var canModifyCheckpoint = CurrentEntity.Enabled && (!rolesThatMayRecordValues.length || rolesThatMayRecordValues.some(function (roleIdentifier) {
            return rolesAtLocation.includes(roleIdentifier);
        }));

        RecorditemDetails.show({
            OID: recorditemOID,
            $Target: $this,
            $Parent: $tableWrapper,
            $Container: $tableWrapper,
            IsReadonly: canModifyCheckpoint === false,
            OnChanged: function (recorditem) {
                if (checkpoint.hasOwnProperty('LastRecorditem')) {
                    checkpoint.LastRecorditem.Value = recorditem.Value;
                    checkpoint.LastRecorditem.IsModified = true;

                    $this
                        .data('oid', recorditem.OID)
                        .html(Tools.values.render(recorditem, { suppressCategory: true }));

                    $tableWrapper.find('img')
                        .off('error')
                        .on('error', Tools.OnImageNotFound);
                }
            },
            OnCommentsChanged: function (recorditem) {
                $this
                    .data('oid', recorditem.OID)
                    .html(Tools.values.render(recorditem, { suppressCategory: true }));

                $tableWrapper.find('img')
                    .off('error')
                    .on('error', Tools.OnImageNotFound);
            }
        });

        $body.off('click.hideRecorditem');
        $body.on('click.hideRecorditem', RecorditemDetails.hide);

        event.stopPropagation();
        return false;
    }

    function updateTable() {
        $tableWrapper.off('scroll.infiniteScroll');

        Tools.Spinner.show();

        load()
            .then(prepareData, function (xhr) {
                Tools.Spinner.hide();
                Tools.handleHttpError(Enums.HttpActionType.Read, xhr);
            })
            .then(renderData)
            .then(function (data) {
                $tableWrapper.find('img')
                    .off('error')
                    .on('error', Tools.OnImageNotFound);

                Tools.Spinner.hide();

                return data;
            });
    }

    function load() {
        if (CurrentEntity.HasCheckpoints) {
            var params = {
                withlastrecorditem: true,
                withchildelements: true,
                withdeactivatedelements: Explorer.GetWithDeactivatedElements()
            };

            return Tools.http.get('elements/' + CurrentEntity.OID, null, null, params);
        }

        return $.Deferred().resolve().promise();
    }

    function prepareData(data) {
        for (var idx = 0, len = (data || []).length; idx < len; idx++) {
            var elem = data[idx];

            elem = Tools.Escape.Element(elem, true);
            elem.Parameters = [];

            elements[elem.OID] = elem;
        }

        for (var oid in elements) {
            if (!elements.hasOwnProperty(oid)) {
                continue;
            }

            var elem = elements[oid];

            if (!elem.Enabled) {
                continue;
            }

            if (elem.Type < 99) {
                continue;
            }

            if (!elem.hasOwnProperty('ParentOID')) {
                continue;
            }

            if (!elements.hasOwnProperty(elem.ParentOID)) {
                continue;
            }

            elem.Parent = elements[elem.ParentOID];

            if (elem.Parent && elem.Parent.Type !== Enums.elementType.Parametergroup) {
                continue;
            }

            if (filterByUserRoles(elem)) {
                continue;
            }

            if (filterByKeywords(elem)) {
                continue;
            }

            if (elem.Type === Enums.elementType.EMailAddresses &&
                !Tools.IsEMailCpEnabled()) {
                continue;
            }

            if (!visibleParametergroups.hasOwnProperty(elem.Parent.OID)) {
                if (elem.Parent.Attribute === 5) {
                    continue;
                }

                visibleParametergroups[elem.Parent.OID] = true;
            }

            elem.Parent.Parameters.push(elem);
            elem.Parent.Parameters.sort(Tools.SortByPosition);
        }

        return data;
    }

    function filterByUserRoles(elem) {
        if (!elem) {
            return true;
        }

        var rolesThatMaySeeThisElement = ((elem.AdditionalSettings || {}).RolesThatMaySeeThisElement || [])
            .filter(function (roleIdentifier) {
                return Roles.hasOwnProperty(roleIdentifier);
            });;

        if (!rolesThatMaySeeThisElement.length) {
            // Keine Einschränkungen vorhanden
            return false;
        }

        var rolesAtLocation = ressources.users.getRolesAtLocation(User.OID, CurrentEntity.OID);

        return !(rolesAtLocation.length && rolesThatMaySeeThisElement.some(function (roleIdentifier) {
            return rolesAtLocation.includes(roleIdentifier);
        }));
    }

    function filterByKeywords(elem) {
        if (!elem) {
            return true;
        }

        var selectedKeywords = ((Explorer.GetCurrentTab() || {}).Configuration || {}).Keywords || [];

        if (!selectedKeywords.length) {
            return false;
        }

        if (elem.Type >= 100 && elem.Parent && (elem.Parent.Properties || []).length) {
            return !(elem.Parent.Properties || []).some(function (identifier) {
                return selectedKeywords.indexOf(identifier) !== -1;
            });
        }

        return !(elem.Properties || []).some(function (identifier) {
            return selectedKeywords.indexOf(identifier) !== -1;
        });
    }

    function renderData(data) {
        var html = [];
        var groups = Object.keys(visibleParametergroups)
            .map(function (groupIdentifier) {
                return elements[groupIdentifier];
            })
            .sort(Tools.SortByPosition);

        if (!groups.length) {
            html = [
                '<div class="content-panel-row">',
                    '<div class="content-panel empty">',
                        '<p class="text">{0}</p>'.format(i18next.t('explorer.values.noDataAvailable')),
                    '</div>',
                '</div>'
            ];

            $tableWrapper.html(html.join(''));
            return data;
        }

        groups.forEach(function (group) {
            html.push('<div class="parametergroup-wrapper">');
            html.push(renderParametergroup(group));

            (group.Parameters || []).forEach(function (param) {
                html.push(renderParameter(param));
            });

            html.push('</div>');
        });

        $tableWrapper.html(html.join(''));

        return data;
    }

    function renderParametergroup(group) {
        if (!group) {
            return null;
        }

        var html = [];

        html.push(
            '<div class="parametergroup expanded" data-oid="{0}">'.format(group.OID),
                '<div class="arrow"></div>',
                '<div class="color" style="background-color: {0};"></div>'.format(group.Color || '#7E1210'),
                '<span class="parametergroup-title">{0}</span>'.format(group.Title || i18next.t('misc.untitled')),
            '</div>'
        );

        return html.join('');
    }

    function renderParameter(param) {
        if (!param) {
            return null;
        }

        var html = [];

        html.push(
            '<div class="parameter expanded" data-oid="{0}" data-parametergroup-oid="{1}">'
                .format(param.OID, param.ParentOID),
            '<div class="parameter-info">',
            '<span class="parameter-title">{0}</span>'.format(param.Title || i18next.t('misc.untitled'))
        );

        if (param.Description) {
            html.push(
                '<p class="parameter-description">',
                param.Description.replace(/(\r\n)|(\n)|(\r)/ig, '<br>'),
                '</p>'
            );
        }

        html.push('</div>');

        if (param.Type !== Enums.elementType.Info) {
            if (param.LastRecorditem) {
                var category = Properties[param.LastRecorditem.CategoryOID];

                html.push(
                    '<div class="recorditem" data-oid="{0}"'.format(param.LastRecorditem.OID),
                    category ? ' style="border-right: 5px solid {0};"'.format(category.Color) : '',
                    '>'
                );
            } else {
                html.push('<div class="recorditem">');
            }

            html.push(
                Tools.values.render(param.LastRecorditem, {suppressCategory: true, elements: elements}),
                '</div>'
            );
        }

        html.push('</div>');

        return html.join('');
    }

    function updateTabCounter(menuItemConfig) {
        if (!menuItemConfig) {
            return $.Deferred().reject().promise();
        }

        if (!CurrentEntity.HasCheckpoints) {
            return $.Deferred().resolve().promise();
        }

        Explorer.SetTabCounterLoading(menuItemConfig.OID);

        var request = Tools.http.post('custommenuitems/' + menuItemConfig.OID + '/counter/' + CurrentEntity.OID);

        request.then(function (counter) {
            Explorer.SetTabCounter(menuItemConfig.OID, counter || 0);
        }, function (xhr, textStatus) {
            if (textStatus !== 'abort') {
                Explorer.SetTabCounter(menuItemConfig.OID, 0);
            }
        });

        return request;
    }

    function disposeTab() {
        $tableWrapper.empty();
    }

    Values.Show = show;
    Values.UpdateTabCounter = updateTabCounter;
    Values.Dispose = disposeTab;

    return (global.Values = Values);
})( window.Explorer || (window.Explorer = {}) );