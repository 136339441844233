/**
 * @require ./explorer.js
 */
(function ( global ) {
	var Info = {};

    var organizationUnitElements;
    var correctiveActionGroups;
    var visibleMasterdataGroups;

    var $body = $('body');
    var $tree = $('#center-tree');
    var $explorerDashboard = $('#explorer-dashboard');
    var $explorerInfo = $('#explorer-info');
    var $explorerValues = $('#explorer-values');
    var $explorerNews = $('#explorer-news');
    var $explorerIssues = $('#explorer-issues');
    var $explorerDisturbances = $('#explorer-disturbances');
    var $explorerScheduling = $('#explorer-scheduling');
    var $explorerFiles = $('#explorer-files');
    var $explorerArchive = $('#explorer-archive');
    var $properties = $('#properties');
    var $wrapper = $explorerInfo.find('.explorer-info-wrapper');
    var $explorerTabMenu = $('#explorer-tab-menu');
    var $explorerOptionMenu = $('#explorer-tab-menu-options');

    var _formulaResolver;

    function show(match) {
        var initResult = Explorer.Init(match);

        if (!initResult.CurrentTabIsAvailable) {
            var currentTab = Explorer.GetCurrentTab();

            if (currentTab && initResult.NewTab && initResult.NewTab.OID !== currentTab.OID) {
                Explorer.ShowTab(initResult.NewTab);
                return;
            }
        }

        Tools.Spinner.show();

        init();
        DataManager.FormLoader.GetAll()
            .then(load, function (xhr) {
                Tools.Spinner.hide();
                Tools.handleHttpError(Enums.HttpActionType.Read, xhr);
            })
            .then(prepareOrganizationUnitElements, function (xhr) {
                Tools.Spinner.hide();
                Tools.handleHttpError(Enums.HttpActionType.Read, xhr);
            })
            .then(render)
            .then(Tools.Spinner.hide);
    }

    function init() {
        organizationUnitElements = {};
        correctiveActionGroups = {};
        visibleMasterdataGroups = {};

        _formulaResolver = new Formula({
            functions: new Tools.FormulaFunctions({
                ouIdentifier: CurrentEntity.OID
            })
        });
        $tree.tree('remove-class-from-all-nodes', 'jquery-tree-node-marker-green jquery-tree-node-marker-yellow jquery-tree-node-marker-red');

        initTab();
        unbindEvents();
        bindEvents();
    }

    function initTab() {
        $explorerDashboard.addClass('hide');
        $explorerInfo.removeClass('hide');
        $explorerValues.addClass('hide');
        $explorerNews.addClass('hide');
        $explorerIssues.addClass('hide');
        $explorerDisturbances.addClass('hide');
        $explorerScheduling.addClass('hide');
        $explorerFiles.addClass('hide');
        $explorerArchive.addClass('hide');
        $properties.removeClass('active');

        $explorerTabMenu.find('.open').removeClass('open');

        $explorerOptionMenu.removeClass('active');
    }

    function unbindEvents() {
        $explorerInfo.off('click.printForm');
        $explorerInfo.off('click.printMeasure');
        $explorerInfo.off('click.showImage');
        $explorerInfo.off('click.showRecorditem');
        $explorerInfo.off('click.exportMasterdata');
        $body.off('click.hideRecorditem');
    }

    function bindEvents() {
        $explorerInfo.on('click.printForm', '.form', onPrintForm);
        $explorerInfo.on('click.printMeasure', '.measure', onPrintMeasure);
        $explorerInfo.on('click.showImage', '.image', onShowImage);
        $explorerInfo.on('click.showRecorditem', '.parameter', onShowRecorditem);
        $explorerInfo.on('click.exportMasterdata', '.export-master-data-of-current-location', onExportMasterdataOfCurrentLocationClick);
    }

    function onPrintForm() {
        var oid = $(this).data('oid');

        Tools.http.get('elements/' + oid)
            .then(function (form) {
                form = Tools.Escape.Element(form);

                PrintPreview.Show({ Element: form, Type: 'PrintBlankForm' });
            });
    }

    function onPrintMeasure() {
        var oid = $(this).data('oid');

        PrintPreview.Show({ Element: organizationUnitElements[oid], Type: 'PrintBlankForm' });
    }

    function onShowImage() {
        var filename = $(this).data('filename');

        ImageViewer.Init({ filelist: CurrentEntity.Files, indexFilename: filename });
    }

    function onShowRecorditem(event) {
        var $this = $(this);
        var recorditemOID = $this.data('recorditem-oid');
        var checkpointOID = $this.data('parameter-oid');
        var checkpoint = organizationUnitElements[checkpointOID];

        if (!checkpoint) {
            return;
        }

        function updateRecorditem(recorditem) {
            $this.data('recorditem-oid', recorditem.OID);
            $this.find('.recorditem-value').html(Tools.values.render(recorditem, {
                suppressCategory: true,
                suppressInfos: true
            }));
        }

        event.stopPropagation();

        if (!Tools.contains(User.Rights, 'EMD', 'ID')) {
            return;
        }

        if (checkpoint.Type === Enums.elementType.Info) {
            return;
        }

        var checkpointGroup = checkpoint.Parent;

        var groupRolesThatMayRecordValues = (checkpointGroup.AdditionalSettings || {}).RolesThatMayRecordValues || [];
        var checkpointRolesThatMayRecordValues = (checkpoint.AdditionalSettings || {}).RolesThatMayRecordValues || [];

        var rolesThatMayRecordValues = groupRolesThatMayRecordValues
            .concat(checkpointRolesThatMayRecordValues)
            .filter(function (roleIdentifier) {
                return Roles.hasOwnProperty(roleIdentifier);
            });

        var rolesAtLocation = ressources.users.getRolesAtLocation(User.OID, CurrentEntity.OID);
        var canModifyMasterdata = CurrentEntity.Enabled && (!rolesThatMayRecordValues.length || rolesThatMayRecordValues.some(function (roleIdentifier) {
            return rolesAtLocation.includes(roleIdentifier);
        }));

        if (!(recorditemOID || canModifyMasterdata)) {
            return;
        }

        RecorditemDetails.show({
            OID: recorditemOID,
            Checkpoint: checkpoint,
            CreateRecorditem: !recorditemOID,
            $Target: $this,
            $Parent: $wrapper,
            $Container: $explorerInfo,
            OnChanged: updateRecorditem,
            IsReadonly: canModifyMasterdata === false
        });

        $body.off('click.hideRecorditem');
        $body.on('click.hideRecorditem',  RecorditemDetails.hide);
    }

    function onExportMasterdataOfCurrentLocationClick() {
        exportMasterdata({
            LocationOID: CurrentEntity.OID,
            WithChildLocations: false,
            OnlyActive: Explorer.GetWithDeactivatedElements() === false
        });
    }

    function exportMasterdata(config) {
        if (!config) {
            return;
        }

        Tools.Spinner.show();

        Tools.http.post('export/masterdata/', config)
            .then(onExportSuccess)
            .fail(function (xhr) {
                Tools.handleHttpError(Enums.HttpActionType.Read, xhr);
                Tools.Spinner.hide();
            });
    }

    function onExportSuccess(filename) {
        window.location = '{0}analysis/export/{1}'.format(Config.BaseUri, filename);
        Tools.Spinner.hide();
    }

    function load() {
        if (!(CurrentEntity.HasMasterdata || CurrentEntity.HasMeasures)) {
            return $.Deferred().resolve().promise();
        }

        var params = {
            withlastrecorditem: true,
            withchildelements: true,
            withdeactivatedelements: Explorer.GetWithDeactivatedElements()
        };

        return Tools.http.get('elements/' + CurrentEntity.OID, null, null, params);
    }

    function prepareOrganizationUnitElements(data) {
        for (var idx = 0, len = (data || []).length; idx < len; idx++) {
            var elem = data[idx];

            elem = Tools.Escape.Element(elem, true);
            elem.Parameters = [];

            organizationUnitElements[elem.OID] = elem;
        }

        for (var oid in organizationUnitElements) {
            if (!organizationUnitElements.hasOwnProperty(oid)) {
                continue;
            }

            var elem = organizationUnitElements[oid];

            if (!elem.Enabled) {
                continue;
            }

            if (elem.Type < 100) {
                continue;
            }

            if (!elem.hasOwnProperty('ParentOID')) {
                continue;
            }

            if (!organizationUnitElements.hasOwnProperty(elem.ParentOID)) {
                continue;
            }

            elem.Parent = organizationUnitElements[elem.ParentOID];

            if (elem.Type === Enums.elementType.EMailAddresses &&
                !Tools.IsEMailCpEnabled()) {
                continue;
            }

            if (elem.Parent.Attribute === 5) {
                if (!correctiveActionGroups.hasOwnProperty(elem.Parent.OID)) {
                    correctiveActionGroups[elem.Parent.OID] = true;
                }
            } else if (elem.Parent.Type === Enums.elementType.MasterdataGroup) {
                if (!visibleMasterdataGroups.hasOwnProperty(elem.Parent.OID)) {
                    visibleMasterdataGroups[elem.Parent.OID] = true;
                }
            }

            elem.Parent.Parameters.push(elem);
            elem.Parent.Parameters.sort(Tools.SortByPosition);
        }

        return data;
    }

    function render() {
        var html = [];

        var currentTab = Explorer.GetCurrentTab();
        var infoConfig = (currentTab.Configuration || {}).Information || {};

        html.push(renderTitle());

        if (infoConfig.ShowAddress) {
            html.push(renderAddress());
        }

        if (infoConfig.ShowCostCenter) {
            html.push(renderCostCenter());
        }

        if (infoConfig.ShowDescription) {
            html.push(renderDescription());
        }

        if (infoConfig.AdditionalPropertiesConfiguration &&
            infoConfig.AdditionalPropertiesConfiguration.ShowAdditionalProperties) {
            html.push(renderAdditionalProperties(infoConfig.AdditionalPropertiesConfiguration));
        }

        if (infoConfig.MasterDataConfiguration &&
            infoConfig.MasterDataConfiguration.ShowMasterData) {
            html.push(renderMasterdata(infoConfig.MasterDataConfiguration));
        }

        if (infoConfig.ShowKeywords) {
            html.push(renderKeywords());
        }

        if (infoConfig.ShowTeams) {
            html.push(renderTeams());
        }

        if (infoConfig.ShowFiles) {
            html.push(renderFiles());
        }

        if (infoConfig.ShowInfoText) {
            html.push(renderInfoText());
        }

        if (infoConfig.ShowForms) {
            html.push(renderForms());
        }

        if (infoConfig.ShowMeasures) {
            html.push(renderMeasures());
        }

        $wrapper
            .html(html.join(''))
            .find('img')
            .off('error')
            .on('error', Tools.OnImageNotFound);
    }

    function renderFieldset(legend, innerHtml) {
        var html = [
            '<div class="content-panel-row">',
                '<div class="content-panel">',
        ];

        if (!!legend) {
            html.push(
                    '<div class="content-panel-header">',
                        legend,
                    '</div>'
            );
        }

        html.push(
                    innerHtml,
                '</div>',
            '</div>'
        );

        return html.join('');
    }

    function renderTextFieldset(legend, text) {
        return renderFieldset(legend, '<p class="text">{0}</p>'.format(text));
    }

    function renderListFieldset(legend, innerHtml) {
        return renderFieldset(legend, '<ul class="text">{0}</ul>'.format(innerHtml));
    }

    function renderTitle() {
        return '<div class="table-title">' + CurrentEntity.Title + '</div>';
    }

    function renderDescription() {
        return CurrentEntity.Description
            ? renderTextFieldset(
                i18next.t('explorer.info.description'),
                CurrentEntity.Description.replace(/(\r\n)|(\n)|(\r)/ig, '<br>'))
            : '';
    }

    function renderAddress() {
        if (!CurrentEntity.Address) {
            return '';
        }

        var markup = [];

        if (CurrentEntity.Address.Street && CurrentEntity.Address.StreetNumber) {
            markup.push(Tools.escapeHtml(CurrentEntity.Address.Street) + ' ' + Tools.escapeHtml(CurrentEntity.Address.StreetNumber) + '<br>');
        }

        if (CurrentEntity.Address.ZipCode && CurrentEntity.Address.City) {
            markup.push(CurrentEntity.Address.ZipCode + ' ' + Tools.escapeHtml(CurrentEntity.Address.City) + '<br>');
        }

        if (CurrentEntity.Address.Phone) {
            markup.push(Tools.escapeHtml(CurrentEntity.Address.Phone) + '<br>');
        }

        if (CurrentEntity.Address.WebAddress) {
            var webAddress = Tools.escapeHtml(CurrentEntity.Address.WebAddress);

            if (!/^http(s)?:\/\//.test(webAddress)) {
                webAddress = 'http://' + webAddress;
            }

            markup.push('<a href="' + webAddress + '" class="link" target="_blank">' + webAddress + '</a>');
        }

        return markup.length
            ? renderTextFieldset(
                i18next.t('explorer.info.address'),
                markup.join(''))
            : '';
    }

    function renderCostCenter() {
        var costCenter, parent;

        parent = CurrentEntity;

        while (parent) {
            if (parent.CostCenter) {
                costCenter = parent.CostCenter;
                break;
            }

            parent = parent.Parent;
        }

        return costCenter
            ? renderTextFieldset(
                i18next.t('explorer.info.costCenter'),
                costCenter.replace(/(\r\n)|(\n)|(\r)/ig, '<br>'))
            : '';
    }

    function renderTeams() {
        var teams = $.map(CurrentEntity.Teams || [], function (elemTeam) {
            if (Teams.hasOwnProperty(elemTeam.OID)) {
                return Teams[elemTeam.OID].Title;
            }
        });

        if (!teams.length) {
            return '';
        }

        teams.sort(Tools.SortStringArray);

        var html = $.map(teams, function (title) {
            return '<li>{0}</li>'.format(title);
        });

        return renderListFieldset(
            i18next.t('explorer.info.teams'),
            html.join(''));
    }

    function renderInfoText() {
        if (!CurrentEntity.InfoText) {
            return '';
        }

        return renderListFieldset(
            i18next.t('explorer.info.infoText'),
            DOMPurify.sanitize(CurrentEntity.InfoText)
        );
    }

    function renderForms() {
        var forms = $.map(CurrentEntity.Forms || [], function (oid) {
            if (DataManager.FormLoader.Data.DataMap.hasOwnProperty(oid)) {
                return DataManager.FormLoader.Data.DataMap[oid];
            }
        });

        if (!forms.length) {
            return '';
        }

        forms.sort(Tools.SortByTitle);

        var html = $.map(forms, function (form) {
            return '<li class="link form" data-oid="{0}">{1}</li>'
                .format(form.OID, form.Title);
        });

        return renderListFieldset(
            i18next.t('explorer.info.forms'),
            html.join(''));
    }

    function renderMeasures() {
        var measures = Object.keys(correctiveActionGroups || {}).map(function (oid) {
            return organizationUnitElements[oid];
        });

        if (!measures.length) {
            return '';
        }

        measures.sort(Tools.SortByTitle);

        var html = $.map(measures, function (measure) {
            return '<span class="link measure" data-oid="{0}">{1}</span>'
                .format(measure.OID, measure.Title);
        });

        return renderTextFieldset(
            i18next.t('explorer.info.measures'),
            html.join(', '));
    }

    function renderKeywords() {
        var keywords, html;

        keywords = $.map(CurrentEntity.Properties || [], function (oid) {
            if (Properties.hasOwnProperty(oid) && Properties[oid].Type === Enums.propertyType.Keyword) {
                return Properties[oid].Title;
            }
        });

        if (!keywords.length) {
            return '';
        }

        keywords.sort(Tools.SortStringArray);

        html = $.map(keywords, function (title) {
            return '<li>{0}</li>'.format(title);
        });

        return renderListFieldset(
            i18next.t('explorer.info.keywords'),
            html.join(''));
    }

    function renderAdditionalProperties(config) {
        if (!config) {
            return '';
        }

        var additionalProperties = Tools.element.prepareAdditionalProperties(CurrentEntity.AdditionalProperties);

        if (!additionalProperties.length) {
            return '';
        }

        var markup = [];
        var applyFilter = (config.Keywords || []).length;
        var excludeMatchedProperties = applyFilter && config.ExcludeMatchedEntities;

        for (var gCnt = 0, gLen = additionalProperties.length; gCnt < gLen; gCnt++) {
            var group = additionalProperties[gCnt];

            if (!(group.Children || []).length) {
                continue;
            }

            var groupHasKeywordIntersection = applyFilter ?
                Tools.HasIntersection(group.Keywords, config.Keywords) :
                true;

            if (excludeMatchedProperties && groupHasKeywordIntersection) {
                continue;
            }

            var properties = groupHasKeywordIntersection ? group.Children : group.Children.filter(
                child => Tools.HasIntersection(child.Keywords, config.Keywords) !== excludeMatchedProperties
            );

            if (properties.length === 0) {
                continue;
            }

            markup.push(
                renderFieldset(
                    group.Title || i18next.t('misc.untitled'),
                    properties.map(property => {
                        return [
                            '<div class="property">',
                                property.Type !== Enums.additionalPropertyType.Info ?
                                    ('<div class="property-title">' + (property.Title || i18next.t('misc.untitled')) + '</div>') :
                                    '',
                                '<div class="property-value">', property.Value, '</div>',
                            '</div>'
                        ].join('');
                    }).join('')
                )
            );
        }

        return markup.join('');
    }

    function renderMasterdata(config) {
        var markup = [];
        var masterdataGroups = Object.keys(visibleMasterdataGroups)
            .map(function (groupIdentifier) {
                return organizationUnitElements[groupIdentifier];
            })
            .filter(filterByUserRoles)
            .sort(Tools.SortByPosition);

        var applyFilter = (config.Keywords || []).length;
        var excludeMatchedProperties = applyFilter && config.ExcludeMatchedEntities;

        for (var gCnt = 0, gLen = masterdataGroups.length; gCnt < gLen; gCnt++) {
            var group = masterdataGroups[gCnt];
            var groupHasKeywordIntersection = applyFilter ?
                Tools.HasIntersection(group.Properties, config.Keywords) :
                true;

            if (excludeMatchedProperties && groupHasKeywordIntersection) {
                continue;
            }

            var checkpoints = groupHasKeywordIntersection ? group.Parameters : group.Parameters.filter(
                child => Tools.HasIntersection(child.Properties, config.Keywords) !== excludeMatchedProperties &&
                    filterByUserRoles(child) &&
                    (child.Type !== Enums.elementType.EMailAddresses || Tools.IsEMailCpEnabled())
            );

            if (checkpoints.length === 0) {
                continue;
            }

            markup.push(
                renderFieldset(
                    group.Title || i18next.t('misc.untitled'),
                    checkpoints.map(checkpoint => {
                        return [
                            '<div class="parameter link" data-parameter-oid="{0}"'.format(checkpoint.OID),
                            checkpoint.LastRecorditem ? (' data-recorditem-oid="' + checkpoint.LastRecorditem.OID + '"') : '',
                            '>',
                                '<div class="grid-row">',
                                    checkpoint.Type === Enums.elementType.Info ?
                                        renderInfoCheckpoint(checkpoint) :
                                        renderGatherableCheckpoint(checkpoint),
                                '</div>',
                            '</div>'
                        ].join('');
                    }).join('')
                )
            );
        }

        return markup.join('');
    }

    function renderInfoCheckpoint(checkpoint) {
        if (!checkpoint) {
            return;
        }

        var description = (checkpoint.Description || '-/-')
            .replace(/(\r\n)|(\n)|(\r)/ig, '<br>');

        return '<div class="grid-col-2-2 recorditem-value">' + description + '</div>';
    }

    function renderGatherableCheckpoint(checkpoint) {
        if (!checkpoint) {
            return;
        }

        var html = [];

        html.push(
            '<div class="grid-col-8-36 parameter-title">',
                checkpoint.Title,
            '</div>'
        );

        var value = checkpoint.LastRecorditem ? Tools.values.render(
            checkpoint.LastRecorditem,
            {suppressCategory: true, suppressInfos: true, elements: organizationUnitElements}
        ) : '-/-';

        html.push(
            '<div class="grid-col-28-36 recorditem-value">',
                value,
            '</div>'
        );

        return html.join('');
    }

    function filterByUserRoles(elem) {
        if (!elem) {
            return false;
        }

        var rolesThatMaySeeThisElement = ((elem.AdditionalSettings || {}).RolesThatMaySeeThisElement || [])
            .filter(function (roleIdentifier) {
                return Roles.hasOwnProperty(roleIdentifier);
            });;

        if (!rolesThatMaySeeThisElement.length) {
            // Keine Einschränkungen vorhanden
            return true;
        }

        var rolesAtLocation = ressources.users.getRolesAtLocation(User.OID, CurrentEntity.OID);

        return rolesAtLocation.length && rolesThatMaySeeThisElement.some(function (roleIdentifier) {
            return rolesAtLocation.includes(roleIdentifier);
        });
    }

    function renderFiles() {
        if (!(CurrentEntity.Files || []).length) {
            return '';
        }

        CurrentEntity.Files.sort(function (a, b) {
            return Tools.SortAssignedFilesFromCatalogue(a, b, true);
        });

        var files = $.map(CurrentEntity.Files || [], function (elemFile) {
            if (Files.hasOwnProperty(elemFile.OID)) {
                return Files[elemFile.OID];
            }
        });

        if (!files.length) {
            return '';
        }

        var html = [
            '<table>',
                '<colgroup>',
                    '<col style="width: 75px;" />',
                '</colgroup>',
                '<tbody>',
                    $.map(files, function (file) {
                        return /image\//.test(file.MimeType)
                            ? renderImage(file)
                            : renderFile(file);
                    }).join(''),
                '</tbody>',
            '</table>'
        ];

        return renderFieldset(i18next.t('explorer.info.files'), html.join(''));
    }

    function renderImage(file) {
        var html = [];
        var src = '{0}images/s/{1}'.format(Config.BaseUri, file.Filename);

        html.push(
            '<tr class="link image" data-filename="{0}">'.format(file.Filename),
                '<td><img src="{0}">'.format(src),
                '<td>',
                    '<span class="file-title" title="{0}">{0}</span>'.format(file.Title || i18next.t('misc.untitled')),
                '</td>',
            '</tr>'
        );

        return html.join('');
    }

    function renderFile(file) {
        var cssClass, href;
        var thumbnailHtml;

        if (file.Type === Enums.FileType.File) {
            if ((file.MimeType || '').contains('pdf')) {
                thumbnailHtml = '<img src="'+ Config.BaseUri + 'images/s/' + file.Filename.replace('.pdf', '.png') + '" />';
            } else {
                cssClass = Tools.Files.getFileIconForMimeType(file.MimeType);
                thumbnailHtml = '<div class="file-type-icons '+ cssClass +'"></div>';
            }

            var sanitizedFilename = Tools.Files.getSanitizedFilename(file.Title || file.Filename);

            href = '{0}files/{1}?friendlyDownloadName={2}'.format(Config.BaseUri, file.Filename, sanitizedFilename);
        } else if (file.Type === Enums.FileType.Link) {
            cssClass = 'file-link';

            try {
                href = !!file.URL.startsWith('"') || Formula.containsFormulaFunctions(file.URL) ?
                    _formulaResolver.evaluate(file.URL) :
                    file.URL;
            }
            catch (e) {
                href = file.URL;
            }

            thumbnailHtml = '<div class="file-type-icons '+ cssClass +'"></div>';
        } else if (file.Type === Enums.FileType.YoutubeVideo) {
            cssClass = 'youtube-video';
            href = 'https://www.youtube.com/watch?v=' + file.VideoID;
            thumbnailHtml = '<div class="file-type-icons '+ cssClass +'"></div>';
        } else if (file.Type === Enums.FileType.VimeoPrivateVideo || file.Type === Enums.FileType.VimeoPublicVideo) {
            cssClass = 'vimeo-video';
            href = 'https://vimeo.com/' + file.VideoID;
            thumbnailHtml = '<div class="file-type-icons '+ cssClass +'"></div>';
        }

        var html = [
            '<tr>',
                '<td>',
                    '<a href="{0}" target="_blank" class="link file">'.format(encodeURI(href)),
                        thumbnailHtml,
                    '</a>',
                '</td>',
                '<td>',
                    '<a href="{0}" target="_blank" class="link file">'.format(encodeURI(href)),
                        '<span class="file-title" title="{0}">{0}</span>'.format(file.Title || i18next.t('misc.untitled')),
                    '</a>',
                '</td>',
            '</tr>'
        ];

        return html.join('');
    }

    function disposeTab() {
        organizationUnitElements = {};
        visibleMasterdataGroups = {};

        $wrapper.empty();
    }

    Info.Show = show;
    Info.Dispose = disposeTab;

    return (global.Info = Info);
})( window.Explorer || (window.Explorer = {}) );