/**
 * @require ./explorer.js
 */
(function (global) {
    var Disturbances = {};

    var panels, currentPanel;
    var issueRecorditemDisturbances, recorditemDisturbances;
    var disturbanceRequest, hideNonResponsibleIssues;
    var IsFilterVisible;
    var userCanAssignAllTeamsAndUsers;
    var users, teams;
    var visibleTeams, selectableTeams;

    var _cachedElements;

    var types;

    var $body = $('body');
    var $tree = $('#center-tree');
    var $explorerDashboard = $('#explorer-dashboard');
    var $explorerInfo = $('#explorer-info');
    var $explorerValues = $('#explorer-values');
    var $explorerNews = $('#explorer-news');
    var $explorerIssues = $('#explorer-issues');
    var $explorerDisturbances = $('#explorer-disturbances');
    var $explorerScheduling = $('#explorer-scheduling');
    var $explorerFiles = $('#explorer-files');
    var $explorerArchive = $('#explorer-archive');
    var $properties = $('#properties');
    var $explorerTabMenu = $('#explorer-tab-menu');
    var $explorerOptionMenu = $('#explorer-tab-menu-options');
    var $tableView = $explorerDisturbances.find('.table-view');
    var $tableWrapper = $explorerDisturbances.find('.tblContentWrapper');
    var $tableHeader = $explorerDisturbances.find('.tbl_header');
    var $tableBody = $tableWrapper.find('.table');
    var $action = $('#explorer-disturbances-action');
    var $typeFilter = $('#explorer-disturbances-type-filter');
    var $formFilter = $('#explorer-disturbances-form-filter');
    var $schedulingFilter = $('#explorer-disturbances-scheduling-filter');
    var $priorityFilter = $('#explorer-disturbances-priority-filter');
    var $resetFilters = $('#explorer-disturbances-reset-filters');
    var $creatorFilter = $('#explorer-disturbances-creator-filter');
    var $userFilter = $('#explorer-disturbances-user-filter');
    var $teamFilter = $('#explorer-disturbances-team-filter');
    var $contactFilter = $('#explorer-disturbances-contact-filter');
    var $contactGroupFilter = $('#explorer-disturbances-contact-group-filter');
    var $keywordFilter = $('#explorer-disturbances-keyword-filter');
    var $classificationFilter = $('#explorer-disturbances-classification-filter');
    var $statusFilter = $('#explorer-disturbances-status-filter');
    var $periodFilter = $('#explorer-disturbances-period-filter');
    var $search = $('#explorer-disturbances-search');
    var $cbHideNonResponsibleIssues = $('#cb-explorer-disturbances-hide-non-responsible-issues');
    var $hideNonResponsibleIssues = $('#explorer-disturbances-hide-non-responsible-issues-lbl');
    var $disturbanceAction = $('#explorer-disturbances .disturbance-action');
    var $toggleControls = $('#explorer-disturbances-toggle-controls');
    var activeFilters = {
        TypeFilter: false,
        FormFilter: false,
        SchedulingFilter: false,
        PriorityFilter: false,
        CreatorsFilter: false,
        UserFilter: false,
        TeamFilter: false,
        ContactFilter: false,
        ContactGroupFilter: false,
        KeywordFilter: false,
        ClassificationFilter: false,
        StatusFilter: false,
        PeriodFilter: false,
        HideNonResponsibleIssues: false
    };

    var disturbanceActionType = {
        CreationAction: 1,
        CompleteDisturbances: 2,
        EditIssues: 3
    };

    var searchTimeout;

    var Disturbance = (function () {
        function Disturbance() {
        }

        Disturbance.prototype.prepareUsers = function (users) {
            users = $.map(users || [], function (oid) {
                return Users[oid];
            });

            users.sort(function (a, b) {
                var titleA = a.Title.toLowerCase();
                var titleB = b.Title.toLowerCase();

                if (titleA === titleB) {
                    return 0;
                }

                return titleA < titleB ? -1 : 1;
            });

            return users;
        };

        Disturbance.prototype.prepareTeams = function (teams) {
            teams = $.map(teams || [], function (oid) {
                return Teams[oid];
            });

            teams.sort(function (a, b) {
                var titleA = a.Title.toLowerCase();
                var titleB = a.Title.toLowerCase();

                if (titleA === titleB) {
                    return 0;
                }

                return titleA < titleB ? -1 : 1;
            });

            return teams;
        };

        Disturbance.prototype.prepareRecipients = function (notificationRecipients) {
            var recipients = [];

            if (notificationRecipients) {
                recipients = $.extend([], notificationRecipients.Users);

                (notificationRecipients.Teams || []).forEach(function (teamOID) {
                    var team = Teams[teamOID];

                    if (!team) {
                        return;
                    }

                    (team.Users || []).forEach(function (user) {
                        recipients = Tools.addUnique(recipients, user.OID);
                    });
                });

                (notificationRecipients.Unsubscribed || []).forEach(function (userOID) {
                    recipients = Tools.remove(recipients, userOID);
                });
            }

            return recipients;
        };

        Disturbance.prototype.prepareComments = function (comments) {
            comments = $.map(comments || [], function (comment) {
                comment.CreationTimestamp = new Date(comment.CreationTimestamp);

                if (Users.hasOwnProperty(comment.CreatorOID)) {
                    comment.Creator = Tools.clone(Users[comment.CreatorOID]);
                    comment.Creator.Image = Files[comment.Creator.ImageOID];
                }

                return comment;
            });

            comments.sort(function (a, b) {
                return a.CreationTimestamp.getTime() - b.CreationTimestamp.getTime();
            });

            return comments;
        };

        Disturbance.Types = {
            IssueRecorditemDisturbance: 1,
            RecorditemDisturbance: 2
        };

        return Disturbance;
    }());

    var IssueDisturbance = (function () {
        function IssueDisturbance(issue) {
            this.OID = issue.OID;
            this.ID = issue.ID;
            this.Revision = issue.Revision;
            this.Type = issue.Type;
            this.Title = issue.Title;
            this.ModificationTimestamp = new Date(issue.ModificationTimestamp);
            this.DeadlineTimestamp = issue.DeadlineTimestamp ? new Date(issue.DeadlineTimestamp) : null;
            this.TerminationTimestamp = issue.TerminationTimestamp ? new Date(issue.TerminationTimestamp) : null;
            this.Editor = Users[issue.EditorOID];
            this.State = Properties[issue.StateOID];
            this.AssignedElementOID = issue.AssignedElementOID;
            this.AssignedFormOID = issue.AssignedFormOID;
            this.OrganizationUnit = DataManager.OrganizationUnitLoader.Data.DataMap[this.AssignedElementOID];
            this.Files = issue.Files;
            this.ResponsibilityAssignments = issue.ResponsibilityAssignments;
            this.Comments = this.prepareComments(issue.Comments);
            this.Recipients = this.prepareRecipients(issue.NotificationRecipients);

            if (issue.InvestigationStatusInformation) {
                this.InvestigationStatusInformation = issue.InvestigationStatusInformation;
            }
        }

        IssueDisturbance.prototype = new Disturbance();

        IssueDisturbance.prototype.constructor = IssueDisturbance;

        IssueDisturbance.prototype.getIcon = function () {
            switch (this.Type) {
                case Enums.issueType.Note:
                    return './img/notes.svg';
                case Enums.issueType.Disturbance:
                    return './img/disturbance_reported.svg';
                case Enums.issueType.Inspection:
                    return './img/jobsite.svg';
                case Enums.issueType.Investigation:
                case Enums.issueType.Form:
                case Enums.issueType.Survey:
                    return './img/form.svg';
                case Enums.issueType.Resubmission:
                    return './img/checklist.svg';
                default:
                    return './img/task.svg';
            }
        };

        IssueDisturbance.prototype.getAbbreviation = function () {
            switch (this.Type) {
                case Enums.issueType.Note:
                    return i18next.t('issueTypes.note_abbreviation');
                case Enums.issueType.Disturbance:
                    return i18next.t('issueTypes.disturbance_abbreviation');
                case Enums.issueType.Inspection:
                    return i18next.t('issueTypes.inspection_abbreviation');
                case Enums.issueType.Investigation:
                    return i18next.t('issueTypes.investigation_abbreviation');
                case Enums.issueType.Survey:
                    return i18next.t('issueTypes.survey_abbreviation');
                default:
                    return i18next.t('issueTypes.task_abbreviation');
            }
        };

        IssueDisturbance.prototype.getElementPath = function () {
            return Explorer.CreateOrganisationUnitPath(this.AssignedElementOID);
        };

        IssueDisturbance.prototype.getImage = function () {
            var files = this.Files || [];
            var i, len;

            for (i = 0, len = files.length; i < len; i++) {
                if (files[i].MimeType && files[i].MimeType.substr(0, 6) === 'image/') {
                    return files[i];
                }
            }

            return null;
        };

        return IssueDisturbance;
    }());

    var RecorditemDisturbance = (function () {
        function RecorditemDisturbance(recorditem) {
            this.OID = recorditem.OID;
            this.ID = recorditem.ID;
            this.Revision = recorditem.Revision;
            this.Type = recorditem.Type;
            this.ModificationTimestamp = new Date(recorditem.ModificationTimestamp);
            this.Editor = Users[recorditem.EditorOID];
            this.Value = recorditem.Value;
            this.Category = Properties[recorditem.CategoryOID];
            this.Element = recorditem.Element;
            this.Files = recorditem.AdditionalFiles;
            this.Comments = this.prepareComments(recorditem.Comments);
            this.HasAdditionalFiles = recorditem.HasAdditionalFiles || false;
            this.HasComments = recorditem.HasComments || false;
            this.HasCorrectiveActions = recorditem.HasCorrectiveActions || false;

            if (!!recorditem.WorkflowInformation) {
                this.WorkflowInformation = Tools.escapeHtml(recorditem.WorkflowInformation);
            }

            if (!!recorditem.AdditionalText) {
                this.AdditionalText = Tools.escapeHtml(recorditem.AdditionalText);
            }

            Tools.Escape.Recorditem(this);
        }

        RecorditemDisturbance.prototype = new Disturbance();

        RecorditemDisturbance.prototype.constructor = RecorditemDisturbance;

        RecorditemDisturbance.prototype.getElementPath = function () {
            return Explorer.CreateOrganisationUnitPath(this.Element.OID, _cachedElements);
        };

        RecorditemDisturbance.prototype.getOrganizationUnit = function () {
            if (this.OrganizationUnit) {
                return this.OrganizationUnit;
            }

            if (!this.Element) {
                return null;
            }

            var parent = this.Element;

            while ((parent = _cachedElements[parent.ParentOID])) {
                if (parent.Type === Enums.elementType.Root || parent.Type === Enums.elementType.Location) {
                    this.OrganizationUnit = parent;
                    break;
                }
            }

            return this.OrganizationUnit;
        };

        return RecorditemDisturbance;
    }());

    var Panel = (function () {
        function Panel() {
            this.Filter = {};
            this.IsAllSelected = false;
        }

        Panel.prototype.resetFilters = function () {
            $resetFilters.removeClass('active');

            activeFilters = {
                TypeFilter: false,
                FormFilter: false,
                SchedulingFilter: false,
                PriorityFilter: false,
                UserFilter: false,
                TeamFilter: false,
                ContactFilter: false,
                ContactGroupFilter: false,
                KeywordFilter: false,
                ClassificationFilter: false,
                StatusFilter: false,
                PeriodFilter: false,
                HideNonResponsibleIssues: false,
                SearchText: false
            };

            this.Filter = {
                LocationOID: CurrentEntity.OID,
                WithChildLocations: Explorer.GetWithChildLocations(),
                WithDeactivatedElements: Explorer.GetWithDeactivatedElements(),
                HideNonResponsibleIssues: false
            };
        };

        Panel.prototype.setFilterState = function () {
            var isActive;

            for (var filter in activeFilters) {
                isActive = activeFilters[filter];

                if (isActive) {
                    break;
                }
            }

            isActive ? $resetFilters.addClass('active') : $resetFilters.removeClass('active');
        };

        Panel.prototype.findDisturbance = function (oid) {
            return Tools.getFirst(this.Disturbances, oid, 'OID');
        };

        Panel.prototype.hasSelectedDisturbances = function () {
            return Tools.contains(this.Disturbances, true, 'IsSelected');
        };

        Panel.prototype.hasSelectedIssueDisturbances = function () {
            return Tools.contains(this.Disturbances, function (disturbance) {
                return disturbance.IsSelected && disturbance instanceof IssueDisturbance;
            });
        };

        Panel.prototype.getSelectedDisturbances = function () {
            return $.grep(this.Disturbances, function (disturbance) {
                return disturbance.IsSelected;
            });
        };

        Panel.prototype.onToggleDropdown = function (event) {
            var $this = $(event.currentTarget);
            var isDisabled = $this.attr('disabled') === 'disabled';
            var isActive = !$disturbanceAction.hasClass('active');

            $disturbanceAction.removeClass('active');
            DatePicker.Close();

            if (!isDisabled) {
                if (isActive) {
                    $disturbanceAction.addClass('active');
                }

                $body.off('click.hideDropdown', $.proxy(this.onHideDropdown, this));
                $body.one('click.hideDropdown', $.proxy(this.onHideDropdown, this));
            }

            event.stopPropagation();
            return false;
        };

        Panel.prototype.onHideDropdown = function () {
            $disturbanceAction.removeClass('active');
        };

        Panel.prototype.onSelectAction = function (event) {
            var selectedAction = parseInt($(event.currentTarget).data('selection'), 10);
            var disturbances = this.getSelectedDisturbances();

            // prepare disturbances for viewer
            disturbances = Tools.clone(disturbances);
            disturbances = $.map(disturbances, function (disturbance) {
                disturbance.EditorOID = disturbance.Editor ? disturbance.Editor.OID : null;
                disturbance.ElementOID = disturbance.Element ? disturbance.Element.OID : (disturbance.ElementOID || disturbance.AssignedElementOID);
                disturbance.Element = _cachedElements && _cachedElements.hasOwnProperty(disturbance.ElementOID) ?
                    _cachedElements[disturbance.ElementOID] :
                    DataManager.OrganizationUnitLoader.Data.DataMap[disturbance.ElementOID];
                disturbance.Timestamp = disturbance.ModificationTimestamp;
                disturbance.CategoryOID = disturbance.Category ? disturbance.Category.OID : null;
                disturbance.StateOID = disturbance.State ? disturbance.State.OID : null;

                if (disturbance instanceof IssueDisturbance) {
                    disturbance.Type = 1;
                }

                return disturbance;
            });

            if (selectedAction === disturbanceActionType.CreationAction) {
                IssueViewer.CreateAction(disturbances, $.proxy(this.update, this));
            } else if (selectedAction === disturbanceActionType.CompleteDisturbances) {
                IssueViewer.CompleteDisturbances(disturbances, $.proxy(this.update, this));
            } else if (selectedAction === disturbanceActionType.EditIssues) {
                var me = this;
                var issueIdentifiers = disturbances
                    .filter(function (disturbance) {
                        return disturbance instanceof IssueDisturbance;
                    })
                    .map(function (disturbance) {
                        return disturbance.OID;
                    });

                if (!issueIdentifiers.length) {
                    return;
                }

                Tools.Spinner.show();
                Tools.http.post('issues/bulk/', issueIdentifiers)
                    .then(function (issues) {
                        (issues || []).forEach(Tools.issue.prepareIssueForWebApp);

                        Tools.Spinner.hide();
                        IssuesEditor.Show(issues, function (modifications) {
                            if (!modifications) {
                                return;
                            }

                            me.update();
                        });
                    }, function (xhr) {
                        Tools.Spinner.hide();
                        Tools.handleHttpError(Enums.HttpActionType.Read, xhr);
                    });
            }
        };

        Panel.prototype.onResetFilters = function () {
            this.resetFilters();
            this.update();
        };

        Panel.prototype.onSetFilterState = function () {
            this.setFilterState();
        };

        Panel.prototype.onShowTypeFilter = function () {
            var that = this;
            var options = {
                title: i18next.t('explorer.disturbances.typeFilter.title'),
                onApply: function (selectedTypes) {
                    that.Filter.Types = selectedTypes;
                    that.update();
                },
                checkedEntities: that.Filter.Types,
                treeOptions: {
                    schema: {id: 'OID', text: 'Title'},
                    opened: true,
                    checkbox: {fullrow: true}
                }
            };

            TreePicker.Show(types, options);
        };

        Panel.prototype.onShowFormFilter = function () {
            var that = this;

            Tools.WindowWrappers.ShowFormSelectionForLocation({
                selectedForms: that.Filter.Forms,
                selectMultiple: true,
                windowCaption: i18next.t('explorer.disturbances.formFilter.title'),
                buttons: {
                    showAllForms: i18next.t('explorer.disturbances.formFilter.showAllForms'),
                    showAssignedFormsOnly: i18next.t('explorer.disturbances.formFilter.showAssignedFormsOnly')
                },
                showCheckbox: true,
                onApplySelection: function (selectedForms) {
                    that.Filter.Forms = selectedForms;
                    that.update();
                }
            });
        };

        Panel.prototype.onShowSchedulingFilter = function () {
            var that = this;

            Tools.WindowWrappers.ShowSchedulingSelectionForLocation({
                selectedSchedulings: that.Filter.Scheduling,
                selectMultiple: true,
                windowCaption: i18next.t('explorer.disturbances.schedulingFilter.title'),
                buttons: {
                    showAllSchedulings: i18next.t('explorer.disturbances.schedulingFilter.showAllSchedulings'),
                    showAssignedSchedulingsOnly: i18next.t('explorer.disturbances.schedulingFilter.showAssignedSchedulingsOnly')
                },
                showCheckbox: true,
                onApplySelection: function (selectedSchedulings) {
                    that.Filter.Scheduling = selectedSchedulings;
                    that.update();
                }
            });
        };

        Panel.prototype.onShowPriorityFilter = function () {
            var that = this;
            var options = {
                title: i18next.t('explorer.disturbances.priorityFilter.title'),
                onApply: function (selectedPriorities) {
                    that.Filter.Priorities = selectedPriorities;
                    that.update();
                },
                checkedEntities: that.Filter.Priorities,
                treeOptions: {
                    schema: {id: 'OID', children: 'Children', text: 'Title', color: 'Color'},
                    opened: true,
                    checkbox: {fullrow: true}
                }
            };

            TreePicker.Show(Priorities, options);
        };

        Panel.prototype.onShowCreatorFilter = function () {
            var that = this;
            var options = {
                title: i18next.t('explorer.disturbances.creatorFilter.title'),
                onApply: function (selectedUsers) {
                    that.Filter.Creators = selectedUsers;
                    that.update();
                },
                checkedEntities: that.Filter.Creators,
                treeOptions: {
                    schema: {id: 'OID', text: 'Fullname'},
                    opened: true,
                    checkbox: {fullrow: true},
                    createContentHtml: function () {
                        var html;

                        html = this.createCheckboxHtml();
                        html += this.createTextHtml();

                        if (this.entity && this.entity.IsLocked) {
                            html += '<img src="./img/locked.svg" class="user-locked">';
                        }

                        return html;
                    }
                }
            };

            TreePicker.Show(users, options);
        };

        Panel.prototype.onShowUserFilter = function () {
            var that = this;
            var options = {
                title: i18next.t('explorer.disturbances.userFilter.title'),
                onApply: function (selectedUsers) {
                    that.Filter.Users = selectedUsers;
                    that.update();
                },
                checkedEntities: that.Filter.Users,
                treeOptions: {
                    schema: {id: 'OID', text: 'Fullname'},
                    opened: true,
                    checkbox: {fullrow: true},
                    createContentHtml: function () {
                        var html;

                        html = this.createCheckboxHtml();
                        html += this.createTextHtml();

                        if (this.entity && this.entity.IsLocked) {
                            html += '<img src="./img/locked.svg" class="user-locked">';
                        }

                        return html;
                    }
                }
            };

            TreePicker.Show(users, options);
        };

        Panel.prototype.onShowTeamFilter = function () {
            var that = this;
            var options = {
                title: i18next.t('explorer.disturbances.teamFilter.title'),
                onApply: function (selectedTeams) {
                    that.Filter.Teams = selectedTeams;
                    that.update();
                },
                checkedEntities: that.Filter.Teams,
                treeOptions: {
                    schema: {id: 'OID', children: 'Children', text: 'Title', color: 'Color'},
                    opened: true,
                    checkbox: {
                        fullrow: true,
                        disabled: userCanAssignAllTeamsAndUsers ? null : function (node) {
                            if (!selectableTeams || !node || !node.entity) {
                                return false;
                            }

                            var team = node.entity;

                            return selectableTeams.indexOf(team.OID) === -1;
                        }
                    },
                    filter: userCanAssignAllTeamsAndUsers ? null : function (team) {
                        if (!visibleTeams || !team) {
                            return false;
                        }

                        return visibleTeams.indexOf(team.OID) > -1;
                    }
                }
            };

            TreePicker.Show(RootTeam, options);
        };

        Panel.prototype.onShowContactFilter = function () {
            var that = this;
            var contacts, options;

            contacts = Object
                .keys(Contacts)
                .map(function (oid) {
                    return Contacts[oid];
                })
                .sort(function (a, b) {
                    var titleA = a.Title.toLowerCase();
                    var titleB = b.Title.toLowerCase();

                    if (titleA === titleB) {
                        return 0;
                    }

                    return titleA > titleB ? 1 : -1;
                });

            options = {
                title: i18next.t('explorer.disturbances.contactFilter.title'),
                onApply: function (selectedContacts) {
                    that.Filter.Contacts = selectedContacts;
                    that.update();
                },
                checkedEntities: that.Filter.Contacts,
                treeOptions: {
                    schema: {id: 'OID', text: 'Title'},
                    opened: true,
                    checkbox: {fullrow: true}
                }
            };

            TreePicker.Show(contacts, options);
        };

        Panel.prototype.onShowContactGroupFilter = function () {
            var that = this;
            var contactGroups, options;

            contactGroups = Object
                .keys(ContactGroups)
                .map(function (oid) { return ContactGroups[oid]; })
                .sort(function (a, b) {
                    var titleA = a.Title.toLowerCase();
                    var titleB = b.Title.toLowerCase();

                    if (titleA === titleB) {
                        return 0;
                    }

                    return titleA > titleB ? 1 : -1;
                });

            options = {
                title: i18next.t('explorer.disturbances.contactGroupFilter.title'),
                onApply: function (selectedContactGroups) {
                    that.Filter.ContactGroups = selectedContactGroups;
                    that.update();
                },
                checkedEntities: that.Filter.ContactGroups,
                treeOptions: {
                    schema: { id: 'OID', text: 'Title' },
                    opened: true,
                    checkbox: { fullrow: true }
                }
            };

            TreePicker.Show(contactGroups, options);
        };

        Panel.prototype.onShowKeywordFilter = function () {
            var that = this;
            var options = {
                title: i18next.t('explorer.disturbances.keywordFilter.title'),
                onApply: function (selectedKeywords) {
                    that.Filter.Keywords = selectedKeywords;
                    that.update();
                },
                checkedEntities: that.Filter.Keywords,
                treeOptions: {
                    schema: {id: 'OID', children: 'Children', text: 'Title'},
                    opened: true,
                    checkbox: {fullrow: true}
                }
            };

            TreePicker.Show(Keywords, options);
        };

        Panel.prototype.onShowClassificationFilter = function () {
            var that = this;
            var options = {
                title: i18next.t('explorer.disturbances.classificationFilter.title'),
                onApply: function (selectedClassifications) {
                    that.Filter.Classifications = selectedClassifications;
                    that.update();
                },
                checkedEntities: that.Filter.Classifications,
                treeOptions: {
                    schema: {id: 'OID', children: 'Children', text: 'Title'},
                    opened: true,
                    checkbox: {fullrow: true}
                }
            };

            TreePicker.Show(Classifications, options);
        };

        Panel.prototype.onShowStatusFilter = function () {
            var that = this;
            var options = {
                title: i18next.t('explorer.disturbances.statusFilter.title'),
                onApply: function (selectedStatus) {
                    that.Filter.States = selectedStatus;
                    that.update();
                },
                checkedEntities: that.Filter.States,
                treeOptions: {
                    schema: {id: 'OID', children: 'Children', text: 'Title', color: 'Color'},
                    opened: true,
                    checkbox: {fullrow: true}
                }
            };

            TreePicker.Show(IssueStates, options);
        };

        Panel.prototype.onSearch = function () {
            clearTimeout(searchTimeout);

            var that = this;

            searchTimeout = setTimeout(function () {
                var searchValue = $search.val().trim();

                that.Filter.SearchValue = searchValue;
                activeFilters.SearchText = !!searchValue;

                that.update();
            }, 500);
        };

        Panel.prototype.onSetPeriodFilter = function (event) {
            var periodFilter = (this.Filter.Period || {});
            var $this = $(event.currentTarget);
            var that = this;

            if ($this.hasClass('active')) {
                DatePicker.Close();
                return;
            }

            $this.addClass('active');
            $disturbanceAction.removeClass('active');

            DatePicker.Show($this, {
                mode: 2,
                withTime: true,
                withTimeControl: true,
                noDateAllowed: true,
                offsetY: 22,
                closeElement: 'body',
                deactivatable: true,
                titleElement: $this,
                dateTypes: [Enums.DatePickerTypes.Creation, Enums.DatePickerTypes.Modification, Enums.DatePickerTypes.Deadline],
                selectedType: Tools.contains([Enums.DatePickerTypes.Creation, Enums.DatePickerTypes.Modification, Enums.DatePickerTypes.Deadline], periodFilter.CurrentType) ?
                    periodFilter.CurrentType :
                    Enums.DatePickerTypes.Modification,
                beforeShow: function () {
                    DatePicker.SetDate(periodFilter.Start, periodFilter.End);
                },
                end: function (filter) {
                    that.Filter.Period = filter;

                    if (that.Filter.Period && (that.Filter.Period.Start || that.Filter.Period.End)) {
                        if (that.Filter.Period.Start) {
                            that.Filter.Period.Start = new Date(that.Filter.Period.Start.setSeconds(0));
                        }

                        if (that.Filter.Period.End) {
                            that.Filter.Period.End = new Date(that.Filter.Period.End.setSeconds(59));
                        }

                        $periodFilter.addClass('filter-selected');
                    } else {
                        $periodFilter.removeClass('filter-selected');
                    }

                    DatePicker.Close();
                    that.update();
                },
                onDatePickerClose: function () {
                    $this.removeClass('active');
                }
            });

            event.stopPropagation();
            return false;
        };

        Panel.prototype.onSetHideNonResponsibleIssues = function () {
            var hideNonResponsibleIssues = this.Filter.HideNonResponsibleIssues;
            var that = this;

            that.Filter.HideNonResponsibleIssues = !hideNonResponsibleIssues;

            that.update();
        };

        Panel.prototype.onToggleSelection = function (event) {
            var $this = $(event.currentTarget);
            var isChecked = $this.prop('checked');

            if ($this.hasClass('select-all')) {
                this.IsAllSelected = isChecked;

                (this.Disturbances || []).forEach(function (disturbance) {
                    disturbance.IsSelected = isChecked;
                });

                $tableBody.find('input:checkbox').prop('checked', !!isChecked);
            } else {
                this.IsAllSelected = false;
                $tableHeader.find('input:checkbox').prop('checked', false);

                oid = $this.closest('tr').data('oid');
                disturbance = this.findDisturbance(oid);

                if (!disturbance) {
                    return;
                }

                disturbance.IsSelected = isChecked;
            }

            this.initAction();
        };

        Panel.prototype.onShowPrintPreview = function (event) {
            event.stopPropagation();

            var oid = $(event.currentTarget).closest('.issue').data('oid');
            var disturbance = this.findDisturbance(oid);

            if (!Client.Licenses.EnablePdfDesigner) {
                this.printDefaultReport(disturbance);
                return;
            }

            const printTemplate = Tools.GetFirstActivePdfTemplate(PdfReportTemplates, disturbance.AssignedFormOID);

            if (!printTemplate) {
                this.printDefaultReport(disturbance);
                return;
            }

            Tools.Message.Show({
                title: i18next.t('explorer.disturbances.printTemplateSelection.title'),
                text: i18next.t('explorer.disturbances.printTemplateSelection.text', { issueId: disturbance.ID }),
                yes: true,
                onYes: () => this.printCustomPdfReport(disturbance.ID, printTemplate.OID),
                no: true,
                onNo: () => this.printDefaultReport(disturbance),
                abort: true,
                captions: [
                    {
                        button: 'yes',
                        text: i18next.t('explorer.disturbances.printTemplateSelection.printCustomReport')
                    },
                    {
                        button: 'no',
                        text: i18next.t('explorer.disturbances.printTemplateSelection.printDefaultReport')
                    }
                ]
            });

            return false;
        };

        Panel.prototype.printDefaultReport = function (issue) {
            if (!issue) {
                return;
            }

            PrintPreview.Show({
                Issue: issue,
                Type: 'Issue'
            });
        };

        Panel.prototype.printCustomPdfReport = function (issueID, templateOID) {
            if (!issueID || !templateOID) {
                return;
            }

            PrintPreview.Show({
                Element: CurrentEntity,
                Type: 'PdfCreator',
                IssueID: issueID,
                PdfTemplateIdentifier: templateOID
            });
        }

        Panel.prototype.onSelectOrganisationUnit = function (event) {
            event.stopPropagation();
        };

        Panel.prototype.onShowIssue = function (event) {
            var issueID = $(event.currentTarget).data('issue-id');

            IssueViewer.Show(issueID, $.proxy(this.update, this), $.proxy(this.updateComments, this));
        };

        Panel.prototype.onShowRecorditem = function (event) {
            var $this = $(event.currentTarget);
            var $details = $this.parents('.details');
            var issueId = $details.data('issue-id');
            var issue = issueId ? Tools.getFirst(this.Disturbances, issueId, 'ID') : null;
            var recorditemOID = $this.data('oid');
            var recorditem = issue == null ? Tools.getFirst(this.Disturbances, recorditemOID, 'OID') : null;
            var organizationUnit = issue ? issue.OrganizationUnit : recorditem.getOrganizationUnit();

            var left = $tableWrapper.scrollLeft();
            var top = $tableWrapper.scrollTop();

            RecorditemDetails.show({
                OID: recorditemOID,
                $Target: $this,
                $Parent: $tableWrapper,
                $Container: $tableWrapper,
                Issue: issue,
                IsReadonly: organizationUnit == null || !organizationUnit.Enabled,
                Offset: {
                    X: left,
                    Y: top
                },
                OnChanged: $.proxy(this.update, this),
                OnCommentsChanged: function (recorditem) {
                    var settings = {
                        categoryInline: true,
                        suppressInfos: false,
                        alignLeft: true,
                        scaledDown: true
                    };

                    $this.find('.recitemValue').replaceWith(Tools.values.render(recorditem, settings));
                }
            });

            event.stopPropagation();
        };

        Panel.prototype.onShowSubscibers = function (event) {
            var $tooltip = $('#gfx-tooltip');
            var $this = $(event.currentTarget);
            var subscribers = ($this.data('subscribers') || '').split('|');
            var offset, abos, html;

            offset = $this.offset();

            abos = $.map(subscribers, function (oid) {
                var subscriber = Users[oid] || Contacts[oid];

                if (subscriber) {
                    return subscriber;
                }
            });

            if (!abos.length) {
                return;
            }

            abos.sort(Tools.SortByFullname);

            html = $.map(abos, function (user) {
                return user.Fullname;
            });

            if (html.length > 10) {
                html = html.slice(0, 10);
                html.push('+ {0} {1}'.format(
                    abos.length - 10,
                    i18next.t('misc.more'))
                );
            }

            $tooltip.html(html.join('<br />'));
            $tooltip.removeClass('hide');
            $tooltip.attr('arrow', 1);
            $tooltip.css({
                left: offset.left - $tooltip.outerWidth(false) / 2 + 8,
                top: offset.top - $tooltip.outerHeight(false) - 8
            });
        };

        Panel.prototype.onHideSubscibers = function () {
            $('#gfx-tooltip').addClass('hide');
        };

        Panel.prototype.onToggleSubscription = function (event) {
            var $this = $(event.currentTarget);
            var $isSubscribed = $this.children('.is-subscribed');
            var $subscribers = $this.children('.subscribers');
            var isSubscribed = /true/i.test($this.data('is-subscribed'));
            var oid = $this.closest('tr[data-oid]').data('oid');
            var disturbance = this.findDisturbance(oid);

            event.stopPropagation();

            if (!disturbance) {
                return;
            }

            if (isSubscribed) {
                Tools.http.post('issues/{0}/unsubscribenotifications'.format(disturbance.ID), $.noop, Tools.Spinner.hide);
                disturbance.Recipients = Tools.remove(disturbance.Recipients, User.OID);

                $isSubscribed.text(i18next.t('misc.subscribe'));
                $this.data('is-subscribed', false);
            } else {
                Tools.http.post('issues/{0}/subscribenotifications'.format(disturbance.ID), $.noop, Tools.Spinner.hide);
                disturbance.Recipients = Tools.addUnique(disturbance.Recipients, User.OID);

                $isSubscribed.text(i18next.t('misc.unsubscribe'));
                $this.data('is-subscribed', true);
            }

            if (disturbance.Recipients.length) {
                $subscribers.text('({0})'.format(disturbance.Recipients.length));
                $subscribers.data('subscribers', disturbance.Recipients.join('|'));
            } else {
                $subscribers.text('');
                $subscribers.data('subscribers', null);
            }
        };

        Panel.prototype.onToggleControls = function (event) {
            onToggleControls(event);
        };

        Panel.prototype.reset = function (filter) {
            this.Disturbances = [];
            this.TotalCount = 0;
            this.IsAllSelected = false;

            filter = filter || {};
            this.Filter.LocationOID = filter.LocationOID;
            this.Filter.WithChildLocations = filter.WithChildLocations;
            this.Filter.WithDeactivatedElements = filter.WithDeactivatedElements;
            this.Filter.HideNonResponsibleIssues = filter.HideNonResponsibleIssues;

            if (filter.Period) {
                this.Filter.Period = filter.Period;
            }

            if (filter.Users) {
                this.Filter.Users = filter.Users;
            }

            if (filter.Teams) {
                this.Filter.Teams = filter.Teams;
            }
        };

        Panel.prototype.update = function () {
            this.reset(this.Filter);
            this.init();
            this.onSetFilterState();
            this.getDisturbances()
                .then($.proxy(this.renderRows, this))
                .then(Explorer.UpdateTabCounters)
                .then(Explorer.ResizeTabMenu);
        };

        Panel.prototype.init = function () {
            $tableView.find('.tab.selected').removeClass('selected');
            $tableView.find('.tab[data-tab="{0}"]'.format(this.DisturbanceType)).addClass('selected');

            this.initControls();
            this.initTable();
            this.initEvents();
        };

        Panel.prototype.initControls = function () {
            this.initAction();
        };

        Panel.prototype.initAction = function () {
            if (this.hasSelectedDisturbances() &&
                ressources.users.hasRightAtLocation('TMFT', CurrentEntity.OID)) {
                $action.removeAttr('disabled');

                var $actionDropdown = $explorerDisturbances.find('.gfx-action-window.disturbance-action');

                if (this.hasSelectedIssueDisturbances()) {
                    $actionDropdown.find('.action-window-content-element[data-selection="3"]')
                        .removeAttr('disabled');
                } else {
                    $actionDropdown.find('.action-window-content-element[data-selection="3"]')
                        .attr('disabled', 'disabled');
                }
            } else {
                $action.attr('disabled', 'disabled');
            }
        };

        Panel.prototype.initToolbars = function () {
            $resetFilters.removeClass('hide');
            $toggleControls.removeClass('hide');

            if (IsFilterVisible) {
                onToggleControls();
            }
        };

        Panel.prototype.initTypeFilter = function () {
            var $btnTitle = $typeFilter.find('.btn-title');
            var typeFilter = (this.Filter || {}).Types || [];
            var text;

            var titles = typeFilter.map(function (typeId) {
                var type = Tools.getFirst(types, typeId, 'OID');

                return type.Title;
            });

            if (titles.length) {
                text = titles.length === types.length ?
                    i18next.t('explorer.disturbances.typeFilter.all') :
                    titles.sort().join(', ');

                $btnTitle.parent().addClass('filter-selected');
                activeFilters.TypeFilter = true;
            } else {
                text = i18next.t('explorer.disturbances.typeFilter.noSelection');

                $btnTitle.parent().removeClass('filter-selected');
                activeFilters.TypeFilter = false;
            }

            $btnTitle.html(text);
        };

        Panel.prototype.initFormFilter = function () {
            var $btnTitle = $formFilter.find('.btn-title');
            var formFilter = (this.Filter || {}).Forms || [];
            var text;

            var titles = formFilter.map(function (oid) {
                var form = DataManager.FormLoader.Data.DataMap[oid];

                return form.Title;
            });

            if (titles.length) {
                text = titles.sort().join(', ');
                $btnTitle.parent().addClass('filter-selected');
                activeFilters.FormFilter = true;
            } else {
                text = i18next.t('explorer.disturbances.formFilter.noSelection');
                $btnTitle.parent().removeClass('filter-selected');
                activeFilters.FormFilter = false;
            }

            $btnTitle.html(text);
        };

        Panel.prototype.initSchedulingFilter = function () {
            var $btnTitle = $schedulingFilter.find('.btn-title');
            var schedulingFilter = (this.Filter || {}).Scheduling || [];
            var text;

            var titles = schedulingFilter.map(function (oid) {
                var scheduling = DataManager.SchedulingLoader.Data.DataMap[oid]

                return scheduling.Title;
            });

            if (titles.length) {
                text = titles.sort().join(', ');
                $btnTitle.parent().addClass('filter-selected');
                activeFilters.SchedulingFilter = true;
            } else {
                text = i18next.t('explorer.disturbances.schedulingFilter.noSelection');
                $btnTitle.parent().removeClass('filter-selected');
                activeFilters.SchedulingFilter = false;
            }

            $btnTitle.html(text);
        };

        Panel.prototype.initPriorityFilter = function () {
            var $btnTitle = $priorityFilter.find('.btn-title');
            var priorityFilter = (this.Filter || {}).Priorities || [];
            var titles = [];
            var priorityCount = 0;
            var text;

            $.each(Properties, function (_oid, property) {
                if (property.Type !== Enums.propertyType.Priority) {
                    return;
                }

                priorityCount++;

                if (Tools.contains(priorityFilter, property.OID)) {
                    titles.push(property.Title);
                }
            });

            if (titles.length) {
                text = titles.length === priorityCount ?
                    i18next.t('explorer.disturbances.priorityFilter.all') :
                    titles.sort().join(', ');

                $btnTitle.parent().addClass('filter-selected');
                activeFilters.StatusFilter = true;
            } else {
                text = i18next.t('explorer.disturbances.priorityFilter.noSelection');

                $btnTitle.parent().removeClass('filter-selected');
                activeFilters.StatusFilter = false;
            }

            $btnTitle.html(text);
        };

        Panel.prototype.initCreatorFilter = function () {
            var $btnTitle = $creatorFilter.find('.btn-title');
            var userFilter = (this.Filter || {}).Creators;
            var titles = [];
            var userCount = 0;
            var text;

            $.each(Users, function (_oid, user) {
                userCount++;

                if (Tools.contains(userFilter, user.OID)) {
                    titles.push(user.Title);
                }
            });

            if (titles.length) {
                text = titles.length === userCount ?
                    i18next.t('explorer.disturbances.userFilter.all') :
                    titles.sort().join(', ');

                $btnTitle.parent().addClass('filter-selected');
                activeFilters.UserFilter = true;
            } else {
                text = i18next.t('explorer.disturbances.userFilter.noSelection');

                $btnTitle.parent().removeClass('filter-selected');
                activeFilters.UserFilter = false;
            }

            $btnTitle.html(text);
            $creatorFilter.removeClass('hide');
        };

        Panel.prototype.initUserFilter = function () {
            var $btnTitle = $userFilter.find('.btn-title');
            var userFilter = (this.Filter || {}).Users;
            var titles = [];
            var userCount = 0;
            var text;

            $.each(Users, function (_oid, user) {
                userCount++;

                if (Tools.contains(userFilter, user.OID)) {
                    titles.push(user.Title);
                }
            });

            if (titles.length) {
                text = titles.length === userCount ?
                    i18next.t('explorer.disturbances.userFilter.all') :
                    titles.sort().join(', ');

                $btnTitle.parent().addClass('filter-selected');
                activeFilters.UserFilter = true;
            } else {
                text = i18next.t('explorer.disturbances.userFilter.noSelection');

                $btnTitle.parent().removeClass('filter-selected');
                activeFilters.UserFilter = false;
            }

            $btnTitle.html(text);
            $userFilter.removeClass('hide');
        };

        Panel.prototype.initTeamFilter = function () {
            var $btnTitle = $teamFilter.find('.btn-title');
            var teamFilter = (this.Filter || {}).Teams;
            var titles = [];
            var teamCount = 0;
            var text;

            $.each(Teams, function (_oid, team) {
                teamCount++;

                if (Tools.contains(teamFilter, team.OID)) {
                    titles.push(team.Title);
                }
            });

            if (titles.length) {
                text = titles.length === teamCount ?
                    i18next.t('explorer.disturbances.teamFilter.all') :
                    titles.sort().join(', ');

                $btnTitle.parent().addClass('filter-selected');
                activeFilters.TeamFilter = true;
            } else {
                text = i18next.t('explorer.disturbances.teamFilter.noSelection');

                $btnTitle.parent().removeClass('filter-selected');
                activeFilters.TeamFilter = false;
            }

            $btnTitle.html(text);
            $teamFilter.removeClass('hide');
        };

        Panel.prototype.initContactFilter = function () {
            var titles = [];
            var contactCount = 0;
            var $btnTitle, contactFilter, text;

            if (!(Client.Licenses && Client.Licenses.Contacts)) {
                $('#explorer-disturbances-contact-filter-lbl').addClass('hide');
                $contactFilter.addClass('hide');
                return;
            }

            $btnTitle = $contactFilter.find('.btn-title');
            contactFilter = (this.Filter || {}).Contacts;

            $.each(Contacts, function (_oid, contact) {
                contactCount++;

                if (Tools.contains(contactFilter, contact.OID)) {
                    titles.push(contact.Title);
                }
            });

            if (titles.length) {
                text = titles.length === contactCount ?
                    i18next.t('explorer.disturbances.contactFilter.all') :
                    titles.sort().join(', ');

                $btnTitle.parent().addClass('filter-selected');
                activeFilters.ContactFilter = true;
            } else {
                text = i18next.t('explorer.disturbances.contactFilter.noSelection');

                $btnTitle.parent().removeClass('filter-selected');
                activeFilters.ContactFilter = false;
            }

            $btnTitle.html(text);
            $contactFilter.removeClass('hide');

            $('#explorer-disturbances-contact-filter-lbl').removeClass('hide');
            $contactFilter.removeClass('hide');
        };

        Panel.prototype.initContactGroupFilter = function () {
            var titles = [];
            var contactGroupCount = 0;
            var $btnTitle, contactGroupFilter, text;

            if (!(Client.Licenses && Client.Licenses.Contacts)) {
                $('#explorer-disturbances-contact-group-filter-lbl').addClass('hide');
                $contactGroupFilter.addClass('hide');
                return;
            }

            $btnTitle = $contactGroupFilter.find('.btn-title');
            contactGroupFilter = (this.Filter || {}).ContactGroups;

            $.each(ContactGroups, function (_oid, contactGroup) {
                contactGroupCount++;

                if (Tools.contains(contactGroupFilter, contactGroup.OID)) {
                    titles.push(contactGroup.Title);
                }
            });

            if (titles.length) {
                text = titles.length === contactGroupCount ?
                    i18next.t('explorer.disturbances.contactGroupFilter.all') :
                    titles.sort().join(', ');

                $btnTitle.parent().addClass('filter-selected');
                activeFilters.ContactGroupFilter = true;
            } else {
                text = i18next.t('explorer.disturbances.contactGroupFilter.noSelection');

                $btnTitle.parent().removeClass('filter-selected');
                activeFilters.ContactGroupFilter = false;
            }

            $btnTitle.html(text);
            $contactGroupFilter.removeClass('hide');

            $('#explorer-disturbances-contact-group-filter-lbl').removeClass('hide');
            $contactGroupFilter.removeClass('hide');
        };

        Panel.prototype.initPeriodFilter = function () {
            if (!this.Filter) {
                this.Filter = {};
            }

            if (!this.Filter.Period) {
                this.Filter.Period = {};
            }

            var text;
            var periodFilter = this.Filter.Period;

            if (periodFilter && (periodFilter.Start || periodFilter.End)) {
                $periodFilter.addClass('filter-selected');
                activeFilters.PeriodFilter = true;
                text =
                    (periodFilter.Start != null ? i18next.t('datePicker.from') + ' ' + periodFilter.Start.toLocaleString() : '') +
                    ' ' +
                    (periodFilter.End != null ? i18next.t('datePicker.to') + ' ' + periodFilter.End.toLocaleString() : '');
            } else {
                text = i18next.t('explorer.issues.formFilter.noSelection');

                $periodFilter.removeClass('filter-selected');
                activeFilters.PeriodFilter = false;
            }

            $periodFilter.html(text);
        };

        Panel.prototype.initHideNonResponsibleIssuesFilter = function () {
            if (!this.Filter) {
                this.Filter = {};
            }

            $hideNonResponsibleIssues.html(i18next.t('explorer.issues.hideNonResponsibleIssues'));
            $cbHideNonResponsibleIssues.prop('checked', this.Filter.HideNonResponsibleIssues);
            activeFilters.HideNonResponsibleIssues = this.Filter.HideNonResponsibleIssues;
        };

        Panel.prototype.initKeywordFilter = function () {
            var $btnTitle = $keywordFilter.find('.btn-title');
            var keywordFilter = (this.Filter || {}).Keywords;
            var titles = [];
            var keywordCount = 0;
            var text;

            $.each(Properties, function (_oid, keyword) {
                if (keyword.Type !== Enums.propertyType.Keyword) {
                    return;
                }

                keywordCount++;

                if (Tools.contains(keywordFilter, keyword.OID)) {
                    titles.push(keyword.Title);
                }
            });

            if (titles.length) {
                text = titles.length === keywordCount ?
                    i18next.t('explorer.disturbances.keywordFilter.all') :
                    titles.sort().join(', ');

                $btnTitle.parent().addClass('filter-selected');
                activeFilters.KeywordFilter = true;
            } else {
                text = i18next.t('explorer.disturbances.keywordFilter.noSelection');

                $btnTitle.parent().removeClass('filter-selected');
                activeFilters.KeywordFilter = false;
            }

            $btnTitle.html(text);
            $keywordFilter.removeClass('hide');
        };

        Panel.prototype.initClassificationFilter = function () {
            var $btnTitle = $classificationFilter.find('.btn-title');
            var classificationFilter = (this.Filter || {}).Classifications;
            var titles = [];
            var classificationCount = 0;
            var text;

            $.each(Properties, function (_oid, classification) {
                if (classification.Type !== Enums.propertyType.Classification) {
                    return;
                }

                classificationCount++;

                if (Tools.contains(classificationFilter, classification.OID)) {
                    titles.push(classification.Title);
                }
            });

            if (titles.length) {
                text = titles.length === classificationCount ?
                    i18next.t('explorer.disturbances.classificationFilter.all') :
                    titles.sort().join(', ');

                $btnTitle.parent().addClass('filter-selected');
                activeFilters.ClassificationFilter = true;
            } else {
                text = i18next.t('explorer.disturbances.classificationFilter.noSelection');

                $btnTitle.parent().removeClass('filter-selected');
                activeFilters.ClassificationFilter = false;
            }

            $btnTitle.html(text);
            $classificationFilter.removeClass('hide');
        };

        Panel.prototype.initStatusFilter = function () {
            var $btnTitle = $statusFilter.find('.btn-title');
            var statusFilter = (this.Filter || {}).States;
            var titles = [];
            var statusCount = 0;
            var text;

            $.each(Properties, function (_oid, status) {
                if (status.Type !== Enums.propertyType.Status) {
                    return;
                }

                statusCount++;

                if (Tools.contains(statusFilter, status.OID)) {
                    titles.push(status.Title);
                }
            });

            if (titles.length) {
                text = titles.length === statusCount ?
                    i18next.t('explorer.disturbances.statusFilter.all') :
                    titles.sort().join(', ');

                $btnTitle.parent().addClass('filter-selected');
                activeFilters.StatusFilter = true;
            } else {
                text = i18next.t('explorer.disturbances.statusFilter.noSelection');

                $btnTitle.parent().removeClass('filter-selected');
                activeFilters.StatusFilter = false;
            }

            $btnTitle.html(text);
            $statusFilter.removeClass('hide');
        };

        Panel.prototype.initSearch = function () {
            activeFilters.SearchText = !!this.Filter.SearchValue;

            $search.val(activeFilters.SearchText ? $.trim(this.Filter.SearchValue) : '');
            $search.attr('placeholder', i18next.t('explorer.search'));
        };

        Panel.prototype.initTable = function () {
            $tableHeader.html(this.renderHeader());
            $tableBody.html(this.renderBody());
        };

        Panel.prototype.initEvents = function () {
            $explorerDisturbances.off('click.toggleDropdown');
            $tableWrapper.off('scroll.infiniteScroll');
            $action.off('click.selectAction');
            $resetFilters.off('click.resetFilters');
            $typeFilter.off('click.showFilter');
            $formFilter.off('click.showFilter');
            $schedulingFilter.off('click.showFilter');
            $priorityFilter.off('click.showFilter');
            $creatorFilter.off('click.showFilter');
            $userFilter.off('click.showFilter');
            $teamFilter.off('click.showFilter');
            $contactFilter.off('click.showFilter');
            $contactGroupFilter.off('click.showFilter');
            $keywordFilter.off('click.showFilter');
            $classificationFilter.off('click.showFilter');
            $statusFilter.off('click.showFilter');
            $search.off('input.search');
            $explorerDisturbances.off('change.toggleSelection');
            $tableBody.off('click.showPrintPreview');
            $tableBody.off('click.onSelectOrganisationUnit');
            $tableBody.off('click.showIssue');
            $tableBody.off('click.showRecorditem');
            $body.off('click.hideRecorditem');
            $body.off('click.resetControls');
            $tableBody.off('mouseenter.showSubscibers');
            $tableBody.off('mouseleave.hideSubscibers');
            $tableBody.off('click.toggleSubscription');
            $tableBody.off('click.toggleComments');
            $tableBody.off('keypress.saveComment');
            $tableBody.off('click.deleteComment');
            $tableBody.off('click.stopPropagation');
            $tableBody.off('keyup.resizeComment');
            $tableBody.off('keydown.resizeComment');
            $tableBody.off('click.stopPropagation');
            $periodFilter.off('click.periodFilter');
            $cbHideNonResponsibleIssues.off('change.hideNonResponsibleIssues');
            $toggleControls.off('click.toggleControls');
            $disturbanceAction.off('click.selectAction');

            $explorerDisturbances.on('click.toggleDropdown', '.dropdown-button', $.proxy(this.onToggleDropdown, this));
            $disturbanceAction.on('click.selectAction', '.action-window-content-element', $.proxy(this.onSelectAction, this));
            $resetFilters.on('click.resetFilters', $.proxy(this.onResetFilters, this));
            $typeFilter.on('click.showFilter', $.proxy(this.onShowTypeFilter, this));
            $formFilter.on('click.showFilter', $.proxy(this.onShowFormFilter, this));
            $schedulingFilter.on('click.showFilter', $.proxy(this.onShowSchedulingFilter, this));
            $priorityFilter.on('click.showFilter', $.proxy(this.onShowPriorityFilter, this));
            $creatorFilter.on('click.showFilter', $.proxy(this.onShowCreatorFilter, this));
            $userFilter.on('click.showFilter', $.proxy(this.onShowUserFilter, this));
            $teamFilter.on('click.showFilter', $.proxy(this.onShowTeamFilter, this));
            $contactFilter.on('click.showFilter', $.proxy(this.onShowContactFilter, this));
            $contactGroupFilter.on('click.showFilter', $.proxy(this.onShowContactGroupFilter, this));
            $keywordFilter.on('click.showFilter', $.proxy(this.onShowKeywordFilter, this));
            $classificationFilter.on('click.showFilter', $.proxy(this.onShowClassificationFilter, this));
            $statusFilter.on('click.showFilter', $.proxy(this.onShowStatusFilter, this));
            $search.on('input.search', $.proxy(this.onSearch, this));
            $explorerDisturbances.on('change.toggleSelection', 'input:checkbox', $.proxy(this.onToggleSelection, this));
            $tableBody.on('click.showPrintPreview', '.print', $.proxy(this.onShowPrintPreview, this));
            $tableBody.on('click.onSelectOrganisationUnit', 'tr[data-issue-id] a', $.proxy(this.onSelectOrganisationUnit, this));
            $tableBody.on('click.showIssue', 'tr[data-issue-id]', $.proxy(this.onShowIssue, this));
            $tableBody.on('click.showRecorditem', 'tr[data-recorditem-id]', $.proxy(this.onShowRecorditem, this));
            $body.on('click.hideRecorditem', RecorditemDetails.hide);
            $body.on('click.resetControls', Panel.resetControls);
            $tableBody.on('mouseenter.showSubscibers', '.subscribers', $.proxy(this.onShowSubscibers, this));
            $tableBody.on('mouseleave.hideSubscibers', '.subscribers', $.proxy(this.onHideSubscibers, this));
            $tableBody.on('click.toggleSubscription', '.subscriptions', $.proxy(this.onToggleSubscription, this));
            $tableBody.on('click.stopPropagation', 'input:checkbox', function (event) {
                event.stopPropagation();
            });
            $periodFilter.on('click.periodFilter', $.proxy(this.onSetPeriodFilter, this));
            $cbHideNonResponsibleIssues.on('change.hideNonResponsibleIssues', $.proxy(this.onSetHideNonResponsibleIssues, this));
            $toggleControls.on('click.toggleControls', $.proxy(this.onToggleControls, this));
        };

        Panel.prototype.renderCommentsAndSubscriptions = function (recipients, comments) {
            var html = [];

            html.push('<p class="commentdetails">');
            html.push(this.renderSubscription(recipients));
            html.push(' - <span class="commentlink"');

            if ((comments || []).length) {
                html.push(' data-comments="{0}"'.format(comments.length));
            }

            html.push('>{0}</p>'.format(i18next.t('misc.comment_plural')));

            return html.join('');
        };

        Panel.prototype.renderSubscription = function (recipients) {
            if (Tools.contains(recipients, User.OID)) {
                return '<span class="subscriptions" data-is-subscribed="true"><span class="is-subscribed">{0}</span> <span class="subscribers" data-subscribers="{1}">({2})</span></span>'
                    .format(
                        i18next.t('misc.unsubscribe'),
                        recipients.join('|'),
                        recipients.length);
            } else if ((recipients || []).length) {
                return '<span class="subscriptions"><span class="is-subscribed">{0}</span> <span class="subscribers" data-subscribers="{1}">({2})</span></span>'
                    .format(
                        i18next.t('misc.subscribe'),
                        recipients.join('|'),
                        recipients.length);
            }

            return '<span class="subscriptions"><span class="is-subscribed">{0}</span> <span class="subscribers"></span></span>'
                .format(i18next.t('misc.subscribe'));
        };

        Panel.prototype.renderImage = function (image) {
            var html = ['<td data-columnid="2" data-filename="{0}" class="image{1}">'.format(image.Filename, show ? '' : ' hide')];

            if (image.Marks) {
                var viewboxRegex = /<svg[^>]*width="(\d+)"[^>]*height="(\d+)"[^>]*>(.*)<\/svg>/ig;
                var pathRegex = /<path [^>\/]+/ig;
                var paths = [];
                var match = viewboxRegex.exec(image.Marks);

                if (!match || match.length !== 4) {
                    return;
                }

                var width = parseInt(match[1], 10);
                var height = parseInt(match[2], 10);
                var svgContent = match[3];

                do {
                    if ((match = pathRegex.exec(image.Marks))) {
                        paths.push(match[0].trim() + '></path>');
                    }
                } while (match);

                if (paths.length) {
                    html.push('<svg xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 {0} {1}">'
                        .format(width, height));
                    html.push('<image xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="{0}/images/m/{1}" x="0" y="0" width="100%" height="100%" crossorigin="use-credentials"></image>'
                        .format(Config.BaseUri, image.Filename));
                    html.push(svgContent);
                    html.push('</svg>');
                }
            } else {
                html.push('<img src="{0}images/s/{1}" />'.format(Config.BaseUri, image.Filename));
            }

            html.push('</td>');

            return html.join('');
        };

        Panel.prototype.renderTimestamp = function (timestamp) {
            return '<td>{0}</td>'.format(
                timestamp ? Tools.dateTime.getDifferenceString(timestamp) : '-/-');
        };

        Panel.prototype.renderState = function (state) {
            return '<td><div class="color" style="background-color: {0}">&nbsp;</div> {1}</td>'
                .format(
                    state ? state.Color : '#fff',
                    state ? state.Title : i18next.t('misc.untitled'));
        };

        Panel.prototype.renderTeamsAndUsers = function (users, teams) {
            var html = [];
            var renderContent = false;
            var groupedTeamsAndUsers = {
                responsible: [],
                accountable: [],
                consulted: [],
                informed: []
            };

            for (var userIdentifier in users) {
                var userResponsibilityEntity = users[userIdentifier];
                var user = Users[userIdentifier];

                if (!user) {
                    continue;
                }

                renderContent = true;

                if (userResponsibilityEntity.IsResponsible) {
                    groupedTeamsAndUsers.responsible.push('<li>{0}</li>'.format(user.Fullname));
                }

                if (userResponsibilityEntity.IsAccountable) {
                    groupedTeamsAndUsers.accountable.push('<li>{0}</li>'.format(user.Fullname));
                }

                if (userResponsibilityEntity.IsConsulted) {
                    groupedTeamsAndUsers.consulted.push('<li>{0}</li>'.format(user.Fullname));
                }

                if (userResponsibilityEntity.IsInformed) {
                    groupedTeamsAndUsers.informed.push('<li>{0}</li>'.format(user.Fullname));
                }
            }

            for (var teamIdentifier in teams) {
                var teamResponsibilityEntity = teams[teamIdentifier];
                var team = Teams[teamIdentifier];

                if (!team) {
                    continue;
                }

                renderContent = true;

                if (teamResponsibilityEntity.IsResponsible) {
                    groupedTeamsAndUsers.responsible.push('<li>{0}</li>'.format(team.Title));
                }

                if (teamResponsibilityEntity.IsAccountable) {
                    groupedTeamsAndUsers.accountable.push('<li>{0}</li>'.format(team.Title));
                }

                if (teamResponsibilityEntity.IsConsulted) {
                    groupedTeamsAndUsers.consulted.push('<li>{0}</li>'.format(team.Title));
                }

                if (teamResponsibilityEntity.IsInformed) {
                    groupedTeamsAndUsers.informed.push('<li>{0}</li>'.format(team.Title));
                }
            }

            groupedTeamsAndUsers.responsible.sort(Tools.SortStringArray);
            groupedTeamsAndUsers.accountable.sort(Tools.SortStringArray);
            groupedTeamsAndUsers.consulted.sort(Tools.SortStringArray);
            groupedTeamsAndUsers.informed.sort(Tools.SortStringArray);

            if (renderContent) {
                html.push('<td>');

                if (groupedTeamsAndUsers.responsible.length) {
                    html.push('<b>' + i18next.t('explorer.disturbances.responsibilities.r') + '</b>');
                    html.push(groupedTeamsAndUsers.responsible.slice(0, 6).join(''));
                    if (groupedTeamsAndUsers.responsible.length > 6) {
                        html.push('<li>+ {0} {1}</li>'.format(
                            groupedTeamsAndUsers.responsible.length - 6,
                            i18next.t('misc.more')));
                    }
                }

                if (groupedTeamsAndUsers.accountable.length) {
                    html.push('<b>' + i18next.t('explorer.disturbances.responsibilities.a') + '</b>');
                    html.push(groupedTeamsAndUsers.accountable.slice(0, 6).join(''));
                    if (groupedTeamsAndUsers.accountable.length > 6) {
                        html.push('<li>+ {0} {1}</li>'.format(
                            groupedTeamsAndUsers.accountable.length - 6,
                            i18next.t('misc.more')));
                    }
                }

                if (groupedTeamsAndUsers.consulted.length) {
                    html.push('<b>' + i18next.t('explorer.disturbances.responsibilities.c') + '</b>');
                    html.push(groupedTeamsAndUsers.consulted.slice(0, 6).join(''));
                    if (groupedTeamsAndUsers.consulted.length > 6) {
                        html.push('<li>+ {0} {1}</li>'.format(
                            groupedTeamsAndUsers.consulted.length - 6,
                            i18next.t('misc.more')));
                    }
                }

                if (groupedTeamsAndUsers.informed.length) {
                    html.push('<b>' + i18next.t('explorer.disturbances.responsibilities.i') + '</b>');
                    html.push(groupedTeamsAndUsers.informed.slice(0, 6).join(''));
                    if (groupedTeamsAndUsers.informed.length > 6) {
                        html.push('<li>+ {0} {1}</li>'.format(
                            groupedTeamsAndUsers.informed.length - 6,
                            i18next.t('misc.more')));
                    }
                }

                html.push('</td>');

            } else {
                html.push('<td>-/-</td>');
            }

            return html.join('');
        };

        Panel.prototype.renderUser = function (user) {
            return user && user.Fullname ?
                '<td>{0}</td>'.format(user.Fullname) :
                '<td>-/-</td>';
        };

        Panel.prototype.renderValue = function (disturbance) {
            var settings = {
                categoryInline: true,
                suppressInfos: false,
                alignLeft: true,
                scaledDown: true
            };

            return '<td>{0}</td>'.format(Tools.values.render(disturbance, settings));
        };

        Panel.prototype.getDisturbances = function (next) {
            var that = this;
            var deferred = $.Deferred();
            var promise = deferred.promise();
            var params = this.getParams();

            if (!next && this.Disturbances.length) {
                deferred.resolve(this.Disturbances);
            } else {
                Tools.Spinner.show();

                this.load(params).then(function () {
                    deferred.resolve(that.Disturbances);
                    Tools.Spinner.hide();
                }, function (xhr) {
                    if (xhr) {
                        Tools.Spinner.hide();
                    }

                    Tools.handleHttpError(Enums.HttpActionType.Read, xhr);
                });
            }

            return promise;
        };

        Panel.prototype.renderRows = function (disturbances) {
            var that = this;
            var html = [];

            if (!this.TotalCount) {
                $tableBody.html(this.renderEmptyRow());

                $tableHeader.width($tableBody.width());

                return;
            }

            html = $.map(disturbances || [], function (disturbance) {
                return that.renderRow(disturbance);
            });

            $tableBody.find('tbody').html(html.join(''));
            $tableBody.find('img').on('error', Tools.OnImageNotFound);

            if (this.Disturbances.length < this.TotalCount) {
                $tableWrapper.on('scroll.infiniteScroll', $.proxy(this.onScroll, this));
            }

            $tableHeader.width($tableBody.width());

            return disturbances;
        };

        Panel.prototype.onScroll = function () {
            var wrapperHeight = $tableWrapper.innerHeight();
            var tableHeight = $tableBody.outerHeight(false);
            var scrollPosition = $tableWrapper.scrollTop();

            if (tableHeight - wrapperHeight - scrollPosition <= 300) {
                $tableWrapper.off('scroll.infiniteScroll');
                this.getDisturbances(true).done($.proxy(this.renderRows, this));
            }
        };

        Panel.prototype.show = function () {
            this.init();

            this.getDisturbances().then($.proxy(this.renderRows, this), function (xhr) {
                Tools.Spinner.hide();
                Tools.handleHttpError(Enums.HttpActionType.Read, xhr);
            });
        };

        Panel.resetControls = function () {
            $userFilter.find('.button').removeClass('active');
            $teamFilter.find('.button').removeClass('active');
            $keywordFilter.find('.button').removeClass('active');
            $classificationFilter.find('.button').removeClass('active');
            $statusFilter.find('.button').removeClass('active');
            $periodFilter.removeClass('active');
        };

        Panel.prototype.resize = function () {
            this.initToolbars();

            $tableHeader.width($tableBody.width());
        };

        return Panel;
    }());

    var IssueRecorditemDisturbancePanel = (function () {
        function IssueRecorditemDisturbancePanel(filter) {
            this.DisturbanceType = Disturbance.Types.IssueRecorditemDisturbance;
            this.Disturbances = [];
            this.TotalCount = 0;

            filter = filter || {};
            this.Filter.LocationOID = filter.LocationOID;
            this.Filter.WithChildLocations = filter.WithChildLocations;
            this.Filter.WithDeactivatedElements = filter.WithDeactivatedElements;
            this.Filter.HideNonResponsibleIssues = filter.HideNonResponsibleIssues;

            if (filter.Period) {
                this.Filter.Period = filter.Period;
            }

            if (filter.Users) {
                this.Filter.Users = filter.Users;
            }

            if (filter.Teams) {
                this.Filter.Teams = filter.Teams;
            }
        }

        IssueRecorditemDisturbancePanel.prototype = new Panel();

        IssueRecorditemDisturbancePanel.prototype.constructor = IssueRecorditemDisturbancePanel;

        IssueRecorditemDisturbancePanel.prototype.findDisturbance = function (oid) {
            var i, len, disturbance;

            for (i = 0, len = (this.Disturbances || []).length; i < len; i++) {
                disturbance = this.Disturbances[i];

                if (disturbance.OID === oid) {
                    return disturbance;
                }

                disturbance = Tools.getFirst(disturbance.Recorditems, oid, 'OID');

                if (disturbance) {
                    return disturbance;
                }
            }
        };

        IssueRecorditemDisturbancePanel.prototype.hasSelectedDisturbances = function () {
            return Tools.contains(this.Disturbances, function (issue) {
                return issue.IsSelected || Tools.contains(issue.Recorditems, true, 'IsSelected');
            });
        };

        IssueRecorditemDisturbancePanel.prototype.getSelectedDisturbances = function () {
            var result = [];

            (this.Disturbances || []).forEach(function (issue) {
                if (issue.IsSelected) {
                    result.push(issue);
                } else {
                    (issue.Recorditems || []).forEach(function (recorditem) {
                        if (recorditem.IsSelected) {
                            result.push(recorditem);
                        }
                    });
                }
            });

            return result;
        };

        IssueRecorditemDisturbancePanel.prototype.onToggleSelection = function (event) {
            var $this = $(event.currentTarget);
            var isChecked = $this.prop('checked');
            var $tr;

            if ($this.hasClass('select-all')) {
                this.IsAllSelected = isChecked;
                $tableBody.find('input:checkbox').prop('checked', !!isChecked);

                (this.Disturbances || []).forEach(function (issue) {
                    issue.IsSelected = isChecked;

                    (issue.Recorditems || []).forEach(function (recorditem) {
                        recorditem.IsSelected = isChecked;
                    });
                });

            } else {
                this.IsAllSelected = false;
                $tableHeader.find('input:checkbox').prop('checked', false);

                oid = $this.closest('tr').data('oid');
                disturbance = this.findDisturbance(oid);

                if (!disturbance) {
                    return;
                }

                disturbance.IsSelected = isChecked;

                if (($tr = $this.closest('.has-details[data-oid="{0}"]'.format(oid))).length) {
                    $tr.siblings('.details[data-oid="{0}"]'.format(oid)).find('input:checkbox').prop('checked', !!isChecked);

                    (disturbance.Recorditems || []).forEach(function (recorditem) {
                        recorditem.IsSelected = isChecked;
                    });
                } else if (($tr = $this.closest('.details[data-oid]')).length) {
                    oid = $tr.data('oid');
                    $tableBody.find('.has-details[data-oid="{0}"] input:checkbox'.format(oid)).prop('checked', false);

                    disturbance = this.findDisturbance(oid);
                    disturbance.IsSelected = false;
                }
            }

            this.initAction();
        };

        IssueRecorditemDisturbancePanel.prototype.onToggleDetails = function (event) {
            var $tr = $(event.currentTarget).closest('tr');
            var oid = $tr.data('oid');
            var disturbance = this.findDisturbance(oid);
            var isExpanded = !$tr.hasClass('is-expanded');

            RecorditemDetails.hide();

            disturbance.IsExpanded = isExpanded;

            if (isExpanded) {
                this.showDetails($tr);
                $tr.addClass('is-expanded');
            } else {
                this.hideDetails($tr);
                $tr.removeClass('is-expanded');
            }

            event.stopPropagation();
        };

        IssueRecorditemDisturbancePanel.prototype.init = function () {
            $tableBody.off('click.toggleDetails');
            $tableBody.on('click.toggleDetails', '.collapsible-handler', $.proxy(this.onToggleDetails, this));

            Panel.prototype.init.call(this);
        };

        IssueRecorditemDisturbancePanel.prototype.initControls = function () {
            Panel.prototype.initControls.call(this);

            $explorerDisturbances.find('.table-filter-column.last-row').removeClass('hide');

            this.initActions();
            this.initTypeFilter();
            this.initFormFilter();
            this.initSchedulingFilter();
            this.initPriorityFilter();
            this.initCreatorFilter();
            this.initUserFilter();
            this.initTeamFilter();
            this.initContactFilter();
            this.initContactGroupFilter();
            this.initKeywordFilter();
            this.initClassificationFilter();
            this.initStatusFilter();
            this.initToolbars();
            this.initPeriodFilter();
            this.initHideNonResponsibleIssuesFilter();
            this.initSearch();
            this.setFilterState();
        };

        IssueRecorditemDisturbancePanel.prototype.initActions = function () {
            var $actionDropdown = $explorerDisturbances.find('.gfx-action-window.disturbance-action');

            $actionDropdown.find('.action-window-content-element.hide').removeClass('hide');
        };

        IssueRecorditemDisturbancePanel.prototype.renderHeader = function () {
            var html = [];

            html.push('<colgroup>');
            html.push('<col style="width: 30px;" />');
            html.push('<col style="width: 30px;" />');
            html.push('<col style="width: 10%;" />');
            html.push('<col style="width: 35%;" />');
            html.push('<col style="width: 10%;" />');
            html.push('<col style="width: 130px;" />');
            html.push('<col style="width: 15%;" />');
            html.push('<col style="width: 15%;" />');
            html.push('</colgroup>');
            html.push('<thead>');
            html.push('<tr>');
            html.push('<th>');

            if (ressources.users.hasRightAtLocation('TMFT', CurrentEntity.OID)) {
                html.push('<input type="checkbox" class="select-all" {0}>'.format(this.IsAllSelected ? 'checked' : ''));
            }

            html.push('</th>');
            html.push('<th style="color: #FF0000; font-size: 18px; text-align: center">!</th>');
            html.push('<th>{0}</th>'.format(i18next.t('explorer.disturbances.tabs.issueRecorditemDisturbances.tableColumns.id')));
            html.push('<th colspan="2">{0}</th>'.format(i18next.t('explorer.disturbances.tabs.issueRecorditemDisturbances.tableColumns.title')));
            html.push('<th>{0}</th>'.format(i18next.t('explorer.disturbances.tabs.issueRecorditemDisturbances.tableColumns.deadline')));
            html.push('<th>{0}</th>'.format(i18next.t('explorer.disturbances.tabs.issueRecorditemDisturbances.tableColumns.status')));
            html.push('<th>{0}</th>'.format(i18next.t('explorer.disturbances.tabs.issueRecorditemDisturbances.tableColumns.teamsAndUsers')));
            html.push('</tr>');
            html.push('</thead>');

            return html.join('');
        };

        IssueRecorditemDisturbancePanel.prototype.renderBody = function () {
            var html = [];

            html.push('<colgroup>');
            html.push('<col style="width: 30px;" />');
            html.push('<col style="width: 30px;" />');
            html.push('<col style="width: 10%;" />');
            html.push('<col style="width: 35%;" />');
            html.push('<col style="width: 10%;" />');
            html.push('<col style="width: 130px;" />');
            html.push('<col style="width: 15%;" />');
            html.push('<col style="width: 15%;" />');
            html.push('</colgroup>');
            html.push('<tbody></tbody>');

            return html.join('');
        };

        IssueRecorditemDisturbancePanel.prototype.renderEmptyRow = function () {
            return '<tr><td colspan="8"><span>{0}</span></td></tr>'
                .format(i18next.t('explorer.disturbances.noDataAvailable'));
        };

        IssueRecorditemDisturbancePanel.prototype.renderRow = function (issue) {
            var html = [];
            var locationPath = issue.getElementPath();
            var image = issue.getImage();
            var mailTokens = ((issue.AdditionalData || {}).MailToken || '').split('|');
            var mailAddresses;

            html.push('<tr class="issue has-details {0}" data-oid="{1}" data-issue-id="{2}">'.format(
                issue.IsExpanded ? 'is-expanded' : '', issue.OID, issue.ID));

            html.push('<td>');

            if (ressources.users.hasRightAtLocation('TMFT', CurrentEntity.OID)) {
                html.push('<input type="checkbox" {0}>'.format(issue.IsSelected ? 'checked' : ''));
            }

            html.push('</td>');
            html.push('<td><div class="collapsible-handler"></div></td>');

            html.push('<td><span class="issue-id">{0}{1}.{2}</span>'.format(
                issue.getAbbreviation(), issue.ID, issue.Revision));

            html.push('<img class="typeIcon" src="{0}" />'.format(issue.getIcon()));
            html.push(' <img class="print" src="./img/pdf-icon.svg">');

            html.push('</td><td {0}><span class="title">{1}</span>'.format(
                image ? '' : 'colspan="2"',
                issue.Title || i18next.t('misc.untitled')));

            if (mailTokens.length > 1) {
                mailAddresses = mailTokens.slice(1).join(', ');

                html.push('<p class="mail-addresses">{0}</p>'.format(mailAddresses));
            }

            if (issue.InvestigationStatusInformation) {
                var errorText = issue.InvestigationStatusInformation.Status === Enums.Investigations.Status.Error ?
                    Tools.issue.getInvestigationErrorText(issue.InvestigationStatusInformation.ErrorCode) :
                    null;

                html.push('<p class="investigation-status{0}" title="{1}">{2}{3}</p>'
                    .format(
                        issue.InvestigationStatusInformation.Status === Enums.Investigations.Status.Error ? ' with-error' : '',
                        i18next.t('misc.investigationStatus.helpText'),
                        Tools.issue.getInvestigationStatusText(issue.InvestigationStatusInformation.Status),
                        !!errorText ? (' (' + errorText + ')') : ''
                    )
                );
            }

            if (locationPath) {
                html.push('<p class="location">{0}</p>'.format(locationPath));
            }

            html.push(this.renderCommentsAndSubscriptions(issue.Recipients, issue.Comments));

            if (image) {
                html.push(this.renderImage(image));
            }

            html.push(this.renderTimestamp(issue.DeadlineTimestamp));
            html.push(this.renderState(issue.State));

            var assignedUsers = (issue.ResponsibilityAssignments || {}).Users || {};
            var assignedTeams = (issue.ResponsibilityAssignments || {}).Teams || {};

            html.push(this.renderTeamsAndUsers(assignedUsers, assignedTeams));

            html.push('</tr>');

            if (issue.IsExpanded) {
                html.push(this.renderDetails(issue));
            }

            return html.join('');
        };

        IssueRecorditemDisturbancePanel.prototype.getParams = function () {
            var filter = this.Filter || {};

            var params = {
                Skip: this.Disturbances.length,
                Take: 50,
                Sorting: 'id',
                SortingOrder: 'desc',
                LocationOID: filter.LocationOID,
                WithChildLocations: filter.WithChildLocations,
                WithDeactivatedElements: filter.WithDeactivatedElements,
                Creators: filter.Creators,
                Users: filter.Users,
                Teams: filter.Teams,
                Contacts: filter.Contacts,
                ContactGroups: filter.ContactGroups,
                Keywords: filter.Keywords,
                Forms: filter.Forms,
                Scheduling: filter.Scheduling,
                Priorities: filter.Priorities,
                Classifications: filter.Classifications,
                States: filter.States,
                HideNonResponsibleIssues: filter.HideNonResponsibleIssues,
                SearchString: filter.SearchValue
            };

            params.Types = Array.prototype.concat.apply([], (filter.Types || []).map(function (typeFilter) {
                return typeFilter
                    .split('-')
                    .map(function (type) {
                        return parseInt(type, 10);
                    });
            }));

            if (filter.Period && (filter.Period.Start || filter.Period.End)) {
                if (filter.Period.CurrentType === Enums.DatePickerTypes.Creation) {
                    params.CreationPeriod = {
                        PeriodStart: filter.Period.Start ? Tools.dateTime.toGMTString(filter.Period.Start) : null,
                        PeriodEnd: filter.Period.End ? Tools.dateTime.toGMTString(filter.Period.End) : null
                    };
                } else if (filter.Period.CurrentType === Enums.DatePickerTypes.Modification) {
                    params.ModificationPeriod = {
                        PeriodStart: filter.Period.Start ? Tools.dateTime.toGMTString(filter.Period.Start) : null,
                        PeriodEnd: filter.Period.End ? Tools.dateTime.toGMTString(filter.Period.End) : null
                    };
                } else if (filter.Period.CurrentType === Enums.DatePickerTypes.Deadline) {
                    params.DeadlinePeriod = {
                        PeriodStart: filter.Period.Start ? Tools.dateTime.toGMTString(filter.Period.Start) : null,
                        PeriodEnd: filter.Period.End ? Tools.dateTime.toGMTString(filter.Period.End) : null
                    };
                }
            }

            return params;
        };

        IssueRecorditemDisturbancePanel.prototype.load = function (params) {
            var that = this;

            if (this.LoadRequest) {
                this.LoadRequest.abort();
                this.LoadRequest = null;
            }

            that.MenuItem = Explorer.GetCurrentTab();

            if (!that.MenuItem) {
                return $.Deferred().reject().promise();
            }

            return (this.LoadRequest = Tools.http.post('reports/disturbances?resulttype=issues', params))
                .then(function (report) {
                    that.LoadRequest = null;
                    that.TotalCount = report.TotalCount;

                    issueRecorditemDisturbances = report.TotalCount > 0;
                    that.setTabCounter(report.TotalCount);

                    updateTabCounter(that.MenuItem, null, true);

                    var missingElements = [];

                    report.Data = (report.Data || []).map(function (issue) {
                        if (!DataManager.OrganizationUnitLoader.Data.DataMap.hasOwnProperty(issue.AssignedElementOID)) {
                            missingElements.push({
                                OID: issue.AssignedElementOID,
                                WithAncestors: true
                            });
                        }

                        var disturbance = new IssueDisturbance(Tools.Escape.Issue(issue));

                        disturbance.IsSelected = that.IsAllSelected;

                        return disturbance;
                    });

                    report.Data.sort(function (a, b) {
                        return b.ID - a.ID;
                    });

                    report.Data.forEach(function (issue) {
                        that.Disturbances = Tools.addUnique(that.Disturbances, issue, 'OID');
                    });

                    return missingElements;
                }, function (xhr) {
                    Tools.handleHttpError(Enums.HttpActionType.Read, xhr);
                })
                .then(function (missingElements) {
                    if (!(missingElements instanceof Array) || !missingElements.length) {
                        return;
                    }

                    return Tools.http.post('elements/', missingElements);
                })
                .then(function (elements) {
                    if (!(elements instanceof Array) || !elements.length) {
                        return;
                    }

                    elements.forEach(function (element) {
                        DataManager.OrganizationUnitLoader.Data.DataMap[element.OID] = element;
                    });

                    elements.forEach(function (element) {
                        if (!element.ParentOID) {
                            return;
                        }

                        var parent = DataManager.OrganizationUnitLoader.Data.DataMap[element.ParentOID];

                        if (!parent) {
                            return;
                        }

                        parent.Children = parent.Children || [];
                        parent.Children.push(element);
                        parent.Children.sort(Tools.SortByPosition);

                        element.Parent = parent;
                    });
                }, function (xhr) {
                    Tools.handleHttpError(Enums.HttpActionType.Read, xhr);
                });
        };

        IssueRecorditemDisturbancePanel.prototype.loadDetails = function (issue) {
            var params = {
                IssueId: issue.ID,
                Skip: 0,
                Take: 1000
            };

            return Tools.http.post('reports/disturbances?resulttype=recorditems', params)
                .then(function (report) {
                    var missingElements = [];

                    issue.Recorditems = $.map(report.Data, function (recorditem) {
                        if (!DataManager.OrganizationUnitLoader.Data.DataMap.hasOwnProperty(recorditem.ElementOID)) {
                            missingElements.push({
                                RevisionOID: recorditem.ElementRevisionOID,
                                WithAncestors: true
                            });
                        }

                        var disturbance = new RecorditemDisturbance(
                            Tools.Escape.Recorditem(recorditem, true, true)
                        );

                        disturbance.IsSelected = issue.IsSelected;

                        return disturbance;
                    });

                    issue.Recorditems.sort(function (a, b) {
                        return a.ModificationTimestamp.getTime() - b.ModificationTimestamp.getTime();
                    });

                    return missingElements;
                }, function (xhr) {
                    Tools.handleHttpError(Enums.HttpActionType.Read, xhr);
                })
                .then(function (missingElements) {
                    if (!missingElements.length) {
                        return;
                    }

                    return Tools.http.post('elements/', missingElements);
                })
                .then(function (elements) {
                    var cache = Tools.element.createHierarchyForDownloadedElements(elements);

                    if (!cache) {
                        return;
                    }

                    for (var oid in cache) {
                        if (!_cachedElements) {
                            break;
                        }

                        _cachedElements[oid] = cache[oid];
                    }
                }, function (xhr) {
                    Tools.handleHttpError(Enums.HttpActionType.Read, xhr);
                });
        };

        IssueRecorditemDisturbancePanel.prototype.getDetails = function (issueOID) {
            var deferred = $.Deferred();
            var promise = deferred.promise();
            var issue = this.findDisturbance(issueOID);

            if (!issue) {
                deferred.reject();
            } else if ((issue.Recorditems || []).length) {
                deferred.resolve(issue);
            } else {
                Tools.Spinner.show();

                this.loadDetails(issue).done(function () {
                    deferred.resolve(issue);
                    Tools.Spinner.hide();
                });
            }

            return promise;
        };

        IssueRecorditemDisturbancePanel.prototype.renderDetails = function (issue) {
            var that = this;
            var html = [];

            html.push('<tr class="details" data-oid="{0}" data-issue-id="{1}">'.format(issue.OID, issue.ID));
            html.push('<td colspan="2">&nbsp;</td>');
            html.push('<td colspan="6">');
            html.push('<table>');
            html.push('<colgroup>');
            html.push('<col style="width: 30px;" />');
            html.push('<col style="width: 35%;" />');
            html.push('<col style="width: 35%;" />');
            html.push('<col style="width: 130px;" />');
            html.push('<col style="width: 15%;" />');
            html.push('</colgroup>');
            html.push('<thead>');
            html.push('<tr>');
            html.push('<th>&nbsp;</th>');
            html.push('<th>{0}</th>'.format(i18next.t('explorer.disturbances.tabs.issueRecorditemDisturbances.detailTableColumns.recorditem')));
            html.push('<th>{0}</th>'.format(i18next.t('explorer.disturbances.tabs.issueRecorditemDisturbances.detailTableColumns.value')));
            html.push('<th>{0}</th>'.format(i18next.t('explorer.disturbances.tabs.issueRecorditemDisturbances.detailTableColumns.timestamp')));
            html.push('<th>{0}</th>'.format(i18next.t('explorer.disturbances.tabs.issueRecorditemDisturbances.detailTableColumns.creator')));
            html.push('</tr>');
            html.push('</thead>');
            html.push('<tbody>');

            if (!(issue.Recorditems || []).length) {
                html.push(RecorditemDisturbancePanel.prototype.renderEmptyRow.call(this));
            } else {
                (issue.Recorditems || []).forEach(function (recorditem) {
                    html.push(RecorditemDisturbancePanel.prototype.renderRow.call(that, recorditem));
                });
            }

            html.push('</tbody>');
            html.push('</table>');
            html.push('</td>');
            html.push('</tr>');

            return html.join('');
        };

        IssueRecorditemDisturbancePanel.prototype.hideDetails = function ($tr) {
            var oid = $tr.data('oid');

            $tr.siblings('.details[data-oid="{0}"]'.format(oid)).remove();
        };

        IssueRecorditemDisturbancePanel.prototype.showDetails = function ($tr) {
            var that = this;
            var issueOID = $tr.data('oid');

            this
                .getDetails(issueOID)
                .done(function (issue) {
                    $tr.after(that.renderDetails(issue));
                    $tr.find('img').on('error', Tools.OnImageNotFound);

                    return issue;
                });
        };

        IssueRecorditemDisturbancePanel.prototype.setTabCounter = function (count) {
            $explorerDisturbances.find('.tab[data-tab="1"] .count').text(count || '');
        };

        IssueRecorditemDisturbancePanel.prototype.updateTabCounter = function () {
            var that = this;
            var params = this.getParams();

            params.Take = 0;

            that.MenuItem = Explorer.GetCurrentTab();

            return this.load(params).then(function (data) {
                if (data) {
                    issueRecorditemDisturbances = data.TotalCount > 0;
                    that.setTabCounter(data.TotalCount);
                }

                updateTabCounter(that.MenuItem, null, true);

                return data.TotalCount;
            }, function () {
                that.setTabCounter('!');
            });
        };

        IssueRecorditemDisturbancePanel.prototype.updateComments = function (changeInfo) {
            if (!changeInfo) {
                return;
            }

            var issue = this.findDisturbance(changeInfo.IssueOID);

            if (!issue) {
                return;
            }

            issue.Comments = Tools.issue.prepareCommentsForWebApp(changeInfo.Comments);

            var $issue = $tableWrapper.find('tr[data-oid="' + issue.OID + '"]');

            if (!$issue.length) {
                return;
            }

            $issue.find('.commentdetails').remove();

            var commentsMarkup = this.renderCommentsAndSubscriptions(issue.Recipients, issue.Comments);
            $issue.find('> td > .location').after(commentsMarkup);
        };

        return IssueRecorditemDisturbancePanel;
    }());

    var RecorditemDisturbancePanel = (function () {
        function RecorditemDisturbancePanel(filter) {
            this.DisturbanceType = Disturbance.Types.RecorditemDisturbance;
            this.Disturbances = [];
            this.TotalCount = 0;

            filter = filter || {};
            this.Filter.LocationOID = filter.LocationOID;
            this.Filter.WithChildLocations = filter.WithChildLocations;
            this.Filter.WithDeactivatedElements = filter.WithDeactivatedElements;
        }

        RecorditemDisturbancePanel.prototype = new Panel();

        RecorditemDisturbancePanel.prototype.constructor = RecorditemDisturbancePanel;

        RecorditemDisturbancePanel.prototype.initControls = function () {
            Panel.prototype.initControls.call(this);

            this.initActions();
            this.initToolbars();
        };

        RecorditemDisturbancePanel.prototype.initActions = function () {
            var $actionDropdown = $explorerDisturbances.find('.gfx-action-window.disturbance-action');

            $actionDropdown.find('.action-window-content-element[data-selection="3"]').addClass('hide');
        };

        RecorditemDisturbancePanel.prototype.initToolbars = function () {
            $resetFilters.addClass('hide');
            $toggleControls.addClass('hide');
            $explorerDisturbances.find('.table-filter-column').addClass('hide');
            $tableWrapper.css('top', $tableView.outerHeight() + $explorerDisturbances.children('.table-container').outerHeight() + 10);
        };

        RecorditemDisturbancePanel.prototype.renderHeader = function () {
            var html = [];

            html.push('<colgroup>');
            html.push('<col style="width: 30px;" />');
            html.push('<col style="width: 35%;" />');
            html.push('<col style="width: 35%;" />');
            html.push('<col style="width: 130px;" />');
            html.push('<col style="width: 15%;" />');
            html.push('</colgroup>');
            html.push('<thead>');
            html.push('<tr>');
            html.push('<th>');

            if (ressources.users.hasRightAtLocation('TMFT', CurrentEntity.OID)) {
                html.push('<input type="checkbox" class="select-all" {0}>'.format(
                    this.IsAllSelected ? 'checked' : ''));
            }

            html.push('</th>');
            html.push('<th>{0}</th>'.format(i18next.t('explorer.disturbances.tabs.recorditemDisturbances.tableColumns.recorditem')));
            html.push('<th>{0}</th>'.format(i18next.t('explorer.disturbances.tabs.recorditemDisturbances.tableColumns.value')));
            html.push('<th>{0}</th>'.format(i18next.t('explorer.disturbances.tabs.recorditemDisturbances.tableColumns.timestamp')));
            html.push('<th>{0}</th>'.format(i18next.t('explorer.disturbances.tabs.recorditemDisturbances.tableColumns.creator')));
            html.push('</tr>');
            html.push('</thead>');

            return html.join('');
        };

        RecorditemDisturbancePanel.prototype.renderBody = function () {
            var html = [];

            html.push('<colgroup>');
            html.push('<col style="width: 30px" />');
            html.push('<col style="width: 35%;" />');
            html.push('<col style="width: 35%;" />');
            html.push('<col style="width: 130px;" />');
            html.push('<col style="width: 15%;" />');
            html.push('</colgroup>');
            html.push('<tbody></tbody>');

            return html.join('');
        };

        RecorditemDisturbancePanel.prototype.renderEmptyRow = function () {
            return '<tr><td colspan="5"><span>{0}</span></td></tr>'
                .format(i18next.t('explorer.disturbances.noDataAvailable'));
        };

        RecorditemDisturbancePanel.prototype.renderRow = function (recorditem) {
            var html = [];
            var locationPath = recorditem.getElementPath();

            html.push('<tr data-oid="{0}" data-recorditem-id="{1}">'.format(
                recorditem.OID, recorditem.ID));

            html.push('<td>');

            if (ressources.users.hasRightAtLocation('TMFT', CurrentEntity.OID)) {
                html.push('<input type="checkbox" {0}>'.format(
                    recorditem.IsSelected ? 'checked' : ''));
            }

            html.push('</td>');
            html.push('<td><span class="title">{0}</span>'.format(
                recorditem.Element.Title || i18next.t('misc.untitled')));

            if (locationPath) {
                html.push('<p class="location">{0}</p>'.format(locationPath));
            }

            html.push(this.renderValue(recorditem));
            html.push(this.renderTimestamp(recorditem.ModificationTimestamp));
            html.push(this.renderUser(recorditem.Editor));

            html.push('</tr>');

            return html.join('');
        };

        RecorditemDisturbancePanel.prototype.getParams = function () {
            var filter = this.Filter || {};

            return {
                Skip: this.Disturbances.length,
                Take: 50,
                Sorting: 'modificationtimestamp',
                SortingOrder: 'desc',
                IssueID: 0,
                LocationOID: filter.LocationOID,
                WithChildLocations: filter.WithChildLocations,
                WithDeactivatedElements: filter.WithDeactivatedElements
            };
        };

        RecorditemDisturbancePanel.prototype.load = function (params) {
            var that = this;

            that.MenuItem = Explorer.GetCurrentTab();

            if (!that.MenuItem) {
                return $.Deferred().reject().promise();
            }

            return Tools.http.post('reports/disturbances?resulttype=recorditems', params)
                .then(function (report) {
                    that.TotalCount = report.TotalCount;

                    recorditemDisturbances = report.TotalCount > 0;
                    that.setTabCounter(report.TotalCount);
                    updateTabCounter(that.MenuItem, null, true);

                    var missingElements = [];

                    report.Data = (report.Data || []).map(function (recorditem) {
                        if (!DataManager.OrganizationUnitLoader.Data.DataMap.hasOwnProperty(recorditem.ElementOID)) {
                            missingElements.push({
                                OID: recorditem.ElementOID,
                                WithAncestors: true
                            });
                        }

                        var disturbance = new RecorditemDisturbance(Tools.Escape.Recorditem(recorditem, true, true));

                        disturbance.IsSelected = that.IsAllSelected;

                        return disturbance;
                    });

                    report.Data.sort(function (a, b) {
                        return a.ModificationTimestamp.getTime() - b.ModificationTimestamp.getTime();
                    });

                    report.Data.forEach(function (disturbance) {
                        that.Disturbances = Tools.addUnique(that.Disturbances, disturbance, 'OID');
                    });

                    return missingElements;
                }, function (xhr) {
                    Tools.handleHttpError(Enums.HttpActionType.Read, xhr);
                })
                .then(function (missingElements) {
                    if (!(missingElements instanceof Array) || !missingElements.length) {
                        return;
                    }

                    return Tools.http.post('elements/', missingElements);
                })
                .then(function (elements) {
                    var cache = Tools.element.createHierarchyForDownloadedElements(elements);

                    if (!cache) {
                        return;
                    }

                    for (var oid in cache) {
                        if (!_cachedElements) {
                            break;
                        }

                        _cachedElements[oid] = cache[oid];
                    }
                }, function (xhr) {
                    Tools.handleHttpError(Enums.HttpActionType.Read, xhr);
                });
        };

        RecorditemDisturbancePanel.prototype.setTabCounter = function (count) {
            $explorerDisturbances
                .find('.tab[data-tab="2"] .count')
                .text(count || '');
        };

        RecorditemDisturbancePanel.prototype.updateTabCounter = function () {
            var that = this;
            var params = this.getParams();

            params.Take = 0;

            that.MenuItem = Explorer.GetCurrentTab();

            this.load(params).then(function (data) {
                if (data) {
                    recorditemDisturbances = data.TotalCount > 0;
                    that.setTabCounter(data.TotalCount);
                }

                updateTabCounter(that.MenuItem, null, true);

                return data;
            }, function () {
                that.setTabCounter('!');
            });
        };

        return RecorditemDisturbancePanel;
    }());

    function init(options) {
        var filter = {
            LocationOID: CurrentEntity.OID,
            WithChildLocations: Explorer.GetWithChildLocations(),
            WithDeactivatedElements: Explorer.GetWithDeactivatedElements(),
            HideNonResponsibleIssues: hideNonResponsibleIssues
        };

        _cachedElements = {};
        userCanAssignAllTeamsAndUsers = ressources.users.hasRightAtLocation(
            Enums.Rights.IssueRights.AllowAssigningAllUsersToIssues,
            CurrentEntity.OID
        );

        $tree.tree('remove-class-from-all-nodes', 'jquery-tree-node-marker-green jquery-tree-node-marker-yellow jquery-tree-node-marker-red');

        initTypes();
        initTab();
        initUsers();
        initTeams();
        initLanguage();
        unbindEvents();
        bindEvents();

        panels = panels || [];

        if (options && options.filter) {
            if (options.filter.PeriodFilter) {
                filter.Period = options.filter.PeriodFilter;
            }

            if (options.filter.Users instanceof Array) {
                filter.Users = options.filter.Users.map(function (user) {
                    return user.OID;
                });
            }

            if (options.filter.Teams instanceof Array) {
                filter.Teams = options.filter.Teams.map(function (team) {
                    return team.OID;
                });
            }
        }

        if (panels.length) {
            panels.forEach(function (panel) {
                panel.reset(filter);
            });
        } else {
            panels.push(new IssueRecorditemDisturbancePanel(filter));
            panels.push(new RecorditemDisturbancePanel(filter));
        }

        if (options && options.panel === 'IssueDisturbances') {
            currentPanel = panels[0];
        } else if (options && options.panel === 'RecorditemDisturbances') {
            currentPanel = panels[1];
        } else {
            currentPanel = currentPanel || panels[0];
        }

        panels.forEach(function (panel) {
            if (panel !== currentPanel) {
                panel.updateTabCounter();
            }
        });
    }

    function initTab() {
        $explorerDashboard.addClass('hide');
        $explorerInfo.addClass('hide');
        $explorerValues.addClass('hide');
        $explorerNews.addClass('hide');
        $explorerIssues.addClass('hide');
        $explorerDisturbances.removeClass('hide');
        $explorerScheduling.addClass('hide');
        $explorerFiles.addClass('hide');
        $explorerArchive.addClass('hide');
        $properties.removeClass('active');

        $explorerTabMenu.find('.open').removeClass('open');

        $explorerOptionMenu.removeClass('active');
    }

    function initLanguage() {
        $tableView.find('.lbl').text(i18next.t('explorer.disturbances.view') + ':');
        $tableView.find('.tab[data-tab="1"] .title').text(i18next.t('explorer.disturbances.tabs.issueRecorditemDisturbances.title'));
        $tableView.find('.tab[data-tab="2"] .title').text(i18next.t('explorer.disturbances.tabs.recorditemDisturbances.title'));

        $action.find('.btn-title').text(i18next.t('explorer.disturbances.measures.label'));
        $disturbanceAction.find('.action-window-content-element[data-selection="1"]').text(i18next.t('explorer.disturbances.measures.createCorrectiveAction'));
        $disturbanceAction.find('.action-window-content-element[data-selection="2"]').text(i18next.t('explorer.disturbances.measures.closeDisturbance'));
        $disturbanceAction.find('.action-window-content-element[data-selection="3"]').text(i18next.t('explorer.disturbances.measures.editIssues'));

        $resetFilters.attr('title', i18next.t('explorer.toolbar.resetFilters'));
        $toggleControls.attr('title', i18next.t('explorer.toolbar.showFilters'));

        $('#explorer-disturbances-type-filter-lbl').text(i18next.t('explorer.disturbances.typeFilter.label'));
        $('#explorer-disturbances-form-filter-lbl').text(i18next.t('explorer.disturbances.formFilter.label'));
        $('#explorer-disturbances-scheduling-filter-lbl').text(i18next.t('explorer.disturbances.schedulingFilter.label'));
        $('#explorer-disturbances-priority-filter-lbl').text(i18next.t('explorer.disturbances.priorityFilter.label'));

        $('#explorer-disturbances-creator-filter-lbl').text(i18next.t('explorer.disturbances.creatorFilter.label'));
        $('#explorer-disturbances-user-filter-lbl').text(i18next.t('explorer.disturbances.userFilter.label'));
        $('#explorer-disturbances-team-filter-lbl').text(i18next.t('explorer.disturbances.teamFilter.label'));
        $('#explorer-disturbances-contact-filter-lbl').text(i18next.t('explorer.disturbances.contactFilter.label'));
        $('#explorer-disturbances-contact-group-filter-lbl').text(i18next.t('explorer.disturbances.contactGroupFilter.label'));
        $('#explorer-disturbances-keyword-filter-lbl').text(i18next.t('explorer.disturbances.keywordFilter.label'));
        $('#explorer-disturbances-classification-filter-lbl').text(i18next.t('explorer.disturbances.classificationFilter.label'));
        $('#explorer-disturbances-status-filter-lbl').text(i18next.t('explorer.disturbances.statusFilter.label'));
        $('#explorer-disturbances-hide-non-responsible-issues-lbl').text(i18next.t('explorer.issues.hideNonResponsibleIssues'));

        $('#explorer-disturbances-period-filter-lbl').text(i18next.t('explorer.disturbances.periodFilter.label'));
        $periodFilter.find('.btn-title').text(i18next.t('explorer.disturbances.periodFilter.button'));
    }

    function initUsers() {
        users = Tools.GetSelectableUsers(CurrentEntity.OID);
        users.sort(Tools.SortByFullname);
    }

    function initTeams() {
        var teamInformation = Tools.GetSelectableTeams(CurrentEntity.OID);

        teams = teamInformation.Teams;
        visibleTeams = teamInformation.VisibleTeams;
        selectableTeams = teamInformation.SelectableTeams;
    }

    function unbindEvents() {
        $tableView.off('click.switchPanel');
    }

    function bindEvents() {
        $tableView.on('click.switchPanel', '.tab:not(.selected)', onSwitchPanel);
        $tableView.find('img').on('error', Tools.OnImageNotFound);
    }

    function onSwitchPanel() {
        var idx = parseInt($(this).data('tab'), 10) - 1;

        currentPanel = panels[idx];
        currentPanel.show();
    }

    function onToggleControls(event) {
        if (event) {
            var $target = $(event.currentTarget);
            IsFilterVisible = !$target.hasClass('collapsed');
        }

        var $tableFilterSelection = $explorerDisturbances.find('.table-filter-selection');

        if ($tableFilterSelection == null) {
            return;
        }

        var $controls = $tableFilterSelection.find('.toggle-table-controls');
        var $firstTableRow = $tableFilterSelection.find('.table-filter-column-first-row');
        var $filterColumns = $tableFilterSelection.find('.table-filter-column:not(.last-row)');

        if ($tableFilterSelection.hasClass('reduced-filters') || IsFilterVisible) {
            $tableFilterSelection.removeClass('reduced-filters');
            $tableFilterSelection.addClass('grid-row');
            $firstTableRow.removeClass('reduced-row');
            $filterColumns.removeClass('hide');
            $filterColumns.find('.table-button').css('margin-bottom', 7);
            $firstTableRow.find('.table-button').css('margin-bottom', 7);

            $toggleControls.attr('title', i18next.t('explorer.toolbar.hideFilters'));
            $controls.addClass('collapsed');
        } else {
            $tableFilterSelection.addClass('reduced-filters');
            $firstTableRow.addClass('reduced-row');
            $filterColumns.addClass('hide');
            $firstTableRow.find('.table-button').css('margin-bottom', 0);

            $toggleControls.attr('title', i18next.t('explorer.toolbar.showFilters'));
            $controls.removeClass('collapsed');
        }

        $tableWrapper.css('top', $tableView.outerHeight() + $explorerDisturbances.children('.table-container').outerHeight() + 10);
    }

    function show(match, options) {
        var initResult = Explorer.Init(match);

        if (!initResult.CurrentTabIsAvailable) {
            var currentTab = Explorer.GetCurrentTab();

            if (currentTab && initResult.NewTab && initResult.NewTab.OID !== currentTab.OID) {
                Explorer.ShowTab(initResult.NewTab);
                return;
            }
        }

        init(options);
        currentPanel.show();
    }

    function initTypes() {
        types = [];

        types.push({ OID: '1-2', Title: i18next.t('explorer.disturbances.typeFilter.tasks') });

        if (Client.Licenses && Client.Licenses.Surveys) {
            types.push({ OID: '3-7', Title: i18next.t('explorer.disturbances.typeFilter.forms') });
        } else {
            types.push({ OID: '3', Title: i18next.t('explorer.disturbances.typeFilter.forms') });
        }

        types.push({ OID: '4', Title: i18next.t('explorer.disturbances.typeFilter.notes') });

        if (Client.Licenses && Client.Licenses.Inspections) {
            types.push({ OID: '6', Title: i18next.t('explorer.disturbances.typeFilter.inspections') });
        }

        if (Client.Licenses && Client.Licenses.Investigations) {
            types.push({ OID: '8', Title: i18next.t('explorer.disturbances.typeFilter.investigations') });
        }

        types.push({ OID: '5', Title: i18next.t('explorer.disturbances.typeFilter.disturbances') });
        types.push({ OID: '9', Title: i18next.t('explorer.disturbances.typeFilter.disturbancesCompleted') });
    }

    function resize() {
        if (currentPanel) {
            currentPanel.resize();
        }
    }

    function setTabCounterLoading(menuItemIdentifier) {
        $explorerTabMenu
            .find('.tab-view-tab[data-tab-id="{0}"]'.format(menuItemIdentifier))
            .addClass('loading');

        $explorerTabMenu
            .find('.dropdown-item[data-tab-id="{0}"]'.format(menuItemIdentifier))
            .addClass('loading');
    }

    function setTabCounter(menuItemIdentifier, newText) {
        var tab = Explorer.MenuItems[menuItemIdentifier];

        if (tab.ActionType !== Enums.CustomMenuItemActionType.ShowDisturbancesFromRecordings) {
            return;
        }

        // noinspection EqualityComparisonWithCoercionJS
        if (newText == 0) {
            newText = '';
        }

        var $menuItem = $explorerTabMenu
            .find('.tab-view-tab[data-tab-id="{0}"]'.format(menuItemIdentifier));
        var $dropdownItem = $explorerTabMenu
            .find('.dropdown-item[data-tab-id="{0}"]'.format(menuItemIdentifier));

        $menuItem
            .removeClass('loading')
            .attr('count', newText)
            .find('.count').html(newText);

        $dropdownItem
            .removeClass('loading')
            .attr('count', newText ? newText : '0');

        $menuItem
            .toggleClass('warning', !!newText);
    }

    function updateTabCounter(menuItem, additionalParams, internal) {
        if (!menuItem) {
            return;
        }

        if (internal) {
            setTabCounter(
                menuItem.OID,
                issueRecorditemDisturbances || recorditemDisturbances ? '!' : ''
            );
        } else {
            setTabCounter(menuItem.OID, '');
            setTabCounterLoading(menuItem.OID);

            var params = {
                LocationOID: CurrentEntity.OID,
                WithChildLocations: Explorer.GetWithChildLocations(),
                WithDeactivatedElements: Explorer.GetWithDeactivatedElements(),
                IssueID: 0,
            };

            $.extend(true, params, additionalParams);

            if (disturbanceRequest) {
                disturbanceRequest.abort();
            }

            disturbanceRequest = Tools.http.post('reports/disturbances?resulttype=disturbances', params, function (report) {
                setTabCounter(menuItem.OID, report ? '!' : '');
            }, function (jqXHR, textStatus, errorThrown) {
                if (textStatus !== 'abort') {
                    setTabCounter(menuItem.OID, '');
                }
            });

            return disturbanceRequest;
        }
    }

    function disposeTab() {
        _cachedElements = null;
        $tableBody.empty();
    }

    Disturbances.Show = show;
    Disturbances.Resize = resize;
    Disturbances.UpdateTabCounter = updateTabCounter;
    Disturbances.Dispose = disposeTab;

    global.Disturbances = Disturbances;

    return global.Disturbances;
})(window.Explorer || (window.Explorer = {}));