/**
 * @require ./explorer.js
 */
(function ( global ) {
    var Scheduling = {};

    var tabs = {
        SchedulingList: 1,
        EffortByUser: 2
    };

    var _searchRegExp;
    var _tableColumnSelection, _tableColumns;
    var _sorting, _sortingorder;
    var _schedulings, _locationSchedulings, _currentSchedulings, _virtualSchedules;
    var _userFilter;
    var _teamFilter;
    var _contactFilter;
    var _contactGroupFilter;
    var _offset;
    var _userCanAssignAllTeamsAndUsers;
    var _users, _teams;
    var _visibleTeams, _selectableTeams;
    /**
     * @type {ApexChart}
     */
    var _effortByUserChart;
    var _currentTabId = tabs.SchedulingList;

    var _schedulingElements;

    var $body = $('body');
    var $tree = $('#center-tree');
    var $explorerDashboard = $('#explorer-dashboard');
    var $explorerInfo = $('#explorer-info');
    var $explorerValues = $('#explorer-values');
    var $explorerNews = $('#explorer-news');
    var $explorerIssues = $('#explorer-issues');
    var $explorerDisturbances = $('#explorer-disturbances');
    var $explorerScheduling = $('#explorer-scheduling');
    var $explorerFiles = $('#explorer-files');
    var $explorerArchive = $('#explorer-archive');
    var $properties = $('#properties');
    var $explorerTabMenu = $('#explorer-tab-menu');
    var $explorerOptionMenu = $('#explorer-tab-menu-options');
    var $tableWrapper = $explorerScheduling.find('.tblContentWrapper');
    var $tableHeader = $explorerScheduling.find('.tbl_header');
    var $tableBody = $tableWrapper.find('.table > tbody');
    var $execute = $('#explorer-scheduling-execute');
    var $tableColumnSelection = $('#explorer-scheduling-table-columns');
    var $resetFilters = $('#explorer-scheduling-reset-filters');
    var $search = $('#explorer-scheduling-search');
    var $userFilter = $('#explorer-scheduling-user-filter');
    var $teamFilter = $('#explorer-scheduling-team-filter');
    var $contactFilter = $('#explorer-scheduling-contact-filter');
    var $contactGroupFilter = $('#explorer-scheduling-contact-group-filter');
    var $tableView = $explorerScheduling.find('.table-view');
    var activeFilters = {
        UserFilter: false,
        TeamFilter: false,
        ContactFilter: false,
        ContactGroup: false
    };

    function show(match) {
        var initResult = Explorer.Init(match);

        if (!initResult.CurrentTabIsAvailable) {
            var currentTab = Explorer.GetCurrentTab();

            if (currentTab && initResult.NewTab && initResult.NewTab.OID !== currentTab.OID) {
                Explorer.ShowTab(initResult.NewTab);
                return;
            }
        }

        init()
            .then(renderCurrentTab);
    }

    function init() {
        _userCanAssignAllTeamsAndUsers = ressources.users.hasRightAtLocation(
            Enums.Rights.IssueRights.AllowAssigningAllUsersToIssues,
            CurrentEntity.OID
        );

        _schedulings = {};
        _schedulingElements = {};

        _offset = 0;
        _currentSchedulings = null;
        _searchRegExp = null;

        $tableBody.empty();
        $search.val('');
        $search.attr('placeholder', i18next.t('explorer.search'));

        $tree.tree('remove-class-from-all-nodes', 'jquery-tree-node-marker-green jquery-tree-node-marker-yellow jquery-tree-node-marker-red');

        initTab();
        initLanguage();
        initTableColumns();
        initTableColumnSelection(false);
        initUsers();
        initTeams();
        initFilters();

        Tools.Spinner.show();

        return loadSchedulingData();
    }

    function loadSchedulingData() {
        return loadUserScheduling()
            .then(function (scheduling) {
                initSchedulings(scheduling);
            }, function (xhr) {
                Tools.Spinner.hide();
                Tools.handleHttpError(Enums.HttpActionType.Read, xhr);
            })
            .then(function () {
                return initLocationSchedulings(true);
            })
            .then(function() {
                initTable();
                unbindEvents();
                bindEvents();
                resize();
                setBtnExecuteState();
                Tools.Spinner.hide();
            }, function (xhr) {
                Tools.Spinner.hide();
                Tools.handleHttpError(Enums.HttpActionType.Read, xhr);
            });
    }

    function loadUserScheduling() {
        var params = createParams();

        return Tools.http.post('scheduling/current-user/' + CurrentEntity.OID, params);
    }

    function loadUserSchedulingCounters(menuItemConfig) {
        if (!menuItemConfig) {
            return $.Deferred().reject().promise();
        }

        var params = createParams();

        return Tools.http.post('custommenuitems/' + menuItemConfig.OID + '/counter/' + CurrentEntity.OID, params);
    }

    function createParams() {
        var params = {
            Users: (_userFilter || []).map(function (u) { return u.OID; }),
            Teams: (_teamFilter || []).map(function (t) { return t.OID; }),
            Contacts: (_contactFilter || []).map(function (c) { return c.OID; }),
            ContactGroups: (_contactGroupFilter || []).map(function (cg) { return cg.OID; })
        };

        if (!params.Users.length) {
            delete params.Users;
        }

        if (!params.Teams.length) {
            delete params.Teams;
        }

        if (!params.Contacts.length) {
            delete params.Contacts;
        }

        if (!params.ContactGroups.length) {
            delete params.ContactGroups;
        }

        if (!Object.keys(params).length) {
            params = null;
        }

        return params;
    }

    function loadMissingElements(scheduling) {
        var deferred = $.Deferred();

        if (!scheduling) {
            return deferred.reject().promise();
        }

        var missingElements = [];
        var elementsToBeCached = {};

        (scheduling.Elements|| []).forEach(function (element) {
            if (DataManager.OrganizationUnitLoader.Data.DataMap.hasOwnProperty(element.OID) ||
                _schedulingElements.hasOwnProperty(element.OID) ||
                elementsToBeCached[element.OID]) {
                return;
            }

            missingElements.push({
                OID: element.OID,
                WithAncestors: false
            });

            elementsToBeCached[element.OID] = true;
        });

        if (!(missingElements instanceof Array) || !missingElements.length) {
            return deferred.resolve().promise();
        }

        Tools.Spinner.show();
        Tools.http.post('elements/', missingElements)
            .then(function (elements) {
                var cache = Tools.element.createHierarchyForDownloadedElements(elements);

                if (!cache) {
                    return;
                }

                for (var oid in cache) {
                    _schedulingElements[oid] = cache[oid];
                }

                Tools.Spinner.hide();
                deferred.resolve();
            }, function (xhr) {
                deferred.reject();
                Tools.Spinner.hide();
                Tools.handleHttpError(Enums.HttpActionType.Read, xhr);
            });

        return deferred.promise();
    }

    function setBtnExecuteState() {
        if (CurrentEntity.Enabled && Tools.contains(User.Rights, 'TMFT', 'ID') &&
            Tools.any(User.Rights, ['TMFT-SCHEDULINGS', 'TMFT-SCHEDULINGS-A', 'TMFT-SCHEDULINGS-SC'], 'ID') &&
            Object.keys(_schedulings).length) {
            $execute.removeAttr('disabled');
        } else {
            $execute.attr('disabled', 'disabled');
        }
    }

    function initTab() {
        $explorerDashboard.addClass('hide');
        $explorerInfo.addClass('hide');
        $explorerValues.addClass('hide');
        $explorerNews.addClass('hide');
        $explorerIssues.addClass('hide');
        $explorerDisturbances.addClass('hide');
        $explorerScheduling.removeClass('hide');
        $explorerFiles.addClass('hide');
        $explorerArchive.addClass('hide');
        $properties.removeClass('active');

        $explorerTabMenu.find('.open').removeClass('open');

        $explorerOptionMenu.removeClass('active');
    }

    function initLanguage() {
        $tableView.find('.lbl').text(i18next.t('explorer.schedulings.tabs.view'));
        $tableView.find('.tab[data-tab="1"] .title').text(i18next.t('explorer.schedulings.tabs.schedulingList.title'));
        $tableView.find('.tab[data-tab="2"] .title').text(i18next.t('explorer.schedulings.tabs.estimatedEffortByUser.title'));

        $execute.text(i18next.t('explorer.schedulings.executeScheduling'));

        $('#explorer-scheduling-table-columns-lbl').text(i18next.t('explorer.schedulings.tableColumns.label'));
        $resetFilters.attr('title', i18next.t('explorer.toolbar.resetFilters'));
        $('#explorer-scheduling-user-filter-lbl').text(i18next.t('explorer.schedulings.userFilter.label'));
        $('#explorer-scheduling-team-filter-lbl').text(i18next.t('explorer.schedulings.teamFilter.label'));
        $('#explorer-scheduling-contact-filter-lbl').text(i18next.t('explorer.schedulings.contactFilter.label'));
        $('#explorer-scheduling-contact-group-filter-lbl').text(i18next.t('explorer.schedulings.contactGroupFilter.label'));
        $('#explorer-scheduling-table-columns').attr('title', i18next.t('explorer.toolbar.selectTableColumns'));

        $tableHeader.find('th[data-columntitle="title"]').text(i18next.t('explorer.schedulings.tableColumns.title'));
        $tableHeader.find('th[data-columntitle="what"]').text(i18next.t('explorer.schedulings.tableColumns.what'));
        $tableHeader.find('th[data-columntitle="where"]').text(i18next.t('explorer.schedulings.tableColumns.where'));
        $tableHeader.find('th[data-columntitle="teams"]').text(i18next.t('explorer.schedulings.tableColumns.teams'));
        $tableHeader.find('th[data-columntitle="users"]').text(i18next.t('explorer.schedulings.tableColumns.users'));
        $tableHeader.find('th[data-columntitle="contactGroups"]').text(i18next.t('explorer.schedulings.tableColumns.contactGroups'));
        $tableHeader.find('th[data-columntitle="contacts"]').text(i18next.t('explorer.schedulings.tableColumns.contacts'));
        $tableHeader.find('th[data-columntitle="estimated-effort"]').text(i18next.t('explorer.schedulings.tableColumns.estimatedEffort'));
        $tableHeader.find('th[data-columntitle="rotations"]').text(i18next.t('explorer.schedulings.tableColumns.rotations'));
        $tableHeader.find('th[data-columntitle="nextDeadlines"]').text(i18next.t('explorer.schedulings.tableColumns.nextDeadlines'));
    }

    function initTableColumns() {
        _tableColumns = [];

        _tableColumns.push({ OID: '1', Title: i18next.t('explorer.schedulings.tableColumns.title') });
        _tableColumns.push({ OID: '2', Title: i18next.t('explorer.schedulings.tableColumns.what') });
        _tableColumns.push({ OID: '3', Title: i18next.t('explorer.schedulings.tableColumns.where') });
        _tableColumns.push({ OID: '4', Title: i18next.t('explorer.schedulings.tableColumns.teams') });
        _tableColumns.push({ OID: '5', Title: i18next.t('explorer.schedulings.tableColumns.users') });
        _tableColumns.push({ OID: 'contact-groups', Title: i18next.t('explorer.schedulings.tableColumns.contactGroups') });
        _tableColumns.push({ OID: 'contacts', Title: i18next.t('explorer.schedulings.tableColumns.contacts') });
        _tableColumns.push({ OID: 'estimated-effort', Title: i18next.t('explorer.schedulings.tableColumns.estimatedEffort') });
        _tableColumns.push({ OID: '6', Title: i18next.t('explorer.schedulings.tableColumns.rotations') });
        _tableColumns.push({ OID: '7', Title: i18next.t('explorer.schedulings.tableColumns.nextDeadlines') });
    }

    function initUsers() {
        _users = Tools.GetSelectableUsers(CurrentEntity.OID);
        _users.sort(Tools.SortByFullname);
    }

    function initTeams() {
        var teamInformation = Tools.GetSelectableTeams(CurrentEntity.OID);

        _teams = teamInformation.Teams;
        _visibleTeams = teamInformation.VisibleTeams;
        _selectableTeams = teamInformation.SelectableTeams;
    }

    function onFiltersInit() {
        var $tableFilterSelection = $explorerScheduling.find('.table-filter-selection');

        if ($tableFilterSelection == null) {
            return;
        }

        var $firstTableRow = $tableFilterSelection.find('.table-filter-column-first-row');
        var $filterColumns = $tableFilterSelection.find('.table-filter-column:not(.last-row)');
        var $lastRow = $tableFilterSelection.find('.table-filter-column:last-child');
        var $lastRowFilters = $lastRow.find('.table-filter:not(:first-child)');

        $tableFilterSelection.addClass('grid-row');
        $lastRowFilters.removeClass('hide');
        $firstTableRow.addClass('flexColumn');
        $filterColumns.find('.table-button').css('margin-bottom', 8);
        $filterColumns.find('.text-column .gfx-t-tc-text').css('margin-bottom', 8);
        $firstTableRow.find('.table-button').css('margin-bottom', 8);
        $lastRow.removeClass('right');
        $tableWrapper.css('top', 102);
    }

    function initSchedulings(scheduling) {
        _schedulings = (scheduling || []).reduce(function (dict, sched) {
            dict[sched.OID] = Tools.Escape.Scheduling(sched);

            return dict;
        }, {});
    }

    function initLocationSchedulings(withVirtualSchedules) {
        var showChildlocations = Explorer.GetWithChildLocations();
        var locationMap = {};
        var schedulingMap = {};

        (function walk(element) {
            switch (element.Type) {
                case Enums.elementType.Root:
                case Enums.elementType.Location:
                    locationMap[element.OID] = true;

                    (element.Parametergroups || []).forEach(walk);

                    if (showChildlocations) {
                        (element.Children || []).forEach(walk);
                    }
                    break;
                case Enums.elementType.Parametergroup:
                    (element.Scheduling || []).forEach(function (elementScheduling) {
                        var sched = _schedulings[elementScheduling.OID];

                        if (sched) {
                            schedulingMap[sched.OID] = sched;
                        }
                    });
                    break;
            }
        })(CurrentEntity);

        Object
            .keys(_schedulings)
            .forEach(function (oid) {
                schedulingMap[oid] = _schedulings[oid];
            });

        if (!withVirtualSchedules) {
            // auf das zusätzliche Laden virtueller Zeitpläne verzichten
            _locationSchedulings = Object
                .keys(schedulingMap)
                .map(function (oid) { return prepareScheduling(schedulingMap[oid]); });

            return $.Deferred().resolve();
        }

        var selectedSchedules = Object
            .keys(schedulingMap)
            .map(function (oid) { return schedulingMap[oid]; });

        return appendVirtualSchedules(selectedSchedules)
            .then(null, function() {
                return $.Deferred().resolve();
            })
            .then(function() {
                _locationSchedulings = Object
                    .keys(schedulingMap)
                    .map(function (oid) { return prepareScheduling(schedulingMap[oid]); });
            });
    }

    function updateTabCounter(menuItemConfig) {
        if (!menuItemConfig) {
            return new $.Deferred().resolve().promise();
        }

        if (!_locationSchedulings) {
            Explorer.SetTabCounterLoading(menuItemConfig.OID);

            var request = loadUserSchedulingCounters(menuItemConfig);

            request.then(function (counter) {
                Explorer.SetTabCounter(menuItemConfig.OID, counter);
            }, function (xhr) {
                Explorer.SetTabCounter(menuItemConfig.OID, 0);
            });

            return request;
        } else {
            if (!_currentSchedulings) {
                _currentSchedulings = sortSchedulingList(filterSchedulingList(_locationSchedulings));
            }

            var counter = (_currentSchedulings || []).length;

            Explorer.SetTabCounter(menuItemConfig.OID, counter);

            return new $.Deferred().resolve().promise();
        }
    }

    function appendVirtualSchedules(schedulings) {
        // finde "Zeitpläne nach Statusänderung"
        var schedulesToLoad = {};

        for (var i = 0; i < schedulings.length; i++) {
            var scheduling = schedulings[i];

            if (!scheduling.Schedules || !scheduling.Schedules.length) {
                continue;
            }

            for (var b = 0; b < scheduling.Schedules.length; b++) {
                var schedule = scheduling.Schedules[b];

                if (schedule && schedule.FrequencySubdayType === Enums.FrequencySubdayTypes.RepeatOnStateChange) {
                    // zur liste zufügen
                    schedulesToLoad[schedule.OID] = schedule;
                }
            }
        }

        var scheduleOIDs = Object.keys(schedulesToLoad);
        if (!scheduleOIDs.length) {
            return $.Deferred().resolve();
        }

        Tools.Spinner.show();

        return Tools.http
            .post('schedules?withvirtualschedules=true', {
                LocationOID: CurrentEntity.OID,
                Schedules: scheduleOIDs
            })
            .then(function (superSchedules) {
                if (!superSchedules || !superSchedules.length) {
                    return;
                }

                _virtualSchedules = {};
                for (var j = 0; j < superSchedules.length; j++) {
                    var schedule = superSchedules[j];

                    _virtualSchedules[schedule.OID] = schedule.VirtualSchedules;
                }
            })
            .always(Tools.Spinner.hide);
    }

    function initTableColumnSelection(selectedColumnsChanged) {
        var $btnTitle = $tableColumnSelection.find('.btn-title');
        var titles, text;

        var columnIds = Tools.GetOfficeSettingValue(Explorer.GetColumnsSettingName());

        if (columnIds && !selectedColumnsChanged) {
            columnIds = columnIds.split(',') || [];

            if (columnIds.length > 0) {
                _tableColumnSelection = [];

                columnIds.forEach(function (oid) {
                    var column = Tools.getFirst(_tableColumns, oid, 'OID');

                    if (column)
                    {
                        _tableColumnSelection.push(column);
                    }
                });
            }
        } else if (!columnIds) {
            _tableColumnSelection = _tableColumns.slice();
        }

        titles = (_tableColumnSelection || []).map(function (tableColumn) {
            return tableColumn.Title;
        });

        if (titles.length) {
            text = titles.length === _tableColumns.length ?
                i18next.t('explorer.schedulings.tableColumns.all') :
                titles.sort().join(', ');
        } else {
            text = i18next.t('explorer.schedulings.tableColumns.noSelection');
        }

        $btnTitle.html(text);

        if ((_tableColumnSelection || []).length && (_tableColumnSelection || []).length !== (_tableColumns || []).length) {
            _tableColumns.forEach(function (tableColumn) {
                var isChecked = Tools.contains(_tableColumnSelection, tableColumn.OID, 'OID');

                if (isChecked) {
                    $explorerScheduling.find('col[data-columnid="{0}"], th[data-columnid="{0}"], td[data-columnid="{0}"]'.format(tableColumn.OID)).removeClass('hide');
                } else {
                    $explorerScheduling.find('col[data-columnid="{0}"], th[data-columnid="{0}"], td[data-columnid="{0}"]'.format(tableColumn.OID)).addClass('hide');
                }
            });
        }
    }

    function resetSchedulingCache() {
        _locationSchedulings = null;
        _currentSchedulings = null;
    }

    function resetFilters() {
        $resetFilters.removeClass('active');

        activeFilters = {
            UserFilter: false,
            TeamFilter: false,
            ContactFilter: false,
            ContactGroupFilter: false,
            KeywordFilter: false,
            ClassificationFilter: false,
            PeriodFilter: false,
            HideNonResponsibleIssues: false
        };

        _userFilter = null;
        _teamFilter = null;
        _contactFilter = null;
        _contactGroupFilter = null;
        _searchRegExp = null;
        $search.val('');
    }

    function initFilters() {
        onFiltersInit();
        initUserFilter();
        initTeamFilter();
        initContactFilter();
        initContactGroupFilter();
    }

    function initUserFilter() {
        var $btnTitle = $userFilter.find('.btn-title');
        var titles, text, userCount;

        titles = (_userFilter || []).map(function (user) {
            return user.Title;
        });

        if (titles.length) {
            userCount = Object.keys(window.Users).length;
            text = titles.length === userCount ?
                i18next.t('explorer.schedulings.userFilter.all') :
                titles.sort().join(', ');

            $btnTitle.parent().addClass('filter-selected');
            activeFilters.UserFilter = true;
        } else {
            text = i18next.t('explorer.schedulings.userFilter.noSelection');

            $btnTitle.parent().removeClass('filter-selected');
            activeFilters.UserFilter = false;
        }

        $btnTitle.html(text);
    }

    function initTeamFilter() {
        var $btnTitle = $teamFilter.find('.btn-title');
        var titles, text, teamCount;

        titles = (_teamFilter || []).map(function (team) {
            return team.Title;
        });

        if (titles.length) {
            teamCount = Object.keys(window.Teams).length;
            text = titles.length === teamCount ?
                i18next.t('explorer.schedulings.teamFilter.all') :
                titles.sort().join(', ');

            $btnTitle.parent().addClass('filter-selected');
            activeFilters.TeamFilter = true;
        } else {
            text = i18next.t('explorer.schedulings.teamFilter.noSelection');

            $btnTitle.parent().removeClass('filter-selected');
            activeFilters.TeamFilter = false;
        }

        $btnTitle.html(text);
    }

    function initContactFilter() {
        var $btnTitle = $contactFilter.find('.btn-title');

        var titles = (_contactFilter || []).map(function (contact) {
            return contact.Title;
        });

        var text, contactCount;

        if (titles.length) {
            contactCount = Object.keys(Contacts).length;
            text = titles.length === contactCount ?
                i18next.t('explorer.schedulings.contactFilter.all') :
                titles.sort().join(', ');

            $btnTitle.parent().addClass('filter-selected');
            activeFilters.ContactFilter = true;
        } else {
            text = i18next.t('explorer.schedulings.contactFilter.noSelection');

            $btnTitle.parent().removeClass('filter-selected');
            activeFilters.ContactFilter = false;
        }

        $btnTitle.html(text);
    }

    function initContactGroupFilter() {
        var $btnTitle = $contactGroupFilter.find('.btn-title');

        var titles = (_contactGroupFilter || []).map(function (contactGroup) {
            return contactGroup.Title;
        });

        var text, contactGroupText;

        if (titles.length) {
            contactGroupText = Object.keys(ContactGroups).length;
            text = titles.length === contactGroupText ?
                i18next.t('explorer.schedulings.contactGroupFilter.all') :
                titles.sort().join(', ');

            $btnTitle.parent().addClass('filter-selected');
            activeFilters.ContactGroupFilter = true;
        } else {
            text = i18next.t('explorer.schedulings.contactGroupFilter.noSelection');

            $btnTitle.parent().removeClass('filter-selected');
            activeFilters.ContactGroupFilter = false;
        }

        $btnTitle.html(text);
    }

    function initTable() {
        var $sortingCol;

        $tableBody.empty();

        $sortingCol = $tableHeader.find('[data-sorting]');

        _sorting = $sortingCol.data('columntitle');
        _sortingorder = $sortingCol.data('sorting');
    }

    function unbindEvents() {
        $tableView.off('click.selectTab');
        $tableHeader.off('click.toggleSorting');
        $tableColumnSelection.off('click.showTableColumnSelection');
        $resetFilters.off('click.resetFilters');
        $search.off('keyup.search input.search');
        $userFilter.off('click.showUserFilter');
        $teamFilter.off('click.showTeamFilter');
        $contactFilter.off('click.showContactFilter');
        $contactGroupFilter.off('click.showContactGroupFilter');
        $explorerScheduling.off('click.print');
        $tableBody.off('click.showScheduling click.toggleDetails click.hideDetails');
        $execute.off('click.showSchedulingPicker');
    }

    function bindEvents() {
        $tableView.on('click.selectTab', '.tab[data-tab]', onTabControlItemClick);
        $tableHeader.on('click.toggleSorting', '.sortable', onToggleSorting);
        $tableColumnSelection.on('click.showTableColumnSelection', onShowTableColumnSelection);
        $resetFilters.on('click.resetFilters', onResetFilters);
        $search.on('keyup.search input.search', onSearch);
        $userFilter.on('click.showUserFilter', onShowUserFilter);
        $teamFilter.on('click.showTeamFilter', onShowTeamFilter);
        $contactFilter.on('click.showContactFilter', onShowContactFilter);
        $contactGroupFilter.on('click.showContactGroupFilter', onShowContactGroupFilter);
        $explorerScheduling.on('click.print', '.print', onPrint);
        $tableBody.on('click.showScheduling', '.table-link[data-link="title"]', onShowScheduling);
        $tableBody.on('click.toggleDetails', '.table-link[data-link="what"], .table-link[data-link="locations"]', onToggleDetails);
        $tableBody.on('click.hideDetails', '.inner-table .gfx-i-t-link', onHideDetails);
        $execute.on('click.showSchedulingPicker', onShowSchedulingPicker);
    }

    function setFilterState() {
        var isActive;

        for (var filter in activeFilters) {
            isActive = activeFilters[filter];

            if (isActive) {
                break;
            }
        }

        isActive ? $resetFilters.addClass('active') : $resetFilters.removeClass('active');
    }

    function reset() {
        _offset = 0;
        _currentSchedulings = null;

        destroyCharts();
        $tableBody.empty();
        setFilterState();
        renderCurrentTab();

        Explorer.UpdateTabCounters()
            .then(Explorer.ResizeTabMenu);
    }

    function updateTable() {
        var newOffset = _offset + 50;
        var html;

        if (!_currentSchedulings) {
            _currentSchedulings = sortSchedulingList(filterSchedulingList(_locationSchedulings));
        }

        html = _currentSchedulings
            .slice(_offset, newOffset)
            .map(renderRow)
            .join('');

        if (!html && !_offset) {
            html = renderEmptyRow();
        }

        $tableBody.append(html);
        $tableHeader.width($tableBody.width());

        _offset = newOffset;

        if (_currentSchedulings.length > _offset) {
            $tableWrapper.on('scroll.infiniteScroll', onScroll);
        }
    }

    function filterSchedulingList(schedulings) {
        return schedulings.filter(function (scheduling) {
            return !_searchRegExp || _searchRegExp.test(scheduling.Title);
        });
    }

    function sortSchedulingList(schedulings) {
        if(!schedulings) {
            return [];
        }

        switch (_sorting) {
            case 'title':
                schedulings.sort(function (a, b) {
                    var titleA = a.Title.toLowerCase();
                    var titleB = b.Title.toLowerCase();

                    if (titleA > titleB) {
                        return _sortingorder === 'desc' ? -1 : 1;
                    }

                    if (titleA < titleB) {
                        return _sortingorder === 'desc' ? 1 : -1;
                    }

                    return 0;
                });
                break;
            case 'what':
                schedulings.sort(function (a, b) {
                    var countA = (a.Parametergroups || []).length + (a.LocationForms || []).length;
                    var countB = (b.Parametergroups || []).length + (b.LocationForms || []).length;

                    return (countA - countB) * (_sortingorder === 'desc' ? -1 : 1);
                });
                break;
            case 'where':
                schedulings.sort(function (a, b) {
                    var titleA, titleB, titles;

                    titleA = $.map(a.Locations, function (location) {
                        return location.Title.toLowerCase();
                    }).sort().join('');

                    titleB = $.map(b.Locations, function (location) {
                        return location.Title.toLowerCase();
                    }).sort().join('');


                    if (titleA > titleB) {
                        return _sortingorder === 'desc' ? -1 : 1;
                    } else if (titleA < titleB) {
                        return _sortingorder === 'desc' ? 1 : -1;
                    }

                    return 0;
                });
                break;
            case 'teams':
                schedulings.sort(function (a, b) {
                    var titleA, titleB;

                    titleA = $.map(a.Teams || [], function (team) {
                        return team.Title.toLowerCase();
                    }).sort().join('');

                    titleB = $.map(b.Teams || [], function (team) {
                        return team.Title.toLowerCase();
                    }).sort().join('');


                    if (titleA > titleB) {
                        return _sortingorder === 'desc' ? -1 : 1;
                    } else if (titleA < titleB) {
                        return _sortingorder === 'desc' ? 1 : -1;
                    }

                    return 0;
                });
                break;
            case 'users':
                schedulings.sort(function (a, b) {
                    var titleA = $
                        .map(a.Users || [], function (user) { return user.Fullname.toLowerCase(); })
                        .sort()
                        .join('');

                    var titleB = $
                        .map(b.Users || [], function (user) { return user.Fullname.toLowerCase(); })
                        .sort()
                        .join('');

                    if (titleA > titleB) {
                        return _sortingorder === 'desc' ? -1 : 1;
                    } else if (titleA < titleB) {
                        return _sortingorder === 'desc' ? 1 : -1;
                    }

                    return 0;
                });
                break;
            case 'contactGroups':
                schedulings.sort(function (a, b) {
                    var titleA, titleB;

                    titleA = $.map(a.ContactGroups || [], function (contactGroup) {
                        return contactGroup.Title.toLowerCase();
                    }).sort().join('');

                    titleB = $.map(b.ContactGroups || [], function (contactGroup) {
                        return contactGroup.Title.toLowerCase();
                    }).sort().join('');


                    if (titleA > titleB) {
                        return _sortingorder === 'desc' ? -1 : 1;
                    } else if (titleA < titleB) {
                        return _sortingorder === 'desc' ? 1 : -1;
                    }

                    return 0;
                });
                break;
            case 'contacts':
                schedulings.sort(function (a, b) {
                    var titleA = $
                        .map(a.Contacts || [], function (contact) { return contact.Title.toLowerCase(); })
                        .sort()
                        .join('');

                    var titleB = $
                        .map(b.Contacts || [], function (contact) { return contact.Title.toLowerCase(); })
                        .sort()
                        .join('');

                    if (titleA > titleB) {
                        return _sortingorder === 'desc' ? -1 : 1;
                    } else if (titleA < titleB) {
                        return _sortingorder === 'desc' ? 1 : -1;
                    }

                    return 0;
                });
                break;
            default:
                schedulings.sort(function (a, b) {
                    var dateA, dateB;

                    if ((a.DeadlineTimestamps || []).length) {
                        dateA = _sortingorder === 'desc' ?
                            a.DeadlineTimestamps[a.DeadlineTimestamps.length - 1] :
                            a.DeadlineTimestamps[0];
                    }

                    if ((b.DeadlineTimestamps || []).length) {
                        dateB = _sortingorder === 'desc' ?
                            b.DeadlineTimestamps[b.DeadlineTimestamps.length - 1] :
                            b.DeadlineTimestamps[0];
                    }

                    if (!dateA && !dateB) {
                        return 0;
                    }

                    if (!dateA) {
                        return _sortingorder === 'desc' ? 1 : -1;
                    }

                    if (!dateB) {
                        return _sortingorder === 'desc' ? -1 : 1;
                    }

                    return (dateA.getTime() - dateB.getTime()) * (_sortingorder === 'desc' ? -1 : 1);
                });
                break;
        }

        return schedulings;
    }

    function renderRow(scheduling) {
        var html = [];

        html.push('<tr data-oid="{0}" style="height: 45px;vertical-align:top;">'.format(scheduling.OID));
        html.push('<td><img src="img/pdf-icon.svg" height="20" class="print"></td>');

        html.push(drawColorAndTitle(scheduling));
        html.push(drawWhat(scheduling));
        html.push(drawLocations(scheduling));
        html.push(drawTeams(scheduling));
        html.push(drawUsers(scheduling));
        html.push(drawContactGroups(scheduling));
        html.push(drawContacts(scheduling));
        html.push(drawEstimatedEffort(scheduling));
        html.push(drawRotations(scheduling));
        html.push(drawDeadlineTimestamps(scheduling));

        html.push('</tr>');

        return html.join('');
    }

    function renderEmptyRow() {
        var colspan = (_tableColumns || []).length + 1;

        return '<tr><td colspan="{0}" class="empty">{1}</td></tr>'.format(
            colspan, i18next.t('explorer.schedulings.noDataAvailable')
        );
    }

    function drawColorAndTitle(scheduling) {
        var show = !_tableColumnSelection || Tools.contains(_tableColumnSelection, '1', 'OID');

        return '<td class="{0}table-link" data-columnid="1" data-link="title"><span class="table-color" style="background-color:{1}"></span>{2}</td>'
            .format(
                show ? '' : 'hide ',
                scheduling.Color || '#7E1210',
                scheduling.Title);
    }

    function drawWhat(scheduling) {
        var show = !_tableColumnSelection || Tools.contains(_tableColumnSelection, '2', 'OID');
        var text = '';

        var parameterGroupCount = 0;
        var formCount = 0;

        (scheduling.Elements || []).forEach(function (element) {
            if (element.Type === Enums.elementType.Parametergroup) {
                parameterGroupCount++;
                return;
            }

            if (element.Type === Enums.elementType.Form &&
                (element.Locations || []).length) {
                formCount += element.Locations.length;
            }
        });

        if (parameterGroupCount) {
            if (parameterGroupCount === 1) {
                text = i18next.t('elementTypes.testgroup_article');
            } else {
                text = parameterGroupCount + ' ' + i18next.t('elementTypes.testgroup_plural');
            }
        }

        if (formCount) {
            if (text) {
                text += '<br>';
            }

            if (formCount === 1) {
                text += i18next.t('elementTypes.form_article');
            } else {
                text += formCount + ' ' + i18next.t('elementTypes.form_plural');
            }
        }

        return '<td class="{0}table-link" data-columnid="2" data-link="what" style="white-space: nowrap;">{1}</td>'
            .format(show ? '' : 'hide ', text);
    }

    function drawLocations(scheduling) {
        var show = !_tableColumnSelection || Tools.contains(_tableColumnSelection, '3', 'OID');
        var titles = (scheduling.Locations || [])
            .map(function (identifier) {
                var location = DataManager.OrganizationUnitLoader.Data.DataMap[identifier];

                return location ? location.Title : null;
            })
            .filter(function (text) { return !!text; })
            .sort(Tools.SortStringArray);

        return '<td class="{0} table-link" data-columnid="3" data-link="locations">{1}{2}</td>'.format(
            show ? '' : 'hide ',
            titles.slice(0, 4).join(', '),
            titles.length > 4 ? ' ...' : '');
    }

    function drawTeams(scheduling) {
        var show = !_tableColumnSelection || Tools.contains(_tableColumnSelection, '4', 'OID');
        var html = [];
        var titles;

        titles = (scheduling.Teams || []).map(function (team) {
            return team.Title;
        });

        titles.sort(Tools.SortStringArray);

        html.push('<td class="{0}" data-columnid="4">'.format(show ? '' : 'hide'));

        if (titles.length) {
            html.push('<ul><li>');
            html.push(titles.join('</li><li>'));
            html.push('</li></ul>');
        } else {
            html.push('&nbsp;');
        }

        html.push('</td>');

        return html.join('');
    }

    function drawUsers(scheduling) {
        var show = !_tableColumnSelection || Tools.contains(_tableColumnSelection, '5', 'OID');
        var html = [];
        var names;

        names = (scheduling.Users || []).map(function (user) {
            return user.Fullname;
        });

        names.sort(Tools.SortStringArray);

        html.push('<td class="{0}" data-columnid="5">'.format(show ? '' : 'hide'));

        if (names.length) {
            html.push('<ul><li>');
            html.push(names.join('</li><li>'));
            html.push('</li></ul>');
        } else {
            html.push('&nbsp;');
        }

        html.push('</td>');

        return html.join('');
    }

    function drawRotations(scheduling) {
        var show = !_tableColumnSelection || Tools.contains(_tableColumnSelection, '6', 'OID');
        var rotations = scheduling.Rotations;
        var html = [];

        html.push('<td class="{0}" data-columnid="6">'.format(show ? '' : 'hide'));

        if ((rotations || []).length) {
            html.push('<ul><li>');
            html.push(rotations.join('</li><li>'));
            html.push('</li></ul>');
        } else {
            html.push('&nbsp;');
        }

        html.push('</td>');

        return html.join('');
    }

    function drawContactGroups(scheduling) {
        var show = !_tableColumnSelection || Tools.contains(_tableColumnSelection, 'contact-groups', 'OID');
        var html = [];

        var titles = (scheduling.ContactGroups || []).map(function (contactGroup) {
            return contactGroup.Title;
        });

        titles.sort(Tools.SortStringArray);

        html.push('<td class="{0}" data-columnid="contact-groups">'.format(show ? '' : 'hide'));

        if (titles.length) {
            html.push('<ul><li>');
            html.push(titles.join('</li><li>'));
            html.push('</li></ul>');
        } else {
            html.push('&nbsp;');
        }

        html.push('</td>');

        return html.join('');
    }

    function drawContacts(scheduling) {
        var show = !_tableColumnSelection || Tools.contains(_tableColumnSelection, 'contacts', 'OID');
        var html = [];

        var names = (scheduling.Contacts || []).map(function (contact) {
            return contact.Fullname;
        });

        names.sort(Tools.SortStringArray);

        html.push('<td class="{0}" data-columnid="contacts">'.format(show ? '' : 'hide'));

        if (names.length) {
            html.push('<ul><li>');
            html.push(names.join('</li><li>'));
            html.push('</li></ul>');
        } else {
            html.push('&nbsp;');
        }

        html.push('</td>');

        return html.join('');
    }

    function drawEstimatedEffort(scheduling) {
        var show = !_tableColumnSelection || Tools.contains(_tableColumnSelection, 'estimated-effort', 'OID');
        var unit = Client.Settings.EstimatedEffortSettings ?
            Properties[Client.Settings.EstimatedEffortSettings.UnitOID] :
            null;

        var estimatedEffort = scheduling.EstimatedEffort ?
            scheduling.EstimatedEffort.toFixed(2).replace('.', ',') :
            null;

        var unitText = unit ? (unit.Description || unit.Title) : '';
        var html = [];

        html.push(
            '<td class="{0}" data-columnid="estimated-effort">'.format(show ? '' : 'hide'),
                !!estimatedEffort ? (estimatedEffort + ' ' + unitText).trim() : '-/-',
            '</td>'
        );

        return html.join('');
    }

    function drawDeadlineTimestamps(scheduling) {
        var show = !_tableColumnSelection || Tools.contains(_tableColumnSelection, '7', 'OID');
        var html = [];
        var deadlineTimestampStrings;

        deadlineTimestampStrings = $.map(scheduling.DeadlineTimestamps || [], Tools.dateTime.getDifferenceString);

        html.push('<td class="{0}" data-columnid="7">'.format(show ? '' : 'hide'));

        if (deadlineTimestampStrings.length) {
            html.push('<ul><li>');
            html.push(deadlineTimestampStrings.join('</li><li>'));
            html.push('</li></ul>');
        } else {
            html.push('&nbsp;');
        }

        html.push('</td>');

        return html.join('');
    }

    function onToggleSorting() {
        var $this = $(this);

        _sorting = $this.attr('data-columntitle');
        _sortingorder = $this.attr('data-sorting');

        _sortingorder = _sortingorder === 'desc' ? 'asc' : 'desc';
        $this.siblings().removeAttr('data-sorting');
        $this.attr('data-sorting', _sortingorder);

        reset();
    }

    function onShowTableColumnSelection() {
        var options = {
            title: i18next.t('explorer.schedulings.tableColumns.selectColumns'),
            onApply: onSelectTableColumns,
            checkedEntities: _tableColumnSelection,
            treeOptions: {
                schema: { id: 'OID', children: 'Children', text: 'Title' },
                opened: true,
                checkbox: { fullrow: true }
            }
        };

        TreePicker.Show(_tableColumns, options);
    }

    function onScroll() {
        var wrapperHeight = $tableWrapper.innerHeight();
        var tableHeight = $tableBody.outerHeight(false);
        var scrollPosition = $tableWrapper.scrollTop();

        if (tableHeight - wrapperHeight - scrollPosition <= 300) {
            $tableWrapper.off('scroll.infiniteScroll');

            updateTable();
        }
    }

    function onSelectTableColumns(selectedTableColumns) {
        _tableColumnSelection = (selectedTableColumns || []).map(function (oid) {
            return Tools.getFirst(_tableColumns, oid, 'OID');
        });

        if (_tableColumnSelection.length && _tableColumnSelection.length !== _tableColumns.length) {
            _tableColumns.forEach(function (tableColumn) {
                var isChecked = Tools.contains(_tableColumnSelection, tableColumn.OID, 'OID');

                if (isChecked) {
                    $explorerScheduling.find('col[data-columnid="{0}"], th[data-columnid="{0}"], td[data-columnid="{0}"]'.format(tableColumn.OID)).removeClass('hide');
                } else {
                    $explorerScheduling.find('col[data-columnid="{0}"], th[data-columnid="{0}"], td[data-columnid="{0}"]'.format(tableColumn.OID)).addClass('hide');
                }
            });
        } else {
            $explorerScheduling.find('col, th, td').removeClass('hide');
        }

        Tools.UpdateOfficeUserSettings(Explorer.GetColumnsSettingName(), (selectedTableColumns || []).join(','))
            .then(function () {
                initTableColumnSelection(true);
                resize();
            });
    }

    function onResetFilters() {
        resetFilters();
        initFilters();

        Tools.Spinner.show();
        loadSchedulingData()
            .then(resize)
            .then(reset);
    }

    function onSearch() {
        var val = $.trim($search.val());

        _searchRegExp = val ?
            new RegExp(Tools.escRegExp(val), 'i') :
            null;

        resize();
        reset();
    }

    function onShowUserFilter() {
        var options = {
            title: i18next.t('explorer.schedulings.userFilter.title'),
            onApply: onSelectUserFilter,
            checkedEntities: _userFilter,
            treeOptions: {
                schema: { id: 'OID', children: 'Children', text: 'Fullname' },
                opened: true,
                checkbox: { fullrow: true },
                createContentHtml: function() {
                    var html;

                    html = this.createCheckboxHtml();
                    html += this.createTextHtml();

                    if (this.entity && this.entity.IsLocked) {
                        html += '<img src="./img/locked.svg" class="user-locked">';
                    }

                    return html;
                }
            }
        };

        TreePicker.Show(_users, options);
    }

    function onSelectUserFilter(selectedUsers) {
        _userFilter = (selectedUsers || []).map(function (oid) {
            return window.Users[oid];
        });

        initUserFilter();

        Tools.Spinner.show();
        loadSchedulingData()
            .then(resize)
            .then(reset);
    }

    function onShowTeamFilter() {
        var options = {
            title: i18next.t('explorer.schedulings.teamFilter.title'),
            onApply: onSelectTeamFilter,
            checkedEntities: _teamFilter,
            treeOptions: {
                schema: { id: 'OID', children: 'Children', text: 'Title', color: 'Color' },
                opened: true,
                checkbox: {
                    fullrow: true,
                    disabled: _userCanAssignAllTeamsAndUsers ? null : function (node) {
                        if (!_selectableTeams || !node || !node.entity) {
                            return false;
                        }

                        var team = node.entity;

                        return _selectableTeams.indexOf(team.OID) === -1;
                    }
                },
                filter: _userCanAssignAllTeamsAndUsers ? null : function (team) {
                    if (!_visibleTeams || !team) {
                        return false;
                    }

                    return _visibleTeams.indexOf(team.OID) > -1;
                }
            }
        };

        TreePicker.Show(RootTeam, options);
    }

    function onSelectTeamFilter(selectedTeams) {
        _teamFilter = (selectedTeams || []).map(function (oid) {
            return window.Teams[oid];
        });

        initTeamFilter();

        Tools.Spinner.show();
        loadSchedulingData()
            .then(resize)
            .then(reset);
    }

    function onShowContactFilter() {
        var contacts = Object
            .keys(Contacts)
            .map(function (oid) { return Contacts[oid]; });

        contacts.sort(Tools.SortByFullname);

        var options = {
            title: i18next.t('explorer.schedulings.contactFilter.title'),
            onApply: onSelectContactFilter,
            checkedEntities: _contactFilter,
            treeOptions: {
                schema: { id: 'OID', text: 'Fullname' },
                opened: true,
                checkbox: { fullrow: true }
            }
        };

        TreePicker.Show(contacts, options);
    }

    function onSelectContactFilter(selectedContacts) {
        _contactFilter = (selectedContacts || []).map(function (oid) {
            return Contacts[oid];
        });

        initContactFilter();

        Tools.Spinner.show();
        loadSchedulingData()
            .then(resize)
            .then(reset);
    }

    function onShowContactGroupFilter() {
        var options = {
            title: i18next.t('explorer.archive.contactGroupFilter.title'),
            onApply: onSelectContactGroupFilter,
            checkedEntities: _contactGroupFilter,
            treeOptions: {
                schema: { id: 'OID', text: 'Title' },
                opened: true,
                checkbox: { fullrow: true }
            }
        };

        var contactGroups = Object.values(ContactGroups);
        contactGroups.sort(Tools.SortByTitle);

        TreePicker.Show(contactGroups, options);
    }

    function onSelectContactGroupFilter(selectedContacts) {
        _contactGroupFilter = (selectedContacts || []).map(function (oid) {
            return ContactGroups[oid];
        });

        initContactGroupFilter();

        Tools.Spinner.show();
        loadSchedulingData()
            .then(resize)
            .then(reset);
    }

    function onPrint() {
        var schedulingOID = $(this).closest('tr').data('oid');

        PrintPreview.Show({
            SchedulingOID: schedulingOID,
            Type: 'PrintSchedulingInformation'
        });
    }

    function onShowScheduling() {
        var oid, options;

        if (!ressources.users.hasRight('AEM')) {
            return;
        }

        oid = $(this).closest('tr').data('oid');

        if (changemode.active) {
            window.location.hash = '#events\/' + oid;
        } else {
            options = {};

            options.title = i18next.t('explorer.schedulings.messages.startChangeMode.title');
            options.text = i18next.t('explorer.schedulings.messages.startChangeMode.text');
            options.no = true;
            options.onYes = function () {
                changemode.activate(function () {
                    window.location.hash = ('#events\/' + oid);
                });
            };

            Tools.Message.Show(options);
        }
    }

    function onToggleDetails() {
        var $tr = $(this).closest('tr');
        var oid = $tr.data('oid');
        var scheduling = _schedulings[oid];

        if (!scheduling) {
            return;
        }

        loadMissingElements(scheduling)
            .then(function () {
                var html = [];
                var colspan;

                var checkpointGroups = (scheduling.Elements || []).filter(function (elem) {
                    return elem.Type === Enums.elementType.Parametergroup;
                });

                var forms = (scheduling.Elements || []).filter(function (elem) {
                    return elem.Type === Enums.elementType.Form;
                });

                if ($tableBody.find('tr[data-what-list="{0}"]'.format(oid)).length) {
                    $tableBody.find('tr[data-what-list="{0}"]'.format(oid)).remove();
                } else {
                    colspan = _tableColumnSelection.length;

                    html.push('<tr data-what-list="{0}">'.format(oid));
                    html.push('<td></td>');
                    html.push('<td colspan="{0}">'.format(colspan));
                    html.push('<div class="inner-table">');
                    html.push('<span class="gfx-i-t-link" style="float:right;margin-bottom:5px;">{0}</span>'.format(i18next.t('misc.close')));
                    html.push('<table class="table"><tbody>');
                    html.push(renderAssignedCheckpointGroups(checkpointGroups));
                    html.push(renderAssignedForms(forms));
                    html.push('</tbody></table>');
                    html.push('</div>');
                    html.push('</td>');
                    html.push('</tr>');

                    $tr.after(html.join(''));
                }
            });
    }

    function renderAssignedCheckpointGroups(checkpointGroups) {
        if (!(checkpointGroups instanceof Array) || !checkpointGroups.length) {
            return;
        }

        var html = [];
        var groupsInfo = [];

        for (var i = 0; i < checkpointGroups.length; i++) {
            var element = checkpointGroups[i];

            if (!_schedulingElements[element.OID]) {
                continue;
            }

            var group = _schedulingElements[element.OID];

            groupsInfo.push({
                Title: group.Title || '',
                OrganisationUnit: Explorer.CreateOrganisationUnitPath(element.OID, _schedulingElements) || ''
            });
        }

        groupsInfo.sort(Tools.SortByTitle);

        for (var ix = 0; ix < groupsInfo.length; ix++) {
            var gInfo = groupsInfo[ix];

            html.push('<tr>');
            html.push('<td class="entity-type">{0}</td>'.format(i18next.t('elementTypes.testgroup')));
            html.push('<td ><b>{0}</b><br><span class="location">{1}</span></td>'
                .format(gInfo.Title, gInfo.OrganisationUnit));
            html.push('</tr>');
        }

        return html.join('');
    }

    function renderAssignedForms(forms) {
        if (!(forms instanceof Array) || !forms.length) {
            return;
        }

        var html = [];
        var executables = [];

        for (var i = 0; i < forms.length; i++) {
            var item = forms[i];
            var form = ((DataManager.FormLoader.Data || {}).DataMap || {})[item.OID] ||
                _schedulingElements[item.OID];

            if (form) {
                item.Locations.forEach(function (locationOID) {
                    var location = DataManager.OrganizationUnitLoader.Data.DataMap[locationOID];

                    if (!location) {
                        return;
                    }

                    executables.push({
                        Title: form.Title || '',
                        OrganisationUnit: Explorer.CreateOrganisationUnitPath(location.OID) || ''
                    });
                });
            }
        }

        executables.sort(Tools.SortByTitle);

        for (var iy = 0; iy < executables.length; iy++) {
            var entry = executables[iy];

            html.push('<tr>');
            html.push('<td class="entity-type">{0}</td>'.format(i18next.t('elementTypes.form')));
            html.push('<td ><b>{0}</b> <span class="location">@ {1}</span>'
                .format(entry.Title, entry.OrganisationUnit));

            if (entry.Hierarchy) {
                html.push('<br><span class="location">{0}</span>'.format(entry.Hierarchy))
            }

            html.push('</td>');
            html.push('</tr>');
        }

        return html.join('');
    }

    function onHideDetails() {
        $(this).closest('tr').remove();
    }

    function onShowSchedulingPicker() {
        if ($(this).attr('disabled')) {
            return;
        }

        Tools.Spinner.show();
        Tools.DataManager.SchedulingLoader
            .GetAll()
            .then(function (schedulingData) {
                Tools.Spinner.hide();

                if (!schedulingData) {
                    return;
                }

                var entities = (function walk (scheduling) {
                    var selectable = Tools.contains(_locationSchedulings, scheduling.OID, 'OID');
                    var children = (scheduling.Children || [])
                        .map(walk)
                        .filter(function (child) {
                            return !!child;
                        });

                    if (selectable || children.length) {
                        return {
                            id: scheduling.OID,
                            text: scheduling.Title,
                            color: scheduling.Color,
                            children: children,
                            selectable: !!selectable,
                            classes: selectable ? '' : 'jquery-tree-node-disabled'
                        };
                    }
                })(schedulingData.Root);

                var options = {
                    title: i18next.t('explorer.schedulings.selectScheduling'),
                    selectMultiple: false,
                    onSelect: function (schedulingOID) {
                        var scheduling = schedulingData.Map[schedulingOID];

                        if (scheduling.HasActiveAssignments) {
                            IssueViewer.ExecuteScheduling(schedulingOID, function () {
                                Explorer.UpdateTabCounters()
                                    .then(Explorer.ResizeTabMenu);
                            });
                        } else {
                            Tools.Message.Show({
                                title: i18next.t('explorer.schedulings.messages.hasNoActiveAssignments.title'),
                                text: i18next.t('explorer.schedulings.messages.hasNoActiveAssignments.text'),
                                ok: true
                            });
                        }
                    },
                    treeOptions: {
                        opened: true,
                        selectable: true
                    }
                };

                TreePicker.Show(entities, options);
            }, function (xhr) {
                Tools.handleHttpError(Enums.HttpActionType.Read, xhr);
                Tools.Spinner.hide();
            });
    }

    function onTabControlItemClick() {
        var $this = $(this);

        if ($this.hasClass('selected')) {
            return;
        }

        $this
            .addClass('selected')
            .siblings()
            .removeClass('selected');

        var tabId = $this.data('tab');
        var $tab = $explorerScheduling.find('.tabs .tab[data-tab="{0}"]'.format(tabId));

        $tab
            .removeClass('hide')
            .siblings()
            .addClass('hide');

        $explorerScheduling
            .find('.table-container .tbl_header')
            .toggleClass('hide', tabId === tabs.EffortByUser);

        _currentTabId = tabId;

        renderCurrentTab();
    }

    function renderCurrentTab() {
        updateTabCounter(Explorer.GetCurrentTab());

        switch (_currentTabId) {
            case tabs.SchedulingList:
                updateTable();
                break;
            case tabs.EffortByUser:
                initEstimatedEffortChartTab();
                break;
        }
    }

    function initEstimatedEffortChartTab() {
        destroyCharts();

        var aggregatedData = getAggregatedEstimatedEfforts();

        if (!Object.keys(aggregatedData).length) {
            $('#explorer-scheduling-effort-by-user-chart')
                .html(renderNoDataAvailableContainer());
            return;
        }

        renderEstimatedEffortByUsersChart(aggregatedData);
    }

    function destroyCharts() {
        if (_effortByUserChart) {
            _effortByUserChart.destroy();
            _effortByUserChart = null;
        }
    }

    function getAggregatedEstimatedEfforts() {
        var aggregatedEstimatedEfforts = {};

        if (!_currentSchedulings) {
            _currentSchedulings = sortSchedulingList(filterSchedulingList(_locationSchedulings));
        }

        _currentSchedulings.forEach(function (scheduling) {
            if (!scheduling.EstimatedEffort) {
                return;
            }

            var aggregatedUsers = {};

            (scheduling.Users || []).forEach(function (user) {
                if (!aggregatedEstimatedEfforts.hasOwnProperty(user.OID)) {
                    aggregatedEstimatedEfforts[user.OID] = {
                        UserOID: user.OID,
                        Fullname: user.Fullname,
                        From: 0,
                        To: 0
                    };
                }

                aggregatedEstimatedEfforts[user.OID].From += scheduling.EstimatedEffort;
                aggregatedEstimatedEfforts[user.OID].To += scheduling.EstimatedEffort;
                aggregatedUsers[user.OID] = true;
            });

            (scheduling.Teams || []).forEach(function (team) {
                (team.Users || []).forEach(function (teamUser) {
                    if (aggregatedUsers.hasOwnProperty(teamUser.OID)) {
                        return;
                    }

                    var user = Users[teamUser.OID];

                    if (!user) {
                        return;
                    }

                    if (!aggregatedEstimatedEfforts.hasOwnProperty(user.OID)) {
                        aggregatedEstimatedEfforts[user.OID] = {
                            UserOID: user.OID,
                            Fullname: user.Fullname,
                            From: 0,
                            To: 0
                        };
                    }

                    aggregatedEstimatedEfforts[user.OID].To += scheduling.EstimatedEffort;
                });
            });
        });

        return aggregatedEstimatedEfforts;
    }

    function renderNoDataAvailableContainer() {
        return [
            '<div class="content-panel-row">',
                '<div class="content-panel empty"><p class="text">',
                    i18next.t('explorer.schedulings.noDataAvailable'),
                '</p></div>',
            '</div>'
        ].join('');
    }

    function renderEstimatedEffortByUsersChart(aggregatedData) {
        if (!aggregatedData) {
            return;
        }

        var data = [[], []];
        var yAxisLabels = [];

        Object.values(aggregatedData)
            .sort(Tools.SortByFullname)
            .forEach(function (effortData) {
                data[0].push(effortData.From);

                if (effortData.From !== effortData.To) {
                    data[1].push(effortData.To - effortData.From);
                } else {
                    data[1].push(0);
                }

                yAxisLabels.push(effortData.Fullname);
            });

        var unit = Client.Settings.EstimatedEffortSettings ?
            Properties[Client.Settings.EstimatedEffortSettings.UnitOID] :
            null;

        var unitTitle = Tools.createUnitTitle(unit);

        _effortByUserChart = Tools.charts.createChart(Tools.charts.chartType.StackedBar, {
            series: [{
                name: i18next.t('explorer.schedulings.tabs.estimatedEffortByUser.chart.directlyAssigned'),
                data: data[0]
            }, {
                name: i18next.t('explorer.schedulings.tabs.estimatedEffortByUser.chart.assignedThroughTeams'),
                data: data[1]
            }],
            element: document.getElementById('explorer-scheduling-effort-by-user-chart'),
            showLegend: true,
            xAxisCategories: yAxisLabels,
            valueFormatter: function (value) {
                return !!unitTitle ? (value + ' ' + unitTitle) : value;
            }
        });

        _effortByUserChart.render();
    }

    function resize() {
        if ($body.width() >= 1200) {
            $explorerScheduling.find('.table-filter-column').css('min-width', '250px');
        } else {
            $explorerScheduling.find('.table-filter-column.last-row').css('min-width', '350px');
        }

        $tableWrapper.css('top', $tableView.outerHeight() + $explorerScheduling.children('.table-container').outerHeight() + 10);
        $tableHeader.width($tableBody.width());

        $.each($explorerScheduling.find('.inner-table'), function (_, table) {
            $(table).parent().attr('colspan', _tableColumnSelection.length);
        });
    }

    function prepareScheduling(scheduling) {
        var retval = $.extend({}, scheduling);

        retval.Teams = prepareSchedulingTeams(scheduling.Teams);
        retval.Users = prepareSchedulingUsers(scheduling.Users);
        retval.Contacts = prepareSchedulingContacts(scheduling.Contacts);
        retval.ContactGroups = prepareSchedulingContactGroups(scheduling.ContactGroups);
        retval.DeadlineTimestamps = prepareSchedulingDeadlineTimestamps(scheduling.Schedules);
        retval.Rotations = prepareSchedulingRotations(scheduling.Schedules);

        return retval;
    }

    function prepareSchedulingTeams(teams) {
        var retval = $.map(teams || [], function (oid) {
            return window.Teams[oid];
        });

        retval.sort(function (a, b) {
            var titleA = (a.Title || '').toLowerCase();
            var titleB = (b.Title || '').toLowerCase();

            if (titleA > titleB) {
                return 1;
            }

            if (titleA < titleB) {
                return -1;
            }

            return 0;
        });

        return retval;
    }

    function prepareSchedulingUsers(users) {
        var retval = $.map(users || [], function (oid) {
            return window.Users[oid];
        });

        retval.sort(function (a, b) {
            var nameA = (a.Lastname || '').toLowerCase();
            var nameB = (b.Lastname || '').toLowerCase();

            if (nameA > nameB) {
                return 1;
            }

            if (nameA < nameB) {
                return -1;
            }

            nameA = (a.Firstname || '').toLowerCase();
            nameB = (b.Firstname || '').toLowerCase();

            if (nameA > nameB) {
                return 1;
            }

            if (nameA < nameB) {
                return -1;
            }

            return 0;
        });

        return retval;
    }

    function prepareSchedulingContacts(contactOIDs) {
        var contacts = $.map(contactOIDs || [], function (oid) {
            return Contacts[oid];
        });

        contacts.sort(Tools.SortByTitle);

        return contacts;
    }

    function prepareSchedulingContactGroups(contactGroupOIDs) {
        var contactGroups = $.map(contactGroupOIDs || [], function (oid) {
            return ContactGroups[oid];
        });

        contactGroups.sort(function (a, b) {
            var titleA = (a.Title || '').toLowerCase();
            var titleB = (b.Title || '').toLowerCase();

            if (titleA > titleB) {
                return 1;
            }

            if (titleA < titleB) {
                return -1;
            }

            return 0;
        });

        return contactGroups;
    }

    function prepareSchedulingDeadlineTimestamps(schedules) {
        var retval = [];

        (schedules || []).forEach(function (schedule) {
            if (schedule.FrequencySubdayType !== Enums.FrequencySubdayTypes.RepeatOnStateChange) {
                retval = Tools.addUnique(retval, schedule.DeadlineTimestamps);
            } else if (_virtualSchedules) {
                var virtuals = _virtualSchedules[schedule.OID];
                if (!virtuals || !virtuals.length) {
                    return;
                }

                for (var vi = 0; vi < virtuals.length; vi++) {
                    var virt = virtuals[vi];
                    if (virt.IsExecuted) {
                        continue;
                    }

                    var deadline = new Date(virt.NextExecution);
                    deadline = new Date(deadline.getTime() - schedule.CreationOffset * 60000);
                    retval = Tools.addUnique(retval, Tools.dateTime.toGMTString(deadline));
                }
            }
        });

        retval = $.map(retval, function (value) { return new Date(value); });

        retval.sort(function (a, b) { return a.getTime() - b.getTime(); });

        return retval;
    }

    function prepareSchedulingRotations(schedules) {
        var today = Tools.dateTime.getDateFromObject(new Date());

        return $.map(schedules || [], function (schedule) {
            if (schedule.EndingDate && schedule.EndingDate < today) {
                return;
            }

            return scheduling.createScheduleRotationText(schedule);
        });
    }

    function disposeTab() {
        destroyCharts();
        $tableBody.empty();
    }

    Scheduling.Show = show;
    Scheduling.Resize = resize;
    Scheduling.PrepareScheduling = prepareScheduling;
    Scheduling.ResetFilters = resetFilters;
    Scheduling.ResetSchedulingCache = resetSchedulingCache;
    Scheduling.UpdateTabCounter = updateTabCounter;
    Scheduling.Dispose = disposeTab;

    return (global.Scheduling = Scheduling);
})( window.Explorer || (window.Explorer = {}) );