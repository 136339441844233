/**
 * @require ./explorer.js
 */
(function (global) {
	var Issues = {};

    var issuesLoaded, issues;
    var tableColumnSelection, tableColumns;
    var sorting, sortingorder;
    var typeFilter, types;
    var creatorFilter, userFilter, users;
    var teamFilter, teams;
    var contactFilter, contacts;
    var contactGroupFilter, contactGroups;
    var keywordFilter, keywords;
    var classificationFilter, classifications;
    var statusFilter, states;
    var priorityFilter, priorities;
    var formFilter;
    var schedulingFilter;
    var processingStatusFilter;
    var processingStatus;
    var selectAll;
    var periodFilter = {};
    var searchTimeout, searchValue, hideNonResponsibleIssues;
    var toggleControls = false;
    var userCanAssignAllTeamsAndUsers;
    var visibleTeams, selectableTeams;
    var activeFilters = {
        TypeFilter: false,
        CreatorFilter: false,
        UserFilter: false,
        TeamFilter: false,
        ContactFilter: false,
        ContactGroupFilter: false,
        KeywordFilter: false,
        ClassificationFilter: false,
        StatusFilter: false,
        PriorityFilter: false,
        FormFilter: false,
        SchedulingFilter: false,
        ProcessingStatusFilter: false,
        PeriodFilter: false,
        SearchValue: false,
        HideNonResponsibleIssues: false
    };
    var interacts = {
        draggable: null,
        droppable: null
    };

    var updateRequest;

    var $body = $('body');
    var $tree = $('#center-tree');
    var $explorerDashboard = $('#explorer-dashboard');
    var $explorerInfo = $('#explorer-info');
    var $explorerValues = $('#explorer-values');
    var $explorerNews = $('#explorer-news');
    var $explorerIssues = $('#explorer-issues');
    var $explorerDisturbances = $('#explorer-disturbances');
    var $explorerScheduling = $('#explorer-scheduling');
    var $explorerFiles = $('#explorer-files');
    var $explorerArchive = $('#explorer-archive');
    var $properties = $('#properties');
    var $explorerTabMenu = $('#explorer-tab-menu');
    var $explorerOptionMenu = $('#explorer-tab-menu-options');
    var $tableWrapper = $explorerIssues.find('.tblContentWrapper');
    var $tableHeader = $explorerIssues.find('.tbl_header');
    var $selectAll = $tableHeader.find('.select-all');
    var $tableBody = $tableWrapper.find('.table > tbody');
    var $resetFilters = $('#explorer-issues-reset-filters');
    var $toggleControls = $('#explorer-issues-toggle-controls');
    var $newIssue = $('#explorer-issues-new-issue');
    var $printIssues = $('#explorer-issues-print-issues');
    var $editIssues = $('#explorer-issues-edit-issues');
    var $typeFilter = $('#explorer-issues-type-filter');
    var $tableColumnSelection = $('#explorer-issues-table-columns');
    var $creatorFilter = $('#explorer-issues-creator-filter');
    var $userFilter = $('#explorer-issues-user-filter');
    var $teamFilter = $('#explorer-issues-team-filter');
    var $contactFilter = $('#explorer-issues-contact-filter');
    var $contactGroupFilter = $('#explorer-issues-contact-group-filter');
    var $keywordFilter = $('#explorer-issues-keyword-filter');
    var $classificationFilter = $('#explorer-issues-classification-filter');
    var $statusFilter = $('#explorer-issues-status-filter');
    var $priorityFilter = $('#explorer-issues-priority-filter');
    var $formFilter = $('#explorer-issues-form-filter');
    var $schedulingFilter = $('#explorer-issues-scheduling-filter');
    var $search = $('#explorer-issues-search');
    var $periodFilter = $('#explorer-issues-period-filter');
    var $processingStatus = $('#locationheader .processing-status');
    var $processingStatusFilter = $('#explorer-issues-processing-status-filter');
    var $newIssueDropdown = $('#explorer-issues .newIssue');

    function show(match, options) {
        var initResult = Explorer.Init(match);

        if (!initResult.CurrentTabIsAvailable) {
            var currentTab = Explorer.GetCurrentTab();

            if (currentTab && initResult.NewTab && initResult.NewTab.OID !== currentTab.OID) {
                Explorer.ShowTab(initResult.NewTab);
                return;
            }
        }

        init(options);
        update()
            .then(Explorer.UpdateTabCounters)
            .then(Explorer.ResizeTabMenu);
    }

    function init(options) {
        userCanAssignAllTeamsAndUsers = ressources.users.hasRightAtLocation(
            Enums.Rights.IssueRights.AllowAssigningAllUsersToIssues,
            CurrentEntity.OID
        );
        issuesLoaded = 0;
        issues = {};
        selectAll = false;
        tableColumnSelection = [];

        $tree.tree('remove-class-from-all-nodes', 'jquery-tree-node-marker-green jquery-tree-node-marker-yellow jquery-tree-node-marker-red');

        if (CurrentEntity.Enabled && Tools.contains(User.Rights, 'TMFT', 'ID')) {
            if (Tools.any(User.Rights, [ 'TMFT-TASKS', 'TMFT-FORMS', 'TMFT-NOTES', 'TMFT-DISTURBANCES',
                        'TMFT-TASKS-A', 'TMFT-FORMS-A', 'TMFT-NOTES-A', 'TMFT-DISTURBANCES-A',
                        'TMFT-TASKS-SC', 'TMFT-FORMS-SC', 'TMFT-NOTES-SC', 'TMFT-DISTURBANCES-SC'], 'ID') ||
                    Client.Licenses &&
                    Client.Licenses.Investigations &&
                    Tools.any(User.Rights, ['TMFT-INVESTIGATIONS', 'TMFT-INVESTIGATIONS-A', 'TMFT-INVESTIGATIONS-SC'], 'ID')) {
                $newIssue.removeAttr('disabled');
            } else {
                $newIssue.attr('disabled', 'disabled');
            }
        } else {
            $newIssue.attr('disabled', 'disabled');
        }

        $printIssues.attr('disabled', 'disabled');
        $editIssues.attr('disabled', 'disabled');

        initTab();
        initLanguage();
        initIssueTypeSelection();
        initTypes();
        initTableColumns();
        initContacts();
        initContactGroups();
        initStatus();
        initPriorities();
        initKeywords();
        initClassifications();
        initProcessingStatus();

        if (options && options.filter) {
            initPreselectedFilter(options.filter);
        }

        initUsers();
        initTeams();

        initTableColumnSelection(false);
        initFilters();
        initTable();

        unbindEvents();
        bindEvents();

        resize();
    }

    function initFilters() {
        initTypeFilter();
        initCreatorFilter();
        initUserFilter();
        initTeamFilter();
        initContactFilter();
        initContactGroupFilter();
        initKeywordFilter();
        initClassificationFilter();
        initStatusFilter();
        initPriorityFilter();
        initFormFilter();
        initSchedulingFilter();
        initProcessingStatusFilter();
        initSearch();
        initPeriodFilter();
        initHideNonResponsibleIssuesFilter();
        setFilterState();
    }

    function setFilterState() {
        var isActive;

        for (var filter in activeFilters) {
            isActive = activeFilters[filter];

            if (isActive) {
                break;
            }
        }

        isActive ? $resetFilters.addClass('active') : $resetFilters.removeClass('active');
    }

    function initTab() {
        $explorerDashboard.addClass('hide');
        $explorerInfo.addClass('hide');
        $explorerValues.addClass('hide');
        $explorerNews.addClass('hide');
        $explorerIssues.removeClass('hide');
        $explorerDisturbances.addClass('hide');
        $explorerScheduling.addClass('hide');
        $explorerFiles.addClass('hide');
        $explorerArchive.addClass('hide');
        $properties.removeClass('active');

        $explorerTabMenu.find('.open').removeClass('open');

        $explorerOptionMenu.removeClass('active');
    }

    function initLanguage() {
        var $controls = $explorerIssues.find('.table-control');

        if (!$controls.hasClass('collapsed')) {
            $toggleControls.attr('title', i18next.t('explorer.toolbar.showFilters'));
        } else {
            $toggleControls.attr('title', i18next.t('explorer.toolbar.hideFilters'));
        }

        $newIssue.find('.btn-title').text(i18next.t('explorer.issues.add.title'));
        $newIssueDropdown.find('.action-window-content-element[data-selection="1"]').text(i18next.t('explorer.issues.add.task'));
        $newIssueDropdown.find('.action-window-content-element[data-selection="3"]').text(i18next.t('explorer.issues.add.form'));
        $newIssueDropdown.find('.action-window-content-element[data-selection="4"]').text(i18next.t('explorer.issues.add.note'));
        $newIssueDropdown.find('.action-window-content-element[data-selection="5"]').text(i18next.t('explorer.issues.add.disturbance'));
        $newIssueDropdown.find('.action-window-content-element[data-selection="8"]').text(i18next.t('explorer.issues.add.investigation'));
        $newIssueDropdown.find('.action-window-content-element[data-selection="10"]').text(i18next.t('explorer.issues.add.info'));
        $newIssueDropdown.find('.close').text(i18next.t('explorer.issues.add.close'));

        $printIssues.text(i18next.t('explorer.issues.print'));
        $editIssues.text(i18next.t('explorer.issues.edit'));

        $resetFilters.attr('title', i18next.t('explorer.toolbar.resetFilters'));
        $('#explorer-issues-type-filter-lbl').text(i18next.t('explorer.issues.typeFilter.label'));
        $('#explorer-issues-table-columns').attr('title', i18next.t('explorer.toolbar.selectTableColumns'));
        $('#explorer-issues-creator-filter-lbl').text(i18next.t('explorer.issues.creatorFilter.label'));
        $('#explorer-issues-user-filter-lbl').text(i18next.t('explorer.issues.userFilter.label'));
        $('#explorer-issues-team-filter-lbl').text(i18next.t('explorer.issues.teamFilter.label'));
        $('#explorer-issues-contact-filter-lbl').text(i18next.t('explorer.issues.contactFilter.label'));
        $('#explorer-issues-contact-group-filter-lbl').text(i18next.t('explorer.issues.contactGroupFilter.label'));
        $('#explorer-issues-keyword-filter-lbl').text(i18next.t('explorer.issues.keywordFilter.label'));
        $('#explorer-issues-classification-filter-lbl').text(i18next.t('explorer.issues.classificationFilter.label'));
        $('#explorer-issues-status-filter-lbl').text(i18next.t('explorer.issues.statusFilter.label'));
        $('#explorer-issues-priority-filter-lbl').text(i18next.t('explorer.issues.priorityFilter.label'));
        $('#explorer-issues-period-filter-lbl').text(i18next.t('explorer.issues.periodFilter.label'));
        $('#explorer-issues-form-filter-lbl').text(i18next.t('explorer.issues.formFilter.label'));
        $('#explorer-issues-scheduling-filter-lbl').text(i18next.t('explorer.issues.schedulingFilter.label'));
        $('#explorer-issues-processing-status-filter-lbl').text(i18next.t('explorer.issues.processingStatusFilter.label'));
        $('#explorer-issues-hide-non-responsible-issues-lbl').text(i18next.t('explorer.issues.hideNonResponsibleIssues'));
        $('#explorer-issues-search-lbl').text(i18next.t('explorer.issues.search'));
        $periodFilter.find('.btn-title').text(i18next.t('explorer.issues.periodFilter.button'));

        $tableHeader.find('th[data-columntitle="id"]').text(i18next.t('explorer.issues.tableColumns.id'));
        $tableHeader.find('th[data-columntitle="custom-id"]').text(i18next.t('explorer.issues.tableColumns.customId'));
        $tableHeader.find('th[data-columntitle="title"]').text(i18next.t('explorer.issues.tableColumns.title'));
        $tableHeader.find('th[data-columntitle="elementcoding"]').text(i18next.t('explorer.issues.tableColumns.elementCoding'));
        $tableHeader.find('th[data-columntitle="creationtimestamp"]').text(i18next.t('explorer.issues.tableColumns.creationTimestamp'));
        $tableHeader.find('th[data-columntitle="modificationtimestamp"]').text(i18next.t('explorer.issues.tableColumns.lastModification'));
        $tableHeader.find('th[data-columntitle="deadlinetimestamp"]').text(i18next.t('explorer.issues.tableColumns.deadline'));
        $tableHeader.find('th[data-columntitle="state"]').text(i18next.t('explorer.issues.tableColumns.status'));
        $tableHeader.find('th[data-columntitle="priority"]').text(i18next.t('explorer.issues.tableColumns.priority'));
        $tableHeader.find('th[data-columntitle="teamsAndUsers"]').text(i18next.t('explorer.issues.tableColumns.teamsAndUsers'));
        $tableHeader.find('th[data-columntitle="checkpointCount"]').text(i18next.t('explorer.issues.tableColumns.checkpointCount'));
        $tableHeader.find('th[data-columntitle="collectedCheckpointCount"]').text(i18next.t('explorer.issues.tableColumns.collectedCheckpointCount'));
        $tableHeader.find('th[data-columntitle="openCheckpointCount"]').text(i18next.t('explorer.issues.tableColumns.openCheckpointCount'));
        $tableHeader.find('th[data-columntitle="requiredCheckpointCount"]').text(i18next.t('explorer.issues.tableColumns.requiredCheckpointCount'));
        $tableHeader.find('th[data-columntitle="collectedRequiredCheckpointCount"]').text(i18next.t('explorer.issues.tableColumns.collectedRequiredCheckpointCount'));
        $tableHeader.find('th[data-columntitle="estimatedEffort"]').text(i18next.t('explorer.issues.tableColumns.estimatedEffort'));

        $tableHeader.find('th[data-columntitle="id"]').attr('title', i18next.t('explorer.issues.tableColumns.id'));
        $tableHeader.find('th[data-columntitle="custom-id"]').attr('title', i18next.t('explorer.issues.tableColumns.customId'));
        $tableHeader.find('th[data-columntitle="title"]').attr('title', i18next.t('explorer.issues.tableColumns.title'));
        $tableHeader.find('th[data-columntitle="elementcoding"]').attr('title', i18next.t('explorer.issues.tableColumns.elementCoding'));
        $tableHeader.find('th[data-columntitle="creationtimestamp"]').attr('title', i18next.t('explorer.issues.tableColumns.lastModification'));
        $tableHeader.find('th[data-columntitle="modificationtimestamp"]').attr('title', i18next.t('explorer.issues.tableColumns.lastModification'));
        $tableHeader.find('th[data-columntitle="deadlinetimestamp"]').attr('title', i18next.t('explorer.issues.tableColumns.deadline'));
        $tableHeader.find('th[data-columntitle="state"]').attr('title', i18next.t('explorer.issues.tableColumns.status'));
        $tableHeader.find('th[data-columntitle="priority"]').attr('title', i18next.t('explorer.issues.tableColumns.priority'));
        $tableHeader.find('th[data-columntitle="teamsAndUsers"]').attr('title', i18next.t('explorer.issues.tableColumns.teamsAndUsers'));
        $tableHeader.find('th[data-columntitle="checkpointCount"]').attr('title', i18next.t('explorer.issues.tableColumns.checkpointCount'));
        $tableHeader.find('th[data-columntitle="collectedCheckpointCount"]').attr('title', i18next.t('explorer.issues.tableColumns.collectedCheckpointCount'));
        $tableHeader.find('th[data-columntitle="openCheckpointCount"]').attr('title', i18next.t('explorer.issues.tableColumns.openCheckpointCount'));
        $tableHeader.find('th[data-columntitle="requiredCheckpointCount"]').attr('title', i18next.t('explorer.issues.tableColumns.requiredCheckpointCount'));
        $tableHeader.find('th[data-columntitle="collectedRequiredCheckpointCount"]').attr('title', i18next.t('explorer.issues.tableColumns.collectedRequiredCheckpointCount'));
        $tableHeader.find('th[data-columntitle="estimatedEffort"]').attr('title', i18next.t('explorer.issues.tableColumns.estimatedEffort'));
    }

    function initIssueTypeSelection() {
        if (!Client.Licenses.EnableInfoBoard) {
            $newIssueDropdown
                .find('.action-window-content-element[data-selection="10"]')
                .remove();
        }

        if (Tools.any(User.Rights, ['TMFT-TASKS', 'TMFT-TASKS-A', 'TMFT-TASKS-SC'], 'ID')) {
            $newIssueDropdown
                .find('.action-window-content-element[data-selection="1"]')
                .removeAttr('disabled');
        } else {
            $newIssueDropdown
                .find('.action-window-content-element[data-selection="1"]')
                .attr('disabled', 'disabled');
        }

        if (Tools.any(User.Rights, ['TMFT-FORMS', 'TMFT-FORMS-A', 'TMFT-FORMS-SC'], 'ID')) {
            $newIssueDropdown
                .find('.action-window-content-element[data-selection="3"]')
                .removeAttr('disabled');
        } else {
            $newIssueDropdown
                .find('.action-window-content-element[data-selection="3"]')
                .attr('disabled', 'disabled');
        }

        if (Tools.any(User.Rights, ['TMFT-NOTES', 'TMFT-NOTES-A', 'TMFT-NOTES-SC'], 'ID')) {
            $newIssueDropdown
                .find('.action-window-content-element[data-selection="4"]')
                .removeAttr('disabled');
        } else {
            $newIssueDropdown
                .find('.action-window-content-element[data-selection="4"]')
                .attr('disabled', 'disabled');
        }

        if (Tools.any(User.Rights, ['TMFT-DISTURBANCES', 'TMFT-DISTURBANCES-A', 'TMFT-DISTURBANCES-SC'], 'ID')) {
            $newIssueDropdown
                .find('.action-window-content-element[data-selection="5"]')
                .removeAttr('disabled');
        } else {
            $newIssueDropdown
                .find('.action-window-content-element[data-selection="5"]')
                .attr('disabled', 'disabled');
        }

        if (!Client.Licenses || !Client.Licenses.Investigations) {
            $newIssueDropdown
                .find('.action-window-content-element[data-selection="8"]')
                .addClass('hide');
        } else if (Tools.any(User.Rights, ['TMFT-INVESTIGATIONS', 'TMFT-INVESTIGATIONS-A', 'TMFT-INVESTIGATIONS-SC'], 'ID')) {
            $newIssueDropdown
                .find('.action-window-content-element[data-selection="8"]')
                .removeClass('hide')
                .removeAttr('disabled');
        } else {
            $newIssueDropdown
                .find('.action-window-content-element[data-selection="8"]')
                .removeClass('hide')
                .attr('disabled', 'disabled');
        }
    }

    function initTypes() {
        types = [];

        types.push({ OID: '1-2', Title: i18next.t('explorer.issues.typeFilter.tasks') });

        if (Client.Licenses && Client.Licenses.Surveys) {
            types.push({ OID: '3-7', Title: i18next.t('explorer.issues.typeFilter.forms') });
        } else {
            types.push({ OID: '3', Title: i18next.t('explorer.issues.typeFilter.forms') });
        }

        types.push({ OID: '4', Title: i18next.t('explorer.issues.typeFilter.notes') });

        if (Client.Licenses && Client.Licenses.Inspections) {
            types.push({ OID: '6', Title: i18next.t('explorer.issues.typeFilter.inspections') });
        }

        if (Client.Licenses && Client.Licenses.Investigations) {
            types.push({ OID: '8', Title: i18next.t('explorer.issues.typeFilter.investigations') });
        }

        types.push({ OID: '5', Title: i18next.t('explorer.issues.typeFilter.disturbances') });
        types.push({ OID: '9', Title: i18next.t('explorer.issues.typeFilter.disturbancesCompleted') });
    }

    function initTableColumns() {
        tableColumns = [
            {OID: '0', Title: i18next.t('explorer.issues.tableColumns.id')},
            {OID: '1', Title: i18next.t('explorer.issues.tableColumns.customId')},
            {OID: '2', Title: i18next.t('explorer.issues.tableColumns.title')},
            {OID: '15', Title: i18next.t('explorer.issues.tableColumns.elementCoding')},
            {OID: '3', Title: i18next.t('explorer.issues.tableColumns.creationTimestamp')},
            {OID: '4', Title: i18next.t('explorer.issues.tableColumns.lastModification')},
            {OID: '5', Title: i18next.t('explorer.issues.tableColumns.deadline')},
            {OID: '6', Title: i18next.t('explorer.issues.tableColumns.status')},
            {OID: '7', Title: i18next.t('explorer.issues.tableColumns.priority')},
            {OID: '8', Title: i18next.t('explorer.issues.tableColumns.teamsAndUsers')},
            {OID: '9', Title: i18next.t('explorer.issues.tableColumns.checkpointCount')},
            {OID: '10', Title: i18next.t('explorer.issues.tableColumns.collectedCheckpointCount')},
            {OID: '11', Title: i18next.t('explorer.issues.tableColumns.openCheckpointCount')},
            {OID: '12', Title: i18next.t('explorer.issues.tableColumns.requiredCheckpointCount')},
            {OID: '13', Title: i18next.t('explorer.issues.tableColumns.collectedRequiredCheckpointCount')},
            {OID: '14', Title: i18next.t('explorer.issues.tableColumns.estimatedEffort')}
        ];
    }

    function initUsers() {
        users = Tools.GetSelectableUsers(CurrentEntity.OID);
        users.sort(Tools.SortByFullname);
    }

    function initTeams() {
        var teamInformation = Tools.GetSelectableTeams(CurrentEntity.OID);

        teams = teamInformation.Teams;
        visibleTeams = teamInformation.VisibleTeams;
        selectableTeams = teamInformation.SelectableTeams;
    }

    function initContacts() {
        contacts = $.map(Contacts || {}, function (contact) { return contact; });
        contacts.sort(Tools.SortByFullname);
    }

    function initContactGroups() {
        contactGroups = $.map(ContactGroups || {}, function (contactGroup) { return contactGroup; });
        contactGroups.sort(Tools.SortByTitle);
    }

    function initStatus() {
        states = $.map(Properties || {}, function (property) {
            if (property.Type === Enums.propertyType.Status) {
                return property;
            }
        });
    }

    function initPriorities() {
        priorities = $.map(Properties || {}, function (property) {
            if (property.Type === Enums.propertyType.Priority) {
                return property;
            }
        });
    }

    function initKeywords() {
        keywords = $.map(Properties || {}, function (property) {
            if (property.Type === Enums.propertyType.Keyword) {
                return property;
            }
        });
    }

    function initClassifications() {
        classifications = $.map(Properties || {}, function (property) {
            if (property.Type === Enums.propertyType.Classification) {
                return property;
            }
        });
    }

    function initProcessingStatus() {
        processingStatus = [
            { Title: i18next.t('changeMode.panels.properties.processingStatuses.notYetDue'), ID: 0 },
            { Title: i18next.t('changeMode.panels.properties.processingStatuses.todayDue'), ID: 1 },
            { Title: i18next.t('changeMode.panels.properties.processingStatuses.overdue'), ID: 2 }
        ];
    }

    function resetFilters() {
        $resetFilters.removeClass('active');

        activeFilters = {
            TypeFilter: false,
            UserFilter: false,
            TeamFilter: false,
            ContactFilter: false,
            ContactGroupFilter: false,
            KeywordFilter: false,
            ClassificationFilter: false,
            StatusFilter: false,
            PriorityFilter: false,
            FormFilter: false,
            SchedulingFilter: false,
            ProcessingStatusFilter: false,
            PeriodFilter: false,
            SearchValue: false,
            HideNonResponsibleIssues: false
        };

        issuesLoaded = 0;
        typeFilter = null;
        creatorFilter = null;
        userFilter = null;
        teamFilter = null;
        contactFilter = null;
        contactGroupFilter = null;
        keywordFilter = null;
        classificationFilter = null;
        statusFilter = null;
        priorityFilter = null;
        formFilter = null;
        schedulingFilter = null;
        processingStatusFilter = null;
        periodFilter = {};
        searchValue = null;
        hideNonResponsibleIssues = false;
    }

    function initPreselectedFilter(filter) {
        if (!Object.keys(filter || {}).length) {
            return;
        }

        resetFilters();

        userFilter = filter.Users || null;
        teamFilter = filter.Teams || null;
        periodFilter = filter.PeriodFilter || null;

        if (filter.status) {
            statusFilter = filter.status
                .map(function (oid) {
                    return Tools.getFirst(states, oid, 'OID');
                })
                .filter(function (status) { return !!status; });
        }

        if (filter.processingStatus) {
            processingStatusFilter = filter.processingStatus;
        }
    }

    function initTypeFilter() {
        var $btnTitle = $typeFilter.find('.btn-title');
        var titles, text;

        titles = (typeFilter || []).map(function (type) {
            return type.Title;
        });

        if (titles.length) {
            text = titles.length === types.length ?
                    i18next.t('explorer.issues.typeFilter.all') :
                    titles.sort().join(', ');

            $btnTitle.parent().addClass('filter-selected');
            activeFilters.TypeFilter = true;
        } else {
            text = i18next.t('explorer.issues.typeFilter.noSelection');

            $btnTitle.parent().removeClass('filter-selected');
            activeFilters.TypeFilter = false;
        }

        $btnTitle.html(text);
        setFilterState();
    }

    function initTableColumnSelection(selectedColumnsChanged) {
        var $btnTitle = $tableColumnSelection.find('.btn-title');
        var width, titles, text;

        var columnIds = Tools.GetOfficeSettingValue(Explorer.GetColumnsSettingName());

        if (columnIds && !selectedColumnsChanged) {
            columnIds = columnIds.split(',') || [];

            if (columnIds.length > 0) {
                tableColumnSelection = [];

                columnIds.forEach(function (oid) {
                    var column = Tools.getFirst(tableColumns, oid, 'OID');

                    if (!column) {
                        return;
                    }

                    tableColumnSelection.push(column);
                });
            }
        } else if (!(tableColumnSelection || []).length && !selectedColumnsChanged) {
            width = $explorerIssues.width();

            tableColumnSelection = [
                Tools.getFirst(tableColumns, '0', 'OID'),
                Tools.getFirst(tableColumns, '2', 'OID'),
                Tools.getFirst(tableColumns, '3', 'OID'),
                Tools.getFirst(tableColumns, '4', 'OID')
            ];

            if (width > 800) {
                tableColumnSelection.push(Tools.getFirst(tableColumns, '5', 'OID'));
            }

            if (width > 970) {
                tableColumnSelection.push(Tools.getFirst(tableColumns, '7', 'OID'));
            }

            if (width > 1080) {
                tableColumnSelection.push(Tools.getFirst(tableColumns, '6', 'OID'));
            }

            if (width > 1190) {
                tableColumnSelection.push(Tools.getFirst(tableColumns, '1', 'OID'));
            }

            if (width > 1245) {
                tableColumnSelection.push(Tools.getFirst(tableColumns, '8', 'OID'));
            }

            if (width > 1335) {
                tableColumnSelection.push(Tools.getFirst(tableColumns, '9', 'OID'));
            }

            if (width > 1415) {
                tableColumnSelection.push(Tools.getFirst(tableColumns, '10', 'OID'));
            }

            if (width > 1525) {
                tableColumnSelection.push(Tools.getFirst(tableColumns, '11', 'OID'));
            }

            if (width > 1680) {
                tableColumnSelection.push(Tools.getFirst(tableColumns, '12', 'OID'));
            }

            if (width > 1795) {
                tableColumnSelection.push(Tools.getFirst(tableColumns, '13', 'OID'));
            }
        }

        titles = $.map(tableColumnSelection, function (tableColumn) {
            return tableColumn.Title;
        });

        text = !titles.length || titles.length === tableColumns.length ?
                i18next.t('explorer.issues.tableColumns.all') :
                titles.sort().join(', ');

        $btnTitle.html(text);
    }

    function initCreatorFilter() {
        var $btnTitle = $creatorFilter.find('.btn-title');
        var titles, text;

        titles = (creatorFilter || []).map(function (user) {
            return user.Title;
        });

        if (titles.length) {
            text = titles.length === users.length ?
                i18next.t('explorer.issues.creatorFilter.all') :
                titles.sort().join(', ');

            $btnTitle.parent().addClass('filter-selected');
            activeFilters.CreatorFilter = true;
        } else {
            text = i18next.t('explorer.issues.creatorFilter.noSelection');

            $btnTitle.parent().removeClass('filter-selected');
            activeFilters.CreatorFilter = false;
        }

        $btnTitle.html(text);
        setFilterState();
    }

    function initUserFilter() {
        var $btnTitle = $userFilter.find('.btn-title');
        var titles, text;

        titles = (userFilter || []).map(function (user) {
            return user.Title;
        });

        if (titles.length) {
            text = titles.length === users.length ?
                    i18next.t('explorer.issues.userFilter.all') :
                    titles.sort().join(', ');

            $btnTitle.parent().addClass('filter-selected');
            activeFilters.UserFilter = true;
        } else {
            text = i18next.t('explorer.issues.userFilter.noSelection');

            $btnTitle.parent().removeClass('filter-selected');
            activeFilters.UserFilter = false;
        }

        $btnTitle.html(text);
        setFilterState();
    }

    function initTeamFilter() {
        var $btnTitle = $teamFilter.find('.btn-title');
        var titles, text;

        titles = (teamFilter || []).map(function (team) {
            return team.Title;
        });

        if (titles.length) {
            text = titles.length === teams.length ?
                    i18next.t('explorer.issues.teamFilter.all') :
                    titles.sort().join(', ');

            $btnTitle.parent().addClass('filter-selected');
            activeFilters.TeamFilter = true;
        } else {
            text = i18next.t('explorer.issues.teamFilter.noSelection');

            $btnTitle.parent().removeClass('filter-selected');
            activeFilters.TeamFilter = false;
        }

        $btnTitle.html(text);
        setFilterState();
    }

    function initContactFilter() {
        var $btnTitle, titles, text;

        if (!(Client.Licenses && Client.Licenses.Contacts)) {
            $('#explorer-issues-contact-filter-lbl').addClass('hide');
            $contactFilter.addClass('hide');
            return;
        }

        $btnTitle = $contactFilter.find('.btn-title');

        titles = (contactFilter || []).map(function (contact) {
            return contact.Title;
        });

        if (titles.length) {
            text = titles.length === contacts.length ?
                i18next.t('explorer.issues.contactFilter.all') :
                titles.sort().join(', ');

            $btnTitle.parent().addClass('filter-selected');
            activeFilters.ContactFilter = true;
        } else {
            text = i18next.t('explorer.issues.contactFilter.noSelection');

            $btnTitle.parent().removeClass('filter-selected');
            activeFilters.ContactFilter = false;
        }

        $btnTitle.html(text);

        $('#explorer-issues-contact-filter-lbl').removeClass('hide');
        $contactFilter.removeClass('hide');

        setFilterState();
    }

    function initContactGroupFilter() {
        var $btnTitle, titles, text;

        if (!(Client.Licenses && Client.Licenses.Contacts)) {
            $('#explorer-issues-contact-group-filter-lbl').addClass('hide');
            $contactGroupFilter.addClass('hide');
            return;
        }

        $btnTitle = $contactGroupFilter.find('.btn-title');

        titles = (contactGroupFilter || []).map(function (contactGroup) {
            return contactGroup.Title;
        });

        if (titles.length) {
            text = titles.length === contactGroups.length ?
                i18next.t('explorer.issues.contactGroupFilter.all') :
                titles.sort().join(', ');

            $btnTitle.parent().addClass('filter-selected');
            activeFilters.ContactGroupFilter = true;
        } else {
            text = i18next.t('explorer.issues.contactGroupFilter.noSelection');

            $btnTitle.parent().removeClass('filter-selected');
            activeFilters.ContactGroupFilter = false;
        }

        $btnTitle.html(text);

        $('#explorer-issues-contact-group-filter-lbl').removeClass('hide');
        $contactGroupFilter.removeClass('hide');

        setFilterState();
    }

    function initKeywordFilter() {
        var $btnTitle = $keywordFilter.find('.btn-title');
        var titles, text;

        titles = (keywordFilter || []).map(function (keyword) {
            return keyword.Title;
        });

        if (titles.length) {
            text = titles.length === keywords.length ?
                    i18next.t('explorer.issues.keywordFilter.all') :
                    titles.sort().join(', ');

            $btnTitle.parent().addClass('filter-selected');
            activeFilters.KeywordFilter = true;
        } else {
            text = i18next.t('explorer.issues.keywordFilter.noSelection');

            $btnTitle.parent().removeClass('filter-selected');
            activeFilters.KeywordFilter = false;
        }

        $btnTitle.html(text);
        setFilterState();
    }

    function initClassificationFilter() {
        var $btnTitle = $classificationFilter.find('.btn-title');
        var titles, text;

        titles = (classificationFilter || []).map(function (classification) {
            return classification.Title;
        });

        if (titles.length) {
            text = titles.length === classifications.length ?
                    i18next.t('explorer.issues.classificationFilter.all') :
                    titles.sort().join(', ');

            $btnTitle.parent().addClass('filter-selected');
            activeFilters.ClassificationFilter = true;
        } else {
            text = i18next.t('explorer.issues.classificationFilter.noSelection');

            $btnTitle.parent().removeClass('filter-selected');
            activeFilters.ClassificationFilter = false;
        }

        $btnTitle.html(text);
        setFilterState();
    }

    function initStatusFilter() {
        var $btnTitle = $statusFilter.find('.btn-title');
        var titles, text;

        titles = (statusFilter || []).map(function (status) {
            return status.Title;
        });

        if (titles.length) {
            text = titles.length === states.length ?
                i18next.t('explorer.issues.statusFilter.all') :
                titles.sort().join(', ');

            $btnTitle.parent().addClass('filter-selected');
            activeFilters.StatusFilter = true;
        } else {
            text = i18next.t('explorer.issues.statusFilter.noSelection');

            $btnTitle.parent().removeClass('filter-selected');
            activeFilters.StatusFilter = false;
        }

        $btnTitle.html(text);
        setFilterState();
    }

    function initPriorityFilter() {
        var $btnTitle = $priorityFilter.find('.btn-title');
        var titles, text;

        titles = (priorityFilter || []).map(function (priority) {
            return priority.Title;
        });

        if (titles.length) {
            text = titles.length === priorities.length ?
                i18next.t('explorer.issues.priorityFilter.all') :
                titles.sort().join(', ');

            $btnTitle.parent().addClass('filter-selected');
            activeFilters.PriorityFilter = true;
        } else {
            text = i18next.t('explorer.issues.priorityFilter.noSelection');

            $btnTitle.parent().removeClass('filter-selected');
            activeFilters.PriorityFilter = false;
        }

        $btnTitle.html(text);
        setFilterState();
    }

    function initFormFilter() {
        var $btnTitle = $formFilter.find('.btn-title');
        var titles, text;

        titles = (formFilter || []).map(function (form) {
            return form.Title;
        });

        if (titles.length) {
            text = titles.sort().join(', ');
            $btnTitle.parent().addClass('filter-selected');
            activeFilters.FormFilter = true;
        } else {
            text = i18next.t('explorer.issues.formFilter.noSelection');

            $btnTitle.parent().removeClass('filter-selected');
            activeFilters.FormFilter = false;
        }

        $btnTitle.html(text);
        setFilterState();
    }

    function initSchedulingFilter() {
        var $btnTitle = $schedulingFilter.find('.btn-title');
        var titles, text;

        titles = (schedulingFilter || []).map(function (scheduling) {
            return scheduling.Title;
        });

        if (titles.length) {
            text = titles.sort().join(', ');
            $btnTitle.parent().addClass('filter-selected');
            activeFilters.SchedulingFilter = true;
        } else {
            text = i18next.t('explorer.issues.schedulingFilter.noSelection');

            $btnTitle.parent().removeClass('filter-selected');
            activeFilters.SchedulingFilter = false;
        }

        $btnTitle.html(text);
        setFilterState();
    }

    function initProcessingStatusFilter() {
        var $btnTitle = $processingStatusFilter.find('.btn-title');
        var titles, text;

        titles = (processingStatusFilter || []).map(function (id) {
            return (Tools.getFirst(processingStatus, id, 'ID') || {}).Title;
        });

        if (titles.length) {
            text = titles.length === processingStatus.length ?
                i18next.t('explorer.issues.processingStatusFilter.all') :
                titles.sort().join(', ');

            $btnTitle.parent().addClass('filter-selected');
            activeFilters.ProcessingStatusFilter = true;
        } else {
            text = i18next.t('explorer.issues.processingStatusFilter.noSelection');

            $btnTitle.parent().removeClass('filter-selected');
            activeFilters.ProcessingStatusFilter = false;
        }

        $btnTitle.html(text);
        setFilterState();
    }

    function initHideNonResponsibleIssuesFilter() {
        var hasNoRight = Tools.contains(User.AllRights, 'SCMI', 'ID') ?
            false :
            !Tools.contains(User.AllRights, 'SAI', 'ID');

        $explorerIssues
            .find('#cb-explorer-issues-hide-non-responsible-issues')
            .toggleClass('hide', hasNoRight)
            .prop('checked', hideNonResponsibleIssues);

        $explorerIssues
            .find('#explorer-issues-hide-non-responsible-issues-lbl')
            .toggleClass('hide', hasNoRight);

        activeFilters.HideNonResponsibleIssues = hideNonResponsibleIssues;
        setFilterState();
    }

    function initSearch() {
        $search.val(searchValue);
        $search.attr('placeholder', i18next.t('explorer.search'));

        activeFilters.SearchValue = !!searchValue;
        setFilterState();
    }

    function initPeriodFilter() {
        var text;

        if (periodFilter && (periodFilter.Start || periodFilter.End)) {
            $periodFilter.addClass('filter-selected');
            activeFilters.PeriodFilter = true;

            text =
                (periodFilter.Start != null ? i18next.t('datePicker.from') + ' ' + periodFilter.Start.toLocaleString() : '') +
                ' ' +
                (periodFilter.End != null ? i18next.t('datePicker.to') + ' ' + periodFilter.End.toLocaleString() : '');
        } else {
            text = i18next.t('explorer.issues.formFilter.noSelection');

            $periodFilter.removeClass('filter-selected');
            activeFilters.PeriodFilter = false;
        }

        $periodFilter.html(text);
        setFilterState();
    }

    function unsetInteracts() {
        if (interacts.draggable) {
            interacts.draggable.unset();
            interacts.draggable = null;
        }

        if (interacts.droppable) {
            interacts.droppable.unset();
            interacts.droppable = null;
        }
    }

    function initIssueDragAndDrop() {
        content.views.removeDragAndDropToTreeHandlers();

        var $info;
        var selectedIssues;
        var movableIssues;
        var unmovableIssues;

        interacts.draggable = interact('#explorer-issues .issue.move-issue-enabled')
            .draggable({
                inertia: true,
                autoScroll: false,
                listeners: {
                    move: function (evt) {
                        var $target = $(evt.currentTarget);

                        selectedIssues = getSelectedIssues();

                        if (!selectedIssues.length) {
                            var oid = $target.data('oid');
                            var issue = issues[oid];

                            if (!issue) {
                                return;
                            }

                            selectedIssues = [issue];
                        }

                        $info = $('#issue-moving-info');

                        if (!$info.length) {
                            var html = createMoveIssuesInformation(selectedIssues);
                            $('body').append(html);
                            $info = $('#issue-moving-info');
                        }

                        var x = evt.clientX + 5;
                        var y = evt.clientY + 5;

                        $info
                            .css({ top: y, left: x })
                            .data({ 'x': x, 'y': y });
                    },
                    end: function () {
                        selectedIssues = null;
                        movableIssues = null;
                        unmovableIssues = null;

                        if (!($info instanceof $)) {
                            return;
                        }

                        $info.remove();
                    }
                }
            });

        interacts.droppable = interact('#center-tree li')
            .dropzone({
                accept: '.issue.move-issue-enabled',
                checker: Tools.InteractJs.IsBeingDraggedOverTreeNode,
                ondragenter: function (evt) {
                    var $dropZoneElement = $(evt.currentTarget);
                    var locationOID = $dropZoneElement.data('id');

                    if (!locationOID) {
                        return;
                    }

                    var location = DataManager.OrganizationUnitLoader.Data.DataMap[locationOID];

                    if (!location) {
                        return;
                    }

                    if (!selectedIssues.length) {
                        return;
                    }

                    movableIssues = [];
                    unmovableIssues = [];

                    selectedIssues.forEach(function (issue) {
                        var currentOU = DataManager.OrganizationUnitLoader.Data.DataMap[issue.AssignedElementOID];

                        if (!(currentOU && currentOU.Enabled)) {
                            unmovableIssues.push(issue);
                            return;
                        }

                        var editIssueRight = Tools.issue.getRightToEditIssueType(issue.Type);
                        var userHasRightToDropHere = (location.Enabled || false) &&
                            ressources.users.hasIssueEditRight(editIssueRight, issue, location.OID) &&
                            issue.AssignedElementOID !== locationOID;

                        if (userHasRightToDropHere &&
                            (+issue.AssignedRecorditemID > 0 || !!issue.AssignedRecorditemOID) &&
                            !ressources.users.hasRightAtLocation('CMC-ISSUE', issue.AssignedElementOID)) {
                            userHasRightToDropHere = false;
                        }

                        if (userHasRightToDropHere) {
                            movableIssues.push(issue);
                        } else {
                            unmovableIssues.push(issue);
                        }
                    });

                    $info
                        .toggleClass('drop-ok', unmovableIssues.length === 0)
                        .toggleClass('drop-partially-ok', movableIssues.length !== selectedIssues.length && movableIssues.length > 0)
                        .toggleClass('drop-not-ok', movableIssues.length === 0);

                    if (movableIssues.length > 0) {
                        $dropZoneElement
                            .addClass('jquery-tree-node-drop jquery-tree-node-drop-to');
                    }
                },
                ondragleave: function (evt) {
                    var $dropZoneElement = $(evt.currentTarget);

                    movableIssues = null;
                    unmovableIssues = null;

                    $info.removeClass('drop-ok drop-not-ok');
                    $dropZoneElement.removeClass('jquery-tree-node-drop jquery-tree-node-drop-to');
                },
                ondrop: function (evt) {
                    if ((selectedIssues || []).length === 0) {
                        return;
                    }

                    var $dropZoneElement = $(evt.currentTarget);

                    if (selectedIssues.length === 1) {
                        onMoveSingleIssue(selectedIssues[0], $dropZoneElement);
                    } else if (movableIssues.length > 0) {
                        onMoveMultipleIssues(movableIssues, unmovableIssues, $dropZoneElement);
                    }
                }
            });
    }

    function createMoveIssuesInformation(issues) {
        if (!(issues instanceof Array) || issues.length === 0) {
            return null;
        }

        var html = [
            '<div id="issue-moving-info" class="drag-drop-info">',
                '<div class="grid-row">',
                    '<div class="grid-col-3-12 icon-wrapper">',
                        '<img class="icon" src="./img/menu/vorgaenge.svg" />',
                    '</div>',
                    '<div class="grid-col-9-12">'
        ];

        if (issues.length === 1) {
            var issue = issues[0];
            var location = DataManager.OrganizationUnitLoader.Data.DataMap[issue.AssignedElementOID];
            var locationInfo = [];

            if (location) {
                locationInfo = [
                    '<p class="hierarchy-info">',
                        '<img class="icon-type" src="./img/menu/pruefpunkte.svg" />',
                        location.Title,
                    '</p>'
                ];
            }

            html.push(
                Tools.GetIssueAbbreviation(issue), issue.ID, '.', issue.Revision, '<br>',
                '<strong>', Tools.escapeHtml(issue.Title) || i18next.t('misc.untitled'), '</strong><br>',
                locationInfo.join('')
            );
        } else {
            html.push(
                '<strong>',
                    issues.length, ' ', i18next.t('misc.issue', { count: issues.length }),
                '</strong>'
            );
        }

        html.push(
                    '</div>',
                '</div>',
            '</div>'
        );

        return html.join('');
    }

    function onMoveSingleIssue(issue, $dropZoneElement) {
        if (!issue) {
            return;
        }

        var previousOrganizationUnit = DataManager.OrganizationUnitLoader.Data.DataMap[issue.AssignedElementOID];

        if (!(previousOrganizationUnit && previousOrganizationUnit.Enabled)) {
            Tools.Message.Show({
                title: i18next.t('explorer.issues.messages.cannotMoveIssueFromInactiveOU.title'),
                text: i18next.t('explorer.issues.messages.cannotMoveIssueFromInactiveOU.text'),
                ok: true
            });

            return;
        }

        var newOrganizationUnitOID = $dropZoneElement.data('id');

        if (issue.AssignedElementOID === newOrganizationUnitOID) {
            Tools.Message.Show({
                title: i18next.t('explorer.issues.messages.cannotMoveIssueToSameLocation.title'),
                text: i18next.t('explorer.issues.messages.cannotMoveIssueToSameLocation.text'),
                ok: true
            });

            return;
        }

        if (!newOrganizationUnitOID) {
            return;
        }

        var newOrganizationUnit = DataManager.OrganizationUnitLoader.Data.DataMap[newOrganizationUnitOID];

        if (!newOrganizationUnit) {
            return;
        }

        if (!newOrganizationUnit.Enabled) {
            Tools.Message.Show({
                title: i18next.t('explorer.issues.messages.cannotMoveIssueToDisabledLocation.title'),
                text: i18next.t('explorer.issues.messages.cannotMoveIssueToDisabledLocation.text'),
                ok: true
            });

            return;
        }

        if (!$dropZoneElement.hasClass('jquery-tree-node-drop')) {
            Tools.Message.Show({
                title: i18next.t('explorer.issues.messages.cannotMoveIssue.title'),
                text: i18next.t('explorer.issues.messages.cannotMoveIssue.text'),
                ok: true
            });

            return;
        }

        $dropZoneElement.removeClass('jquery-tree-node-drop jquery-tree-node-drop-to');

        issue.AssignedElementOID = newOrganizationUnitOID;

        issue = Tools.issue.prepareIssueForWebService(issue);

        Tools.Spinner.show();

        return Tools.http.put('issues/' + issue.OID, issue)
            .then(reset, function (xhr) {
                Tools.Spinner.hide();
                Tools.handleHttpError(Enums.HttpActionType.Write, xhr);
            });
    }

    function onMoveMultipleIssues(movableIssues, unmovableIssues, $dropZoneElement) {
        var newLocationOID = $dropZoneElement.data('id');
        var newLocation = DataManager.OrganizationUnitLoader.Data.DataMap[newLocationOID];

        if (!newLocation) {
            return;
        }

        function moveIssues() {
            var preparedIssues = [];

            Tools.Spinner.show();

            movableIssues.forEach(function (issue) {
                issue.AssignedElementOID = newLocationOID;

                preparedIssues.push(Tools.issue.prepareIssueForWebService(issue));
            });

            return Tools.http.post('issues', preparedIssues);
        }

        $dropZoneElement.removeClass('jquery-tree-node-drop jquery-tree-node-drop-to');

        if (unmovableIssues.length) {
            var messageBoxOptions = {
                title: i18next.t('explorer.issues.messages.moveMultipleIssuesInfo.title'),
                text: i18next.t('explorer.issues.messages.moveMultipleIssuesInfo.text', { locationTitle: newLocation.Title }),
                abort: true
            };

            if (movableIssues.length > 0) {
                messageBoxOptions.onYes = function () {
                    moveIssues()
                        .then(reset, function (xhr) {
                            Tools.Spinner.hide();
                            Tools.handleHttpError(Enums.HttpActionType.Write, xhr);
                        });
                };
            }

            unmovableIssues.sort(function (a, b) {
                return a.ID - b.ID;
            });

            messageBoxOptions.assignments = unmovableIssues.map(function (issue) {
                return [
                    Tools.GetIssueAbbreviation(issue),
                    issue.ID, ' - ', issue.Title || i18next.t('misc.noTitle')
                ].join('');
            });

            Tools.Message.Show(messageBoxOptions);
        } else {
            moveIssues()
                .then(reset, function (xhr) {
                    Tools.Spinner.hide();
                    Tools.handleHttpError(Enums.HttpActionType.Write, xhr);
                });
        }
    }

    function resize() {
        if ($body.width() >= 1200) {
            $explorerIssues.find('.table-filter-column').css('min-width', '250px');
        } else {
            $explorerIssues.find('.table-filter-column.last-row').css('min-width', '350px');
        }

        $tableWrapper.css('top', $explorerIssues.children('.table-container').outerHeight() + 10);

        $newIssueDropdown.removeClass('active');
        $tableHeader.width($tableBody.width());
    }

    function initTable() {
        var $sortingCol;

        $tableBody.empty();

        $sortingCol = $tableHeader.find('[data-sorting]');

        sorting = $sortingCol.data('columntitle');
        sortingorder = $sortingCol.data('sorting');

        $selectAll.prop('checked', !!selectAll);

        if (tableColumnSelection.length && tableColumnSelection.length !== tableColumns.length) {
            tableColumns.forEach(function (tableColumn) {
                var isChecked = Tools.contains(tableColumnSelection, tableColumn.OID, 'OID');
                var $col = $explorerIssues.find('col[data-columnid="{0}"], th[data-columnid="{0}"], td[data-columnid="{0}"]'.format(tableColumn.OID));

                if (isChecked) {
                    $col.removeClass('hide');
                } else {
                    $col.addClass('hide');
                }
            });
        } else {
            $explorerIssues.find('col, th, td').removeClass('hide');
        }
    }

    function reset() {
        issuesLoaded = 0;
        issues = {};
        selectAll = false;

        if (Tools.contains(User.Rights, 'TMFT', 'ID')) {
            if (Tools.any(User.Rights, ['TMFT-TASKS', 'TMFT-FORMS', 'TMFT-NOTES', 'TMFT-DISTURBANCES',
                        'TMFT-TASKS-A', 'TMFT-FORMS-A', 'TMFT-NOTES-A', 'TMFT-DISTURBANCES-A',
                        'TMFT-TASKS-SC', 'TMFT-FORMS-SC', 'TMFT-NOTES-SC', 'TMFT-DISTURBANCES-SC'], 'ID') ||
                    Client.Licenses &&
                    Client.Licenses.Investigations &&
                    Tools.any(User.Rights, ['TMFT-INVESTIGATIONS', 'TMFT-INVESTIGATIONS-A', 'TMFT-INVESTIGATIONS-SC'], 'ID')) {
                $newIssue.removeAttr('disabled');
            } else {
                $newIssue.attr('disabled', 'disabled');
            }
        } else {
            $newIssue.attr('disabled', 'disabled');
        }

        $printIssues.attr('disabled', 'disabled');
        $editIssues.attr('disabled', 'disabled');
        $selectAll.prop('checked', false);
        $tableBody.empty();

        update()
            .then(Explorer.UpdateTabCounters)
            .then(Explorer.ResizeTabMenu);
    }

    function unbindEvents() {
        $tableWrapper.off('scroll.infiniteScroll');
        $explorerIssues.off('click.toggleDropdown');
        $tableHeader.off('click.toggleSorting');
        $selectAll.off('change.toggleSelection');

        $resetFilters.off('click.resetFilters');
        $toggleControls.off('click.toggleControls');
        $newIssueDropdown.off('click.newIssue');
        $printIssues.off('click.printIssues');
        $editIssues.off('click.editIssues');
        $typeFilter.off('click.showTypeFilter');
        $tableColumnSelection.off('click.showTableColumnSelection');
        $creatorFilter.off('click.showCreatorFilter');
        $userFilter.off('click.showUserFilter');
        $teamFilter.off('click.showTeamFilter');
        $contactFilter.off('click.showContactFilter');
        $contactGroupFilter.off('click.showContactGroupFilter');
        $keywordFilter.off('click.showKeywordFilter');
        $classificationFilter.off('click.showClassificationFilter');
        $statusFilter.off('click.showStatusFilter');
        $priorityFilter.off('click.showPriorityFilter');
        $formFilter.off('click.showFormFilter');
        $schedulingFilter.off('click.showSchedulingFilter');

        $periodFilter.off('click.togglePeriodFilter');
        $explorerIssues.off('click.toggleProcessingStatusFilter');
        $processingStatusFilter.off('click.showProcessingStatusFilter');
        $explorerIssues.off('change.toggleHideNonResponsibleIssues');

        $search.off('input.search');

        $tableBody.off('change.toggleSelection');
        $tableBody.off('click.changeOrganisationUnit');
        $tableBody.off('click.showIssue');
        $tableBody.off('click.showPrintPreview');
        $tableBody.off('click.showImage');
        $tableBody.off('click.toggleSubscription');
        $tableBody.off('click.showComments');
        $tableBody.off('click.stopPropagation');
        $tableBody.off('mouseenter.showSubscriptionsAndLikes');
        $tableBody.off('mouseleave.hideSubscriptionsAndLikes');
    }

    function bindEvents() {
        $explorerIssues.on('click.toggleDropdown', '.dropdown-button', onToggleDropdown);
        $tableHeader.on('click.toggleSorting', '.sortable', onToggleSorting);
        $selectAll.on('change.toggleSelection', onToggleSelection);

        $resetFilters.on('click.resetFilters', onResetFilters);
        $toggleControls.on('click.toggleControls', onToggleControls);
        $newIssueDropdown.on('click.newIssue', '.action-window-content-element', onNewIssue);
        $printIssues.on('click.printIssues', onPrintIssues);
        $editIssues.on('click.editIssues', onEditIssues);
        $typeFilter.on('click.showTypeFilter', onShowTypeFilter);
        $tableColumnSelection.on('click.showTableColumnSelection', onShowTableColumnSelection);
        $creatorFilter.on('click.showCreatorFilter', onShowCreatorFilter);
        $userFilter.on('click.showUserFilter', onShowUserFilter);
        $teamFilter.on('click.showTeamFilter', onShowTeamFilter);
        $contactFilter.on('click.showContactFilter', onShowContactFilter);
        $contactGroupFilter.on('click.showContactGroupFilter', onShowContactGroupFilter);
        $keywordFilter.on('click.showKeywordFilter', onShowKeywordFilter);
        $classificationFilter.on('click.showClassificationFilter', onShowClassificationFilter);
        $statusFilter.on('click.showStatusFilter', onShowStatusFilter);
        $priorityFilter.on('click.showPriorityFilter', onShowPriorityFilter);
        $formFilter.on('click.showFormFilter', onShowFormFilter);
        $schedulingFilter.on('click.showSchedulingFilter', onShowSchedulingFilter);
        $periodFilter.on('click.togglePeriodFilter', onTogglePeriodFilter);
        $explorerIssues.on('click.toggleProcessingStatusFilter', '.explorer-issues-processing-status-filter', onToggleProcessingStatusFilter);
        $explorerIssues.on('change.toggleHideNonResponsibleIssues', '#cb-explorer-issues-hide-non-responsible-issues', onToggleHideNonResponsibleIssues);
        $search.on('input.search', onSearch);
        $processingStatusFilter.on('click.showProcessingStatusFilter', onShowProcessingStatusFilter);

        $tableBody.on('click.toggleSelection', 'input:checkbox', onToggleSelection);
        $tableBody.on('click.changeOrganisationUnit', '.issue a', onSelectOrganisationUnit);
        $tableBody.on('click.showIssue', '.issue', onShowIssue);
        $tableBody.on('click.showPrintPreview', '.print', onShowPrintPreview);
        $tableBody.on('click.showImage', '.image', onShowImage);
        $tableBody.on('click.toggleSubscription', '.abos', onToggleSubscription);
        $tableBody.on('click.showComments', '.commentlink', onShowComments);
        $tableBody.on('mouseenter.showSubscriptionsAndLikes', '.abos .details', onShowSubscriptionsAndLikes);
        $tableBody.on('mouseleave.hideSubscriptionsAndLikes', '.abos .details', onHideSubscriptionsAndLikes);
    }

    function onShowProcessingStatusFilter() {
        var options = {
            title: i18next.t('explorer.issues.processingStatusFilter.title'),
            onApply: onSelectProcessingStatusFilter,
            checkedEntities: processingStatusFilter,
            treeOptions: {
                schema: { text: 'Title', id: 'ID'},
                opened: true,
                checkbox: { fullrow: true }
            }
        };

        TreePicker.Show(processingStatus, options);
    }

    function onSelectProcessingStatusFilter(processingStatusses) {
        processingStatusFilter = (processingStatusses || []).map(function (id) {
            return id;
        });

        initProcessingStatusFilter();
        resize();
        reset();
    }

    function onToggleDropdown(event) {
        var isButtonDisabled = $newIssue.attr('disabled') === 'disabled';
        var isDropdownDisabled = $newIssueDropdown.attr('disabled')  === 'disabled';
        var isActive = !$newIssueDropdown.hasClass('active');

        if (isButtonDisabled) {
            return;
        }

        $newIssueDropdown.removeClass('active');
        DatePicker.Close();

        if (!isDropdownDisabled) {
            if (isActive) {
                $newIssueDropdown.addClass('active');
            }

            $body.off('click.hideDropdown', onHideDropdown);
            $body.one('click.hideDropdown', onHideDropdown);
        }

        event.stopPropagation();
        return false;
    }

    function onHideDropdown() {
        $newIssueDropdown.removeClass('active');
    }

    function onResetFilters() {
        resetFilters();
        initFilters();
        initTable();

        update()
            .then(Explorer.UpdateTabCounters)
            .then(Explorer.ResizeTabMenu);
    }

    function onToggleControls() {
        var $tableFilterSelection = $explorerIssues.find('.table-filter-selection');

        if ($tableFilterSelection == null) {
            return;
        }

        var $controls = $tableFilterSelection.find('.toggle-table-controls');
        var $firstTableRow = $tableFilterSelection.find('.table-filter-column-first-row');
        var $filterColumns = $tableFilterSelection.find('.table-filter-column:not(.last-row)');
        var $lastRow = $tableFilterSelection.find('.table-filter-column:last-child');
        var $lastRowFilters = $lastRow.find('.table-filter:not(:first-child)');

        if ($tableFilterSelection.hasClass('reduced-filters')) {
            toggleControls = true;
            $tableFilterSelection.removeClass('reduced-filters');
            $tableFilterSelection.addClass('grid-row');
            $firstTableRow.removeClass('reduced-row');
            $filterColumns.removeClass('hidden');
            $lastRowFilters.removeClass('hidden');
            $firstTableRow.addClass('flexColumn');
            $filterColumns.find('.table-button').css('margin-bottom', 7);
            $firstTableRow.find('.table-button').css('margin-bottom', 7);
            $lastRow.addClass('flexColumn');
            $lastRow.removeClass('right');

            $toggleControls.attr('title', i18next.t('explorer.toolbar.hideFilters'));
            $controls.addClass('collapsed');
            $newIssueDropdown.css('top', 32);
        } else {
            toggleControls = false;
            $tableFilterSelection.addClass('reduced-filters');
            $firstTableRow.addClass('reduced-row');
            $filterColumns.addClass('hidden');
            $lastRowFilters.addClass('hidden');
            $firstTableRow.removeClass('flexColumn');
            $lastRow.removeClass('flexColumn');
            $lastRow.addClass('right');
            $firstTableRow.find('.table-button').css('margin-bottom', 0);

            $toggleControls.attr('title', i18next.t('explorer.toolbar.showFilters'));
            $controls.removeClass('collapsed');
            $newIssueDropdown.css('top', 34);
        }

        resize();
    }

    function onNewIssue(event) {
        var $this = $(this);
        var selection = $this.data('selection');

        if ($this.attr('disabled') === 'disabled') {
            return;
        }

        switch (selection) {
            case Enums.issueType.Form:
                createFormIssue();

                $newIssueDropdown.removeClass('active');
                event.stopPropagation();
                break;
            case Enums.issueType.Note:
                createNoteIssue();
                break;
            case Enums.issueType.Disturbance:
                createDisturbanceIssue();
                break;
            case Enums.issueType.Investigation:
                createInvestigationIssue();

                $newIssueDropdown.removeClass('active');
                event.stopPropagation();
                break;
            case 10: // Dummy-Type für Auswahl
                createIssueTemplate();
                break;
            default:
                createTaskIssue();
                break;
        }
    }

    function createTaskIssue() {
        if (!states.length) {
            Tools.Message.Show({
                title: i18next.t('explorer.issues.messages.noStatusAvailable.title'),
                text: i18next.t('explorer.issues.messages.noStatusAvailable.text'),
                ok: true
            });

            return;
        }

        if (!priorities.length) {
            Tools.Message.Show({
                title: i18next.t('explorer.issues.messages.noPriorityAvailable.title'),
                text: i18next.t('explorer.issues.messages.noPriorityAvailable.text'),
                ok: true
            });

            return;
        }

        IssueViewer.Create(Enums.issueType.Incident, reset);
    }

    function createFormIssue() {
        if (!states.length) {
            Tools.Message.Show({
                title: i18next.t('explorer.issues.messages.noStatusAvailable.title'),
                text: i18next.t('explorer.issues.messages.noStatusAvailable.text'),
                ok: true
            });

            return;
        }

        if (!priorities.length) {
            Tools.Message.Show({
                title: i18next.t('explorer.issues.messages.noPriorityAvailable.title'),
                text: i18next.t('explorer.issues.messages.noPriorityAvailable.text'),
                ok: true
            });

            return;
        }

        Tools.Spinner.show();
        Tools.DataManager.FormLoader
            .GetAll()
            .then(function (data) {
                if (!data) {
                    return;
                }

                if (!data.Data.length) {
                    Tools.Message.Show({
                        title: i18next.t('explorer.issues.messages.noFormAvailable.title'),
                        text: i18next.t('explorer.issues.messages.noFormAvailable.text'),
                        ok: true
                    });

                    return;
                }

                Tools.WindowWrappers.ShowFormSelectionForLocation({
                    selectMultiple: false,
                    disableEmptyForms: true,
                    disableSurveys: true,
                    windowCaption: i18next.t('explorer.issues.formFilter.titleSingular'),
                    buttons: {
                        showAllForms: i18next.t('explorer.issues.formFilter.showAllForms'),
                        showAssignedFormsOnly: i18next.t('explorer.issues.formFilter.showAssignedFormsOnly')
                    },
                    onApplySelection: function (formIdentifier) {
                        showFormIssueViewer(data.Map[formIdentifier]);
                    }
                });
            });
    }

    function createNoteIssue() {
        IssueViewer.Create(Enums.issueType.Note, reset);
    }

    function createDisturbanceIssue() {
        IssueViewer.Create(Enums.issueType.Disturbance, reset);
    }

    function createIssueTemplate() {
        IssueViewer.Create(Enums.issueType.Incident, reset, { IsTemplate: true });
    }

    function createInvestigationIssue() {
        if (!states.length) {
            Tools.Message.Show({
                title: i18next.t('explorer.issues.messages.noStatusAvailable.title'),
                text: i18next.t('explorer.issues.messages.noStatusAvailable.text'),
                ok: true
            });

            return;
        }

        if (!priorities.length) {
            Tools.Message.Show({
                title: i18next.t('explorer.issues.messages.noPriorityAvailable.title'),
                text: i18next.t('explorer.issues.messages.noPriorityAvailable.text'),
                ok: true
            });

            return;
        }

        Tools.Spinner.show();
        Tools.DataManager.FormLoader
            .GetAll()
            .then(function (data) {
                Tools.Spinner.hide();

                if (!data) {
                    return;
                }

                var investigationForms = (data.Data || []).filter(function (form) {
                    return form.IsInvestigation && form.HasCheckpoints;
                });

                if (!investigationForms.length) {
                    Tools.Message.Show({
                        title: i18next.t('explorer.issues.messages.noFormAvailable.title'),
                        text: i18next.t('explorer.issues.messages.noFormAvailable.text'),
                        ok: true
                    });

                    return;
                }

                if (investigationForms.length > 1) {
                    var formsInHierarchy = [];

                    if ((investigationForms || []).length) {
                        for (var lCnt = 0, lLen = investigationForms.length; lCnt < lLen; lCnt++) {
                            var form = investigationForms[lCnt];

                            if (form) {
                                do {
                                    if (!Tools.contains(formsInHierarchy, form.OID) && form.Type === Enums.elementType.Form) {
                                        formsInHierarchy.push(form.OID);
                                    }
                                } while ((form = form.Parent));
                            }
                        }
                    }

                    var options = {
                        title: i18next.t('explorer.issues.add.selectInvestigation'),
                        selectMultiple: false,
                        treeOptions: {
                            opened: true,
                            selectable: function (entity) {
                                return Tools.contains(investigationForms, entity.OID, 'OID');
                            },
                            schema: function (entity) {
                                return {
                                    id: entity.OID,
                                    text: entity.Title,
                                    color: entity.Color,
                                    children: entity.Children
                                };
                            },
                            filter: function (entity) {
                                return entity.Type === Enums.elementType.Form && Tools.contains(formsInHierarchy, entity.OID);
                            }
                        },
                        onSelect: function (formIdentifier) {
                            showFormIssueViewer(data.Map[formIdentifier]);
                        }
                    };

                    TreePicker.Show(data.Root, options);
                } else {
                    showFormIssueViewer(data.Map[investigationForms[0].OID]);
                }
            });
    }

    function showFormIssueViewer(form) {
        Tools.Spinner.hide();

        if (!form) {
            return;
        }

        var issueType = form.IsInspection ?
            Enums.issueType.Inspection :
            form.IsInvestigation ? Enums.issueType.Investigation :
                Enums.issueType.Form;

        IssueViewer.Create(issueType, reset, { FormOID: form.OID });
    }

    function onPrintIssues() {
        var selectedIssues = getSelectedIssues();

        if (selectedIssues.length) {
            PrintPreview.Show({
                Issues: selectedIssues,
                Type: 'Issue'
            });
        }
    }

    function onEditIssues() {
        var selectedIssues = getSelectedIssues();

        if (selectedIssues.length) {
            IssuesEditor.Show(selectedIssues, function(modifications) {
                if (!modifications) {
                    return;
                }

                reset();
            });
        }
    }

    function getSelectedIssues() {
        var $selectedIssues = $tableBody.find('input:checked');

        return $.map($selectedIssues, function (cb) {
            var $tr = $(cb).closest('.issue');
            var issueOID = $tr.data('oid');

            return issues[issueOID];
        });
    }

    function onShowTypeFilter() {
        var options = {
            title: i18next.t('explorer.issues.typeFilter.title'),
            onApply: onSelectTypeFilter,
            checkedEntities: typeFilter,
            treeOptions: {
                schema: { id: 'OID', text: 'Title' },
                opened: true,
                checkbox: { fullrow: true }
            }
        };

        TreePicker.Show(types, options);
    }

    function onSelectTypeFilter(selectedTypes) {
        typeFilter = (selectedTypes || []).map(function (oid) {
            return Tools.getFirst(types, oid, 'OID');
        });

        initTypeFilter();
        resize();
        reset();
    }

    function onShowTableColumnSelection() {
        var options = {
            title: i18next.t('explorer.issues.tableColumns.selectColumns'),
            onApply: onSelectTableColumns,
            checkedEntities: tableColumnSelection,
            treeOptions: {
                schema: { id: 'OID', text: 'Title' },
                opened: true,
                checkbox: { fullrow: true }
            }
        };

        TreePicker.Show(tableColumns, options);
    }

    function onSelectTableColumns(selectedTableColumns) {
        tableColumnSelection = $.map(selectedTableColumns || [], function (oid) {
            return Tools.getFirst(tableColumns, oid, 'OID');
        });

        if (tableColumnSelection.length && tableColumnSelection.length !== tableColumns.length) {
            tableColumns.forEach(function (tableColumn) {
                var isChecked = Tools.contains(tableColumnSelection, tableColumn.OID, 'OID');
                var $col = $explorerIssues.find('col[data-columnid="{0}"], th[data-columnid="{0}"], td[data-columnid="{0}"]'.format(tableColumn.OID));

                if (isChecked) {
                    $col.removeClass('hide');
                } else {
                    $col.addClass('hide');
                }
            });
        } else {
            $explorerIssues.find('col, th, td').removeClass('hide');
        }

        var allColumnIDs = tableColumns.map(function(entity) {
            return entity.OID
        }) || [];

        var selectedColumns = (selectedTableColumns || []).join(',') || allColumnIDs.join(',');

        Tools.UpdateOfficeUserSettings(Explorer.GetColumnsSettingName(), selectedColumns)
            .then(function () {
                initTableColumnSelection(true);
                resize();
            });
    }

    function onShowCreatorFilter() {
        var options = {
            title: i18next.t('explorer.issues.creatorFilter.title'),
            onApply: onSelectCreatorFilter,
            checkedEntities: creatorFilter,
            treeOptions: {
                schema: { id: 'OID', text: 'Fullname' },
                opened: true,
                checkbox: { fullrow: true },
                createContentHtml: function() {
                    var html;

                    html = this.createCheckboxHtml();
                    html += this.createTextHtml();

                    if (this.entity && this.entity.IsLocked) {
                        html += '<img src="./img/locked.svg" class="user-locked">';
                    }

                    return html;
                }
            }
        };

        TreePicker.Show(users, options);
    }

    function onSelectCreatorFilter(selectedUsers) {
        creatorFilter = (selectedUsers || []).map(function (oid) {
            return Tools.getFirst(users, oid, 'OID');
        });

        initCreatorFilter();
        resize();
        reset();
    }

    function onShowUserFilter() {
        var options = {
            title: i18next.t('explorer.issues.userFilter.title'),
            onApply: onSelectUserFilter,
            checkedEntities: userFilter,
            treeOptions: {
                schema: { id: 'OID', text: 'Fullname' },
                opened: true,
                checkbox: { fullrow: true },
                createContentHtml: function() {
                    var html;

                    html = this.createCheckboxHtml();
                    html += this.createTextHtml();

                    if (this.entity && this.entity.IsLocked) {
                        html += '<img src="./img/locked.svg" class="user-locked">';
                    }

                    return html;
                }
            }
        };

        TreePicker.Show(users, options);
    }

    function onSelectUserFilter(selectedUsers) {
        userFilter = (selectedUsers || []).map(function (oid) {
            return Tools.getFirst(users, oid, 'OID');
        });

        initUserFilter();
        resize();
        reset();
    }

    function onShowTeamFilter() {
        var options = {
            title: i18next.t('explorer.issues.teamFilter.title'),
            onApply: onSelectTeamFilter,
            checkedEntities: teamFilter,
            treeOptions: {
                schema: { id: 'OID', children: 'Children', text: 'Title', color: 'Color' },
                opened: true,
                checkbox: {
                    fullrow: true,
                    disabled: userCanAssignAllTeamsAndUsers ? null : function (node) {
                        if (!selectableTeams || !node || !node.entity) {
                            return false;
                        }

                        var team = node.entity;

                        return selectableTeams.indexOf(team.OID) === -1;
                    }
                },
                filter: userCanAssignAllTeamsAndUsers ? null : function (team) {
                    if (!visibleTeams || !team) {
                        return false;
                    }

                    return visibleTeams.indexOf(team.OID) > -1;
                }
            }
        };

        TreePicker.Show(RootTeam, options);
    }

    function onSelectTeamFilter(selectedTeams) {
        teamFilter = (selectedTeams || []).map(function (oid) {
            return Tools.getFirst(teams, oid, 'OID');
        });

        initTeamFilter();
        resize();
        reset();
    }

    function onShowContactFilter() {
        var options = {
            title: i18next.t('explorer.issues.contactFilter.title'),
            onApply: onSelectContactFilter,
            checkedEntities: contactFilter,
            treeOptions: {
                schema: { id: 'OID', text: 'Fullname' },
                opened: true,
                checkbox: { fullrow: true }
            }
        };

        TreePicker.Show(contacts, options);
    }

    function onSelectContactFilter(selectedContacts) {
        contactFilter = (selectedContacts || []).map(function (oid) {
            return Tools.getFirst(contacts, oid, 'OID');
        });

        initContactFilter();
        resize();
        reset();
    }

    function onShowContactGroupFilter() {
        var options = {
            title: i18next.t('explorer.issues.contactGroupFilter.title'),
            onApply: onSelectContactGroupFilter,
            checkedEntities: contactGroupFilter,
            treeOptions: {
                schema: { id: 'OID', text: 'Title' },
                opened: true,
                checkbox: { fullrow: true }
            }
        };

        TreePicker.Show(contactGroups, options);
    }

    function onSelectContactGroupFilter(selectedContactGroups) {
        contactGroupFilter = (selectedContactGroups || []).map(function (oid) {
            return Tools.getFirst(contactGroups, oid, 'OID');
        });

        initContactGroupFilter();
        resize();
        reset();
    }

    function onShowKeywordFilter() {
        var options = {
            title: i18next.t('explorer.issues.keywordFilter.title'),
            onApply: onSelectKeywordFilter,
            checkedEntities: keywordFilter,
            treeOptions: {
                schema: { id: 'OID', children: 'Children', text: 'Title' },
                opened: true,
                checkbox: { fullrow: true }
            }
        };

        TreePicker.Show(Keywords, options);
    }

    function onSelectKeywordFilter(selectedKeywords) {
        keywordFilter = (selectedKeywords || []).map(function (oid) {
            return Tools.getFirst(keywords, oid, 'OID');
        });

        initKeywordFilter();
        resize();
        reset();
    }

    function onShowClassificationFilter() {
        var options = {
            title: i18next.t('explorer.issues.classificationFilter.title'),
            onApply: onSelectClassificationFilter,
            checkedEntities: classificationFilter,
            treeOptions: {
                schema: { id: 'OID', children: 'Children', text: 'Title' },
                opened: true,
                checkbox: { fullrow: true }
            }
        };

        TreePicker.Show(Classifications, options);
    }

    function onSelectClassificationFilter(selectedClassifications) {
        classificationFilter = (selectedClassifications || []).map(function (oid) {
            return Tools.getFirst(classifications, oid, 'OID');
        });

        initClassificationFilter();
        resize();
        reset();
    }

    function onShowStatusFilter() {
        Tools.WindowWrappers.ShowStatusSelection({
            selectedStatusses: statusFilter,
            selectMultiple: true,
            windowCaption: i18next.t('explorer.issues.statusFilter.title'),
            showCheckbox: true,
            onApplySelection: onSelectStatusFilter,
            statusState: Enums.StatusState.Open,
            source: Properties,
            sourceTree: IssueStates
        });
    }

    function onSelectStatusFilter(selectedStatus) {
        statusFilter = (selectedStatus || []).map(function (oid) {
            return Tools.getFirst(states, oid, 'OID');
        });

        initStatusFilter();
        resize();
        reset();
    }

    function onShowPriorityFilter() {
        var options = {
            title: i18next.t('explorer.issues.priorityFilter.title'),
            onApply: onSelectPriorityFilter,
            checkedEntities: priorityFilter,
            treeOptions: {
                schema: { id: 'OID', children: 'Children', text: 'Title', color: 'Color' },
                opened: true,
                checkbox: { fullrow: true }
            }
        };

        TreePicker.Show(Priorities, options);
    }

    function onSelectPriorityFilter(selectedPriorities) {
        priorityFilter = (selectedPriorities || []).map(function (oid) {
            return Tools.getFirst(priorities, oid, 'OID');
        });

        initPriorityFilter();
        resize();
        reset();
    }

    function onShowFormFilter() {
        Tools.WindowWrappers.ShowFormSelectionForLocation({
            selectedForms: formFilter,
            selectMultiple: true,
            windowCaption: i18next.t('explorer.issues.formFilter.title'),
            buttons: {
                showAllForms: i18next.t('explorer.issues.formFilter.showAllForms'),
                showAssignedFormsOnly: i18next.t('explorer.issues.formFilter.showAssignedFormsOnly')
            },
            showCheckbox: true,
            onApplySelection: onSelectFormFilter
        });
    }

    function onSelectFormFilter(selectedForms) {
        formFilter = (selectedForms || []).map(function (oid) {
            return DataManager.FormLoader.Data.DataMap[oid];
        });

        initFormFilter();
        resize();
        reset();
    }

    function onShowSchedulingFilter() {
        Tools.WindowWrappers.ShowSchedulingSelectionForLocation({
            selectedSchedulings: schedulingFilter,
            selectMultiple: true,
            windowCaption: i18next.t('explorer.issues.schedulingFilter.title'),
            buttons: {
                showAllSchedulings: i18next.t('explorer.issues.schedulingFilter.showAllSchedulings'),
                showAssignedSchedulingsOnly: i18next.t('explorer.issues.schedulingFilter.showAssignedSchedulingsOnly')
            },
            showCheckbox: true,
            onApplySelection: onSelectSchedulingFilter
        });
    }

    function onSelectSchedulingFilter(selectedSchedulings) {
        schedulingFilter = (selectedSchedulings || []).map(function (oid) {
            return DataManager.SchedulingLoader.Data.DataMap[oid];
        });

        initSchedulingFilter();
        resize();
        reset();
    }

    function onTogglePeriodFilter(event) {
        var $this = $(this);

        if ($this.hasClass('active')) {
            DatePicker.Close();
            return;
        }

        $this.addClass('active');
        $newIssueDropdown.removeClass('active');

        DatePicker.Show(this, {
            mode: 2,
            withTime: true,
            noDateAllowed: true,
            offsetY: 28,
            closeElement: 'body',
            deactivatable: true,
            titleElement: $this,
            dateTypes: [ Enums.DatePickerTypes.Creation, Enums.DatePickerTypes.Modification, Enums.DatePickerTypes.Deadline ],
            selectedType: Tools.contains([ Enums.DatePickerTypes.Creation, Enums.DatePickerTypes.Modification, Enums.DatePickerTypes.Deadline ], periodFilter.CurrentType) ?
                    periodFilter.CurrentType :
                    Enums.DatePickerTypes.Modification,
            beforeShow: function () {
                DatePicker.SetDate(periodFilter.Start, periodFilter.End);
            },
            end: function (filter) {
                periodFilter = filter;

                if (periodFilter && (periodFilter.Start || periodFilter.End)) {
                    if (periodFilter.Start) {
                        periodFilter.Start = new Date(periodFilter.Start.setSeconds(0));
                    }

                    if (periodFilter.End) {
                        periodFilter.End = new Date(periodFilter.End.setSeconds(59));
                    }

                    $periodFilter.addClass('filter-selected');
                    activeFilters.PeriodFilter = true;
                } else {
                    $periodFilter.removeClass('filter-selected');
                    activeFilters.PeriodFilter = false;
                }

                DatePicker.Close();
                setFilterState();
                resize();
                reset();
            },
            onDatePickerClose: function () {
                $this.removeClass('active');
            }
        });

        event.stopPropagation();
        return false;
    }

    function onToggleProcessingStatusFilter() {
        var $this = $(this);

        if ($this.hasClass('selected')) {
            processingStatusFilter = Tools.remove(processingStatusFilter, $this.data('status'));
        } else {
            processingStatusFilter = Tools.addUnique(processingStatusFilter, $this.data('status'));
        }

        initProcessingStatusFilter();
        resize();
        reset();
    }

    function onSearch() {
        clearTimeout(searchTimeout);

        searchTimeout = setTimeout(function () {
            searchValue = $search.val().trim();
            activeFilters.SearchValue = !!searchValue;
            setFilterState();
            resize();
            reset();
        }, 500);
    }

    function onToggleHideNonResponsibleIssues() {
        hideNonResponsibleIssues = !hideNonResponsibleIssues;
        activeFilters.HideNonResponsibleIssues = hideNonResponsibleIssues;
        setFilterState();
        reset();
    }

    function onScroll() {
        var wrapperHeight = $tableWrapper.innerHeight();
        var tableHeight = $tableBody.outerHeight(false);
        var scrollPosition = $tableWrapper.scrollTop();

        if (tableHeight - wrapperHeight - scrollPosition <= 300) {
            $tableWrapper.off('scroll.infiniteScroll');

            update();
        }
    }

    function onToggleSorting() {
        var $this = $(this);

        sorting = $this.attr('data-columntitle');
        sortingorder = $this.attr('data-sorting');

        sortingorder = sortingorder === 'desc' ? 'asc' : 'desc';
        $this.siblings().removeAttr('data-sorting');
        $this.attr('data-sorting', sortingorder);

        reset();
    }

    function onToggleSelection(event) {
        var $this = $(this);

        if ($this.hasClass('select-all')) {
            selectAll = $selectAll.prop('checked');

            $tableBody.find('input:checkbox').prop('checked', !!selectAll);
        } else {
            selectAll = false;
            $selectAll.prop('checked', false);
        }

        if ($tableBody.find('input:checked').length) {
            $printIssues.removeAttr('disabled');

            if (User && User.LicenseType !== Enums.LicenseType.ViewRight) {
                $editIssues.removeAttr('disabled');
            }
        } else {
            $printIssues.attr('disabled', 'disabled');
            $editIssues.attr('disabled', 'disabled');
        }

        event.stopPropagation();
    }

    function onSelectOrganisationUnit(event) {
        event.stopPropagation();
    }

    function onShowIssue(event) {
        var $this = $(this);
        var issueID = $this.data('id');

        IssueViewer.Show(issueID, reset, onCommentsChanged);

        event.stopPropagation();
        return false;
    }

    function onCommentsChanged(changeInfo) {
        if (!changeInfo) {
            return;
        }

        var issue = issues[changeInfo.IssueOID];

        if (!issue) {
            return;
        }

        issue.Comments = Tools.issue.prepareCommentsForWebApp(changeInfo.Comments);

        var $issue = $tableWrapper.find('tr[data-oid="' + issue.OID + '"]');

        if (!$issue.length) {
            return;
        }

        $issue.find('.commentdetails').remove();

        var commentsMarkup = renderComments(issue);
        $issue.find('.location').after(commentsMarkup);
    }

    function onShowImage(event) {
        var $this = $(this);
        var filename = $this.data('filename');
        var issueOID = $this.parent().data('oid');
        var issue = issues[issueOID];

        if (issue) {
            var files = issue.Files || [];

            if (!files.length && issue.AdditionalData && issue.AdditionalData.CheckpointPreviewImages) {
                var previewImagesFromCheckpoints = issue.AdditionalData.CheckpointPreviewImages;
                // Indices für Vorschaubilder aufsteigend sortieren
                var indices = Object.keys(previewImagesFromCheckpoints).sort(function(a, b) { return +a - +b; });

                for (var i = 0; i < indices.length; i++) {
                    var key = indices[i]

                    const previewImage = previewImagesFromCheckpoints[key];
                    if (previewImage && previewImage.Filename === filename) {
                        files.push(previewImage);
                        break;
                    }
                }
            }

            ImageViewer.Init({
                filelist: files,
                indexFilename: filename,
                sTitle: issue.Title || '',
                sUserOID: issue.Editor ? issue.Editor.OID : null,
                sTimestamp: issue.ModificationTimestamp
            });
        }

        event.stopPropagation();
        return false;
    }

    function onShowPrintPreview(event) {
        event.stopPropagation();

        const issueOID = $(this).closest('.issue').data('oid');
        const issue = issues[issueOID];

        if (!Client.Licenses.EnablePdfDesigner) {
            printDefaultReport(issue);
            return;
        }

        const printTemplate = Tools.GetFirstActivePdfTemplate(PdfReportTemplates, issue.AssignedFormOID);

        if (!printTemplate) {
            printDefaultReport(issue);
            return;
        }

        Tools.Message.Show({
            title: i18next.t('explorer.issues.printTemplateSelection.title'),
            text: i18next.t('explorer.issues.printTemplateSelection.text', { issueId: issue.ID }),
            yes: true,
            onYes: () => printCustomPdfReport(issue.ID, printTemplate.OID),
            no: true,
            onNo: () => printDefaultReport(issue),
            abort: true,
            captions: [
                {
                    button: 'yes',
                    text: i18next.t('explorer.issues.printTemplateSelection.printCustomReport')
                },
                {
                    button: 'no',
                    text: i18next.t('explorer.issues.printTemplateSelection.printDefaultReport')
                }
            ]
        });

        return false;
    }

    function printDefaultReport(issue) {
        if (!issue) {
            return;
        }

        PrintPreview.Show({
            Issue: issue,
            Type: 'Issue'
        });
    }

    function printCustomPdfReport(issueID, templateOID) {
        if (!issueID || !templateOID) {
            return;
        }

        PrintPreview.Show({
            Element: CurrentEntity,
            Type: 'PdfCreator',
            IssueID: issueID,
            PdfTemplateIdentifier: templateOID
        });
    }

    function onToggleSubscription(event) {
        var $this = $(this);
        var $tr = $this.parents('tr');
        var issueOID = $tr.data('oid');
        var subscribe = !$this.data('isabo');
        var issue;

        $('#gfx-tooltip').addClass('hide');

        if ((issue = issues[issueOID])) {
            Tools.Spinner.show();

            var method = 'issues/{0}/{1}'.format(issue.ID, subscribe ? 'subscribenotifications' : 'unsubscribenotifications');

            Tools.http.post(method, null, function () {
                    issue.Recipients = subscribe ?
                        Tools.addUnique(issue.Recipients, User.OID) :
                        Tools.remove(issue.Recipients, User.OID);

                    $this.replaceWith(renderSubscription(issue.Recipients));

                    Tools.Spinner.hide();
                }, Tools.Spinner.hide);
        }

        event.stopPropagation();
        return false;
    }

    function onShowSubscriptionsAndLikes(event) {
        var $tooltip = $('#gfx-tooltip');
        var $this = $(this);
        var offset = $this.offset();
        var abos, subscribers;

        abos = ($this.data('abos') || '').split('|');

        subscribers = $.map(abos, function (oid) {
            var subscriber = Users[oid] || Contacts[oid];

            if (subscriber) {
                return subscriber.Fullname;
            }
        });

        subscribers.sort(Tools.SortStringArray);

        if (subscribers.length > 10) {
            subscribers = subscribers.slice(0, 10);
            subscribers.push('+ {0} {1}'.format(
                abos.length - 10,
                i18next.t('misc.more')));
        }

        $tooltip.html(subscribers.join('<br />'));
        $tooltip.removeClass('hide');
        $tooltip.attr('arrow', 1);
        $tooltip.css({
            left: offset.left - $tooltip.outerWidth(false) / 2 + 8,
            top: offset.top - $tooltip.outerHeight(false) - 8
        });
    }

    function onHideSubscriptionsAndLikes() {
        $('#gfx-tooltip').addClass('hide');
    }

    function onShowComments(event) {
        var $this = $(this);
        var issueID = $this.parents('tr').data('id');

        IssueViewer.Show(issueID, reset, onCommentsChanged, { Panel: 'comments' });

        event.stopPropagation();
        return false;
    }

    function update() {
        if (updateRequest) {
            updateRequest.abort();
            updateRequest = null;
        }

        $tableWrapper.off('scroll.infiniteScroll');

        Tools.Spinner.show();

        var currentMenuItem = Explorer.GetCurrentTab();
        var params = createParams(currentMenuItem);

        if (!params) {
            return $.Deferred().reject().promise();
        }

        Explorer.SetTabCounterLoading(currentMenuItem.OID);

        return (updateRequest = load(params))
            .then(prepareData, function (xhr) {
                Explorer.SetTabCounter(currentMenuItem.OID, 0);

                if (xhr) {
                    Tools.Spinner.hide();
                }

                return Tools.handleHttpError(Enums.HttpActionType.Read, xhr);
            })
            .then(appendIssues)
            .then(function (data) {
                updateRequest = null;

                if (!data) {
                    Explorer.SetTabCounter(currentMenuItem.OID, 0);

                    return null;
                }

                Explorer.SetTabCounter(currentMenuItem.OID, data.IssueCount);
                setProcessingStatus(data.SelectedLocationProcessingStatus);

                $tableBody.find('img')
                    .off('error')
                    .on('error', Tools.OnImageNotFound);

                initIssueDragAndDrop();

                if (data.Issues.length >= params[0].Take) {
                    $tableWrapper.on('scroll.infiniteScroll', onScroll);
                }

                Tools.Spinner.hide();

                return data;
            });
    }

    function appendIssues(data) {
        var html = [];

        if (!issuesLoaded) {
            $tableBody.html('<tr><td colspan="16"><span>{0}</span></td></tr>'.format(
                i18next.t('explorer.issues.noDataAvailable')));
        } else {
            html = $.map(data.Issues || [], renderIssue);

            $tableBody.append(html.join(''));
        }

        $tableHeader.width($tableBody.width());

        return data;
    }

    function renderIssue(issue) {
        var html = [];
        var now = new Date();
        var tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
        var cssClasses;

        if (!issue.DeadlineTimestamp) {
            cssClasses = 'issue marker marker-yellow';
        } else if (issue.DeadlineTimestamp.getTime() < now.getTime()) {
            cssClasses = 'issue marker marker-red';
        } else if (issue.DeadlineTimestamp.getTime() < tomorrow.getTime()) {
            cssClasses = 'issue marker marker-yellow';
        } else {
            cssClasses = 'issue marker marker-green';
        }

        var editIssueRights = Tools.issue.getRightToEditIssueType(issue.Type);
        var editIssues = ressources.users.hasIssueEditRight(editIssueRights, issue);

        if (editIssues &&
            (+issue.AssignedRecorditemID > 0 || !!issue.AssignedRecorditemOID) &&
            !ressources.users.hasRightAtLocation('CMC-ISSUE', issue.AssignedElementOID)) {
            editIssues = false;
        }

        var editIssueLocation = editIssues && ressources.users.hasIssueEditRight('TMFT-LOCATION', issue);

        if (editIssueLocation) {
            cssClasses += ' move-issue-enabled';
        }

        html.push('<tr class="{0}" data-oid="{1}" data-id="{2}" data-location-oid="{3}">'
            .format(cssClasses, issue.OID, issue.ID, issue.AssignedElementOID)
        );

        html.push(renderCheckbox(issue));

        html.push(renderGenericIssueInformation(issue));
        html.push(renderComments(issue));

        if (issue.Image) {
            html.push(renderImage(issue.Image));
        }

        html.push(renderCoding(issue.FormCoding));
        html.push(renderCreation(issue.CreationTimestamp, issue.Creator));
        html.push(renderModification(issue.ModificationTimestamp, issue.Editor));
        html.push(renderDeadline(issue.DeadlineTimestamp));
        html.push(renderState(issue.State));
        html.push(renderPriority(issue.Priority));

        var assignedUsers = (issue.ResponsibilityAssignments || {}).Users || {};
        var assignedTeams = (issue.ResponsibilityAssignments || {}).Teams || {};

        html.push(renderTeamsAndUsers(assignedUsers, assignedTeams));
        html.push(renderParameterCount(issue));
        html.push(renderCollectedParameterCount(issue));
        html.push(renderOpenParameterCount(issue));
        html.push(renderRequiredParameterCount(issue));
        html.push(renderCollectedRequiredParameterCount(issue));
        html.push(renderEstimatedEffort(issue));

        html.push('</tr>');

        return html.join('');
    }

    function renderCheckbox(issue) {
        var html = [];

        html.push('<td>');
        html.push('<input type="checkbox" value="{0}"{1}>'.format(issue.ID, selectAll ? ' checked' : ''));
        html.push('</td>');

        return html.join('');
    }

    function renderGenericIssueInformation(issue) {
        var html = [];
        var abbr = Tools.GetIssueAbbreviation(issue);
        var icon = getIssueIcon(issue);
        var mailTokens = ((issue.AdditionalData || {}).MailToken || '').split('|');
        var show, mailAddresses;

        show = !tableColumnSelection || Tools.contains(tableColumnSelection, '0', 'OID');

        html.push('<td data-columnid="0"{0}><span class="issue-id">{1}{2}.{3}</span>'
            .format(
                show ? '' : ' class="hide"',
                abbr,
                issue.ID,
                issue.Revision));

        html.push('<img src="{0}" class="typeIcon">'.format(icon));
        html.push('<img src="./img/pdf-icon.svg" class="print">');
        html.push('</td>');

        show = !tableColumnSelection || Tools.contains(tableColumnSelection, '1', 'OID');

        html.push('<td data-columnid="1"{0}>{1}</td>'
            .format(
                show ? '' : ' class="hide"',
                issue.CustomID));

        show = !tableColumnSelection || Tools.contains(tableColumnSelection, '2', 'OID');

        html.push('<td data-columnid="2"{0}{1}><span class="title">{2}</span>'.format(
            show ? '' : ' class="hide"',
            issue.Image ? '' : ' colspan="2"',
            issue.Title || i18next.t('misc.untitled')));

        if (mailTokens.length > 1) {
            mailAddresses = mailTokens.slice(1).join(', ');

            html.push('<p class="mail-addresses">{0}</p>'.format(mailAddresses));
        }

        if (issue.InvestigationStatusInformation) {
            var errorText = issue.InvestigationStatusInformation.Status === Enums.Investigations.Status.Error ?
                Tools.issue.getInvestigationErrorText(issue.InvestigationStatusInformation.ErrorCode) :
                null;

            html.push('<p class="investigation-status{0}" title="{1}">{2}{3}</p>'
                .format(
                    issue.InvestigationStatusInformation.Status === Enums.Investigations.Status.Error ? ' with-error' : '',
                    i18next.t('misc.investigationStatus.helpText'),
                    Tools.issue.getInvestigationStatusText(issue.InvestigationStatusInformation.Status),
                    !!errorText ? (' (' + errorText + ')') : ''
                )
            );
        }

        if (issue.LocationPath) {
            html.push('<p class="location">{0}</p>'.format(issue.LocationPath));
        }

        return html.join('');
    }

    function renderComments(issue) {
        var html = [];

        html.push('<p class="commentdetails">');
        html.push(renderSubscription(issue.Recipients));
        html.push(' - <span class="commentlink"');

        if ((issue.Comments || []).length) {
            html.push(' data-comments="{0}"'.format(issue.Comments.length));
        }

        html.push('>{0}</span></p>'.format(i18next.t('misc.comment_plural')));
        html.push('</td>');

        return html.join('');
    }

    function renderSubscription(recipients) {
        if (Tools.contains(recipients, User.OID)) {
            return '<span class="abos" data-isabo="true">{0} <span class="details" data-abos="{1}">({2})</span></span>'
                .format(
                    i18next.t('misc.unsubscribe'),
                    recipients.join('|'),
                    recipients.length);
        } else if ((recipients || []).length) {
            return '<span class="abos">{0} <span class="details" data-abos="{1}">({2})</span></span>'
                .format(
                    i18next.t('misc.subscribe'),
                    recipients.join('|'),
                    recipients.length);
        }

        return '<span class="abos">{0}</span>'.format(i18next.t('misc.subscribe'));
    }

    function renderImage(image) {
        var show = !tableColumnSelection || Tools.contains(tableColumnSelection, '2', 'OID');
        var html = ['<td data-columnid="2" data-filename="{0}" class="image{1}">'.format(image.Filename, show ? '' : ' hide')];

        if (image.Marks) {
            var viewboxRegex = /<svg[^>]*width="(\d+)"[^>]*height="(\d+)"[^>]*>(.*)<\/svg>/ig;
            var pathRegex = /<path [^>\/]+/ig;
            var paths = [];
            var match = viewboxRegex.exec(image.Marks);

            if (!match || match.length !== 4) {
                return;
            }

            var width = parseInt(match[1], 10);
            var height = parseInt(match[2], 10);
            var svgContent = match[3];

            do {
                if ((match = pathRegex.exec(image.Marks))) {
                    paths.push(match[0].trim() + '></path>');
                }
            } while (match);

            if (paths.length) {
                html.push('<svg xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 {0} {1}">'
                    .format(width, height));
                html.push('<image xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="{0}/images/m/{1}" x="0" y="0" width="100%" height="100%" crossorigin="use-credentials"></image>'
                    .format(Config.BaseUri, image.Filename));
                html.push(svgContent);
                html.push('</svg>');
            }
        } else {
            html.push('<img src="{0}images/s/{1}" />'.format(Config.BaseUri, image.Filename));
        }

        html.push('</td>');

        return html.join('');
    }

    function renderCoding(formCoding) {
        var show = !tableColumnSelection || Tools.contains(tableColumnSelection, '15', 'OID');
        var coding = formCoding ?
            Tools.element.formatElementCoding(Tools.escapeHtml(formCoding.Prefix), formCoding.CodingIdent) :
            '-/-';

        return '<td data-columnid="15"{0}>{1}</td>'.format(
            show ? '' : ' class="hide"', coding
        );
    }

    function renderCreation(timestamp, creator) {
        var show = !tableColumnSelection || Tools.contains(tableColumnSelection, '3', 'OID');

        return '<td data-columnid="3"{0}>{1}<br /><span class="creator">{2}</span></td>'.format(
            show ? '' : ' class="hide"',
            Tools.dateTime.getDifferenceString(timestamp),
            creator ? creator.Title : i18next.t('misc.unknown'));
    }

    function renderModification(timestamp, editor) {
        var show = !tableColumnSelection || Tools.contains(tableColumnSelection, '4', 'OID');

        return '<td data-columnid="4"{0}>{1}<br /><span class="lastEditor">{2}</span></td>'.format(
            show ? '' : ' class="hide"',
            Tools.dateTime.getDifferenceString(timestamp),
            editor ? editor.Title : i18next.t('misc.unknown'));
    }

    function renderDeadline(timestamp) {
        var show = !tableColumnSelection || Tools.contains(tableColumnSelection, '5', 'OID');

        return '<td data-columnid="5"{0}>{1}</td>'.format(
            show ? '' : ' class="hide"',
            timestamp ? Tools.dateTime.getDifferenceString(timestamp) : 'n.a.');
    }

    function renderState(state) {
        var show = !tableColumnSelection || Tools.contains(tableColumnSelection, '6', 'OID');

        if (state) {
            return '<td data-columnid="6"{0}><div class="color" style="background-color: {1}">&nbsp;</div> {2}</td>'
                .format(show ? '' : ' class="hide"', state.Color, state.Title);
        }

        return '<td data-columnid="6"{0}>{1}</td>'.format(show ? '' : ' class="hide"', 'n.a.');
    }

    function renderPriority(priority) {
        var show = !tableColumnSelection || Tools.contains(tableColumnSelection, '7', 'OID');

        if (priority) {
            return '<td data-columnid="7"{0}><div class="color" style="background-color: {1}">&nbsp;</div> {2}</td>'
                .format(show ? '' : ' class="hide"', priority.Color, priority.Title);
        }

        return '<td data-columnid="7"{0}>{1}</td>'.format(show ? '' : ' class="hide"', 'n.a.');
    }

    function renderTeamsAndUsers(users, teams) {
        var show = !tableColumnSelection || Tools.contains(tableColumnSelection, '8', 'OID');
        var html = [];
        var renderContent = false;
        var groupedTeamsAndUsers = {
            responsible: [],
            accountable: [],
            consulted: [],
            informed: []
        };

        for (var userIdentifier in users) {
            var userResponsibilityEntity = users[userIdentifier];
            var user = Users[userIdentifier];

            if (!user) {
                continue;
            }

            renderContent = true;

            if (userResponsibilityEntity.IsResponsible) {
                groupedTeamsAndUsers.responsible.push('<li>{0}</li>'.format(user.Fullname));
            }

            if (userResponsibilityEntity.IsAccountable) {
                groupedTeamsAndUsers.accountable.push('<li>{0}</li>'.format(user.Fullname));
            }

            if (userResponsibilityEntity.IsConsulted) {
                groupedTeamsAndUsers.consulted.push('<li>{0}</li>'.format(user.Fullname));
            }

            if (userResponsibilityEntity.IsInformed) {
                groupedTeamsAndUsers.informed.push('<li>{0}</li>'.format(user.Fullname));
            }
        }

        for (var teamIdentifier in teams) {
            var teamResponsibilityEntity = teams[teamIdentifier];
            var team = Teams[teamIdentifier];

            if (!team) {
                continue;
            }

            renderContent = true;

            if (teamResponsibilityEntity.IsResponsible) {
                groupedTeamsAndUsers.responsible.push('<li>{0}</li>'.format(team.Title));
            }

            if (teamResponsibilityEntity.IsAccountable) {
                groupedTeamsAndUsers.accountable.push('<li>{0}</li>'.format(team.Title));
            }

            if (teamResponsibilityEntity.IsConsulted) {
                groupedTeamsAndUsers.consulted.push('<li>{0}</li>'.format(team.Title));
            }

            if (teamResponsibilityEntity.IsInformed) {
                groupedTeamsAndUsers.informed.push('<li>{0}</li>'.format(team.Title));
            }
        }

        groupedTeamsAndUsers.responsible.sort(Tools.SortStringArray);
        groupedTeamsAndUsers.accountable.sort(Tools.SortStringArray);
        groupedTeamsAndUsers.consulted.sort(Tools.SortStringArray);
        groupedTeamsAndUsers.informed.sort(Tools.SortStringArray);

        if (renderContent) {
            html.push('<td data-columnid="8"{0}>'.format(show ? '' : ' class="hide"'));

            if (groupedTeamsAndUsers.responsible.length) {
                html.push('<b>' + i18next.t('explorer.issues.responsibilities.r') + '</b>');
                html.push(groupedTeamsAndUsers.responsible.slice(0, 6).join(''));
                if (groupedTeamsAndUsers.responsible.length > 6) {
                    html.push('<li>+ {0} {1}</li>'.format(
                        groupedTeamsAndUsers.responsible.length - 6,
                        i18next.t('misc.more')));
                }
            }

            if (groupedTeamsAndUsers.accountable.length) {
                html.push('<b>' + i18next.t('explorer.issues.responsibilities.a') + '</b>');
                html.push(groupedTeamsAndUsers.accountable.slice(0, 6).join(''));
                if (groupedTeamsAndUsers.accountable.length > 6) {
                    html.push('<li>+ {0} {1}</li>'.format(
                        groupedTeamsAndUsers.accountable.length - 6,
                        i18next.t('misc.more')));
                }
            }

            if (groupedTeamsAndUsers.consulted.length) {
                html.push('<b>' + i18next.t('explorer.issues.responsibilities.c') + '</b>');
                html.push(groupedTeamsAndUsers.consulted.slice(0, 6).join(''));
                if (groupedTeamsAndUsers.consulted.length > 6) {
                    html.push('<li>+ {0} {1}</li>'.format(
                        groupedTeamsAndUsers.consulted.length - 6,
                        i18next.t('misc.more')));
                }
            }

            if (groupedTeamsAndUsers.informed.length) {
                html.push('<b>' + i18next.t('explorer.issues.responsibilities.i') + '</b>');
                html.push(groupedTeamsAndUsers.informed.slice(0, 6).join(''));
                if (groupedTeamsAndUsers.informed.length > 6) {
                    html.push('<li>+ {0} {1}</li>'.format(
                        groupedTeamsAndUsers.informed.length - 6,
                        i18next.t('misc.more')));
                }
            }

            html.push('</td>');

            return html.join('');
        }

        return '<td data-columnid="8"{0}>{1}</td>'.format(show ? '' : ' class="hide"', 'n.a.');
    }

    function renderParameterCount(issue) {
        var show = !tableColumnSelection || Tools.contains(tableColumnSelection, '9', 'OID');

        return '<td data-columnid="9"{0}>{1}</td>'.format(
            show ? '' : ' class="hide"',
            issue.ParameterCount);
    }

    function renderCollectedParameterCount(issue) {
        var show = !tableColumnSelection || Tools.contains(tableColumnSelection, '10', 'OID');

        return '<td data-columnid="10"{0}>{1}</td>'.format(
            show ? '' : ' class="hide"',
            issue.CollectedParameterCount);
    }

    function renderOpenParameterCount(issue) {
        var show = !tableColumnSelection || Tools.contains(tableColumnSelection, '11', 'OID');

        return '<td data-columnid="11"{0}>{1}</td>'.format(
            show ? '' : ' class="hide"',
            issue.OpenParameterCount);
    }

    function renderRequiredParameterCount(issue) {
        var show = !tableColumnSelection || Tools.contains(tableColumnSelection, '12', 'OID');

        return '<td data-columnid="12"{0}>{1}</td>'.format(
            show ? '' : ' class="hide"',
            issue.RequiredParameterCount);
    }

    function renderCollectedRequiredParameterCount(issue) {
        var show = !tableColumnSelection || Tools.contains(tableColumnSelection, '13', 'OID');

        return '<td data-columnid="13"{0}>{1}</td>'.format(
            show ? '' : ' class="hide"',
            issue.CollectedRequiredParameterCount);
    }

    function renderEstimatedEffort(issue) {
        var show = !tableColumnSelection || Tools.contains(tableColumnSelection, '14', 'OID');
        var unit, unitOID, unitTitle;

        if (Client && Client.Settings && Client.Settings.EstimatedEffortSettings
            && Client.Settings.EstimatedEffortSettings.UnitOID
            && (issue.EstimatedEffort === 0 || issue.EstimatedEffort)) {
            unitOID = Client.Settings.EstimatedEffortSettings.UnitOID;
            unit = Properties[unitOID];
            unitTitle = unit.Title || '';
        }

        return '<td data-columnid="14"{0}>{1} {2} </td>'.format(
            show ? '' : ' class="hide"',
            issue.EstimatedEffort === 0 || issue.EstimatedEffort ? issue.EstimatedEffort : '-/-',
            unitTitle || '');
    }

    function createParams(menuItem, take) {
        menuItem = menuItem || Explorer.GetCurrentTab();

        if (!menuItem) {
            return null;
        }

        var menuItemConfig = menuItem.Configuration || {};
        var params = {
            Skip: issuesLoaded || 0,
            Take: (take == null || isNaN(take)) ? 50 : take,
            IncludeCounter: true,
            LoadIssues: true,
            Sorting: sorting,
            SortingOrder: sortingorder,
            LocationOID: CurrentEntity.OID,
            WithProcessingStatusInformation: true,
            WithChildLocations: Explorer.GetWithChildLocations(),
            WithDeactivatedElements: Explorer.GetWithDeactivatedElements(),
            HideNonResponsibleIssues: hideNonResponsibleIssues,
            Types: $.extend(true, [], menuItemConfig.IssueTypes),
            Users: $.extend(true, [], menuItemConfig.Users),
            Teams: $.extend(true, [], menuItemConfig.Teams),
            Contacts: $.extend(true, [], menuItemConfig.Contacts),
            ContactGroups: $.extend(true, [], menuItemConfig.ContactGroups),
            Keywords: $.extend(true, [], menuItemConfig.Keywords),
            Classifications: $.extend(true, [], menuItemConfig.Classifications),
            States: $.extend(true, [], menuItemConfig.Statuses),
            Priorities: $.extend(true, [], menuItemConfig.Priorities),
            Forms: $.extend(true, [], menuItemConfig.Forms),
            Schedulings: $.extend(true, [], menuItemConfig.Schedulings),
            ProcessingStatus: $.extend(true, [], menuItemConfig.ProcessingStatuses)
        };

        return [params, createUserFilterParams(menuItem)];
    }

    function createUserFilterParams(menuItem) {
        var params = {
            Creators: (creatorFilter || []).map(function (user) { return user.OID; }),
            Users: (userFilter || []).map(function (user) { return user.OID; }),
            Teams: (teamFilter || []).map(function (team) { return team.OID; }),
            Contacts: (contactFilter || []).map(function (contact) { return contact.OID; }),
            ContactGroups: (contactGroupFilter || []).map(function (contactGroup) { return contactGroup.OID; }),
            Keywords: (keywordFilter || []).map(function (keyword) { return keyword.OID; }),
            Classifications: (classificationFilter || []).map(function (classification) { return classification.OID; }),
            States: (statusFilter || []).map(function (status) { return status.OID; }),
            Priorities: (priorityFilter || []).map(function (priority) { return priority.OID; }),
            Forms: (formFilter || []).map(function (form) { return form.OID; }),
            Schedulings: (schedulingFilter || []).map(function (scheduling) { return scheduling.OID; })
        };

        params.Types = Array.prototype.concat.apply([], (typeFilter || []).map(function (typeFilter) {
            return typeFilter.OID
                .split('-')
                .map(function (type) {
                    return parseInt(type, 10);
                });
        }));

        if (periodFilter && (periodFilter.Start || periodFilter.End)) {
            if (periodFilter.CurrentType === Enums.DatePickerTypes.Creation) {
                params.CreationPeriod = {
                    PeriodStart: periodFilter.Start ? Tools.dateTime.toGMTString(periodFilter.Start) : null,
                    PeriodEnd: periodFilter.End ? Tools.dateTime.toGMTString(periodFilter.End) : null
                };
            } else if (periodFilter.CurrentType === Enums.DatePickerTypes.Modification) {
                params.ModificationPeriod = {
                    PeriodStart: periodFilter.Start ? Tools.dateTime.toGMTString(periodFilter.Start) : null,
                    PeriodEnd: periodFilter.End ? Tools.dateTime.toGMTString(periodFilter.End) : null
                };
            } else if (periodFilter.CurrentType === Enums.DatePickerTypes.Deadline) {
                params.DeadlinePeriod = {
                    PeriodStart: periodFilter.Start ? Tools.dateTime.toGMTString(periodFilter.Start) : null,
                    PeriodEnd: periodFilter.End ? Tools.dateTime.toGMTString(periodFilter.End) : null
                };
            }
        }

        if ((processingStatusFilter || []).length && processingStatusFilter.length < 3) {
            params.ProcessingStatus = processingStatusFilter;
        }

        if (searchValue) {
            params.SearchString = searchValue;
        }

        if (menuItem && menuItem.Configuration) {
            params.IncludeTemplates = menuItem.Configuration.ShowIssueTemplatesAndDerivations || false;
            params.IncludeDerivations = menuItem.Configuration.ShowIssueTemplatesAndDerivations || false;
        }

        return params;
    }

    function createSortFunction() {
        switch(sorting) {
            case 'title':
                return function (a, b) {
                    var titleA = a.Title || '';
                    var titleB = b.Title || '';

                    titleA = titleA.toLowerCase();
                    titleB = titleB.toLowerCase();

                    if (titleA === titleB) {
                        return 0;
                    }

                    if (titleA > titleB) {
                        return sortingorder === 'asc' ? 1 : -1;
                    }

                    return sortingorder === 'asc' ? -1 : 1;
                };
            case 'creationtimestamp':
                return function (a, b) {
                    return (a.CreationTimestamp.getTime() - b.CreationTimestamp.getTime()) *
                        (sortingorder === 'asc' ? 1 : -1);
                };
            case 'modificationtimestamp':
                return function (a, b) {
                    return (a.ModificationTimestamp.getTime() - b.ModificationTimestamp.getTime()) *
                        (sortingorder === 'asc' ? 1 : -1);
                };
            case 'deadlinetimestamp':
                return function (a, b) {
                    var valA = a.DeadlineTimestamp ? a.DeadlineTimestamp.getTime() : 0;
                    var valB = b.DeadlineTimestamp ? b.DeadlineTimestamp.getTime() : 0;

                    return (valA - valB) * (sortingorder === 'asc' ? 1 : -1);
                };
            case 'state':
                return function (a, b) {
                    var sortResult = sortByPropertyHierarchy(a.State, b.State);
                    return sortingorder === 'asc' ? sortResult*-1 : sortResult;
                };
            case 'priority':
                return function (a, b) {
                    var sortResult = sortByPropertyHierarchy(a.Priority, b.Priority);
                    return sortingorder === 'asc' ? sortResult*-1 : sortResult;
                };
            case 'checkpointCount':
                return function (a, b) {
                    return (a.ParameterCount - b.ParameterCount) * (sortingorder === 'asc' ? 1 : -1);
                };
            case 'collectedCheckpointCount':
                return function (a, b) {
                    return (a.CollectedParameterCount - b.CollectedParameterCount) * (sortingorder === 'asc' ? 1 : -1);
                };
            case 'openCheckpointCount':
                return function (a, b) {
                    return (a.OpenParameterCount - b.OpenParameterCount) * (sortingorder === 'asc' ? 1 : -1);
                };
            case 'requiredCheckpointCount':
                return function (a, b) {
                    return (a.RequiredParameterCount - b.RequiredParameterCount) * (sortingorder === 'asc' ? 1 : -1);
                };
            case 'collectedRequiredCheckpointCount':
                return function (a, b) {
                    return (a.CollectedRequiredParameterCount - b.CollectedRequiredParameterCount) * (sortingorder === 'asc' ? 1 : -1);
                };
            case 'custom-id':
                return function (a, b) {
                    var naturalizedCustomIDA = a.NaturalizedCustomID.toLowerCase();
                    var naturalizedCustomIDB = b.NaturalizedCustomID.toLowerCase();

                    if (naturalizedCustomIDA === naturalizedCustomIDB) {
                        return 0;
                    }

                    if (naturalizedCustomIDA > naturalizedCustomIDB) {
                        return sortingorder === 'asc' ? 1 : -1;
                    }

                    return sortingorder === 'asc' ? -1 : 1;
                };
            case 'estimatedEffort':
                return function (a, b) {
                    return (a.EstimatedEffort - b.EstimatedEffort) * (sortingorder === 'asc' ? 1 : -1);
                };
            default:
                return function (a, b) {
                    return (a.ID - b.ID) * (sortingorder === 'asc' ? 1 : -1);
                };
        }
    }

    function sortByPropertyHierarchy(a, b) {
        if (a && !b) {
            return -1;
        } else if (!a && b) {
            return 1;
        } else if (!a || !b) {
            return 0;
        }

        if (a.OID === b.OID) {
            return 0;
        }

        if (a.ParentOID === b.ParentOID) {
            return a.Position - b.Position;
        }

        var stackA = [a];
        while (a.Parent) {
            stackA.unshift(a.Parent);
            a = a.Parent;
        }

        var stackB = [b];
        while (b.Parent) {
            stackB.unshift(b.Parent);
            b = b.Parent;
        }

        var maxDepth = Math.min(stackA.length, stackB.length);
        for (var i = 0; i < maxDepth; i++) {
            if(stackA[i].Position == stackB[i].Position) {
                continue;
            }
            return stackA[i].Position - stackB[i].Position;
        }

        return stackA.length - stackB.length;
    }

    function prepareData(data) {
        data.Issues = $.map(data.Issues || [], Tools.issue.prepareIssueForWebApp);
        data.Issues.sort(createSortFunction());

        issuesLoaded = (issuesLoaded || 0) + data.Issues.length;
        issues = issues || {};

        data.Issues.forEach(function (issue) {
            issues[issue.OID] = issue;
			if(issue.Files && issue.Files.length>1){
				issue.Files.sort(Tools.SortByPosition);
			}
        });

        return data;
    }

    function getIssueIcon(issue) {
        if (!issue) {
            return null;
        }

        if (issue.IsTemplate) {
            return './img/file-lines-regular.svg';;
        }

        switch (issue.Type) {
            case Enums.issueType.Note:
                return './img/notes.svg';
            case Enums.issueType.Disturbance:
                return './img/disturbance_reported.svg';
            case Enums.issueType.DisturbancesCompleted:
                return './img/archive-icon.png';
            case Enums.issueType.Inspection:
                return './img/jobsite.svg';
            case Enums.issueType.Investigation:
            case Enums.issueType.Form:
            case Enums.issueType.Survey:
                return './img/form.svg';
            case Enums.issueType.Resubmission:
                return './img/checklist.svg';
            default:
                return './img/task.svg';
        }
    }

    function load(params) {
        return Tools.http.post('reports/issues', params);
    }

    function setProcessingStatus(selectedLocationProcessingStatus) {
        var processingStatus = 0;

        $.each(selectedLocationProcessingStatus, function(_, s) {
            processingStatus = Math.max(s, processingStatus);
        });

        switch (processingStatus) {
            case 0:
                $processingStatus
                    .removeClass('hidden yellow red')
                    .addClass('green');
                break;
            case 1:
                $processingStatus
                    .removeClass('hidden green red')
                    .addClass('yellow');
                break;
            case 2:
                $processingStatus
                    .removeClass('hidden green yellow')
                    .addClass('red');
                break;
        }
    }

    function updateTabCounter(menuItemConfig) {
        if (!menuItemConfig) {
            return;
        }

        var params = createParams(menuItemConfig, 0);

        Explorer.SetTabCounterLoading(menuItemConfig.OID);

        var request = load(params);

        request.then(function (data) {
            Explorer.SetTabCounter(menuItemConfig.OID, data.IssueCount);
        }, function () {
            Explorer.SetTabCounter(menuItemConfig.OID, 0);
        });

        return request;
    }

    function disposeTab() {
        if (CurrentEntity) {
            delete CurrentEntity.Parametergroups;
        }

        $tableBody.empty();
    }

    Issues.Show = show;
    Issues.UpdateTabCounter = updateTabCounter;
    Issues.Resize = resize;
    Issues.ResetFilters = resetFilters;
    Issues.RemoveDragAndDropHandlers = unsetInteracts;
    Issues.Dispose = disposeTab;

    return (global.Issues = Issues);
})( window.Explorer || (window.Explorer = {}) );