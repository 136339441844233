/**
 * @require ./explorer.js
 */
(function (global) {
    var Dashboard = {};
    var periodFilter = {};
    var userFilter = [];
    var teamFilter = [];
    var users;
    var teams;
    var _result;

    /**
     * @type {ApexChart}
     */
    var _statusChart;
    /**
     * @type {ApexChart}
     */
    var _processingStatusChart;
    /**
     * @type {ApexChart}
     */
    var _userChart;
    /**
     * @type {ApexChart}
     */
    var _teamsChart;
    /**
     * @type {ApexChart}
     */
    var _userProcessingStatusIssueCount;
    /**
     * @type {ApexChart}
     */
    var _teamsProcessingStatusIssueCount;
    var hideNonResponsibleIssues;
    var userCanAssignAllTeamsAndUsers;
    var visibleTeams, selectableTeams;

    var $nav = $('#nav');
    var $tree = $('#center-tree');
    var $explorerDashboard = $('#explorer-dashboard');
    var $explorerInfo = $('#explorer-info');
    var $explorerValues = $('#explorer-values');
    var $explorerNews = $('#explorer-news');
    var $explorerIssues = $('#explorer-issues');
    var $explorerDisturbances = $('#explorer-disturbances');
    var $explorerScheduling = $('#explorer-scheduling');
    var $explorerFiles = $('#explorer-files');
    var $explorerArchive = $('#explorer-archive');
    var $properties = $('#properties');
    var $explorerTabMenu = $('#explorer-tab-menu');
    var $explorerOptionMenu = $('#explorer-tab-menu-options');
    var $resetFilters = $('#explorer-dashboard-reset-filters');
    var $userFilter = $('#explorer-dashboard-user-filter');
    var $teamFilter = $('#explorer-dashboard-team-filter');
    var $periodFilter = $('#explorer-dashboard-period-filter');
    var $cbHideNonResponsibleIssues = $('#cb-explorer-dashboard-hide-non-responsible-issues');
    var activeFilters = {
        UserFilter: false,
        TeamFilter: false,
        HideNonResponsibleIssues: false,
        PeriodFilter: false
    };

    function show(match) {
        Tools.Spinner.show();

        var initResult = Explorer.Init(match);

        if (!initResult.CurrentTabIsAvailable) {
            var currentTab = Explorer.GetCurrentTab();

            if (currentTab && initResult.NewTab && initResult.NewTab.OID !== currentTab.OID) {
                Explorer.ShowTab(initResult.NewTab);
                return;
            }
        }

        init();

        load()
            .always(Tools.Spinner.hide)
            .then(function (result) { _result = result; }, function (xhr) {
                Tools.Spinner.hide();
                Tools.handleHttpError(Enums.HttpActionType.Read, xhr);
            })
            .then(render);
    }

    function init() {
        userCanAssignAllTeamsAndUsers = ressources.users.hasRightAtLocation(
            Enums.Rights.IssueRights.AllowAssigningAllUsersToIssues,
            CurrentEntity.OID
        );

        $tree.tree('remove-class-from-all-nodes', 'jquery-tree-node-marker-green jquery-tree-node-marker-yellow jquery-tree-node-marker-red');

        disposeCharts();

        $explorerDashboard.empty();

        initTab();

        unbindEvents();
        bindEvents();
    }

    function initTab() {
        $explorerDashboard.removeClass('hide');
        $explorerInfo.addClass('hide');
        $explorerValues.addClass('hide');
        $explorerNews.addClass('hide');
        $explorerIssues.addClass('hide');
        $explorerDisturbances.addClass('hide');
        $explorerScheduling.addClass('hide');
        $explorerFiles.addClass('hide');
        $explorerArchive.addClass('hide');
        $properties.removeClass('active');

        $explorerTabMenu.find('.open').removeClass('open');

        $explorerOptionMenu.removeClass('active');
    }

    function unbindEvents() {
        $explorerDashboard.off('click');
        $resetFilters.off('click.resetFilters');
        $userFilter.off('click.showUserFilter');
        $teamFilter.off('click.showTeamFilter');
        $periodFilter.off('click.togglePeriodFilter');
        $cbHideNonResponsibleIssues.off('change.hideNonResponsibleIssues');
    }

    function bindEvents() {
        $resetFilters.on('click.resetFilters', onResetFilters);
        $userFilter.on('click.toggleUserFilter', onShowUserFilter);
        $teamFilter.on('click.showTeamFilter', onShowTeamFilter);
        $periodFilter.on('click.togglePeriodFilter', onTogglePeriodFilter);
        $cbHideNonResponsibleIssues.on('change.hideNonResponsibleIssues', onToggleHideNonResponsibleIssues);

        $explorerDashboard.on('click', '.explorer-dashboard-issue-disturbances', onShowIssueDisturbances);
        $explorerDashboard.on('click', '.explorer-dashboard-recorditem-disturbances', onShowRecorditemDisturbances);
        $explorerDashboard.on('click', '.explorer-dashboard-issues', onShowIssues);
        $explorerDashboard.on('click', '.explorer-dashboard-panel-clickable[data-chart]', onChartButtonClick);
        $explorerDashboard.on('click', '.content-panel-row[data-chart] .close', onCloseChartClick);
    }

    function load() {
        var params = {
            LocationOID: CurrentEntity.OID,
            WithChildLocations: Explorer.GetWithChildLocations(),
            WithDeactivatedElements: Explorer.GetWithDeactivatedElements(),
            WithIssueDisturbanceCount: true,
            WithRecorditemDisturbanceCount: true,
            Users: userFilter.map(function (user) { return user.OID; }),
            Teams: teamFilter.map(function (team) { return team.OID; }),
            CreationPeriod: periodFilter.CreationPeriod,
            ModificationPeriod: periodFilter.ModificationPeriod,
            DeadlinePeriod: periodFilter.DeadlinePeriod,
            HideNonResponsibleIssues: hideNonResponsibleIssues
        };

        return Tools.http.post('reports/dashboard', params);
    }

    function render() {
        var html = '';

        if (!_result) {
            return;
        }

        html += '<div class="table-container">';
        html += '<div class="table-control">';
        html += '<div class="grid-row">';
        html += '<span class="gfx-t-tc-text scmi" id="explorer-dashboard-hide-non-responsible-issues-lbl"></span>';
        html += '<input id="cb-explorer-dashboard-hide-non-responsible-issues" class="scmi-value" type="checkbox">';
        html += '<span id="explorer-dashboard-user-filter-lbl" class="gfx-t-tc-text"></span>';
        html += '<div id="explorer-dashboard-user-filter" class="table-button table-filter-button resizable">';
        html += '<div class="btn-title"></div>';
        html += '</div><span id="explorer-dashboard-team-filter-lbl" class="gfx-t-tc-text"></span>';
        html += '<div id="explorer-dashboard-team-filter" class="table-button table-filter-button resizable">';
        html += '<div class="btn-title"></div></div>';
        html += '<div id="explorer-dashboard-reset-filters" class="table-button reset-filters" title="{0}"></div>'.format(i18next.t('explorer.toolbar.resetFilters'));
        html += '</div>';
        html += '<div class="grid-row">';
        html += '<span id="explorer-dashboard-period-filter-lbl" class="gfx-t-tc-text"></span>';
        html += '<div id="explorer-dashboard-period-filter" class="table-button table-filter-button">';
        html += '</div></div>';
        html += '</div></div></div>';
        html += '<div class="grid-row content-panel-row">';
        html += '<div class="grid-col-4-12 content-panel explorer-dashboard-issue-disturbances">';
        html += '<h5 class="panel-header">{0}</h5>'.format(i18next.t('explorer.dashboard.issueDisturbances'));
        html += '<div class="panel-value {0}">{1}</div>'.format(
            _result.IssueDisturbanceCount ? 'red' : 'green',
            _result.IssueDisturbanceCount);
        html += '</div>';
        html += '<div class="grid-col-4-12 content-panel explorer-dashboard-recorditem-disturbances">';
        html += '<h5 class="panel-header">{0}</h5>'.format(i18next.t('explorer.dashboard.recorditemDisturbances'));
        html += '<div class="panel-value {0}">{1}</div>'.format(
            _result.RecorditemDisturbanceCount ? 'red' : 'green',
            _result.RecorditemDisturbanceCount);
        html += '</div>';
        html += '<div class="grid-col-4-12 content-panel explorer-dashboard-issues">';
        html += '<h5 class="panel-header">{0}</h5>'.format(i18next.t('explorer.dashboard.issues'));
        html += '<div class="panel-value">{0}</div>'.format(_result.IssueCount);
        html += '</div>';
        html += '</div>';

        if ((_result.StatusGroupings || []).length &&
                (_result.ProcessingStatusGroupings || []).length) {
            if ($explorerDashboard.innerWidth() >= 1200) {
                html += '<div class="grid-row content-panel-row">';
                html += '<div id="explorer-dashboard-processing-status-chart" class="grid-col-1-2 content-panel">';
                html += '</div>';
                html += '<div id="explorer-dashboard-status-chart" class="grid-col-1-2 content-panel"></div>';
                html += '</div>';
            } else {
                html += '<div class="grid-row content-panel-row">';
                html += '<div id="explorer-dashboard-processing-status-chart" class="grid-col-2-2 content-panel"></div>';
                html += '</div>';
                html += '<div class="grid-row content-panel-row">';
                html += '<div id="explorer-dashboard-status-chart" class="grid-col-2-2 content-panel"></div>';
                html += '</div>';
            }
        }

        if (_result.IssueCount > 0) {
            html += initBarCharts();
        }

        $explorerDashboard.html(html);

        $('#explorer-dashboard-user-filter-lbl').text(i18next.t('explorer.issues.userFilter.label'));
        $('#explorer-dashboard-team-filter-lbl').text(i18next.t('explorer.issues.teamFilter.label'));
        $('#explorer-dashboard-period-filter-lbl').text(i18next.t('explorer.issues.periodFilter.label'));
        $('#explorer-dashboard-hide-non-responsible-issues-lbl').text(i18next.t('explorer.issues.hideNonResponsibleIssues'));

        $resetFilters = $('#explorer-dashboard-reset-filters');
        $userFilter = $('#explorer-dashboard-user-filter');
        $teamFilter = $('#explorer-dashboard-team-filter');
        $periodFilter = $('#explorer-dashboard-period-filter');
        $cbHideNonResponsibleIssues = $('#cb-explorer-dashboard-hide-non-responsible-issues');

        initUsers();
        initTeams();
        initFilters();
        resizeTableHeaderControls();

        if (_result.IssueCount > 0) {
            if (Tools.GetOfficeSettingValue(getChartSettingName('maturityTeams')) === true) {
                renderTeamsProcessingStatusBarChart(_result.ProcessingStatusIssueCountByTeams);
            }

            if (Tools.GetOfficeSettingValue(getChartSettingName('maturityUsers')) === true) {
                renderUserProcessingStatusBarChart(_result.ProcessingStatusIssueCountByUsers);
            }

            if (Tools.GetOfficeSettingValue(getChartSettingName('statusTeams')) === true) {
                renderTeamsChart(_result.IssueCountByTeams);
            }

            if (Tools.GetOfficeSettingValue(getChartSettingName('statusUsers')) === true) {
                renderUsersChart(_result.IssueCountByUsers);
            }
        }

        if ((_result.StatusGroupings || []).length &&
                (_result.ProcessingStatusGroupings || []).length) {
            renderStatusChart(_result.StatusGroupings);
            renderProcessingStatusChart(_result.ProcessingStatusGroupings);
        }

        unbindEvents();
        bindEvents();
    }

    function initBarCharts() {
        var showStatusUsers = Tools.GetOfficeSettingValue(getChartSettingName('statusUsers')) === true;
        var showStatusTeams = Tools.GetOfficeSettingValue(getChartSettingName('statusTeams')) === true;
        var showMaturityUsers = Tools.GetOfficeSettingValue(getChartSettingName('maturityUsers')) === true;
        var showMaturityTeams = Tools.GetOfficeSettingValue(getChartSettingName('maturityTeams')) === true;

        return [
            '<div class="grid-row content-panel-row">',
                initChartButton(3, 'maturityUsers', showMaturityUsers),
                initChartButton(3, 'maturityTeams', showMaturityTeams),
                initChartButton(3, 'statusUsers', showStatusUsers),
                initChartButton(3, 'statusTeams', showStatusTeams),
            '</div>',
            initChartContainer('explorer-dashboard-processing-status-user-bar-chart', 'maturityUsers', !showMaturityUsers),
            initChartContainer('explorer-dashboard-processing-status-teams-bar-chart', 'maturityTeams', !showMaturityTeams),
            initChartContainer('explorer-dashboard-user-chart', 'statusUsers', !showStatusUsers),
            initChartContainer('explorer-dashboard-teams-chart', 'statusTeams', !showStatusTeams)
        ].join('');
    }

    function initChartButton(colWidth, chartName, isHidden) {
        var upperCaseChartName = chartName[0].toUpperCase() + chartName.slice(1);

        return [
            '<div class="grid-col-{0}-12 content-panel explorer-dashboard-panel-clickable{1}" data-chart="{2}">'
                .format(colWidth, isHidden ?  ' hide' : '', chartName),
            '<h5 class="panel-header">{0}</h5>'.format(i18next.t('explorer.dashboard.show' + upperCaseChartName)),
            '</div>'
        ].join('');
    }

    function initChartContainer(id, chartName, isHidden) {
        return [
            '<div class="grid-row content-panel-row{0}" data-chart="{1}">'.format(isHidden ? ' hide' : '', chartName),
            '<div class="close">×</div>',
            '<div id="{0}" class="grid-col-2-2 content-panel"></div>'.format(id),
            '</div>'
        ].join('');
    }

    function initUsers() {
        users = Tools.GetSelectableUsers(CurrentEntity.OID);
        users.sort(Tools.SortByFullname);
    }

    function initTeams() {
        var teamInformation = Tools.GetSelectableTeams(CurrentEntity.OID);

        teams = teamInformation.Teams;
        visibleTeams = teamInformation.VisibleTeams;
        selectableTeams = teamInformation.SelectableTeams;
    }

    function initFilters() {
        initUserFilter();
        initTeamFilter();
        initPeriodFilter();
        initHideNonResponsibleIssuesFilter();
        setFilterState();
    }

    function setFilterState() {
        var isActive;

        for (var filter in activeFilters) {
            isActive = activeFilters[filter];

            if (isActive) {
                break;
            }
        }

        isActive ? $resetFilters.addClass('active') : $resetFilters.removeClass('active');
    }

    function initUserFilter() {
        var $btnTitle = $userFilter.find('.btn-title');
        var titles, text;

        titles = (userFilter || []).map(function (user) {
            return user.Title;
        });

        if (titles.length) {
            text = titles.length === users.length ?
                    i18next.t('explorer.issues.userFilter.all') :
                    titles.sort().join(', ');

            $btnTitle.parent().addClass('filter-selected');
            activeFilters.UserFilter = true;
        } else {
            text = i18next.t('explorer.issues.userFilter.noSelection');

            $btnTitle.parent().removeClass('filter-selected');
            activeFilters.UserFilter = false;
        }

        $btnTitle.html(text);
    }

    function initPeriodFilter() {
        if (periodFilter && (periodFilter.Start || periodFilter.End)) {
            $periodFilter.addClass('filter-selected');
            $periodFilter.text((periodFilter.Start != null ? i18next.t('datePicker.from') + ' ' + periodFilter.Start.toLocaleString() : '') + ' ' + (periodFilter.End != null ? i18next.t('datePicker.to') + ' ' + periodFilter.End.toLocaleString() : ''));
            activeFilters.PeriodFilter = true;
        } else {
            $periodFilter.removeClass('filter-selected');
            $periodFilter.text(i18next.t('explorer.issues.periodFilter.button'));
            activeFilters.PeriodFilter = false;
        }
    }

    function initHideNonResponsibleIssuesFilter() {
        $cbHideNonResponsibleIssues.prop('checked', hideNonResponsibleIssues);
        activeFilters.HideNonResponsibleIssues = hideNonResponsibleIssues;
    }

    function initTeamFilter() {
        var $btnTitle = $teamFilter.find('.btn-title');
        var titles, text;

        titles = (teamFilter || []).map(function (team) {
            return team.Title;
        });

        if (titles.length) {
            text = titles.length === teams.length ?
                    i18next.t('explorer.issues.teamFilter.all') :
                    titles.sort().join(', ');

            $btnTitle.parent().addClass('filter-selected');
            activeFilters.TeamFilter = true;
        } else {
            text = i18next.t('explorer.issues.teamFilter.noSelection');

            $btnTitle.parent().removeClass('filter-selected');
            activeFilters.TeamFilter = false;
        }

        $btnTitle.html(text);
    }

    function resetFilters() {
        $resetFilters.removeClass('active');
        activeFilters = {
            UserFilter: false,
            TeamFilter: false,
            HideNonResponsibleIssues: false,
            PeriodFilter: false
        };

        periodFilter = {};
        userFilter = [];
        teamFilter = [];
        hideNonResponsibleIssues = false;
    }

    function onResetFilters() {
        resetFilters();
        initFilters();
        show();
    }

    function onShowUserFilter() {
        var options = {
            title: i18next.t('explorer.issues.userFilter.title'),
            onApply: onSelectUserFilter,
            checkedEntities: userFilter.map(function (user) { return user.OID; }),
            treeOptions: {
                schema: { id: 'OID', text: 'Fullname' },
                opened: true,
                checkbox: { fullrow: true },
                createContentHtml: function() {
                    var html;

                    html = this.createCheckboxHtml();
                    html += this.createTextHtml();

                    if (this.entity && this.entity.IsLocked) {
                        html += '<img src="./img/locked.svg" class="user-locked">';
                    }

                    return html;
                }
            }
        };

        TreePicker.Show(users, options);
    }

    function onShowTeamFilter() {
        var options = {
            title: i18next.t('explorer.issues.teamFilter.title'),
            onApply: onSelectTeamFilter,
            checkedEntities: teamFilter.map(function (team) { return team.OID; }),
            treeOptions: {
                schema: { id: 'OID', children: 'Children', text: 'Title', color: 'Color' },
                opened: true,
                checkbox: {
                    fullrow: true,
                    disabled: userCanAssignAllTeamsAndUsers ? null : function (node) {
                        if (!selectableTeams || !node || !node.entity) {
                            return false;
                        }

                        var team = node.entity;

                        return selectableTeams.indexOf(team.OID) === -1;
                    }
                },
                filter: userCanAssignAllTeamsAndUsers ? null : function (team) {
                    if (!visibleTeams || !team) {
                        return false;
                    }

                    return visibleTeams.indexOf(team.OID) > -1;
                }
            }
        };

        TreePicker.Show(RootTeam, options);
    }

    function onSelectUserFilter(selectedUsers) {
        userFilter = (selectedUsers || []).map(function (oid) {
            return Tools.getFirst(users, oid, 'OID');
        });

        show();
    }

    function onSelectTeamFilter(selectedTeams) {
        teamFilter = (selectedTeams || []).map(function (oid) {
            return Tools.getFirst(teams, oid, 'OID');
        });

        show();
    }

    function resizeTableHeaderControls() {
        var $tableControl = $explorerDashboard.find('.table-control');
        var remainingWidth = $tableControl.width() - $resetFilters.outerWidth(true);
        var resizableControls = [];

        $.each($tableControl.children().eq(0).children(), function (_, control) {
            var $control = $(control);
            var controlWidth;

            if ($control.hasClass('resizable')) {
                controlWidth = $control.width() + 1;
                remainingWidth -= $control.outerWidth(true) - controlWidth + 2;

                resizableControls.push([ $control, controlWidth ]);
            } else {
                remainingWidth -= $control.outerWidth(true);
            }
        });

        resizableControls.sort(function (a, b) { return a[1] - b[1]; });

        Tools.reduce(resizableControls, function (control, acc) {
            var $control = control[0];
            var controlWidth = control[1];
            var remainingWidth = acc[0];
            var controlCount = acc[1];
            var divider = Math.floor(remainingWidth / controlCount);

            if (controlWidth <= divider) {
                remainingWidth -= controlWidth;
            } else {
                $control.css('max-width', divider);
                remainingWidth -= divider;
            }

            return [ remainingWidth, controlCount - 1 ];
        }, [ remainingWidth, resizableControls.length ]);
    }

    function onTogglePeriodFilter(event) {
        var $this = $(this);

        if ($this.hasClass('active')) {
            DatePicker.Close();
            return;
        }

        $this.addClass('active');

        DatePicker.Show(this, {
            mode: 2,
            withTime: true,
            noDateAllowed: true,
            offsetY: 28,
            closeElement: 'body',
            deactivatable: true,
            titleElement: $this,
            dateTypes: [ Enums.DatePickerTypes.Creation, Enums.DatePickerTypes.Modification, Enums.DatePickerTypes.Deadline ],
            selectedType: Tools.contains([ Enums.DatePickerTypes.Creation, Enums.DatePickerTypes.Modification, Enums.DatePickerTypes.Deadline ], periodFilter.CurrentType) ?
                    periodFilter.CurrentType :
                    Enums.DatePickerTypes.Modification,
            beforeShow: function () {
                DatePicker.SetDate(periodFilter.Start, periodFilter.End);
            },
            end: function (filter) {
                periodFilter = filter;

                if (periodFilter && (periodFilter.Start || periodFilter.End)) {
                    if (periodFilter.Start) {
                        periodFilter.Start = new Date(periodFilter.Start.setSeconds(0));
                    }

                    if (periodFilter.End) {
                        periodFilter.End = new Date(periodFilter.End.setSeconds(59));
                    }

                    $periodFilter.addClass('items-selected');
                } else {
                    $periodFilter.removeClass('items-selected');
                }

                if (periodFilter && (periodFilter.Start || periodFilter.End)) {
                    if (periodFilter.CurrentType === Enums.DatePickerTypes.Creation) {
                        periodFilter.CreationPeriod = {
                            PeriodStart: periodFilter.Start ? Tools.dateTime.toGMTString(periodFilter.Start) : null,
                            PeriodEnd: periodFilter.End ? Tools.dateTime.toGMTString(periodFilter.End) : null
                        };
                    } else if (periodFilter.CurrentType === Enums.DatePickerTypes.Modification) {
                        periodFilter.ModificationPeriod = {
                            PeriodStart: periodFilter.Start ? Tools.dateTime.toGMTString(periodFilter.Start) : null,
                            PeriodEnd: periodFilter.End ? Tools.dateTime.toGMTString(periodFilter.End) : null
                        };
                    } else if (periodFilter.CurrentType === Enums.DatePickerTypes.Deadline) {
                        periodFilter.DeadlinePeriod = {
                            PeriodStart: periodFilter.Start ? Tools.dateTime.toGMTString(periodFilter.Start) : null,
                            PeriodEnd: periodFilter.End ? Tools.dateTime.toGMTString(periodFilter.End) : null
                        };
                    }
                }

                DatePicker.Close();
                show();
            },
            onDatePickerClose: function () {
                $this.removeClass('active');
            }
        });

        event.stopPropagation();
        return false;
    }

    function onToggleHideNonResponsibleIssues() {
        hideNonResponsibleIssues = !hideNonResponsibleIssues;

        show();
    }

    function renderProcessingStatusChart(processingStatusGroupings) {
        var options = {
            series: [],
            seriesContext: [],
            labels: [],
            colors: []
        };

        processingStatusGroupings.forEach(function (grouping) {
            options.series.push(grouping.Count);

            /**
             * @type {ApexSeriesContext}
             */
            var context;
            switch (grouping.ProcessingStatus) {
                case Enums.IssueProcessingStatus.Warning:
                    context = {
                        Identifier: grouping.ProcessingStatus,
                        Title: i18next.t('explorer.dashboard.todayDue'),
                        Color: '#ffe300'
                    };
                    break;
                case Enums.IssueProcessingStatus.Overdue:
                    context = {
                        Identifier: grouping.ProcessingStatus,
                        Title: i18next.t('explorer.dashboard.overdue'),
                        Color: '#f00'
                    };
                    break;
                default:
                    context = {
                        Identifier: grouping.ProcessingStatus,
                        Title: i18next.t('explorer.dashboard.notYetDue'),
                        Color: '#91d700'
                    };
                    break;
            }

            options.seriesContext.push(context);
            options.labels.push(context.Title);
            options.colors.push(context.Color);
        });

        _processingStatusChart = Tools.charts.createChart(Tools.charts.chartType.Pie, {
            element: document.querySelector('#explorer-dashboard-processing-status-chart'),
            chartTitle: i18next.t('explorer.dashboard.maturity'),
            labels: options.labels,
            labelFontColors: options.labelFontColors,
            series: options.series,
            xAxisCategories: options.seriesContext,
            colors: options.colors,
            showLegend: true,
            onDataPointClick: function (event, chartContext, dataPointContext) {
                if (!event) {
                    return;
                }

                event.stopImmediatePropagation();

                /**
                 * @type {ApexSeriesContext}
                 */
                var context = chartContext.w.config.xAxis.categories[dataPointContext.dataPointIndex];

                if (!context) {
                    return;
                }

                onChartDataPointClick({ filter: { processingStatus: [context.Identifier] } });
            }
        });

        _processingStatusChart.render();
    }

    function renderStatusChart(statusGroupings) {
        var options = {
            series: [],
            seriesContext: [],
            labels: [],
            labelFontColors: [],
            colors: []
        };

        statusGroupings.forEach(function (grouping) {
            var context;

            if (grouping.StatusOID) {
                var status = Properties[grouping.StatusOID];

                context = {
                    Identifier: grouping.StatusOID,
                    Color: status ? status.Color : null,
                    Title: status ? Tools.unescapeHtml(status.Title) : i18next.t('misc.unknown')
                };
            } else {
                context = {
                    Identifier: Tools.GetEmptyGuid(),
                    Color: null,
                    Title: i18next.t('explorer.dashboard.noStatus')
                };
            }

            options.series.push(grouping.Count);
            options.seriesContext.push(context);
            options.labels.push(context.Title ? Tools.unescapeHtml(context.Title) : null);
            options.labelFontColors.push(context.Color ? new Color(context.Color).getContrastTextColor() : '#333');
            options.colors.push(context.Color || '#fff');
        });

        _statusChart = Tools.charts.createChart(Tools.charts.chartType.Pie, {
            element: document.querySelector('#explorer-dashboard-status-chart'),
            chartTitle: i18next.t('explorer.dashboard.status'),
            labels: options.labels,
            labelFontColors: options.labelFontColors,
            series: options.series,
            xAxisCategories: options.seriesContext,
            colors: options.colors,
            showLegend: true,
            onDataPointClick: function (event, chartContext, dataPointContext) {
                if (!event) {
                    return;
                }

                event.stopImmediatePropagation();

                /**
                 * @type {ApexSeriesContext}
                 */
                var context = chartContext.w.config.xAxis.categories[dataPointContext.dataPointIndex];

                if (!context || context.Identifier === Tools.GetEmptyGuid()) {
                    return;
                }

                onChartDataPointClick({ filter: { status: [context.Identifier] } });
            }
        });

        _statusChart.render();
    }

    function onChartDataPointClick(issueFilter) {
        if (!issueFilter) {
            return;
        }

        var options = {
            filter: {
                Users: userFilter,
                Teams: teamFilter,
                PeriodFilter: periodFilter
            }
        };

        var menuItemId = Enums.CustomMenuItemSystemID.IssueReport;

        if (!Explorer.IsMenuItemAvailable(menuItemId)) {
            return;
        }

        var menuItem = Explorer.GetMenuItemById(menuItemId);

        if (!menuItem) {
            return;
        }

        Explorer.ShowTab(menuItem.OID, Object.assign(options, issueFilter));
    }

    function getChartUserLabel(userOID) {
        return Users.hasOwnProperty(userOID) ?
            Tools.unescapeHtml(Users[userOID].Title) :
            userOID === Tools.GetEmptyGuid() ? i18next.t('explorer.dashboard.noResponsibilities') : null;
    }

    function getChartTeamLabel(teamOID) {
        return Teams.hasOwnProperty(teamOID) ?
            Tools.unescapeHtml(Teams[teamOID].Title) :
            teamOID === Tools.GetEmptyGuid() ? i18next.t('explorer.dashboard.noResponsibilities') : null;
    }

    function renderUserProcessingStatusBarChart(processingStatusIssueCountByUsers) {
        var usersInChart = getUsersForChart();
        var series = [];

        for (var processingStatus in processingStatusIssueCountByUsers) {
            var processingStatusUsers = processingStatusIssueCountByUsers[processingStatus];

            var serie = {};
            serie.id = processingStatus;

            switch (parseInt(processingStatus, 10)) {
                case Enums.IssueProcessingStatus.Warning:
                    serie.name = i18next.t('explorer.dashboard.todayDue');
                    serie.color = '#ffe300';
                    break;
                case Enums.IssueProcessingStatus.Overdue:
                    serie.name = i18next.t('explorer.dashboard.overdue');
                    serie.color = '#f00';
                    break;
                default:
                    serie.name = i18next.t('explorer.dashboard.notYetDue');
                    serie.color = '#91d700';
                    break;
            }

            serie.data = [];

            for (var i = 0, len = usersInChart.length; i < len; i++) {
                var userOID = usersInChart[i].OID;
                var userTitle = getChartUserLabel(userOID);

                if (userTitle) {
                    serie.data.push({
                        x: userTitle,
                        y: processingStatusUsers[userOID] || 0,
                        UserOID: userOID,
                        ProcessingStatus: processingStatus
                    });
                }
            }

            series.push(serie);
        }

        series.sort(Tools.SortByCategoryName);

        if (!series.length) {
            var seriesData = [];

            for (var i = 0, len = usersInChart.length; i < len; i++) {
                var userOID = usersInChart[i].OID;
                var userTitle = getChartUserLabel(userOID);

                if (userTitle) {
                    seriesData.push({
                        x: userTitle,
                        y: 0,
                        UserOID: userOID
                    });
                }
            }

            for (var i = 0, len = usersInChart.length; i < len; i++) {
                series.push({
                    data: seriesData,
                    name: i18next.t('explorer.dashboard.noStatus')
                });
            }
        }

        var seriesContext = usersInChart.map(function (u) { return Tools.unescapeHtml(u.Title); });

        _userProcessingStatusIssueCount = Tools.charts.createChart(Tools.charts.chartType.StackedBar, {
            element: document.getElementById('explorer-dashboard-processing-status-user-bar-chart'),
            chartTitle: i18next.t('explorer.dashboard.maturityUsers'),
            series: series,
            xAxisCategories: seriesContext,
            showLegend: processingStatusIssueCountByUsers != null,
            onDataPointClick: function (event, chartContext, dataPointContext) {
                event.stopImmediatePropagation();

                var series = chartContext.w.config.series[dataPointContext.seriesIndex];

                if (!series) {
                    return;
                }

                var context = series.data[dataPointContext.dataPointIndex];

                if (!context || context.UserOID === Tools.GetEmptyGuid() || !context.ProcessingStatus) {
                    return;
                }

                onChartDataPointClick({
                    filter: {
                        Users: [Users[context.UserOID]],
                        processingStatus: [parseInt(context.ProcessingStatus, 10)]
                    }
                });
            }
        });

        _userProcessingStatusIssueCount.render();
    }

    function renderTeamsProcessingStatusBarChart(processingStatusIssueCountByTeams) {
        var teamsInChart = getTeamsForChart();
        var series = [];

        for (var processingStatus in processingStatusIssueCountByTeams) {
            var processingStatusTeams = processingStatusIssueCountByTeams[processingStatus];

            var singleSeries = {};
            singleSeries.id = processingStatus;

            switch (parseInt(processingStatus, 10)) {
                case Enums.IssueProcessingStatus.Warning:
                    singleSeries.name = i18next.t('explorer.dashboard.todayDue');
                    singleSeries.color = '#ffe300';
                    break;
                case Enums.IssueProcessingStatus.Overdue:
                    singleSeries.name = i18next.t('explorer.dashboard.overdue');
                    singleSeries.color = '#f00';
                    break;
                default:
                    singleSeries.name = i18next.t('explorer.dashboard.notYetDue');
                    singleSeries.color = '#91d700';
                    break;
            }

            singleSeries.data = [];

            for (var i = 0, len = teamsInChart.length; i < len; i++) {
                var teamOID = teamsInChart[i].OID;
                var teamTitle = getChartTeamLabel(teamOID);

                if (teamTitle) {
                    singleSeries.data.push({
                        x: teamTitle,
                        y: processingStatusTeams[teamOID] || 0,
                        TeamOID: teamOID,
                        ProcessingStatus: processingStatus
                    });
                }
            }

            series.push(singleSeries);
        }

        series.sort(Tools.SortByCategoryName);

        if (!series.length) {
            var seriesData = [];

            for (var i = 0, len = teamsInChart.length; i < len; i++) {
                var teamOID = teamsInChart[i].OID;
                var teamTitle = getChartTeamLabel(teamOID);

                if (teamTitle) {
                    seriesData.push({
                        x: teamTitle,
                        y: 0,
                        TeamOID: teamOID
                    });
                }
            }

            for (var i = 0, len = teamsInChart.length; i < len; i++) {
                series.push({
                    data: seriesData,
                    name: i18next.t('explorer.dashboard.noStatus')
                });
            }
        }

        var seriesContext = teamsInChart.map(function (t) { return Tools.unescapeHtml(t.Title); });

        _teamsProcessingStatusIssueCount = Tools.charts.createChart(Tools.charts.chartType.StackedBar, {
            element: document.getElementById('explorer-dashboard-processing-status-teams-bar-chart'),
            chartTitle: i18next.t('explorer.dashboard.maturityTeams'),
            series: series,
            xAxisCategories: seriesContext,
            showLegend: processingStatusIssueCountByTeams != null,
            onDataPointClick: function (event, chartContext, dataPointContext) {
                event.stopImmediatePropagation();

                var series = chartContext.w.config.series[dataPointContext.seriesIndex];

                if (!series) {
                    return;
                }

                var context = series.data[dataPointContext.dataPointIndex];

                if (!context || context.TeamOID === Tools.GetEmptyGuid() || !context.ProcessingStatus) {
                    return;
                }

                onChartDataPointClick({
                    filter: {
                        Teams: [Teams[context.TeamOID]],
                        processingStatus: [parseInt(context.ProcessingStatus, 10)]
                    }
                });
            }
        });

        _teamsProcessingStatusIssueCount.render();
    }

    function renderUsersChart(userIssueCounters) {
        var usersInChart = getUsersForChart();
        var series = [];

        for (var stateOID in userIssueCounters) {
            var state = Properties[stateOID] || {};
            var stateUsers = userIssueCounters[stateOID];

            var serie = {};
            serie.name = stateOID === Tools.GetEmptyGuid() ?
                    i18next.t('explorer.dashboard.noStatus') :
                    (state.Title || i18next.t('misc.unknown'));

            serie.color = state.Color || '#B2B2B2';
            serie.data = [];

            for (var i = 0, len = usersInChart.length; i < len; i++) {
                var userOID = usersInChart[i].OID;
                var userTitle = getChartUserLabel(userOID);

                if (userTitle) {
                    serie.data.push({
                        x: userTitle,
                        y: stateUsers[userOID] || 0,
                        UserOID: userOID,
                        StatusOID: stateOID
                    });
                }
            }

            series.push(serie);
        }

        series.sort(Tools.SortByCategoryName);

        if (!series.length) {
            var seriesData = [];

            for (var i = 0, len = usersInChart.length; i < len; i++) {
                var userOID = usersInChart[i].OID;
                var userTitle = getChartUserLabel(userOID);

                if (userTitle) {
                    seriesData.push({
                        x: userTitle,
                        y: stateUsers[userOID] || 0,
                        UserOID: userOID
                    });
                }
            }

            for (var i = 0, len = usersInChart.length; i < len; i++) {
                series.push({
                    data: seriesData,
                    name: i18next.t('explorer.dashboard.noStatus')
                });
            }
        }

        var seriesContext = usersInChart.map(function (u) { return Tools.unescapeHtml(u.Title); });

        _userChart = Tools.charts.createChart(Tools.charts.chartType.StackedBar, {
            element: document.getElementById('explorer-dashboard-user-chart'),
            chartTitle: i18next.t('explorer.dashboard.statusUsers'),
            series: series,
            xAxisCategories: seriesContext,
            showLegend: userIssueCounters != null,
            onDataPointClick: function (event, chartContext, dataPointContext) {
                event.stopImmediatePropagation();

                var series = chartContext.w.config.series[dataPointContext.seriesIndex];

                if (!series) {
                    return;
                }

                var context = series.data[dataPointContext.dataPointIndex];

                if (!context || context.UserOID === Tools.GetEmptyGuid() || !context.StatusOID) {
                    return;
                }

                onChartDataPointClick({
                    filter: {
                        status: [context.StatusOID],
                        Users: [Users[context.UserOID]]
                    }
                });
            }
        });

        _userChart.render();
    }

    function renderTeamsChart(teamIssueCounters) {
        var teamsInChart = getTeamsForChart();
        var series = [];

        for (var stateOID in teamIssueCounters) {
            var state = Properties[stateOID] || {};
            var stateTeams = teamIssueCounters[stateOID];

            var serie = {};
            serie.name = stateOID === Tools.GetEmptyGuid() ?
                    i18next.t('explorer.dashboard.noStatus') :
                    (state.Title || i18next.t('misc.unknown'));

            serie.color = state.Color || '#B2B2B2';
            serie.data = [];

            for (var i = 0, len = teamsInChart.length; i < len; i++) {
                var teamOID = teamsInChart[i].OID;
                var teamTitle = getChartTeamLabel(teamOID);

                if (teamTitle) {
                    serie.data.push({
                        x: teamTitle,
                        y: stateTeams[teamOID] || 0,
                        TeamOID: teamOID,
                        StatusOID: stateOID
                    });
                }
            }

            series.push(serie);
        }

        series.sort(Tools.SortByCategoryName);

        if (!series.length) {
            var seriesData = [];

            for (var i = 0, len = teamsInChart.length; i < len; i++) {
                var teamOID = teamsInChart[i].OID;
                var teamTitle = getChartTeamLabel(teamOID);

                if (teamTitle) {
                    serie.data.push({
                        x: teamTitle,
                        y: 0,
                        TeamOID: teamOID
                    });
                }
            }

            for (var i = 0, len = teamsInChart.length; i < len; i++) {
                series.push({
                    data: seriesData,
                    name: i18next.t('explorer.dashboard.noStatus')
                });
            }
        }

        var seriesContext = teamsInChart.map(function (t) { return Tools.unescapeHtml(t.Title); });

        _teamsChart = Tools.charts.createChart(Tools.charts.chartType.StackedBar, {
            element: document.getElementById('explorer-dashboard-teams-chart'),
            chartTitle: i18next.t('explorer.dashboard.statusTeams'),
            series: series,
            xAxisCategories: seriesContext,
            showLegend: teamIssueCounters != null,
            onDataPointClick: function (event, chartContext, dataPointContext) {
                event.stopImmediatePropagation();

                var series = chartContext.w.config.series[dataPointContext.seriesIndex];

                if (!series) {
                    return;
                }

                var context = series.data[dataPointContext.dataPointIndex];

                if (!context || context.TeamOID === Tools.GetEmptyGuid()|| !context.StatusOID) {
                    return;
                }

                onChartDataPointClick({
                    filter: {
                        Teams: [Teams[context.TeamOID]],
                        status: [context.StatusOID]
                    }
                });
            }
        });

        _teamsChart.render();
    }

    function getUsersForChart() {
        if (userFilter.length) {
            return userFilter
                .map(function (user) { return { OID: user.OID, Title: user.Fullname }; })
                .sort(Tools.SortByTitle);
        }

        var usersForChart = users
            .map(function (user) { return { OID: user.OID, Title: user.Fullname }; })
            .sort(Tools.SortByTitle);

        usersForChart.push({ OID: Tools.GetEmptyGuid(), Title: i18next.t('explorer.dashboard.noResponsibilities') });

        return usersForChart;
    }

    function getTeamsForChart() {
        if (teamFilter.length) {
            return teamFilter
                .map(function (team) { return { OID: team.OID, Title: team.Title }; })
                .sort(Tools.SortByTitle);
        }

        var teamsForChart = teams
            .map(function (team) { return { OID: team.OID, Title: team.Title }; })
            .sort(Tools.SortByTitle);

        teamsForChart.push({ OID: Tools.GetEmptyGuid(), Title: i18next.t('explorer.dashboard.noResponsibilities') });

        return teamsForChart;
    }

    function resize() {
        disposeCharts();
        render();
        resizeTableHeaderControls();
    }

    function onShowIssueDisturbances() {
        var menuItemId = Enums.CustomMenuItemSystemID.DisturbancesFromRecordings;

        if (Explorer.IsMenuItemAvailable(menuItemId)) {
            var menuItem = Explorer.GetMenuItemById(menuItemId);

            if (!menuItem) {
                return;
            }

            Explorer.ShowTab(
                menuItem.OID,
                {
                    panel: 'IssueDisturbances',
                    filter: {
                        Users: userFilter,
                        Teams: teamFilter,
                        PeriodFilter: periodFilter
                    }
                }
            );
        }
    }

    function onShowRecorditemDisturbances() {
        var menuItemId = Enums.CustomMenuItemSystemID.DisturbancesFromRecordings;

        if (Explorer.IsMenuItemAvailable(menuItemId)) {
            var menuItem = Explorer.GetMenuItemById(menuItemId);

            if (!menuItem) {
                return;
            }

            Explorer.ShowTab(
                menuItem.OID,
                {
                    panel: 'RecorditemDisturbances'
                }
            );
        }
    }

    function onShowIssues() {
        var menuItemId = Enums.CustomMenuItemSystemID.IssueReport;

        if (Explorer.IsMenuItemAvailable(menuItemId)) {
            var menuItem = Explorer.GetMenuItemById(menuItemId);

            if (!menuItem) {
                return;
            }

            Explorer.ShowTab(
                menuItem.OID,
                {
                    filter: {
                        Users: userFilter,
                        Teams: teamFilter,
                        PeriodFilter: periodFilter
                    }
                }
            );
        }
    }

    function onChartButtonClick() {
        var $this = $(this);

        $this.addClass('hide');

        var chartName = $this.data('chart');

        switch (chartName) {
            case 'statusUsers':
                $('#explorer-dashboard-user-chart').parent().removeClass('hide');
                renderUsersChart(_result.IssueCountByUsers);
                break;
            case 'statusTeams':
                $('#explorer-dashboard-teams-chart').parent().removeClass('hide');
                renderTeamsChart(_result.IssueCountByTeams);
                break;
            case 'maturityUsers':
                $('#explorer-dashboard-processing-status-user-bar-chart').parent().removeClass('hide');
                renderUserProcessingStatusBarChart(_result.ProcessingStatusIssueCountByUsers);
                break;
            case 'maturityTeams':
                $('#explorer-dashboard-processing-status-teams-bar-chart').parent().removeClass('hide');
                renderTeamsProcessingStatusBarChart(_result.ProcessingStatusIssueCountByTeams);
                break;
        }

        Tools.UpdateOfficeUserSettings(getChartSettingName(chartName), true);
    }

    function onCloseChartClick() {
        var $chartContainer = $(this).parent();
        var chartName = $chartContainer.data('chart');

        $chartContainer.addClass('hide');

        $explorerDashboard.find('.explorer-dashboard-panel-clickable[data-chart="{0}"]'.format(chartName))
            .removeClass('hide');

        switch (chartName) {
            case 'statusUsers':
                if (_userChart) {
                    _userChart.destroy();
                    _userChart = null;
                }
                break;
            case 'statusTeams':
                if (_teamsChart) {
                    _teamsChart.destroy();
                    _teamsChart = null;
                }
                break;
            case 'maturityUsers':
                if (_userProcessingStatusIssueCount) {
                    _userProcessingStatusIssueCount.destroy();
                    _userProcessingStatusIssueCount = null;
                }
                break;
            case 'maturityTeams':
                if (_teamsProcessingStatusIssueCount) {
                    _teamsProcessingStatusIssueCount.destroy();
                    _teamsProcessingStatusIssueCount = null;
                }
                break;
        }

        Tools.UpdateOfficeUserSettings(getChartSettingName(chartName), false);
    }

    function getChartSettingName(chartName) {
        if (!chartName) {
            return null;
        }

        return 'Explorer-Dashboard-Show-' + chartName;
    }

    function disposeCharts() {
        if (_statusChart) {
            _statusChart.destroy();
            _statusChart = null;
        }

        if (_processingStatusChart) {
            _processingStatusChart.destroy();
            _processingStatusChart = null;
        }

        if (_userChart) {
            _userChart.destroy();
            _userChart = null;
        }

        if (_teamsChart) {
            _teamsChart.destroy();
            _teamsChart = null;
        }

        if (_userProcessingStatusIssueCount) {
            _userProcessingStatusIssueCount.destroy();
            _userProcessingStatusIssueCount = null;
        }

        if (_teamsProcessingStatusIssueCount) {
            _teamsProcessingStatusIssueCount.destroy();
            _teamsProcessingStatusIssueCount = null;
        }
    }

    function disposeTab() {
        disposeCharts();
        $explorerDashboard.empty();
    }

    Dashboard.Show = show;
    Dashboard.Resize = resize;
    Dashboard.ResetFilters = resetFilters;
    Dashboard.GetChartSettingName = getChartSettingName;
    Dashboard.Dispose = disposeTab;

    return (global.Dashboard = Dashboard);
})( window.Explorer || (window.Explorer = {}) );