(function ( global ) {
    var Explorer = {
        MenuItems: {}
    };

    var $explorer = $('#explorer');
    var $explorerMessagePanel = $('#explorer-message-panel');
    var $explorerTabMenu = $('#explorer-tab-menu');
    var $explorerMoreMenu = $('#explorer-tab-menu-more');
    var $explorerHeader = $('#locationheader .location');
    var $optionMenu = $('#explorer-tab-menu-options');
    var $withChildLocations = $optionMenu.find('.dropdown-item[data-option="withChildLocations"]');
    var $withDeactivatedElements = $optionMenu.find('.dropdown-item[data-option="withDeactivatedElements"]');
    var $changemodeTabMenu = $('#change-mode-tab-menu');

    var counterDeferreds;

    var availableMenuItems;

    var lastMatch, currentTab;

    function show(match) {
        if (!Tools.Sessions.hasActiveSession()) {
            return;
        }

        if (!CurrentEntity || !match) {
            CurrentEntity = DataManager.OrganizationUnitLoader.Data.Root;
        }

        if (CurrentEntity && !DataManager.OrganizationUnitLoader.Data.DataMap.hasOwnProperty(CurrentEntity.OID)) {
            CurrentEntity = null;
        }

        if (CurrentEntity) {
            if (!match || match.length < 2) {
                availableMenuItems = prepareMenuConfig(Explorer.MenuItems) || [];

                var firstAvailableTab = getFirstAvailableMenuItem();

                if (firstAvailableTab) {
                    match = Tools.ResolveUrlPattern(
                        'explorer/{0}/{1}'.format(firstAvailableTab.OID, CurrentEntity.OID),
                        '^explorer/{0}/{1}$'.format(firstAvailableTab.OID, CurrentEntity.OID)
                    ).args;
                }

                lastMatch = match || [];
            } else {
                lastMatch = match || [];

                var newLocationOID = match.length === 2 ? lastMatch[1] : lastMatch[2];

                if (CurrentEntity.OID !== newLocationOID && DataManager.OrganizationUnitLoader.Data.DataMap[newLocationOID]) {
                    CurrentEntity = DataManager.OrganizationUnitLoader.Data.DataMap[newLocationOID];
                    availableMenuItems = prepareMenuConfig(Explorer.MenuItems) || [];
                } else if (!availableMenuItems) {
                    availableMenuItems = prepareMenuConfig(Explorer.MenuItems) || [];
                }
            }

            User.Rights = ressources.users.getRightsAtLocation(CurrentEntity.OID);
        }

        if (Mode !== 'explorer') {
            Mode = 'explorer';
            content.reset();
            Center.Show();
        } else {
            Center.Select(CurrentEntity);
        }

        $changemodeTabMenu.addClass('hide');
        $explorer.removeClass('hide');

        if (currentTab == null) {
            $explorer.find('> div').addClass('hide');
        }

        $explorerTabMenu.removeClass('hide');

        $explorerHeader.html(
            '<span class="textf">{0} › </span><span class="text">{1}</span>'
                .format(
                    i18next.t('nav.current.title'),
                    i18next.t('explorer.title')));

        Explorer.Tabs.Generate(availableMenuItems);

        $explorerMoreMenu = $('#explorer-tab-menu-more');
        $optionMenu = $('#explorer-tab-menu-options');
        $withChildLocations = $optionMenu.find('.dropdown-item[data-option="withChildLocations"]');
        $withDeactivatedElements = $optionMenu.find('.dropdown-item[data-option="withDeactivatedElements"]');

        $explorerTabMenu.find('.tab-view-tab[data-tab-id], .dropdown-item[data-tab-id]').on('click.showTab', onShowTab);
        $explorerMoreMenu.off('click.showMoreMenu').on('click.showMoreMenu', onShowMoreMenu);
        $optionMenu.off('click.showOptionMenu').on('click.showOptionMenu', onShowOptionMenu);
        $optionMenu.find('.dropdown-item').on('click.showOptionMenu', onSelectOption);

        var $navigation = $('#nav');

        $navigation.find('.active').removeClass('active');
        $navigation.find('a[href="#explorer"]').addClass('active');

        var newTabOID = lastMatch ? lastMatch[1] : null;

        currentTab = Tools.getFirst(availableMenuItems, newTabOID, 'OID');

        var withDeactivatedElements = $withDeactivatedElements.hasClass('dropdown-checkbox-item-checked');

        if (!CurrentEntity ||
            !(CurrentEntity.Type === Enums.elementType.Root ||
                CurrentEntity.Type === Enums.elementType.Location) ||
            !CurrentEntity.Enabled &&
            !withDeactivatedElements) {
            CurrentEntity = DataManager.OrganizationUnitLoader.Data.Root;
        }

        changeSelectedTab(match);
    }

    function initExplorer() {
        var isCurrentTabAvailable = currentTab ? ressources.users.hasOfficeUIRight(currentTab.ID) : false;

        if (!currentTab ||
            !isCurrentTabAvailable ||
            !Tools.contains(availableMenuItems, currentTab.OID, 'OID')) {
            currentTab = getFirstAvailableMenuItem();

            if (!currentTab) {
                return {
                    CurrentTabIsAvailable: false
                };
            }

            isCurrentTabAvailable = false;
        }

        if (currentTab.ActionType !== Enums.CustomMenuItemActionType.ShowIssueReport) {
            updateTabCounters().then(resizeTabMenu);
        }

        setCurrentTabAsActive();

        return {
            CurrentTabIsAvailable: isCurrentTabAvailable,
            NewTab: currentTab
        };
    }

    function prepareMenuConfig(menuItems) {
        if (!CurrentEntity || !menuItems) {
            return null;
        }

        if (typeof menuItems === 'object') {
            menuItems = Object.values(menuItems);
        }

        if (!(menuItems instanceof Array) || menuItems.length === 0) {
            return [];
        }

        var initializedConfig = [];
        var enabledMenuItems = getAvailableMenuItems(CurrentEntity.OID);

        var updateValuesCounter = function () {
            var deferred = new $.Deferred();

            if (currentTab.OID === this.OID) {
                return deferred.resolve().promise();
            }

            return Explorer.Values.UpdateTabCounter(this);
        };

        var updateIssueCounter = function () {
            var deferred = new $.Deferred();

            if (currentTab.OID === this.OID) {
                return deferred.resolve().promise();
            }

            return Explorer.Issues.UpdateTabCounter(this);
        };

        var updateDisturbancesCounter = function () {
            var deferred = new $.Deferred();

            if (currentTab.OID === this.OID) {
                return deferred.resolve().promise();
            }

            return Explorer.Disturbances.UpdateTabCounter(this);
        };

        var updateSchedulingsCounter = function () {
            var deferred = new $.Deferred();

            if (currentTab.OID === this.OID) {
                return deferred.resolve().promise();
            }

            Explorer.Scheduling.ResetSchedulingCache();

            return Explorer.Scheduling.UpdateTabCounter(this);
        };

        for (var i = 0; i < menuItems.length; i++) {
            var mItem = menuItems[i];

            if (!showMenuItemInOffice(mItem, enabledMenuItems)) {
                continue;
            }

            mItem.Title = !!mItem.Title ?
                Tools.escapeHtml(mItem.Title) :
                CustomMenuItems.GetItemTitle(mItem).Title;

            mItem.Icon = CustomMenuItems.GetOfficeIconFilePath(
                mItem.ActionType,
                mItem.OfficeIconOID
            );

            mItem.Position = Tools.getFirst(enabledMenuItems, mItem.ID, 'ID').Position;

            if (mItem.ActionType === Enums.CustomMenuItemActionType.ShowCheckpoints) {
                mItem.FnUpdateCounter = updateValuesCounter.bind(mItem);
            } else if (mItem.ActionType === Enums.CustomMenuItemActionType.ShowIssueReport) {
                mItem.FnUpdateCounter = updateIssueCounter.bind(mItem)
            } else if (mItem.ActionType === Enums.CustomMenuItemActionType.ShowDisturbancesFromRecordings) {
                mItem.FnUpdateCounter = updateDisturbancesCounter.bind(mItem);
            } else if (mItem.ActionType === Enums.CustomMenuItemActionType.ShowSchedulingOverview) {
                mItem.FnUpdateCounter = updateSchedulingsCounter.bind(mItem);
            }

            initializedConfig.push(mItem);
        }

        initializedConfig = Tools.sort(initializedConfig, 'Position');

        return initializedConfig;
    }

    function showMenuItemInOffice(menuItem, enabledMenuItems) {
        if (!enabledMenuItems) {
            return false;
        }

        if (enabledMenuItems instanceof Array &&
            !Tools.contains(enabledMenuItems, menuItem.ID, 'ID')) {
            return false;
        }

        if (menuItem.ModificationType === Enums.ModificationType.Deleted) {
            return false;
        }

        if (!menuItem.IsAvailableInOffice) {
            return false;
        }

        if (menuItem.ActionType === Enums.CustomMenuItemActionType.ShowCalendar ||
            menuItem.ActionType === Enums.CustomMenuItemActionType.OpenSchedulingWindow ||
            menuItem.ActionType === Enums.CustomMenuItemActionType.OpenFormWindow ||
            menuItem.ActionType === Enums.CustomMenuItemActionType.ShowFloorPlan ||
            menuItem.ActionType === Enums.CustomMenuItemActionType.ShowBluetoothSettings ||
            menuItem.ActionType === Enums.CustomMenuItemActionType.Link ||
            menuItem.ActionType === Enums.CustomMenuItemActionType.ShowIndividualData ||
            menuItem.ActionType === Enums.CustomMenuItemActionType.ShowScaleSettings) {
            return false;
        }

        if (menuItem.ActionType === Enums.CustomMenuItemActionType.ShowIssueReport &&
            menuItem.Configuration && menuItem.Configuration.PoolFormIssues) {
            return false;
        }

        return true;
    }

    function getAvailableMenuItems(locationOID) {
        var roles = ressources.users.getRolesAtLocation(User.OID, locationOID);

        if (!(roles || []).length) {
            return null;
        }

        var uiItems = [];

        function walk(uiItem) {
            if (uiItem.IsEnabled) {
                if (!Tools.contains(uiItems, uiItem.ID, 'ID')) {
                    uiItems.push({ ID: uiItem.ID, Position: uiItem.Position });
                }
            }

            (uiItem.Children || []).forEach(walk);
        }

        (roles || []).forEach(function(identifier) {
            var role = Roles[identifier];

            if (!role) {
                return;
            }

            (role.OfficeUIRights || []).forEach(walk);
        });

        return uiItems.length ? uiItems : null;
    }

    function getFirstAvailableMenuItem() {
        if (!availableMenuItems) {
            availableMenuItems = prepareMenuConfig(Explorer.MenuItems) || [];
        }

        if (!availableMenuItems.length) {
            return null;
        }

        return availableMenuItems[0];
    }

    function showTab(tabIdentifier, options) {
        if (!CurrentEntity || !tabIdentifier) {
            $('#nav .active').removeClass('active');
            $explorerMessagePanel.text(i18next.t('explorer.noTabAvailable'));
            $explorerMessagePanel.removeClass('hide');
            return;
        }

        disposeCurrentTab();

        if (tabIdentifier !== 'more') {
            currentTab = Tools.getFirst(availableMenuItems, tabIdentifier, 'OID');
            setCurrentTabAsActive();
        }

        if (!currentTab) {
            return;
        }

        $explorerMessagePanel.addClass('hide');

        history.pushState(
            null,
            navigator.title,
            '#explorer/{0}/{1}'.format(currentTab.OID, CurrentEntity.OID)
        );

        changeSelectedTab(null, options);
    }

    function changeSelectedTab(match, options) {
        if (!CurrentEntity) {
            CurrentEntity = DataManager.OrganizationUnitLoader.Data.Root;
        }

        if (!currentTab) {
            var menuItemId = $('#explorer-tab-menu .tab-view-tab:not(.hide)').first().data('tab-id');

            currentTab = Tools.getFirst(availableMenuItems, menuItemId, 'OID');

            if (!currentTab) {
                initExplorer(match);

                $('#explorer .st-vb').addClass('hide');

                var $navigation = $('#nav');
                $navigation.find('.active').removeClass('active');
                $navigation.find('a[href="#explorer"]').addClass('active');

                $explorerMessagePanel.text(i18next.t('explorer.noTabAvailable'));
                $explorerMessagePanel.removeClass('hide');

                return;
            }
        }

        if (!CurrentEntity) {
            return;
        }

        match = match || Tools.ResolveUrlPattern(
            'explorer/{0}/{1}'.format(currentTab.OID, CurrentEntity.OID),
            '^explorer/{0}/{1}$'.format(currentTab.OID, CurrentEntity.OID)
        ).args;

        handleSwitchTab(match, options);
    }

    function handleSwitchTab(match, options) {
        switch (currentTab.ActionType) {
            case Enums.CustomMenuItemActionType.ShowDashboardOffice:
                Explorer.Dashboard.Show(match, options);
                break;
            case Enums.CustomMenuItemActionType.ShowInformation:
                Explorer.Info.Show(match, options);
                break;
            case Enums.CustomMenuItemActionType.ShowCheckpoints:
                Explorer.Values.Show(match, options);
                break;
            case Enums.CustomMenuItemActionType.ShowNews:
                Explorer.News.Show(match, options);
                break;
            case Enums.CustomMenuItemActionType.ShowIssueReport:
                Explorer.Issues.Show(match, options);
                break;
            case Enums.CustomMenuItemActionType.ShowDisturbancesFromRecordings:
                Explorer.Disturbances.Show(match, options);
                break;
            case Enums.CustomMenuItemActionType.ShowSchedulingOverview:
                Explorer.Scheduling.Show(match, options);
                break;
            case Enums.CustomMenuItemActionType.ShowRecordedFiles:
                Explorer.Files.Show(match, options);
                break;
            case Enums.CustomMenuItemActionType.ShowIssueArchive:
                Explorer.Archive.Show(match, options);
                break;
            default:
                Explorer.Dashboard.Show(match, options);
                break;
        }
    }

    function disposeCurrentTab() {
        if (!currentTab) {
            return;
        }

        disposeTab(currentTab.ActionType);
    }

    function disposeTab(previousActionType) {
        switch (previousActionType) {
            case Enums.CustomMenuItemActionType.ShowDashboardOffice:
                Explorer.Dashboard.Dispose();
                break;
            case Enums.CustomMenuItemActionType.ShowInformation:
                Explorer.Info.Dispose();
                break;
            case Enums.CustomMenuItemActionType.ShowCheckpoints:
                Explorer.Values.Dispose();
                break;
            case Enums.CustomMenuItemActionType.ShowNews:
                Explorer.News.Dispose();
                break;
            case Enums.CustomMenuItemActionType.ShowIssueReport:
                Explorer.Issues.Dispose();
                break;
            case Enums.CustomMenuItemActionType.ShowDisturbancesFromRecordings:
                Explorer.Disturbances.Dispose();
                break;
            case Enums.CustomMenuItemActionType.ShowSchedulingOverview:
                Explorer.Scheduling.Dispose();
                break;
            case Enums.CustomMenuItemActionType.ShowRecordedFiles:
                Explorer.Files.Dispose();
                break;
            case Enums.CustomMenuItemActionType.ShowIssueArchive:
                Explorer.Archive.Dispose();
                break;
        }
    }

    function updateTabCounters() {
        var counterDeferred = new $.Deferred();

        if (availableMenuItems.length === 0) {
            return counterDeferred.resolve().promise();
        }

        abortCounterRequests();

        counterDeferreds = [];

        for (var cnt = 0, len = availableMenuItems.length; cnt < len; cnt++) {
            var menuItem = availableMenuItems[cnt];

            if (menuItem.FnUpdateCounter instanceof Function) {
                counterDeferreds.push(menuItem.FnUpdateCounter((currentTab || {}).OID == menuItem.OID));
            }
        }

        $.when.apply(this, counterDeferreds).then(counterDeferred.resolve);

        return counterDeferred.promise();
    }

    function setCurrentTabAsActive() {
        if (!currentTab) {
            return;
        }

        $explorerTabMenu.find('.tab-view-tab[data-tab-id="'+ currentTab.OID +'"]')
            .addClass('active')
            .siblings('.active')
            .removeClass('active');

        $explorerMoreMenu.find('.dropdown-item[data-tab-id="'+ currentTab.OID +'"]')
            .addClass('active')
            .siblings('.active')
            .removeClass('active');
    }

    function resize() {
        if (!currentTab) {
            return;
        }

        switch (currentTab.ActionType) {
            case Enums.CustomMenuItemActionType.ShowDashboardOffice:
                Explorer.Dashboard.Resize();
                break;
            case Enums.CustomMenuItemActionType.ShowNews:
                Explorer.News.Resize();
                break;
            case Enums.CustomMenuItemActionType.ShowIssueReport:
                Explorer.Issues.Resize();
                break;
            case Enums.CustomMenuItemActionType.ShowDisturbancesFromRecordings:
                Explorer.Disturbances.Resize();
                break;
            case Enums.CustomMenuItemActionType.ShowSchedulingOverview:
                Explorer.Scheduling.Resize();
                break;
            case Enums.CustomMenuItemActionType.ShowRecordedFiles:
                Explorer.Files.Resize();
                break;
            case Enums.CustomMenuItemActionType.ShowIssueArchive:
                Explorer.Archive.Resize();
                break;
            default:
                Explorer.Dashboard.Resize();
                break;
        }

        resizeTabMenu();
    }

    function resizeTabMenu() {
        var $optionsMenu = $('#explorer-tab-menu-options');
        var $tabItems = $explorerTabMenu.children('.tab-view-tab').not('.disabled').not($explorerMoreMenu);
        var maxWidth = $explorerTabMenu.width() - $optionsMenu.outerWidth(false);
        var tabItems = [];
        var tabItemsWidth = 0;
        var remainingWidth;

        $tabItems.removeClass('hide');

        $explorerMoreMenu = $('#explorer-tab-menu-more');

        $.each($tabItems, function (_idx, elem) {
            var $elem = $(elem);
            var width = $elem.outerWidth(false) +
                parseInt($elem.css('padding-left'), 10) +
                parseInt($elem.css('padding-right'), 10);

            tabItems.push({ elem: $elem, width: width });

            tabItemsWidth += width;
        });

        if (tabItemsWidth < maxWidth) {
            $explorerMoreMenu.addClass('hide');
            $tabItems.removeClass('hide');
        } else {
            remainingWidth = maxWidth - $explorerMoreMenu.outerWidth(false);

            $.each(tabItems, function (_idx, item) {
                var tabId = item.elem.data('tab-id');
                var $moreElem = $explorerMoreMenu.find('.dropdown-item[data-tab-id="' + tabId + '"]');

                remainingWidth -= item.width;

                if (remainingWidth <= 0) {
                    item.elem.addClass('hide');
                    $moreElem.removeClass('hide');
                } else {
                    item.elem.removeClass('hide');
                    $moreElem.addClass('hide');
                }
            });

            $explorerMoreMenu.removeClass('active, hide');
        }

        var moreChildIsActive = $explorerMoreMenu.find('.dropdown-item.active:not(.hide)').length > 0;

        $explorerMoreMenu.toggleClass('active', moreChildIsActive);
    }

    function getCurrentTab() {
        return currentTab;
    }

    function onShowTab(evt) {
        DatePicker.Close();
        evt.stopPropagation();

        var $tabItem = $(this);
        var tabId = $tabItem.data('tab-id');

        showTab(tabId);
    }

    function onShowMoreMenu(evt) {
        DatePicker.Close();
        $optionMenu.removeClass('active');
        $optionMenu.children('.dropdown-menu').removeClass('open');

        if (this.tagName.toLowerCase() === 'body') {
            $explorerMoreMenu.children('.dropdown-menu').removeClass('open');
        } else {
            $explorerMoreMenu.children('.dropdown-menu').toggleClass('open');
        }

        var isOpen = $explorerMoreMenu.children('.dropdown-menu').hasClass('open');

        $explorerMoreMenu.toggleClass('active', isOpen);

        if (isOpen) {
            evt.stopPropagation();
            $('body').on('click.toggleExplorerMoreMenu', onShowMoreMenu);
        } else {
            $('body').off('click.toggleExplorerMoreMenu');

            if ($explorerMoreMenu.find('.active:not(.hide)').length) {
                $explorerMoreMenu.addClass('active');
            }
        }
    }

    function onShowOptionMenu(evt) {
        DatePicker.Close();
        evt.stopPropagation();

        $explorerMoreMenu.children('.dropdown-menu').removeClass('open');

        if ($optionMenu.hasClass('active')) {
            $('body').off('click.toggleExplorerOptions');
            $optionMenu.removeClass('active');
            $optionMenu.children('.dropdown-menu').removeClass('open');
        } else {
            $('body').on('click.toggleExplorerOptions', onShowOptionMenu);
            $optionMenu.addClass('active');
            $optionMenu.children('.dropdown-menu').addClass('open');
        }
    }

    function onSelectOption(event) {
        var $this = $(this);
        var option = $this.data('option');

        $('body').off('click.toggleExplorerOptions');

        $optionMenu.removeClass('active');
        $optionMenu.children('.dropdown-menu').removeClass('open');

        if ($this.hasClass('dropdown-checkbox-item')) {
            $this.toggleClass('dropdown-checkbox-item-checked');
        }

        var withChildLocations = getWithChildLocations();
        var withDeactivatedElements = getWithDeactivatedElements()

        if (!withChildLocations || withDeactivatedElements) {
            $optionMenu.children('span').addClass('items-selected');
        } else {
            $optionMenu.children('span').removeClass('items-selected');
        }

        switch (option) {
            case 'showQRCode':
                if (!CurrentEntity) {
                    break;
                }

                Tools.QRCode.Show(CurrentEntity.QRCode || CurrentEntity.OID, CurrentEntity.Title);
                break;
            case 'withChildLocations':
                Tools.UpdateOfficeUserSettings('ExplorerWithChildLocations', withChildLocations)
                    .then(function () {
                        changeSelectedTab(lastMatch);
                    });
                break;
            case 'withDeactivatedElements':
                if (!CurrentEntity) {
                    break;
                }

                Tools.UpdateOfficeUserSettings('ExplorerWithDeactivatedElements', withDeactivatedElements)
                    .then(function () {
                        Center.Show();

                        if (withDeactivatedElements || CurrentEntity.Enabled) {
                            changeSelectedTab(lastMatch);
                        } else {
                            location.hash = '#' + Mode;
                        }
                    });
                break;
        }

        event.stopPropagation();
    }

    function isMenuItemAvailable(id) {
        if (!id) {
            return false;
        }

        return Tools.contains(availableMenuItems, id, 'ID');
    }

    function getMenuItemById(id) {
        if (!id) {
            return null;
        }

        return Tools.getFirst(availableMenuItems, id, 'ID');
    }

    function getColumnsSettingName() {
        var menuItem = getCurrentTab();

        if (!menuItem) {
            return;
        }

        var menuItemOID = menuItem.OID;

        return 'Explorer' + menuItemOID + 'ColumnIds';
    }

    function getWithChildLocations() {
        if ($withChildLocations.length) {
            return $withChildLocations.hasClass('dropdown-checkbox-item-checked');
        }

        var value = Tools.GetOfficeSettingValue('ExplorerWithChildLocations');

        return typeof value === 'boolean' ? value : true;
    }

    function getWithDeactivatedElements() {
        if ($withDeactivatedElements.length) {
            return $withDeactivatedElements.hasClass('dropdown-checkbox-item-checked');
        }

        var value = Tools.GetOfficeSettingValue('ExplorerWithDeactivatedElements');

        return typeof value === 'boolean' ? value : false;
    }

    function createOrganisationUnitLink(menuitemIdentifier, ouIdentifier) {
        if (!menuitemIdentifier || !ouIdentifier) {
            return null;
        }

        return '#explorer/' + menuitemIdentifier + '/' + ouIdentifier;
    }

    function createOrganisationUnitPath(assignedElementOID, elementCache) {
        if (!assignedElementOID) {
            return;
        }

        elementCache = elementCache || DataManager.OrganizationUnitLoader.Data.DataMap;

        var currentTab = Explorer.GetCurrentTab();
        var parent = elementCache[assignedElementOID];

        if (parent) {
            var locationPath;
            var locationLink;

            if (currentTab) {
                if (parent.Type === Enums.elementType.Root || parent.Type === Enums.elementType.Location) {
                    locationLink = createOrganisationUnitLink(currentTab.OID, assignedElementOID);
                }

                locationPath = !!locationLink ?
                    '<a href="' + locationLink + '">' + parent.Title + '</a>' :
                    parent.Title;

            } else {
                locationPath = parent.Title;
            }

            for (var i = 3; i > 0 && (parent = parent.Parent); i--) {
                if (currentTab &&
                    (parent.Type === Enums.elementType.Root || parent.Type === Enums.elementType.Location)) {
                    locationLink = createOrganisationUnitLink(currentTab.OID, parent.OID);
                }

                locationPath = !!locationLink ?
                    ('<a href="' + locationLink + '">' + parent.Title + '</a> &rsaquo; ' + locationPath) :
                    '{0} &rsaquo; {1}'.format(parent.Title, locationPath);
            }
        }

        return locationPath;
    }

    function setTabCounterLoading(menuItemIdentifier) {
        if (!menuItemIdentifier) {
            return;
        }

        $explorerTabMenu
            .find('.tab-view-tab[data-tab-id="{0}"]'.format(menuItemIdentifier))
            .addClass('loading');

        $explorerTabMenu
            .find('.dropdown-item[data-tab-id="{0}"]'.format(menuItemIdentifier))
            .addClass('loading');
    }

    function setTabCounter(menuItemIdentifier, count) {
        var counterText = Tools.GetBadgeCounterText(count);

        $explorerTabMenu.find('.tab-view-tab[data-tab-id="{0}"]'.format(menuItemIdentifier))
            .removeClass('loading')
            .toggleClass('small-counter-text', count >= 1000)
            .data('count', count)
            .attr('count', counterText);

        $explorerTabMenu
            .find('.dropdown-item[data-tab-id="{0}"]'.format(menuItemIdentifier))
            .removeClass('loading')
            .toggleClass('small-counter-text', count >= 1000)
            .data('count', count)
            .attr('count', counterText);
    }

    function abortCounterRequests() {
        if (!(counterDeferreds || []).length) {
            return;
        }

        counterDeferreds.forEach(function (deferred) {
            if (deferred.abort instanceof Function) {
                deferred.abort();
            }
        });
    }

    function resetExplorer() {
        availableMenuItems = null;
        currentTab = null;

        Explorer.Archive.ResetFilters();
        Explorer.Dashboard.ResetFilters();
        Explorer.Files.ResetFilters();
        Explorer.Issues.ResetFilters();
        Explorer.News.ResetFilters();
        Explorer.Scheduling.ResetFilters();
    }

    Explorer.Init = initExplorer;
    Explorer.Show = show;
    Explorer.ShowTab = showTab;
    Explorer.GetFirstAvailableMenuItem = getFirstAvailableMenuItem;
    Explorer.Resize = resize;
    Explorer.ResizeTabMenu = resizeTabMenu;
    Explorer.SetCurrentTabAsActive = setCurrentTabAsActive;
    Explorer.GetCurrentTab = getCurrentTab;
    Explorer.IsMenuItemAvailable = isMenuItemAvailable;
    Explorer.GetMenuItemById = getMenuItemById;
    Explorer.GetColumnsSettingName = getColumnsSettingName;
    Explorer.GetWithChildLocations = getWithChildLocations;
    Explorer.GetWithDeactivatedElements = getWithDeactivatedElements;
    Explorer.SetTabCounterLoading = setTabCounterLoading;
    Explorer.SetTabCounter = setTabCounter;
    Explorer.UpdateTabCounters = updateTabCounters;
    Explorer.CreateOrganisationUnitPath = createOrganisationUnitPath;
    Explorer.DisposeCurrentTab = disposeCurrentTab;
    Explorer.Reset = resetExplorer;

    return (global.Explorer = Explorer);
})( window );