/**
 * @require ./explorer.js
 */
(function ( global ) {
    var Files = {};

    /**
     * @constant {number}
     */
    var DEFAULT_TAKE = 50;
    var take = DEFAULT_TAKE;

    var filesLoaded;
    var files;
    var fileFormatFilter, fileFormats;
    var issueTypesFilter, issueTypes;

    var fileElementsCache;

    var FILE_SOURCE = {
        /**
         * Vorgänge
         */
        Issues: 1,
        /**
         * Zusatzbilder an Erfassungen
         */
        AdditionalRecordFiles: 2,
        /**
         * Erfasste Foto-Prüfpunkte
         */
        RecordedPhotoValues: 3
    };

    /**
     * @type {FILE_SOURCE}
     */
    var _currentSource = FILE_SOURCE.Issues;

    var $tree = $('#center-tree');
    var $explorerDashboard = $('#explorer-dashboard');
    var $explorerInfo = $('#explorer-info');
    var $explorerValues = $('#explorer-values');
    var $explorerNews = $('#explorer-news');
    var $explorerIssues = $('#explorer-issues');
    var $explorerDisturbances = $('#explorer-disturbances');
    var $explorerScheduling = $('#explorer-scheduling');
    var $explorerFiles = $('#explorer-files');
    var $tableView = $explorerFiles.find('.table-view');
    var $explorerArchive = $('#explorer-archive');
    var $properties = $('#properties');
    var $explorerTabMenu = $('#explorer-tab-menu');
    var $explorerOptionMenu = $('#explorer-tab-menu-options');
    var $listWrapper = $explorerFiles.find('.list-wrapper');
    var $listBody = $listWrapper.find('.list');
    var $resetFilters = $('#explorer-files-reset-filters');
    var $lblFileFormatFilter = $('#explorer-files-file-format-filter-lbl');
    var $fileFormatFilter = $('#explorer-files-file-format-filter');
    var $lblIssueTypeFilter = $('#explorer-issue-type-filter-lbl');
    var $issueTypeFilter = $('#explorer-issue-type-filter');
    var $btnLoadMoreFiles = $explorerFiles.find('.btn-load-files');
    var activeFilters = {
        FileFormat: false,
        IssueTypes: false
    };

    function show(match) {
        var initResult = Explorer.Init(match);

        if (!initResult.CurrentTabIsAvailable) {
            var currentTab = Explorer.GetCurrentTab();

            if (currentTab && initResult.NewTab && initResult.NewTab.OID !== currentTab.OID) {
                Explorer.ShowTab(initResult.NewTab);
                return;
            }
        }

        init();
        updateList();
    }

    function init() {
        initTab();
        initTake();

        fileElementsCache = {};
        filesLoaded = 0;
        files = [];

        $tree.tree(
            'remove-class-from-all-nodes',
            'jquery-tree-node-marker-green jquery-tree-node-marker-yellow jquery-tree-node-marker-red'
        );

        purgeFilesList();

        initLanguage();
        initFileFormats();
        initIssueTypes();
        initFilters();
        unbindEvents();
        bindEvents();

        resize();
    }

    function initTake() {
        // take auf einen Wert setzen, der die Galerie beim initialen Laden an Hand der aktuellen Abmessungen füllt
        // 150x150 entspricht einem Dummy-Wert für quadratische Bilder
        // fügt anschließend eine zusätzliche Zeile an Bildern hinzu
        var containerWidth = $explorerFiles.outerWidth(true);
        var containerHeight = $explorerFiles.outerHeight(true);
        var imageContainerArea = containerWidth * containerHeight;
        var dummyImageArea = 150 * 150;
        var fittedImagesCount = imageContainerArea / dummyImageArea;
        var extraRowItemsCount = containerWidth / 150;

        take = Math.ceil(fittedImagesCount + extraRowItemsCount);

        if (take < DEFAULT_TAKE) {
            take = DEFAULT_TAKE;
        }
    }

    function initTab() {
        $explorerDashboard.addClass('hide');
        $explorerInfo.addClass('hide');
        $explorerValues.addClass('hide');
        $explorerNews.addClass('hide');
        $explorerIssues.addClass('hide');
        $explorerDisturbances.addClass('hide');
        $explorerScheduling.addClass('hide');
        $explorerFiles.removeClass('hide');
        $explorerArchive.addClass('hide');
        $properties.removeClass('active');

        $explorerTabMenu.find('.open').removeClass('open');

        $explorerOptionMenu.removeClass('active');
    }

    function initLanguage() {
        $tableView.find('.lbl').text(i18next.t('explorer.files.sources.view'));
        $tableView.find('.tab[data-source="1"] .title').text(i18next.t('explorer.files.sources.issues.title'));
        $tableView.find('.tab[data-source="2"] .title').text(i18next.t('explorer.files.sources.additionalRecordFiles.title'));
        $tableView.find('.tab[data-source="3"] .title').text(i18next.t('explorer.files.sources.recordedPhotoValues.title'));

        $resetFilters.attr('title', i18next.t('explorer.toolbar.resetFilters'));
        $lblFileFormatFilter.text(i18next.t('explorer.files.fileFormatFilter.label'));
        $lblIssueTypeFilter.text(i18next.t('explorer.files.issueTypeFilter.label'));
        $btnLoadMoreFiles.text(i18next.t('explorer.files.loadMoreFiles'));
    }

    function initFileFormats() {
        fileFormats = [];

        fileFormats.push({ OID: '1', Title: i18next.t('explorer.files.fileFormatFilter.images') });
        fileFormats.push({ OID: '2', Title: i18next.t('explorer.files.fileFormatFilter.audio') });
    }

    function initIssueTypes() {
        issueTypes = [];

        issueTypes.push({ OID: '1-2', Title: i18next.t('explorer.files.issueTypeFilter.tasks') });
        issueTypes.push({ OID: '3-7', Title: i18next.t('explorer.files.issueTypeFilter.forms') });
        issueTypes.push({ OID: '4', Title: i18next.t('explorer.files.issueTypeFilter.notes') });
        issueTypes.push({ OID: '5', Title: i18next.t('explorer.files.issueTypeFilter.disturbances') });
        issueTypes.push({ OID: '6', Title: i18next.t('explorer.files.issueTypeFilter.inspections') });
        issueTypes.push({ OID: '8', Title: i18next.t('explorer.files.issueTypeFilter.investigations') });
    }

    function setFilterState() {
        var isActive = activeFilters.FileFormat;

        if (!isActive && _currentSource === FILE_SOURCE.Issues) {
            isActive = isActive || activeFilters.IssueTypes;
        }

        $resetFilters.toggleClass('active', isActive);
    }

    function initFilters() {
        initFileFormatFilter();
        initIssueTypeFilter();
    }

    function initFileFormatFilter() {
        var $btnTitle = $fileFormatFilter.find('.btn-title');
        var titles, text;

        titles = (fileFormatFilter || []).map(function (fileFormat) {
            return fileFormat.Title;
        });

        if (titles.length) {
            text = titles.length === fileFormats.length ?
                    i18next.t('explorer.files.fileFormatFilter.all') :
                    titles.sort().join(', ');

            $btnTitle.parent().addClass('filter-selected');
            activeFilters.FileFormat = true;
        } else {
            text = i18next.t('explorer.files.fileFormatFilter.noSelection');

            $btnTitle.parent().removeClass('filter-selected');
            activeFilters.FileFormat = false;
        }

        $btnTitle.html(text);
    }

    function initIssueTypeFilter() {
        var $btnTitle = $issueTypeFilter.find('.btn-title');
        var text;

        var titles = $.map(issueTypes, function (issueType) {
            if (Tools.contains(issueTypesFilter, issueType.OID, 'OID')) {
                return issueType.Title;
            }
        });

        if (titles.length) {
            text = titles.length === issueTypes.length ?
                    i18next.t('explorer.files.issueTypeFilter.all') :
                    titles.sort().join(', ');
            $btnTitle.parent().addClass('filter-selected');
            activeFilters.IssueTypes = true;
        } else {
            text = i18next.t('explorer.files.issueTypeFilter.noSelection');
            $btnTitle.parent().removeClass('filter-selected');
            activeFilters.IssueTypes = false;
        }

        $btnTitle.html(text);
    }

    function reset(refreshCounters) {
        refreshCounters = refreshCounters || false;

        filesLoaded = 0;
        files = [];

        RecorditemDetails.hide();

        initTake();
        setFilterState();
        purgeFilesList();

        if (refreshCounters) {
            updateList()
                .then(Explorer.UpdateTabCounters)
                .then(Explorer.ResizeTabMenu);

            return;
        }

        updateList();
    }

    function unbindEvents() {
        $tableView.off('click.selectTab');
        $listWrapper.off('scroll.infiniteScroll');
        $resetFilters.off('click.resetFilters');
        $fileFormatFilter.off('click.showFileFormatFilter');
        $issueTypeFilter.off('click.showIssueTypeFilter');
        $listBody.off('click.showFile');
        $listBody.off('click.showDetails');
        $explorerFiles.off('click.loadMoreFiles');
    }

    function bindEvents() {
        $tableView.on('click.selectTab', '.tab[data-source]', onSelectSourceItemClick);
        $resetFilters.on('click.resetFilters', onResetFilters);
        $fileFormatFilter.on('click.showFileFormatFilter', onShowFileFormatFilter);
        $issueTypeFilter.on('click.showIssueTypeFilter', onShowIssueTypeFilter);
        $listBody.on('click.showFile', '.file', onShowFile);
        $listBody.on('click.showDetails', '.list-item-details', onShowDetails);
        $explorerFiles.on('click.loadMoreFiles', '.btn-load-files', onBtnLoadMoreFilesClick);
    }

    function resize() {
        $explorerFiles.find('.table-control .resizable').css('max-width', 'none');

        $.each($explorerFiles.find('.table-control'), function (_, row) {
            var $row = $(row);
            var remainingWidth = $row.width();
            var resizableControls = [];

            $.each($row.children(), function (_, control) {
                var $control = $(control);
                var controlWidth;

                if ($control.hasClass('resizable')) {
                    controlWidth = $control.width() + 1;
                    remainingWidth -= $control.outerWidth(true) - controlWidth + 2;

                    resizableControls.push([ $control, controlWidth ]);
                } else {
                    remainingWidth -= $control.outerWidth(true);
                }
            });

            resizableControls.sort(function (a, b) { return a[1] - b[1]; });

            Tools.reduce(resizableControls, function (control, acc) {
                var $control = control[0];
                var controlWidth = control[1];
                var remainingWidth = acc[0];
                var controlCount = acc[1];
                var divider = Math.floor(remainingWidth / controlCount);

                if (controlWidth <= divider) {
                    remainingWidth -= controlWidth;
                } else {
                    $control.css('max-width', divider);
                    remainingWidth -= divider;
                }

                return [ remainingWidth, controlCount - 1 ];
            }, [ remainingWidth, resizableControls.length ]);
        });
    }

    function onBtnLoadMoreFilesClick() {
        if ($btnLoadMoreFiles.hasClass('disabled')) {
            return;
        }

        updateList();
    }

    function updateList() {
        $listWrapper.off('scroll.infiniteScroll');

        Tools.Spinner.show();
        $btnLoadMoreFiles.addClass('disabled');

        var params = createParams();

        return load(params)
            .then(loadMissingElements)
            .then(prepareFiles, function (xhr) {
                $btnLoadMoreFiles.removeClass('disabled');
                Tools.Spinner.hide();
                Tools.handleHttpError(Enums.HttpActionType.Read, xhr);
            })
            .then(appendFiles)
            .then(function (files) {
                $btnLoadMoreFiles.removeClass('disabled');

                if (!(files instanceof Array)) {
                    return null;
                }

                if (files.length === take) {
                    $listWrapper.on('scroll.infiniteScroll', onScroll);
                } else {
                    $btnLoadMoreFiles.remove();
                    $btnLoadMoreFiles = null;
                }

                // take zurücksetzen auf initialen Wert
                take = DEFAULT_TAKE;

                $listBody.find('img, image')
                    .off('error')
                    .on('error', Tools.OnImageNotFound);

                Tools.Spinner.hide();

                return files;
            });
    }

    function resetFilters() {
        $resetFilters.removeClass('active');
        fileFormatFilter = null;

        if (_currentSource === FILE_SOURCE.Issues) {
            issueTypesFilter = null;
        }
    }

    function onResetFilters() {
        resetFilters();
        initFilters();
        resize();
        reset(true);
    }

    function onShowFileFormatFilter() {
        var options = {
            title: i18next.t('explorer.files.fileFormatFilter.title'),
            onApply: onSelectFileFormatFilter,
            checkedEntities: fileFormatFilter,
            treeOptions: {
                schema: { id: 'OID', text: 'Title' },
                opened: true,
                checkbox: { fullrow: true }
            }
        };

        TreePicker.Show(fileFormats, options);
    }

    function onSelectFileFormatFilter(selectedFileFormats) {
        fileFormatFilter = (selectedFileFormats || []).map(function (oid) {
            return Tools.getFirst(fileFormats, oid, 'OID');
        });

        initFileFormatFilter();
        resize();
        reset(true);
    }

    function onShowIssueTypeFilter() {
        var options = {
            title: i18next.t('explorer.files.issueTypeFilter.title'),
            onApply: onSelectIssueTypeFilter,
            checkedEntities: issueTypesFilter,
            treeOptions: {
                schema: { id: 'OID', text: 'Title' },
                opened: true,
                checkbox: { fullrow: true }
            }
        };

        TreePicker.Show(issueTypes, options);
    }

    function onSelectIssueTypeFilter(selectedIssueTypes) {
        issueTypesFilter = (selectedIssueTypes || []).map(function (oid) {
            return Tools.getFirst(issueTypes, oid, 'OID');
        });

        initIssueTypeFilter();
        resize();
        reset(true);
    }

    function onShowFile(event) {
        var $this = $(this);
        var filename = $this.data('filename');
        var file = Tools.getFirst(files, filename, 'Filename');
        var altFilename;

        if (file) {
            if (/^image\//.test(file.MimeType)) {
                ImageViewer.Init({
                    filelist: files,
                    indexFilename: filename,
                    IssueOID: file.IsAssignedToIssue ? file.OriginOID : null,
                    IssueID: file.IsAssignedToIssue ? file.OriginID : null,
                    pTitle: 'OriginTitle',
                    pUserOID: 'UserOID',
                    pTimestamp: 'Date'
                });
            } else if (/^audio\//.test(file.MimeType)) {
                if (file.Metadata && Tools.contains(file.Metadata.AlternativeMimeTypes, 'audio/ogg')) {
                    altFilename = /\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/.exec(filename) + '.ogg';
                }

                var audioPlayerOptions = {
                    M4AFilename: filename,
                    OGGFilename: altFilename,
                    Title: file.Title
                };

                if (file.IsAssignedToIssue) {
                    audioPlayerOptions.RecordInformation = {
                        Issue: {
                            ID: file.OriginID,
                            Title: file.OriginTitle,
                            Type: file.OriginType
                        }
                    };
                }

                new Tools.Popups.AudioPlayer(audioPlayerOptions)
                    .Show();
            }
        }

        event.stopPropagation();
        return false;
    }

    function onShowDetails(event) {
        var $this = $(this);
        var type = $this.data('type');

        if (type === FILE_SOURCE.Issues) {
            var id = $this.data('id');

            IssueViewer.Show(id);
        } else {
            var oid = $this.data('oid');

            RecorditemDetails.show({
                OID: oid,
                $Target: $this,
                $Parent: $explorerFiles,
                $Container: $explorerFiles,
                IsReadonly: true
            });
        }

        event.stopPropagation();
        return false;
    }

    function onScroll() {
        var wrapperHeight = $listWrapper.innerHeight();
        var listHeight = $listBody.outerHeight(false);
        var scrollPosition = $listWrapper.scrollTop();

        if (listHeight - wrapperHeight - scrollPosition <= 300) {
            $listWrapper.off('scroll.infiniteScroll');

            updateList();
        }
    }

    function loadMissingElements(files) {
        var missingElements = [];

        (files || []).forEach(function (file) {
            if (!file.LocationOID) {
                return;
            }

            if (DataManager.OrganizationUnitLoader.Data.DataMap.hasOwnProperty(file.LocationOID) ||
                fileElementsCache.hasOwnProperty(file.LocationOID)) {
                return;
            }

            missingElements.push({ OID: file.LocationOID });
        });

        if (!missingElements.length) {
            return $.Deferred().resolve(files).promise();
        }

        return Tools.http.post('elements/', missingElements)
            .then(function (elements) {
                (elements || []).forEach(function (element) {
                    fileElementsCache[element.OID] = element;
                });

                return files;
            });
    }

    function prepareFiles(files) {
        filesLoaded = (filesLoaded || 0) + (files || []).length;

        return $.map(files || [], prepareFile);
    }

    function prepareFile(file) {
        var tmp;

        file.Timestamp = new Date(file.Timestamp);
        file.Metrics = {
            s: { width: 200, height: 200 },
            m: { width: 200, height: 200 },
            l: { width: 0, height: 0 },
            o: { width: 0, height: 0 }
        };

        if (file.Metadata) {
            if ((tmp = (file.Metadata.SizeS || '').split('x')).length === 2) {
                file.Metrics.s.width = parseInt(tmp[0], 10) || 200;
                file.Metrics.s.height = parseInt(tmp[1], 10) || 200;
            }

            if ((tmp = (file.Metadata.SizeM || '').split('x')).length === 2) {
                file.Metrics.m.width = parseInt(tmp[0], 10) || 200;
                file.Metrics.m.height = parseInt(tmp[1], 10) || 200;
            }

            if ((tmp = (file.Metadata.SizeL || '').split('x')).length === 2) {
                file.Metrics.l.width = parseInt(tmp[0], 10) || 0;
                file.Metrics.l.height = parseInt(tmp[1], 10) || 0;
            }

            if ((tmp = (file.Metadata.SizeO || '').split('x')).length === 2) {
                file.Metrics.o.width = parseInt(tmp[0], 10) || 0;
                file.Metrics.o.height = parseInt(tmp[1], 10) || 0;
            }
        }

        files.push(file);

        return file;
    }

    function appendFiles(files) {
        var html = [];

        if (!filesLoaded) {
            html = [
                '<div class="content-panel-row">',
                    '<div class="content-panel empty">',
                        '<p class="text">{0}</p>'.format(i18next.t('explorer.values.noDataAvailable')),
                    '</div>',
                '</div>'
            ];

            $listBody.html(html.join(''));
            return files;
        }

        html = $.map(files || [], renderFile);

        $listBody.find('.btn-load-files').before(html.join(''));

        return files;
    }

    function onSelectSourceItemClick() {
        var $this = $(this);

        if ($this.hasClass('selected')) {
            return;
        }

        $this
            .addClass('selected')
            .siblings()
            .removeClass('selected');

        _currentSource = $this.data('source');

        $resetFilters.toggleClass('hide', _currentSource === FILE_SOURCE.RecordedPhotoValues);
        $lblFileFormatFilter.toggleClass('hide', _currentSource === FILE_SOURCE.RecordedPhotoValues);
        $fileFormatFilter.toggleClass('hide', _currentSource === FILE_SOURCE.RecordedPhotoValues);

        $lblIssueTypeFilter.toggleClass('hide', _currentSource !== FILE_SOURCE.Issues);
        $issueTypeFilter.toggleClass('hide', _currentSource !== FILE_SOURCE.Issues);

        resize();
        reset(false);
    }

    function renderFile(file) {
        var html = [];

        html.push('<li class="list-item file" data-filename="{0}">'.format(file.Filename));

        if (/^audio\//.test(file.MimeType)) {
            html.push(renderMicrophone());
        } else if (file.Marks) {
            html.push(renderSvg(file));
        } else {
            html.push(renderImage(file));
        }

        html.push(renderDetails(file));

        html.push('</li>');

        return html.join('');
    }

    function renderMicrophone() {
        return '<img src="./img/microphone.svg">';
    }

    function renderImage(file) {
        return '<img src="{0}" crossorigin="use-credentials">'.format(Config.BaseUri + 'images/m/' + file.Filename);
    }

    function renderSvg(file) {
        var html = [];
        var viewboxRegex = /<svg[^>]*width="(\d+)"[^>]*height="(\d+)"[^>]*>(.*)<\/svg>/ig;
        var pathRegex = /<path [^>\/]+/ig;
        var paths = [];

        var match = viewboxRegex.exec(file.Marks);

        if (!match || match.length !== 4) {
            return;
        }

        var width = parseInt(match[1], 10);
        var height = parseInt(match[2], 10);
        var svgContent = match[3];

        do {
            if ((match = pathRegex.exec(file.Marks))) {
                paths.push(match[0].trim() + '></path>');
            }
        } while (match);

        if (paths.length) {
            html.push('<svg width="100%" height="100%" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 {0} {1}">'
                .format(width, height));
            html.push('<image xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="{0}/images/m/{1}" x="0" y="0" width="100%" height="100%" crossorigin="use-credentials"></image>'.format(Config.BaseUri, file.Filename));

            html.push(svgContent);
            html.push('</svg>');
        }

        return html.join('');
    }

    function renderDetails(file) {
        return file.IsAssignedToIssue ?
                renderIssueDetails(file) :
                renderRecorditemDetails(file);
    }

    function renderIssueDetails(file) {
        var abbr = Tools.GetIssueAbbreviation({ Type: file.OriginType });
        var title = file.OriginTitle || i18next.t('misc.untitled');

        return '<div class="list-item-details" data-type="1" data-oid="{0}" data-id="{1}">{2}{3} – {4}</div>'
            .format(file.OriginOID, file.OriginID, abbr, file.OriginID, Tools.escapeHtml(title));
    }

    function renderRecorditemDetails(file) {
        var element = DataManager.OrganizationUnitLoader.Data.DataMap[file.LocationOID];

        if (!element) {
            element = fileElementsCache[file.LocationOID];
        }

        if (!element) {
            element = { Title: i18next.t('misc.unknown') };
        }

        return '<div class="list-item-details" data-type="2" data-oid="{0}" data-id="{1}">{2}: {3}</div>'
            .format(
                file.OriginOID,
                file.OriginID,
                i18next.t('misc.recording'),
                Tools.escapeHtml(element.Title)
            );
    }

    function createParams() {
        var params = {
            Skip: filesLoaded || 0,
            Take: take,
            LocationOID: CurrentEntity.OID,
            WithChildlocations: Explorer.GetWithChildLocations(),
            WithDeactivatedElements: Explorer.GetWithDeactivatedElements(),
            OriginType: _currentSource
        };

        params.FileTypes = (fileFormatFilter || []).map(function (fileFormat) {
            return parseInt(fileFormat.OID, 10);
        });

        if (!params.FileTypes.length) {
            delete params.FileTypes;
        }

        if (_currentSource === FILE_SOURCE.Issues) {
            params.IssueTypes = [];

            (issueTypesFilter || []).forEach(function (issueType) {
                issueType.OID
                    .split('-')
                    .forEach(function (s) {
                        params.IssueTypes = Tools.addUnique(params.IssueTypes, parseInt(s, 10));
                    });
            });

            if (!params.IssueTypes.length) {
                delete params.IssueTypes;
            }
        }

        return params;
    }

    function load(params) {
        return Tools.http.post('reports/files', params);
    }

    function purgeFilesList() {
        $listBody.empty();
        $listBody.append('<li class="btn btn-load-files">{0}</li>'
            .format(i18next.t('explorer.files.loadMoreFiles')));

        $btnLoadMoreFiles = $explorerFiles.find('.btn-load-files');
    }

    function disposeTab() {
        fileElementsCache = null;

        purgeFilesList();
        RecorditemDetails.hide();
    }

    Files.Show = show;
    Files.Resize = resize;
    Files.ResetFilters = resetFilters;
    Files.Dispose = disposeTab;

    return (global.Files = Files);
})( window.Explorer || (window.Explorer = {}) );